namespace Umbrella.CustomerService {
    import ActivityModel = Modules.ActivityModel;
    import TaskTransferredActivityModel = Modules.Tasks.TaskTransferredActivityModel;
    import TaskCreatedActivityModel = Modules.Tasks.TaskCreatedActivityModel;
    import TaskActivityModel = Modules.Tasks.TaskActivityModel;
    import TaskRejectedActivityModel = Modules.Tasks.TaskRejectedActivityModel;
    import CustomSelfServiceReportCreatedActivityModel = Umbrella.Modules.SelfService.Custom.CustomSelfServiceReportCreatedActivityModel;

    @Pipe('CustomerService', 'activityTitle')
    @Inject('$filter')
    class ActivityTitleFilter {
        constructor(private $filter) { }

        public transform(activity): string {
            if (activity.task) return this.getActivityName(activity);

            if (activity.case && activity.case.faq) return activity.case.faq.question;

            return this.getActivityName(activity);
        }

        private getActivityName(activity: ActivityModel): string {
            const activityName = activity.type.toLowerCase();
            switch (activityName) {
                case 'billpaymentactivity':
                    return 'Betaaltransactie';
                case 'changepaymentmethodreportcreatedactivity':
                    return 'Betalingswijze aangepast';
                case 'complaintsubmittedactivity':
                    return 'Klacht gemeld';
                case 'complimentsubmittedactivity':
                    return 'Compliment gemeld';
                case 'contactactivity':
                    return 'Contactmoment vastgelegd';
                case 'correspondenceactivity':
                case 'documentactivity':
                    return 'Document vastgelegd';
                case 'disturbancesubmittedactivity':
                case 'disturbancereportsubmittedactivity':
                    return 'Overlast gemeld';
                case 'houseadjustmentactivity':
                case 'houseadjustmentreportsubmittedactivity':
                case 'houseadjustmentreportcreatedactivity':
                    return 'Woningaanpassing';
                case 'initialinspectionreportsubmittedactivity':
                    return 'Voorinspectie uitgevoerd';
                case 'initialinspectionreportworkorderscreatedactivity':
                    return 'Werkbonnen aangemaakt';
                case 'finalinspectionreportsubmittedactivity':
                    return 'Eindinspectie uitgevoerd';
                case 'terminatedcontractpreinspectionappointmentdeletedactivity':
                    return 'Voorinspectie afspraak verwijderd';
                case 'terminatedcontractpostinspectionappointmentdeletedactivity':
                    return 'Eindinspectie afspraak verwijderd';
                case 'outgoingmailactivity':
                case 'outgoingsmsactivity':
                    return 'Bericht verzonden';
                case 'phonecallactivity':
                    return 'Telefoongesprek gevoerd';
                case 'paymentarrangementreportcreatedactivity':
                    return 'Betalingsregeling getroffen';
                case 'stateofcommencementreportsubmittedactivity':
                    return 'Staat van aanvang opgesteld';
                case 'joinservicefundreportsubmittedactivity':
                    return 'Service abonnement(en) afgesloten';
                case 'repairrequestcanceledactivity':
                    return 'Reparatieverzoek geannuleerd';
                case 'repairrequestcompletedactivity':
                    return 'Reparatieverzoek afgerond';
                case 'repairrequestmodifiedactivity':
                    return 'Reparatieverzoek gewijzigd';
                case 'repairrequestreopenedactivity':
                    return 'Reparatieverzoek heropend';
                case 'repairrequestsubmittedactivity':
                    return 'Reparatieverzoek ingediend';
                case 'repairrequestappointmentdeletedactivity':
                    return 'Reparatieverzoek afspraak verwijderd';
                case 'repairrequestappointmentmodifiedactivity':
                    return 'Reparatieverzoek afspraak gewijzigd';
                case 'personaldatachangedactivity':
                    return 'Persoonlijke gegevens bijgewerkt';
                case 'taakaangemaaktactivity':
                case 'taskcreatedactivity':
                    return `${this.getTaskType(<TaskActivityModel>activity)} ${this.getCompleteBy(<
                        TaskCreatedActivityModel
                        >activity)}`;
                case 'taakgewijzigdactivity':
                case 'taskdetailsmodifiedactivity':
                    return 'Taak gewijzigd';
                case 'taakverwijderdactivity':
                case 'taskdeletedactivity':
                    return 'Taak verwijderd';
                case 'taakdiscussieactivity':
                case 'taskdiscussionactivity':
                    return 'Opmerking';
                case 'taakvoltooidactivity':
                case 'taskfinishedactivity':
                    return 'Taak afgerond';
                case 'taakaangenomenactivity':
                case 'taskpickedupactivity':
                    return 'Taak opgepakt';
                case 'taakgeweigerdactivity':
                case 'taskrejectedactivity':
                    return `Taak geweigerd en terug gegeven aan ${this.getNextOwner(<TaskRejectedActivityModel>(
                        activity
                    ))}`;
                case 'taakovergenomenactivity':
                case 'tasktakenoveractivity':
                    return 'Taak overgenomen';
                case 'taakovergedragenactivity':
                case 'tasktransferredactivity':
                    return `Taak overgedragen aan ${this.getNewOwner(<TaskTransferredActivityModel>activity)}`;
                case 'taskanonymouslinkedtocontractactivity':
                case 'taskanonymouslinkedactivity':
                    return 'Taak gekoppeld aan relatie';
                case 'terminatecontractreportcreatedactivity':
                    return 'Huur opgezegd';
                case 'customselfservicereportcreatedactivity':
                    return (
                        this.getReportTitle(<CustomSelfServiceReportCreatedActivityModel>activity) ||
                        'Selfservice scenario afgerond'
                    );
                case 'customselfservicechangedmessageactivity':
                    return 'Bericht';
                case 'caseflowstartedactivity':
                    return 'Zaak aangemaakt';
                case 'caseflowreviewedactivity':
                    return 'Zaak beoordeeld';
                case 'caseflowsteppickedupactivity':
                    return 'Zaakstap opgepakt';
                case 'caseflowstepfinishedactivity':
                    return 'Zaakstap afgerond';
                case 'caseflowcancelledactivity':
                    return 'Zaak geannuleerd';
                case 'mediaaddedactivity':
                    return 'Document toegevoegd';
                case 'mediadeletedactivity':
                    return 'Document verwijderd';
                case 'activitydeletedactivity':
                    return 'Gebeurtenis verwijderd';
                case 'activityrestoredactivity':
                    return 'Gebeurtenis hersteld';
                case 'casedeletedactivity':
                    return 'Zaak verwijderd';
                case 'caserestoredactivity':
                    return 'Zaak hersteld';
                case 'accountactivatedactivity':
                    return 'Account geactiveerd';
                case 'accountemailmodifiedactivity':
                    return 'Account e-mailadres aangepast';
                case 'accountcreatedactivity':
                    return 'Account aangemaakt';
                case 'accountactivationstartedactivity':
                    return 'Account activatie gestart';
                case 'accountrecoverystartedactivity':
                    return 'Herstel verificatie token aangevraagd';
                case 'accountrestoreactivity':
                    return 'Wachtwoord herstel aangevraagd';
                case 'customerscorecreatedactivity':
                    return 'Enquête klanttevredenheid afgenomen';
                case 'personaltimelineexportingactivity':
                    return 'Exporteren van de tijdlijn gestart';
                case 'personaltimelineexportedactivity':
                    return 'Exporteren van de tijdlijn voltooid';
                default:
                    return 'Zaak aangemaakt';
            }
        }

        private getTaskType(activity: TaskActivityModel) {
            if (activity && activity.task && activity.task.taskType.name) return activity.task.taskType.name;
            else return 'Taak';
        }

        private getReportTitle(activity: CustomSelfServiceReportCreatedActivityModel): string | null {
            return activity && activity.report && activity.report.title;
        }

        private getCompleteBy(activity: TaskCreatedActivityModel): string {
            if (
                !activity ||
                (!activity.completeBy && !(activity.completeByFunctiongroup && activity.completeByFunctiongroup.length))
            )
                return '';

            let result = '';
            if (activity.completeBy) result += activity.completeBy.name;
            if (activity.completeBy && activity.completeByFunctiongroup && activity.completeByFunctiongroup.length)
                result += ' van ';
            if (activity.completeByFunctiongroup && activity.completeByFunctiongroup.length)
                result += activity.completeByFunctiongroup[0].title;

            return result.length ? `voor ${result}` : result;
        }

        private getNewOwner(activity: TaskTransferredActivityModel): string {
            const newOwner = activity.newOwner ? activity.newOwner.name : '';
            const newFunctiongroup =
                activity.newFunctiongroup && activity.newFunctiongroup.length ? activity.newFunctiongroup[0].title : '';
            const assignedTo =
                newOwner.length && newFunctiongroup.length
                    ? `${newOwner} van ${newFunctiongroup}`
                    : newOwner.length
                        ? `${newOwner}`
                        : `${newFunctiongroup}`;
            return assignedTo;
        }

        private getNextOwner(activity: TaskRejectedActivityModel): string {
            const newOwner = activity.nextOwner ? activity.nextOwner.name : '';
            const newFunctiongroup =
                activity.nextFunctiongroup && activity.nextFunctiongroup.length
                    ? activity.nextFunctiongroup[0].title
                    : '';
            const assignedTo =
                newOwner.length && newFunctiongroup.length
                    ? `${newOwner} van ${newFunctiongroup}`
                    : newOwner.length
                        ? `${newOwner}`
                        : `${newFunctiongroup}`;
            return assignedTo;
        }
    }
}
