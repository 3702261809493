/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.CustomerCard {
    @Service('CustomerService', 'CustomerCardCustomerInfoService')
    @Inject('CustomerResource', 'CustomerCardStore')
    export class CustomerCardCustomerInfoService {
        constructor(
            private customerResource: Modules.Customers.ICustomerResource,
            private customerCardStore: CustomerCardStore
        ) {
            const customerId = (x: CustomerCardState) =>
                x &&
                x.personal &&
                x.personal.person &&
                x.personal.person.roles &&
                (<any>x.personal.person.roles).customer;

            this.customerCardStore.state$
                .map(customerId)
                .distinctUntilChanged()
                .subscribe(customerId => {
                    if (customerId != null)
                        this.load(customerId);
                    else
                        this.remove();
                });
        }

        public remove(): void {
            this.emit({
                type: 'CustomerCardCustomerLoadedEvent',
                customer: null
            });
        }

        public load(id: System.Guid): void {
            this.emit({ type: 'CustomerCardCustomerLoadingEvent', id });

            this.customerResource
                .getDetailed({ id })
                .$promise.then(customer => {
                    this.emit({
                        type: 'CustomerCardCustomerLoadedEvent',
                        customer
                    });
                })
                .catch(e =>
                    this.emit({
                        type: 'CustomerCardCustomerLoadErrorEvent',
                        error: e
                    })
                );
        }

        private emit(e: CustomerCardEvent) {
            this.customerCardStore.event$.onNext(e);
        }
    }
}
