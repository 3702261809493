﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../ComponentRouteUtils.ts" />

namespace Umbrella.CustomerService.CustomerCard.Impersonation {
    import ChannelType = Modules.ChannelType;

    export interface ImpersonationWindowState {
        personId: System.Guid;
        scenarioId: System.Guid;
        caseId?: System.Guid;
        caseFlowId?: System.Guid;
        channel: ChannelType;
    }

    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('customerservice.customercard.impersonation', {
                url: '/impersonation/:scenarioId?caseId&caseFlowId',
                reloadOnSearch: false,
                views: {
                    'tab@customerservice': {
                        template:
                            '<impersonation-window state="state$ | async:this"></impersonation-window>',
                        controller: createComponentRouteController<
                            CustomerCardState,
                            ImpersonationWindowState
                        >('CustomerCardStore', s => ({
                            personId:
                                s.personal &&
                                s.personal.person &&
                                s.personal.person.id,
                            scenarioId:
                                s &&
                                s.activityRegistrationInfo &&
                                s.activityRegistrationInfo.selectedScenario &&
                                s.activityRegistrationInfo.selectedScenario.id,
                            channel:
                                (s &&
                                    s.activityRegistrationInfo &&
                                    s.activityRegistrationInfo
                                        .selectedChannelType) ||
                                ChannelType.Unknown
                        }))
                    }
                },
                onEnter: [
                    'ImpersonationService',
                    '$stateParams',
                    (
                        impersonationService: ImpersonationService,
                        $stateParams: ImpersonationWindowState
                    ) => {
                        const stateParamsSet = !!(
                            $stateParams.personId &&
                            $stateParams.scenarioId &&
                            $stateParams.channel.toString()
                        );

                        if (stateParamsSet)
                            impersonationService.updateImpersonationState(
                                $stateParams.scenarioId,
                                $stateParams.personId,
                                $stateParams.caseFlowId
                            );
                    }
                ],
                onExit: [
                    'ImpersonationService',
                    (impersonationService: ImpersonationService) => {
                        impersonationService.leave();
                    }
                ]
            });
        }
    ]);
}
