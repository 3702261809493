namespace Umbrella.Modules.Chat {
    import IResource = ng.resource.IResource;

    export interface IAvatarResourceProvider {
        getBlob(params: { id: System.Guid }): IResource<any>;
        getDefaultPhoto(): IResource<any>;
        getInlinePhoto(params: {
            id: System.Guid;
            useDefault: boolean;
        }): IResource<any>;
        getInlineDefaultPhoto(): IResource<any>;
    }

    angular.module('Chat').service('AvatarResourceProvider', [
        '$resource',
        $resource =>
            $resource(
                '/api/v1/colleague/photo/:action/:subaction/:id',
                {},
                {
                    getBlob: {
                        method: 'GET',
                        isArray: false,
                        params: { action: '', id: '@id', width: 40, height: 40 }
                    },
                    getDefaultPhoto: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'default' }
                    },
                    getInlinePhoto: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'inline',
                            id: '@id',
                            width: 40,
                            height: 40
                        }
                    },
                    getInlineDefaultPhoto: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'inline', subaction: 'default' }
                    }
                }
            )
    ]);
}
