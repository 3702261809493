namespace Umbrella.CustomerService {
    import ActivityModel = Modules.ActivityModel;

    @Pipe('CustomerService', 'activityLogUrl')
    class ActivityLogUrlFilter {
        public transform(activity: ActivityModel) {
            if (!activity || !activity.startTime) return '';

            return this.getLogUrlByTimespan(activity.startTime);
        }

        private getLogUrlByTimespan(startTime: Date): string {
            const timespanInMinutes = 1;
            let date = new Date(startTime);
            return (
                Config.getManagementLogRelativeUrl() +
                '&since=' +
                this.transformDateToUrlString(date) +
                '&until=' +
                this.transformDateToUrlString(date.addMinutes(timespanInMinutes))
            );
        }

        private transformDateToUrlString(date: Date): string {
            return (
                date.getFullYear() +
                '-' +
                (date.getUTCMonth() + 1) +
                '-' +
                date.getDate() +
                '%20' +
                date.getHours() +
                ':' +
                (date.getMinutes() < 10 ? '0' : '') +
                date.getMinutes()
            );
        }
    }
}
