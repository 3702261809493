namespace Umbrella.Modules.Knowledgebase {
    @Service('Knowledgebase', 'FaqService')
    @Inject('EmptyGuid')
    export class FaqService {
        constructor(private emptyGuid: System.Guid) {}

        createNewFollowUpQuestion(
            question: string,
            answer: string,
            name: string,
            index: number,
            parent: FollowupQuestionModel
        ): FollowupQuestionModel {
            return {
                id: this.emptyGuid,
                parent,
                question,
                answer,
                icon: null,
                value: null,
                name,
                index,
                parentId: parent ? parent.id : null,
                options: []
            };
        }

        createEmptyFaq(applicationIds: System.Guid[] = []): AdminFaqModel {
            return <AdminFaqModel>{
                //Update
                applicationIds,
                media: [],
                followupQuestion: null,
                targetGroups: [],

                //FaqModel
                additionalInternalInfo: '',
                mainTag: null,
                tags: [],
                synonyms: [],

                //SimpleFaqModel
                answer: '',
                author: null,
                created: new Date(),
                faqType: {
                    name: FaqType.Default.toString(),
                    value: FaqType.Default
                },
                lastModified: null,
                lastModifiedBy: null,
                lastPublished: null,
                question: '',
                deletion: null,
                status: PublicationStatus.Concept
            };
        }
        createEmptyFollowUpQuestion(): FollowupQuestionModel {
            return this.createNewFollowUpQuestion('', '', '', 0, null);
        }
        createEmptyFollowUpQuestionLinkedToParent(
            parent: FollowupQuestionModel
        ): FollowupQuestionModel {
            return this.createNewFollowUpQuestion('', '', '', 0, parent);
        }
    }
}
