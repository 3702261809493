angular.module('Housing').filter('contractstatus', function() {
    'use strict';

    return function(val) {
        switch (val) {
            case 'Future':
                return 'Toekomstig';
            case 'Active':
                return 'Lopend';
            case 'Expired':
                return 'Beëindigd';
            case 'Cancelled':
                return 'Geannuleerd';
            default:
                return '';
        }
    };
});
