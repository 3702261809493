/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../Scripts/TypeScript/rxjs/rx.lite.es6.d.ts"/>
/// <reference path="../RootStore.ts"/>

namespace Umbrella {
    import PhoneStatsModel = Umbrella.TelephonyControl.Statistics.PhoneStatsModel;
    import ChatConversation = Umbrella.ChatConversationHandling.ChatConversation;
    import RoleModel = Umbrella.Modules.RoleModel;
    import RoleModelDictionary = Umbrella.Modules.Telephony.RoleModelDictionary;
    import isPendingConversation = ChatConversationHandling.isPendingConversation;
    import SelfServiceScenarioModel = Umbrella.Modules.SelfService.SelfServiceScenarioModel;

    export namespace Impersonation {
        export interface EnterEvent {
            readonly type: 'ImpersonationEnterEvent';
            readonly person: PersonModel;
            readonly scenario: SelfServiceScenarioModel;
            readonly caseFlowId?: System.Guid;
        }

        export interface LeaveEvent {
            readonly type: 'ImpersonationLeaveEvent';
        }

        export interface FullScreenSwitchEvent {
            readonly type: 'ImpersonationFullScreenSwitchEvent';
            readonly enabled: boolean;
        }

        export interface State {
            readonly enabled: boolean;
            readonly person: PersonModel;
            readonly scenario: SelfServiceScenarioModel;
            readonly caseFlowId?: System.Guid;
            readonly fullscreenMode: boolean;
        }

        export function set(s: TopBarState, replacement: State): TopBarState {
            return {
                impersonationInfo: replacement,
                telephonyInfo: s && s.telephonyInfo
                // chatInfo: s && s.chatInfo
            };
        }
    }

    export namespace Telephony {
        export interface ChangedEvent {
            readonly type: 'TelephonyChangedEvent';
            readonly phone: Umbrella.TelephonyControl.PhoneModel;
            readonly availabilityStatus: Umbrella.TelephonyControl.AvailabilityStatus;
        }

        export interface PhoneCallRolesChangedEvent {
            readonly type: 'PhoneCallRolesChangedEvent';
            readonly phoneCallRoleModel: Umbrella.TelephonyControl.PhoneCallRoleModel;
        }

        export interface TelephonyRoleModelRetrievedEvent {
            readonly type: 'TelephonyRoleModelRetrievedEvent';
            readonly role: RoleModel;
        }

        export interface AvailabilityChangedEvent {
            readonly type: 'TelephonyAvailabilityChangedEvent';
            readonly availabilityStatus: Umbrella.TelephonyControl.AvailabilityStatus;
        }

        export interface RingingEvent {
            readonly type: 'TelephonyCallRingingEvent';
            readonly call: Umbrella.TelephonyControl.PhoneCallModel;
            readonly phone: Umbrella.TelephonyControl.PhoneModel;
        }

        export interface RingingStoppedEvent {
            readonly type: 'TelephonyCallRingingStoppedEvent';
            readonly phone: Umbrella.TelephonyControl.PhoneModel;
        }

        export interface LoggedOutEvent {
            readonly type: 'TelephonyLoggedOutEvent';
            readonly userId: System.Guid;
            readonly phone: Umbrella.TelephonyControl.PhoneModel;
        }

        export interface LineClosedEvent {
            readonly type: 'TelephonyLineClosedEvent';
            readonly call: Umbrella.TelephonyControl.PhoneCallModel;
            readonly phone: Umbrella.TelephonyControl.PhoneModel;
        }

        export interface ErrorEvent {
            readonly type: 'TelephonyErrorEvent';
            readonly phone: Umbrella.TelephonyControl.PhoneModel;
            readonly error: any;
        }

        export interface StatisticsChangedEvent {
            readonly type: 'TelephonyStatisticsChangedEvent';
            readonly statistics: {
                queueTime: number;
                queueLength: number;
                queueLengthKpiState: Umbrella.TelephonyControl.Statistics.TelephonyQueueKpiState;
                queueTimeRequiresAttentionCap: number;
                queueTimeCriticalCap: number;
            };
        }

        export interface State {
            readonly statistics: PhoneStatsModel;
            readonly availabilityStatus: Umbrella.TelephonyControl.AvailabilityStatus;
            readonly phone: Umbrella.TelephonyControl.PhoneModel;
            readonly incomingCall: Umbrella.TelephonyControl.PhoneCallModel;
            readonly error: any;
            readonly rolesCache: RoleModelDictionary;
        }

        export function set(s: TopBarState, replacement: State): TopBarState {
            return {
                ...s,
                telephonyInfo: replacement
            };
        }
    }

    export type TopBarEvent =
        | Telephony.ChangedEvent
        | Telephony.PhoneCallRolesChangedEvent
        | Telephony.TelephonyRoleModelRetrievedEvent
        | Telephony.AvailabilityChangedEvent
        | Telephony.ErrorEvent
        | Telephony.RingingEvent
        | Telephony.RingingStoppedEvent
        | Telephony.LoggedOutEvent
        | Telephony.LineClosedEvent
        | Telephony.StatisticsChangedEvent
        | Impersonation.EnterEvent
        | Impersonation.LeaveEvent
        | Impersonation.FullScreenSwitchEvent;

    export interface TopBarState {
        readonly telephonyInfo: Telephony.State;
        readonly impersonationInfo: Impersonation.State;
    }

    function applyPhoneCallRoleChange(
        phoneModel: Umbrella.TelephonyControl.PhoneModel,
        phoneCallRoleModel: Umbrella.TelephonyControl.PhoneCallRoleModel
    ) {
        for (const phoneCall of phoneModel.calls) {
            if (phoneCall.phoneCallRegistrationId === phoneCallRoleModel.phoneCallRegistrationId) {
                phoneCall.candidateRoleIds = phoneCallRoleModel.candidateRoleIds;
                phoneCall.subjectRoleId = phoneCallRoleModel.subjectRoleId;
            }
        }

        return phoneModel;
    }

    function addRoleToCache(rolesCache: RoleModelDictionary, roleModel: RoleModel) {
        if (!rolesCache) rolesCache = {};

        if (roleModel && roleModel.id) rolesCache[roleModel.id.toString()] = roleModel;

        return rolesCache;
    }

    function reduce(e: TopBarEvent): (s: TopBarState) => TopBarState {
        switch (e.type) {
            case 'TelephonyChangedEvent':
                return s =>
                    Telephony.set(s, {
                        ...(s && s.telephonyInfo),
                        error: null,
                        phone: e.phone,
                        availabilityStatus: e.availabilityStatus
                    });

            case 'TelephonyAvailabilityChangedEvent':
                return s =>
                    Telephony.set(s, {
                        ...(s && s.telephonyInfo),
                        availabilityStatus: e.availabilityStatus
                    });

            case 'TelephonyRoleModelRetrievedEvent':
                return s =>
                    Telephony.set(s, {
                        ...(s && s.telephonyInfo),
                        rolesCache: addRoleToCache(s.telephonyInfo.rolesCache, e.role)
                    });

            case 'PhoneCallRolesChangedEvent':
                return s =>
                    Telephony.set(s, {
                        ...(s && s.telephonyInfo),
                        error: null,
                        phone: applyPhoneCallRoleChange(s.telephonyInfo.phone, e.phoneCallRoleModel)
                    });

            case 'TelephonyCallRingingEvent':
                return s =>
                    Telephony.set(s, {
                        ...(s && s.telephonyInfo),
                        error: null,
                        phone: e.phone,
                        incomingCall: e.call
                    });

            case 'TelephonyCallRingingStoppedEvent':
                return s =>
                    Telephony.set(s, {
                        ...(s && s.telephonyInfo),
                        error: null,
                        phone: e.phone,
                        incomingCall: null
                    });

            case 'TelephonyLineClosedEvent':
                return s =>
                    Telephony.set(s, {
                        ...(s && s.telephonyInfo),
                        error: null,
                        phone: e.phone,
                        incomingCall: null
                    });

            case 'TelephonyLoggedOutEvent':
                return s =>
                    Telephony.set(s, {
                        ...(s && s.telephonyInfo),
                        error: null,
                        phone: e.phone,
                        incomingCall: null
                    });

            case 'TelephonyErrorEvent':
                return s =>
                    Telephony.set(s, {
                        ...(s && s.telephonyInfo),
                        phone: e.phone,
                        error: e.error,
                        incomingCall: null
                    });

            case 'TelephonyStatisticsChangedEvent':
                return s =>
                    Telephony.set(s, {
                        ...(s && s.telephonyInfo),
                        statistics: e.statistics
                    });

            case 'ImpersonationEnterEvent':
                return s =>
                    Impersonation.set(s, {
                        enabled: true,
                        person: e.person,
                        scenario: e.scenario,
                        caseFlowId: e.caseFlowId,
                        fullscreenMode: false
                    });

            case 'ImpersonationLeaveEvent':
                return s =>
                    Impersonation.set(s, {
                        enabled: false,
                        person: null,
                        scenario: null,
                        caseFlowId: null,
                        fullscreenMode: false
                    });

            case 'ImpersonationFullScreenSwitchEvent':
                return s =>
                    Impersonation.set(s, {
                        ...(s && s.impersonationInfo),
                        fullscreenMode: e.enabled
                    });
        }
    }

    export type TopBarStore = ObservableStore.EventStore<TopBarState, TopBarEvent>;

    export const topBarStore: TopBarStore = rootStore.map<TopBarState, TopBarEvent>(
        lens<TopBarState>('TopBar'),
        reduce
    );

    angular.module('Umbrella').value('TopBarStore', topBarStore);
}
