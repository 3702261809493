namespace Umbrella.Modules.Knowledgebase {
    import IResource = ng.resource.IResource;
    import SmartlabelModel = Umbrella.Smartlabels.SmartlabelModel;

    export interface ISmartlabelResource {
        getById(params: IdRequestParams): IResource<SmartlabelModel>;
        getAll(
            params: PagedRequestParams
        ): IResource<PagedItemsModel<SmartlabelModel>>;
        findByName(
            params: FindRequestParams
        ): IResource<PagedItemsModel<SmartlabelModel>>;

        save(model: SmartlabelModel): IResource<SmartlabelModel>;
        update(model: SmartlabelModel): IResource<SmartlabelModel>;
        delete(params: IdRequestParams): IResource<void>;
        deleteWithIdsIn(params: { ids: System.Guid[] }): IResource<void>;

        getUsages(
            params: IdRequestParams
        ): IResource<SmartlabelDocumentUsageModel[]>;
    }

    angular.module('Knowledgebase').factory('SmartlabelResource', [
        '$resource',
        ($resource: ng.resource.IResourceService) =>
            $resource(
                '/api/v1/smartlabels/:action/:id',
                {},
                {
                    getAll: {
                        method: 'GET',
                        isArray: false,
                        params: { action: '', page: 0, pageSize: 10 }
                    },
                    getRoot: {
                        method: 'GET',
                        isArray: false,
                        params: { action: '', page: 0, pageSize: 10 }
                    },
                    findByName: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'Find',
                            id: '',
                            index: 0,
                            page: 0,
                            pageSize: 25,
                            mediaUrl: Config.makeAbsoluteApiUrl('api/v1/media/view/')
                        }
                    },
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { action: '', id: '' }
                    },
                    getUsages: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'usages', id: '@id' }
                    },
                    save: {
                        method: 'POST',
                        isArray: false,
                        params: { action: '' }
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: { action: '' }
                    },
                    deleteWithIdsIn: {
                        method: 'DELETE',
                        isArray: false,
                        params: { action: 'deletewithidsin' }
                    }
                }
            )
    ]);
}
