namespace Umbrella.Modules.Announcements {
    export interface IAnnouncementsResource
        extends angular.resource.IResource<AnnouncementModel> {}

    export interface PersonAnnouncementResourceClass
        extends angular.resource.IResourceClass<IAnnouncementsResource> {
        getById(params: PagedRequestWithIdParams): IAnnouncementsResource;
        getPagedByPersonId(
            params: PagedRequestWithIdParams
        ): angular.resource.IResource<PagedItemsModel<AnnouncementModel>>;
    }

    angular.module('Umbrella').service('PersonAnnouncementResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) => {
            return $resource(
                '/api/v1/announcement/:action/:id',
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getPagedByPersonId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'person', id: '' }
                    }
                }
            );
        }
    ]);
}
