﻿namespace Umbrella.CustomerService {
    import FaqResourceClass = Umbrella.Modules.Knowledgebase.Faq.FaqResourceClass;
    import FaqModel = Umbrella.Modules.Knowledgebase.FaqModel;
    import AdminFaqModel = Umbrella.Modules.Knowledgebase.AdminFaqModel;
    import ThesaurusTagModel = Umbrella.Modules.Knowledgebase.ThesaurusTagModel;
    import ThesaurusResource = Umbrella.Modules.Knowledgebase.ThesaurusResource;
    import debounce = Umbrella.Helpers.debounce;
    import FaqTargetGroupModel = Umbrella.Modules.Knowledgebase.FaqTargetGroupModel;
    import PublicationStatus = Umbrella.Modules.Knowledgebase.PublicationStatus;
    import GetEnumValueAsNumber = Umbrella.Helpers.GetEnumValueAsNumber;
    import TargetGroupModel = Umbrella.Modules.Knowledgebase.TargetGroupModel;

    interface OnSelectedParam {
        faq: FaqModel;
        tag?: ThesaurusTagModel;
        targetGroupId?: System.Guid;
        followUpQuestionId?: System.Guid;
    }

    export interface ButtonDefinition {
        name: string;
        primary: boolean;
        onExecute: Function;
    }

    export interface KnowledgebaseSuggestionsInput {
        basedOn: KnowledgebaseSuggestionsBasedOn;
        query: string;
    }

    export enum KnowledgebaseSuggestionsBasedOn {
        Chat = 0,
        Email
    }

    @Component('CustomerService', {
        selector: 'knowledgebase',
        templateUrl: 'CustomerService/KnowledgebaseComponent/Knowledgebase.html',
        bindings: {
            searchQuery: '@',
            suggestionsQuery: '<',
            faqActions: '<',
            onFaqSelected: '&',
            onClose: '&',
            subscribedTargetgroups: '<'
        }
    })
    @Inject('$mdDialog', 'FaqResource', 'ThesaurusResource', 'LocalStorageService', 'TargetGroupResource')
    export class KnowledgebaseComponent {
        public searchQuery: string;
        public suggestionsQuery: KnowledgebaseSuggestionsInput;
        public onFaqSelected: (params: OnSelectedParam) => void;
        public onClose: () => void;
        public faqActions: ButtonDefinition[] = [];
        public faqs: PagedItemsModel<FaqModel>;
        public tags: ThesaurusTagModel[] = [];
        public selectedFaqId: System.Guid;
        public selectedTag: ThesaurusTagModel;
        public newFaq: FaqModel;
        public loadingTags: boolean;
        public loadingFaqs: boolean;
        public targetGroupChoices: { [id: string]: FaqTargetGroupModel } = {};
        public targetGroupAnswers: { [id: string]: string } = {};
        public recentFaqs: FaqModel[] = [];
        public suggestedFaqs: FaqModel[] = [];        
        public suggestionsTitle: string = '';
        public subscribedTargetgroups: TargetGroupModel[];
        public answersForSubscribedTargetGroups: FaqTargetGroupModel[] = [];
        public answersForUnsubscribedTargetGroups: FaqTargetGroupModel[] = [];
        private pendingSuggestionsUpdate: boolean;
        private page = 0;
        private pageSize = 10;
        private faqAnswerSelected: boolean;
        private maxSuggestedFaqs = 5;

        constructor(
            private $mdDialog,
            private faqResource: FaqResourceClass,
            private thesaurusResource: ThesaurusResource,
            private localStorageService: LocalStorageService,
            private targetGroupResource: Umbrella.Modules.Knowledgebase.TargetGroup.TargetGroupResourceClass
        ) {}

        public $onInit() {
            if (!this.subscribedTargetgroups) this.subscribedTargetgroups = [];

            this.initializeSuggestions();        
            if (this.searchQuery) {
                this.search(this.searchQuery);
                return;
            }

            this.loadRootItems();
        }

        $onChanges(bindings: { suggestionsQuery: IBindingChange<string> }) {
            if (bindings.suggestionsQuery && bindings.suggestionsQuery.currentValue) {
                this.pendingSuggestionsUpdate = true;
            }
        }

        public async initializeSuggestions(): Promise<void> {
            if (this.suggestionsQuery) {
                switch (this.suggestionsQuery.basedOn) {
                    case KnowledgebaseSuggestionsBasedOn.Chat:
                        this.suggestionsTitle = 'Voorgestelde vragen op basis van chat gesprek';
                        break;
                    default:
                        this.suggestionsTitle = 'Voorgestelde vragen';
                }
                await this.generateSuggestions(this.suggestionsQuery.query);
            }

            if (!this.suggestionsQuery || this.suggestedFaqs.length < 1) {
                const promise = this.loadRecentFaqs(); 
                if (promise)
                    await promise.finally(() => this.suggestedFaqs = this.recentFaqs);
                else 
                    this.suggestedFaqs = [];

                await this.loadMostViewedFaqs();
            }
        }

        public loadRootItems(): void {
            this.reset();
            this.loadTags();
        }

        public selectFaq(faq: FaqModel | AdminFaqModel): void {
            if (!faq) return;

            if (faq.targetGroupAnswers) {
                const ids = faq.targetGroupAnswers.map(x => x.targetGroupId);
                this.targetGroupResource.getByIds({ ids: ids }).$promise.then(data => {
                    data.forEach(x => {
                        this.targetGroupAnswers[x.id.toString()] = x.name;
                    });
                });
            }

            this.selectedFaqId = this.selectedFaqId === faq.id ? null : faq.id;

            if (!this.selectedFaqId) return;

            this.answersForSubscribedTargetGroups = this.getAnswersForSubscribedTargetGroups(faq);
            this.answersForUnsubscribedTargetGroups = this.getAnswersForUnsubscribedTargetGroups(faq);

            // tslint:disable-next-line:no-string-literal
            const faqStatus = faq['status'] || PublicationStatus.Published;
            if (GetEnumValueAsNumber(PublicationStatus, faqStatus) === PublicationStatus.Published)
                this.faqResource.increaseViewCount({ id: this.selectedFaqId });

            const tag = this.getSelectedTag();
            this.onFaqSelected({ faq, tag });
        }

        public selectTag(tag: ThesaurusTagModel): void {
            if (!tag) return;

            this.reset();
            this.selectedTag = tag;

            this.loadTags(tag.id);
            this.loadFaqs(tag.id);
        }

        public putInRecentFaqs(faq: FaqModel): void {
            const faqFoundInRecents = this.recentFaqs.filter(x => x.id === faq.id);
            if (faqFoundInRecents.length > 0) {
                this.recentFaqs = this.recentFaqs.filter(x => x.id !== faq.id);
            } else if (this.recentFaqs) {
                this.recentFaqs = this.recentFaqs.slice(0, this.maxSuggestedFaqs - 1);
            }
            this.recentFaqs.unshift(faq);

            this.localStorageService.store('KnowledgebaseRecentFaqs', JSON.stringify(this.recentFaqs));
        }

        public loadRecentFaqs(): ng.IPromise<void> {
            var recentIds = this.getRecentFaqs().map(x => x.id);
            if (recentIds && recentIds.length > 0) { 
                return this.faqResource.getByIds({ ids: recentIds.join(',') })
                    .$promise
                    .then((faqs) => {
                        this.recentFaqs = faqs.sort((a, b) => recentIds.indexOf(a.id) - recentIds.indexOf(b.id));
                        this.localStorageService.store('KnowledgebaseRecentFaqs', JSON.stringify(faqs));
                    });
            }

            return null;
        }

        public async loadMostViewedFaqs(): Promise<void> {
            var additionalFaqsToLoad = this.maxSuggestedFaqs - this.suggestedFaqs.length;
            if(additionalFaqsToLoad > 0)
            {
                const models = await this.faqResource.getOrderedByViewCount({ count: additionalFaqsToLoad}).$promise;
                this.suggestedFaqs.push(...models);
            }
        }

        public getRecentFaqs(): any {
            const storedRecentFaqs = this.localStorageService.get('KnowledgebaseRecentFaqs');
            const parsedRecentFaqs = JSON.parse(storedRecentFaqs);
            return parsedRecentFaqs && parsedRecentFaqs.length > 0 ? parsedRecentFaqs : [];
        }

        public getSelectedTag(): ThesaurusTagModel {
            return this.isInSearchMode() ? null : this.selectedTag;
        }

        public getSelectedFaqTargetGroup(): FaqTargetGroupModel {
            if (this.faqAnswerSelected) return null;

            return (
                this.targetGroupChoices[String(this.selectedFaqId)] ||
                (this.answersForSubscribedTargetGroups &&
                    this.answersForSubscribedTargetGroups.length > 0 &&
                    this.answersForSubscribedTargetGroups[0])
            );
        }

        public registerFaq(tag: ThesaurusTagModel) {
            if (!tag || !tag.parent) return;

            this.$mdDialog
                .show({
                    template: `<register-faq-popup tag="tag"></register-faq-popup>`,
                    locals: {
                        tag
                    },
                    controller: [
                        '$scope',
                        'tag',
                        ($scope, tag) => {
                            $scope.tag = tag;
                        }
                    ],
                    targetEvent: null,
                    clickOutsideToClose: false
                })
                .then(faq => {
                    this.newFaq = faq;
                    this.selectedFaqId = faq.id;
                });
        }

        public applySearch = debounce(
            (query: string) => {
                this.reset();
                this.search(query);
            },
            500,
            false
        );

        public isInSearchMode(): boolean {
            return !!(this.searchQuery && this.searchQuery.length);
        }

        public isLoadMoreVisible(): boolean {
            return this.faqs && !this.loadingFaqs && this.getTotalRemainingFaqCount() > 0;
        }

        public getTotalRemainingFaqCount(): number {
            if (!this.faqs || !this.faqs.items) return 0;

            return this.faqs.total - this.faqs.items.length;
        }

        public getNextRemainingFaqCount(): number {
            if (!this.faqs || !this.faqs.items) return 0;

            return Math.min(this.faqs.pageSize, this.getTotalRemainingFaqCount());
        }

        public loadMore(): void {
            this.page += 1;

            if (this.searchQuery && this.searchQuery.length) this.search(this.searchQuery);
            else if (this.selectedTag) this.loadFaqs(this.selectedTag.id);
        }

        public hasTargetGroupContent(faq: FaqModel): boolean {
            return faq.targetGroupAnswers.length > 0;
        }

        public selectTargetGroupForFaq(faq: FaqModel, targetGroupAnswer?: FaqTargetGroupModel): void {
            if (!targetGroupAnswer) {
                delete this.targetGroupChoices[String(faq.id)];
                this.faqAnswerSelected = true;
                return;
            }

            this.targetGroupChoices[String(faq.id)] = targetGroupAnswer;
            this.faqAnswerSelected = false;
        }

        public isTargetGroupSelected(faq: FaqModel, targetGroupAnswer?: FaqTargetGroupModel): boolean {
            const savedTargetGroupInfo = this.targetGroupChoices && this.targetGroupChoices[String(faq.id)];

            if (!targetGroupAnswer && !savedTargetGroupInfo) return true;

            if (
                savedTargetGroupInfo &&
                targetGroupAnswer &&
                savedTargetGroupInfo.targetGroupId === targetGroupAnswer.targetGroupId
            )
                return true;

            return false;
        }

        public getFaqAnswer(faq: FaqModel): string {
            const savedTargetGroupInfo = this.targetGroupChoices && this.targetGroupChoices[String(faq.id)];
            return (savedTargetGroupInfo && savedTargetGroupInfo.answer) || faq.answer;
        }

        public getAnswersForSubscribedTargetGroups(faq: FaqModel): FaqTargetGroupModel[] {
            const targetGroupsLinkedToPerson = this.subscribedTargetgroups;
            const targetGroupAnswers = faq && faq.targetGroupAnswers;

            if (!targetGroupAnswers || !targetGroupsLinkedToPerson.length) return [];

            return targetGroupAnswers.filter(
                x => targetGroupsLinkedToPerson.map(x => x.id).indexOf(x.targetGroupId) >= 0
            );
        }

        public getAnswersForUnsubscribedTargetGroups(faq: FaqModel): FaqTargetGroupModel[] {
            const targetGroupsLinkedToPerson = this.subscribedTargetgroups;
            const targetGroupAnswers = faq && faq.targetGroupAnswers;

            if (!targetGroupAnswers) return [];

            if (!targetGroupsLinkedToPerson.length) return targetGroupAnswers;

            return targetGroupAnswers.filter(
                x => targetGroupsLinkedToPerson.map(x => x.id).indexOf(x.targetGroupId) == -1
            );
        }

        public supplementAnswerWithTargetGroupInfoText(answer: string, targetGroupName: string): string {
            return `${answer}
                </br><p>Je leest het antwoord voor de doelgroep <strong>${targetGroupName}</strong>. Lees het antwoord voor:</p>`;
        }

        public executeFaqAction(faq: FaqModel, action: ButtonDefinition) {
            this.putInRecentFaqs(faq);
            const faqTargetGroup = this.getSelectedFaqTargetGroup();
            action.onExecute(faq, this.getSelectedTag(), faqTargetGroup && faqTargetGroup.targetGroupId);
        }

        private async search(searchQuery: string): Promise<void> {
            if (!searchQuery || !searchQuery.length) {
                this.loadRootItems();
                return;
            }

            this.loadingFaqs = true;
            const model = await this.faqResource.search({
                q: searchQuery,
                page: this.page,
                pageSize: this.pageSize,
                getFaqsWithoutItems: true,
                targetGroups: this.subscribedTargetgroups.map(x => x.id)
            }).$promise;
            this.loadingFaqs = false;

            if (!model || !model.items) return;

            this.updateFaqList(model);
        }

        public async generateSuggestions(suggestionsQuery: string, limit = 5): Promise<void> {
            if (!suggestionsQuery || !suggestionsQuery.length) return;

            this.loadingFaqs = true;
            this.pendingSuggestionsUpdate = false;
            const model = await this.faqResource.similar({
                content: suggestionsQuery,
                page: 0,
                pageSize: limit,
                targetGroups: []
            }).$promise;
            this.loadingFaqs = false;

            const suggestions = model.items;

            for (let i = suggestions.length - 1; i >= 0; i--) {
                const faqAlreadyInList = this.suggestedFaqs.filter(x => x.id === suggestions[i].id);

                if (faqAlreadyInList.length > 0)
                    this.suggestedFaqs = this.suggestedFaqs.filter(x => x.id !== suggestions[i].id);

                this.suggestedFaqs.unshift(suggestions[i]);
            }

            if (this.suggestedFaqs.length > limit) this.suggestedFaqs = this.suggestedFaqs.slice(0, limit);
        }

        public isOnStartPage(): boolean {
            return (!this.selectedTag || this.selectedTag === null) && !this.isInSearchMode();
        }

        public cancelSearch() {
            this.searchQuery = '';
            this.reset();
        }

        private async loadTags(parentTagId?: System.Guid): Promise<void> {
            this.loadingTags = true;

            if (!parentTagId && this.pendingSuggestionsUpdate) this.initializeSuggestions();

            this.tags = await this.thesaurusResource.forParent({
                id: parentTagId
            }).$promise;
            this.loadingTags = false;
        }

        private async loadFaqs(tagId: System.Guid): Promise<void> {
            this.loadingFaqs = true;
            const model = await this.faqResource.getByTagIdPaged({
                id: tagId,
                page: this.page,
                pageSize: this.pageSize
            }).$promise;
            this.loadingFaqs = false;

            if (!model || !model.items) return;

            this.updateFaqList(model);
        }

        private updateFaqList(model: PagedItemsModel<FaqModel>): void {
            if (!this.faqs) {
                this.faqs = model;
                return;
            }

            this.faqs = {
                ...this.faqs,
                page: model.page,
                items: [...this.faqs.items, ...model.items]
            };
        }

        private reset(): void {
            this.page = 0;
            this.faqs = null;
            this.tags = [];
            this.selectedTag = null;
            this.selectedFaqId = null;
            this.newFaq = null;
        }
    }
}
