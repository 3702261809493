/// <reference path="ToastMessageService.ts"/>
/// <reference path="SignalR.ts"/>

namespace Umbrella.Modules {
    @Service('Umbrella', 'OfflineService')
    @Inject('ToastMessageService')
    export class OfflineService {
        private firstTimeConnecting = true;
        private isOfflineMessage = null;

        constructor(private toastMessageService: ToastMessageService) {
            isOnline$.subscribe(online => {
                if (!online) {
                    if (this.firstTimeConnecting) return;

                    this.isOfflineMessage = this.toastMessageService.warning(
                        'Netwerkverbinding verbroken',
                        3600000
                    );
                } else {
                    if (this.firstTimeConnecting)
                        this.firstTimeConnecting = false;

                    if (this.isOfflineMessage) {
                        this.toastMessageService.clear(this.isOfflineMessage);
                        this.isOfflineMessage = null;
                    }
                }
            });
        }

        public isOfflineMessageDisplayed(): boolean {
            return !!this.isOfflineMessage;
        }
    }
}
