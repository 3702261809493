/// <reference path="_umbrella-locale_nl-nl.ts"/>

/**
 * Localization service responsible for looking up translated strings.
 *
 * IMPORTANT! resource file should be loaded before this module!
 *
 */
angular
    .module('Umbrella')
    .factory('localize', [
        '$rootScope',
        '$filter',
        'resources',
        ($rootScope, $filter, resources) => {
            const localize = {
                // checks the dictionary for a localized resource string
                getLocalizedString: (selector, key) => {
                    if (key && typeof key == 'string') key = key.toLowerCase();

                    let result = key;
                    let library = 'generic';
                    let prefix = '';

                    if (selector) {
                        if (selector.indexOf('/')) {
                            const selectorArr = selector.split('/');
                            if (selectorArr.length > 1) {
                                library = selectorArr[0];
                                prefix = selectorArr[1];
                            }
                        } else {
                            prefix = selector;
                        }
                    }

                    // add (optional) prefix to string
                    if (prefix && prefix != '') key = prefix + '.' + key;

                    // make sure the dictionary has valid data
                    if (
                        resources &&
                        resources[library] &&
                        resources[library].length > 0
                    ) {
                        // use the filter service to only return those entries which match the value
                        // and only take the first result
                        const entry = $filter('filter')(
                            resources[library],
                            element => {
                                return element.key === key;
                            }
                        )[0];

                        if (entry) result = entry.value;
                    }
                    // return the value to the call
                    return result;
                }
            };

            // return the local instance when called
            return localize;
        }
    ])
    // basic translation filter
    // usage {{ TOKEN | i18n:'library/prefix' }}
    .filter('i18n', [
        'localize',
        localize => {
            return (input, params) => {
                return localize.getLocalizedString(params, input);
            };
        }
    ]);
