﻿/// <reference path="../../../../../Window.d.ts" />

namespace Umbrella.TaskHandling {
    import TaskModel = Modules.Tasks.TaskModel;
    import CustomSelfServiceReportModel = Modules.SelfService.Custom.CustomSelfServiceReportModel;
    import SelfServiceReportResource = Modules.SelfService.SelfServiceReportResource;
    import isDate = Umbrella.Helpers.isDate;
    import TaskOverviewService = Umbrella.TaskHandling.TaskOverviewService;

    export interface TaskInformationComponentState {
        task: TaskModel.Detailed;
    }

    @Component('TaskHandling', {
        selector: 'task-information',
        templateUrl:
            '/TaskHandling/_Overview/_Details/_Information/TaskInformationComponent/TaskInformation.html',
        bindings: {
            state: '<',
        }
    })
    @Inject('SelfServiceReportResource', 'TaskOverviewService')
    class TaskInformationComponent {
        public state: TaskInformationComponentState;

        public selfServiceReport: CustomSelfServiceReportModel;

        constructor(
            private selfServiceReportResource: SelfServiceReportResource,
            private taskOverviewService: TaskOverviewService
            ) {
        }

        public $onChanges(): void {
            const flowStateId = this.state.task && this.state.task.properties && this.state.task.properties.flowStateId;
            if (flowStateId && !this.selfServiceReport)
                this.selfServiceReportResource.getByFlowStateId({ id: flowStateId })
                    .$promise
                    .then((report) => this.selfServiceReport = report);
        }

        public getErrorDescription(): string {
            let description = '';

            if (!this.state.task || !this.state.task.properties)
                return description;

            if (this.state.task.properties.application)
                description += `Er is iets fout gegaan in de ${
                    this.state.task.properties.application
                }\n`;

            if (
                this.state.task.properties.notificationName &&
                this.state.task.properties.consumerName
            )
                description += `Bij het verwerken van de notificatie ${
                    this.state.task.properties.notificationName
                } in de ${this.state.task.properties.consumerName}\n`;

            description += `\n Overig beschikbare informatie: \r\n`;
            for (const prop in this.state.task.properties) {
                if (!this.state.task.properties.hasOwnProperty(prop)) continue;

                description += `${prop}: ${this.state.task.properties[prop]}\n`;
            }

            return description;
        }
    }
}
