namespace Umbrella {
    import switchDeviceMode = Umbrella.switchDeviceMode;

    @Component('Umbrella', {
        selector: 'top-bar',
        templateUrl: '/Top/TopBarComponent/TopBar.html'
    })
    class TopBarComponent {
        public isSurfaceTab(): boolean {
            return !!(window.device && window.device.surface());
        }

        public toggleDeviceMode(): void {
            switchDeviceMode();
        }

        public closeSearchOverlay() {
            if (!isDesktopModeActive()) {
                if (window.device.tablet() || window.device.mobile() || window.device.surface()) {
                    isSearchActive$.onNext(null);
                    toggleNavigationIcon$.onNext('search');
                }
            }
        }
    }
}
