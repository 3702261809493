/// <reference path="../../../../../Window.d.ts"/>
/// <reference path="../../../../../SelectedColleagueOrFunctiongroup.d.ts"/>
/// <reference path="../../../../../CustomerService/ChannelInfo.ts"/>
/// <reference path="../../../../../Top/PhoneService.ts"/>
/// <reference path="../../../../../ChatConversationHandling/ChatService.ts"/>

namespace Umbrella.CustomerService.CustomerCard.Activities.Registration {
    import TaskModel = Modules.Tasks.TaskModel.Detailed;
    import MediaModel = Umbrella.Media.MediaModel;

    import TaskService = Umbrella.Modules.Tasks.TaskService;
    import ITaskResourceClass = Modules.Tasks.ITaskResourceClass;
    import TaskTypeModel = Modules.Tasks.TaskTypeModel;
    import IContractResource = Modules.Housing.IContractResource;
    import ContractFilterModel = Modules.Housing.ContractFilterModel;
    import RoleModel = Modules.RoleModel;
    import SelectedColleagueOrFunctiongroup = Modules.SelectedColleagueOrFunctiongroup;
    import FunctionGroupModel = Modules.Knowledgebase.FunctionGroupModel;
    import SummarizedColleagueModel = Modules.Colleagues.SummarizedColleagueModel;
    import ColleagueModel = Modules.Colleagues.ColleagueModel;
    import IColleagueResourceClass = Modules.Colleagues.IColleagueResourceClass;
    import TaskTypeCategory = Modules.Tasks.TaskTypeCategory;
    import ContractModel = Modules.Housing.ContractModel;
    import IFunctionGroupResourceClass = Modules.Colleagues.IFunctionGroupResourceClass;
    import ChannelType = Modules.ChannelType;
    import CreateTaskModel = Modules.Tasks.CreateTaskModel;
    import IResidentialZoneResourceClass = Modules.Housing.IResidentialZoneResourceClass;
    import ResidentialZoneModel = Modules.Housing.ResidentialZoneModel;
    import PhoneService = Modules.Telephony.PhoneService;
    import ChatService = Umbrella.ChatConversationHandling.ChatService;
    import ChatCustomerInformation = Umbrella.Modules.Chat.ChatCustomerInformation;
    import FaqResourceClass = Umbrella.Modules.Knowledgebase.Faq.FaqResourceClass;

    @Component('CustomerService', {
        selector: 'register-task-popup',
        templateUrl:
            '/CustomerService/_CustomerCard/_Activities/Registration/RegisterTaskPopupComponent/RegisterTaskPopup.html',
        bindings: {
            personId: '@',
            residentialZoneId: '@',
            channelType: '@',
            faqId: '@',
            tagId: '@',
            caseId: '@',
            contractId: '@',
            assignTo: '<',
            taskType: '<',
            chatCustomerInformation: '<'
        }
    })
    @Inject(
        '$mdDialog',
        'PersonResource',
        'TaskService',
        'TaskResource',
        'ContractResource',
        'ColleagueResource',
        'FunctionGroupResource',
        'Upload',
        'ResidentialZoneResource',
        'ToastMessageService',
        'LocalStorageService',
        'ActivityRegistrationService',
        'PhoneService',
        'ChatService',
        'FaqResource'
    )
    class RegisterUnitTaskPopupComponent {
        // bindings
        private personId: System.Guid;
        private residentialZoneId: System.Guid;
        private faqId: System.Guid;
        private tagId: System.Guid;
        private channelType: ChannelType;
        private caseId: System.Guid;
        private contractId: System.Guid;
        private assignTo: ColleagueModel | FunctionGroupModel;
        private taskType: TaskTypeCategory;
        private chatCustomerInformation: ChatCustomerInformation;

        public task: TaskModel;
        public media: MediaModel[];
        public taskTypes: TaskTypeModel[] = [];
        public selectedTaskType: TaskTypeModel;
        public allContracts: ContractFilterModel[] = [];
        public displayedContracts: ContractFilterModel[] = [];
        public selectedContract: ContractFilterModel;
        public contractors: RoleModel[] = [];
        public selectedContractor: RoleModel;
        public createdBy: SelectedColleagueOrFunctiongroup;
        public createdByList: SelectedColleagueOrFunctiongroup[] = [];
        public assignedTo: SummarizedColleagueModel | ColleagueModel.Detailed | FunctionGroupModel;
        public assignedToList: SummarizedColleagueModel[] = [];
        public now = new Date();
        public isSaving: boolean;
        public channels: ChannelInfo[];
        public selectedChannel: ChannelInfo;
        public isCustomer: boolean;
        public isPublic: boolean;
        public isUploading: boolean;

        private person: PersonModel;
        private residentialZone: ResidentialZoneModel;
        private readonly LAST_SELECTED_FUNCTIONGROUP = 'lastSelectedFunctionGroup';

        constructor(
            private $mdDialog,
            private personResource: PersonResource,
            private taskService: TaskService,
            private taskResource: ITaskResourceClass,
            private contractResource: IContractResource,
            private colleagueResource: IColleagueResourceClass,
            private functiongroupResource: IFunctionGroupResourceClass,
            private uploadService: any,
            private residentialZoneResource: IResidentialZoneResourceClass,
            private toastMessageService: ToastMessageService,
            private localStorageService: LocalStorageService,
            private activityRegistrationService: ActivityRegistrationService,
            private phoneService: PhoneService,
            private chatService: ChatService,
            private faqResource: FaqResourceClass
        ) {}

        public async $onInit() {
            this.task = this.taskService.createNewTask(null, null, this.chatCustomerInformation);
            if (this.personId) await this.loadPerson(this.personId);

            await this.setIsPublic();

            this.loadChannels();
            if (this.residentialZoneId) this.loadResidentialZone(this.residentialZoneId);

            this.loadAndPreSelectDropdowns();

            this.addPasteEventListener();

            this.media = [];
        }

        public isCustomerOrProspect(): boolean {
            return (
                this.person && this.person.roles && ('customer' in this.person.roles || 'prospect' in this.person.roles)
            );
        }

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        public save(): void {
            this.isSaving = true;

            const task: CreateTaskModel = {
                description: this.task.description,
                endDate: this.task.ends,
                subject: this.generateSubject(),
                taskType: this.selectedTaskType,
                caseId: this.caseId,
                completeById: (this.assignedTo && this.assignedTo.id) || window.user.id,
                contractId: this.selectedContract && this.selectedContract.id,
                fromId: (this.createdBy && this.createdBy.id) || window.user.id,
                roleId: (this.selectedContractor && this.selectedContractor.id) || this.getRoleId() || null,
                mediaIds: this.task.mediaIds,
                faqId: this.faqId,
                tagId: this.tagId,
                channel: (this.selectedChannel && this.selectedChannel.type) || ChannelType.Unknown,
                residentialZoneId: this.residentialZoneId,
                isPublic: this.isPublic
            };

            this.taskResource
                .save(task)
                .$promise.then(createdTask => {
                    this.toastMessageService.success('Taak succesvol geregistreerd');

                    if (
                        !this.phoneService.hasActiveCall(task.roleId) &&
                        !this.chatService.hasActiveConversation(this.personId)
                    )
                        this.activityRegistrationService.selectChannel(task.channel);

                    this.$mdDialog.hide(createdTask);
                })
                .catch(() => {
                    if (this.faqId === 'null' || this.faqId === 'undefined') {
                        this.toastMessageService.warning('Er is geen FAQ aan deze taak gekoppeld');
                        return;
                    }
                    if (this.tagId === 'null' || this.tagId === 'undefined') {
                        this.toastMessageService.warning('Er is geen Tag aan deze FAQ gekoppeld');
                        return;
                    }
                })
                .finally(() => (this.isSaving = false));
        }

        public searchContract(query: string) {
            this.displayedContracts = this.allContracts.filter(
                x => x.addressLine.toLowerCase().indexOf(query.toLowerCase()) > -1
            );
        }

        public selectContract(contract: ContractFilterModel): void {
            if (!contract) {
                this.selectedContract = null;
                this.contractors = [];
                return;
            }

            this.selectedContract = contract;
            this.loadContractors(contract.id);
            this.preSelectAssignedTo();
        }

        public async searchAssignedTo(query = ''): Promise<void> {
            const pagedList = await this.colleagueResource.getColleaguesAndFunctiongroups({
                page: 0,
                pageSize: 20,
                id: query,
                userId: null
            }).$promise;
            this.assignedToList = pagedList.items;
        }

        public upload = (file: any) => {
            const url = Config.makeAbsoluteApiUrl('/api/v2/media?mediaCategory=TaskMedia');
            if (file) {
                this.isUploading = true;
                file.upload = this.uploadService
                    .upload({
                        url,
                        data: { file }
                    })
                    .then(result => {
                        this.isUploading = false;
                        this.media.push(result.data);
                        this.task.mediaIds.push(result.data.id);
                        this.toastMessageService.success('Bestand is succesvol toegevoegd');
                    })
                    .catch(() => {
                        this.isUploading = false;
                        this.toastMessageService.error('Bestand toevoegen is mislukt');
                    });
            }
        };

        public setCreatedBy(colleagueOrFunctionGroup: SelectedColleagueOrFunctiongroup): void {
            if (!colleagueOrFunctionGroup) return;

            if (this.isFunctionGroup(colleagueOrFunctionGroup))
                this.localStorageService.store(this.LAST_SELECTED_FUNCTIONGROUP, colleagueOrFunctionGroup);
        }

        private async loadPerson(personId: System.Guid): Promise<void> {
            if (!personId) return;

            this.person = await this.personResource.getById({ id: personId }).$promise;
        }

        private getCustomerId(): System.Guid {
            // tslint:disable-next-line:no-string-literal
            return this.person && this.person.roles['customer'];
        }

        private getRoleId(): System.Guid {
            return (
                this.person &&
                // tslint:disable-next-line:no-string-literal
                (this.person.roles['customer'] || this.person.roles['vendor'] || this.person.roles['prospect'])
            );
        }

        private async loadAndPreSelectDropdowns(): Promise<void> {
            this.loadTaskTypes();
            this.loadCreatedByList();
            this.preSelectCreatedBy();
            await this.loadAssignedToList();
            if (this.personId) await this.loadContracts();
            this.preSelectAssignedTo();
        }

        private async loadTaskTypes() {
            this.taskTypes = await this.taskResource.queryVisibleTypes().$promise;

            if (this.taskType || this.taskType === 0)
                // 0 = call me back (terugbellen)
                this.selectedTaskType = this.taskTypes[this.taskType];
            else if (this.taskTypes.length) {
                const requestTaskType = this.taskTypes.filter(x => x.name.toLowerCase().includes('verzoek'))[0];
                this.selectedTaskType = requestTaskType || this.taskTypes[0];
            }
        }

        private async loadContracts(): Promise<void> {
            const customerId = this.getCustomerId();
            this.isCustomer = !!customerId;

            if (!this.isCustomer) return;

            const contractList = await this.contractResource.getAllFilterValuesByRoleId({
                id: customerId
            }).$promise;
            this.displayedContracts = this.allContracts = contractList;

            const preselectedContract = this.contractId
                ? this.allContracts.filter(x => x.id === this.contractId)[0] ||
                  (await this.getContract(this.contractId))
                : !this.selectedContract && this.allContracts.filter(x => this.isActiveOrFutureContract(x))[0];

            if (preselectedContract)
                this.selectContract(this.allContracts.filter(x => x.id === preselectedContract.id)[0]);
        }

        private isActiveOrFutureContract(contract: ContractFilterModel): boolean {
            switch (contract.status.toString().toLowerCase()) {
                case 'active':
                case '1':
                case 'future':
                case '2':
                    return true;
                default:
                    return false;
            }
        }

        private async loadAssignedToList(): Promise<void> {
            await this.searchAssignedTo('');
        }

        private async preSelectAssignedTo(): Promise<void> {
            if (this.assignTo) {
                if (this.assignedToList.filter(x => x.id === this.assignTo.id).length)
                    this.assignedTo = this.assignedToList.filter(x => x.id === this.assignTo.id)[0];
                else {
                    this.assignedTo = <any>this.assignTo;
                    this.assignedToList.push(<any>this.assignedTo);
                }
                return;
            }

            if (!this.tagId) return;

            if (this.selectedContract && this.selectedContract.unitId)
                this.loadExpertiseForUnitAndMainTag(this.selectedContract.unitId, this.tagId);
            else this.loadExpertiseForMainTag(this.tagId);
        }

        private async loadExpertiseForMainTag(tagId: System.Guid): Promise<void> {
            const functiongroupList = await this.functiongroupResource.getPagedByTagId({ id: tagId }).$promise;
            const firstFunctiongroup = functiongroupList && functiongroupList.total && functiongroupList.items[0];
            this.assignToFunctionGroup(firstFunctiongroup);
        }

        private async loadExpertiseForUnitAndMainTag(unitId: System.Guid, tagId: System.Guid): Promise<void> {
            try {
                const functiongroup = await this.functiongroupResource.getByUnitAndTagId({ id: unitId, tagId })
                    .$promise;
                this.assignToFunctionGroup(functiongroup);
            } catch (error) {
                this.loadExpertiseForMainTag(tagId);
            }
        }

        private assignToFunctionGroup(functiongroup: FunctionGroupModel): void {
            if (functiongroup) {
                if (this.assignedToList.filter(x => x.id === functiongroup.id).length)
                    this.assignedTo = this.assignedToList.filter(x => x.id === functiongroup.id)[0];
                else {
                    this.assignedTo = functiongroup;
                    this.assignedToList.push(<any>functiongroup);
                }
            }
        }

        private async loadContractors(contractId: System.Guid): Promise<void> {
            this.contractors = await this.contractResource.getContacts({
                id: contractId
            }).$promise;
            this.selectedContractor = this.contractors.filter(x => x.id === this.getCustomerId())[0];
        }

        private loadCreatedByList(): void {
            this.createdByList = this.getUserWithFunctiongroups();
        }

        private preSelectCreatedBy(): void {
            const lastSelectedFunctionGroup = this.localStorageService.get(this.LAST_SELECTED_FUNCTIONGROUP);
            if (
                lastSelectedFunctionGroup &&
                lastSelectedFunctionGroup.id &&
                this.createdByList.find(x => x.id === lastSelectedFunctionGroup.id)
            ) {
                this.createdBy = this.createdByList.find(x => x.id === lastSelectedFunctionGroup.id);
                return;
            }

            if (this.createdByList.length) this.createdBy = this.createdByList[0];
        }

        private isFunctionGroup(colleagueOrFunctionGroup: SelectedColleagueOrFunctiongroup): boolean {
            return colleagueOrFunctionGroup.type === 'FunctionGroups';
        }

        private getUserWithFunctiongroups(): SelectedColleagueOrFunctiongroup[] {
            const currentUser: SelectedColleagueOrFunctiongroup = {
                id: window.user.id,
                name: window.user.name,
                department: window.user.department,
                type: 'Colleagues'
            };
            const toModel = (x: FunctionGroupModel) =>
                <SelectedColleagueOrFunctiongroup>{
                    id: x.id,
                    name: x.title,
                    department: '',
                    type: 'FunctionGroups'
                };
            const orderByTitle = (x, y) => (x.title > y.title ? 1 : -1);
            const functiongroups: SelectedColleagueOrFunctiongroup[] = window.user.functionGroups
                .sort(orderByTitle)
                .map(toModel);
            return [...functiongroups, currentUser];
        }

        private async getContract(contractId: System.Guid): Promise<ContractModel> {
            const contract = await this.contractResource.getById({
                id: contractId
            }).$promise;
            return contract;
        }

        private generateSubject(): string {
            if (this.chatCustomerInformation) return this.getSubjectForChat();
            if (this.residentialZone) return this.residentialZone.name;
            if (this.person) return this.getSubjectForPerson();

            return '';
        }

        private getSubjectForChat(): string {
            return `Chatgesprek met
                "${this.chatCustomerInformation.firstName}${
                this.chatCustomerInformation.lastName.length > 0 ? ' ' : ''
            }${this.chatCustomerInformation.lastName}" - ${this.chatCustomerInformation.phoneNumber}${
                this.chatCustomerInformation.email
            }`;
        }

        private getSubjectForPerson(): string {
            const name = this.selectedContractor ? this.selectedContractor.name : this.person.name;

            const selectedContractAddress = this.selectedContract && this.selectedContract.fullAddress;
            const selectedContractorHome =
                this.selectedContractor &&
                this.selectedContractor.homeAddress &&
                this.selectedContractor.homeAddress.fullAddressLine;
            const selectedContractorPostal =
                this.selectedContractor &&
                this.selectedContractor.postalAddress &&
                this.selectedContractor.postalAddress.fullAddressLine;
            const personAddress =
                this.person.contactDetails.addresses.length && this.person.contactDetails.addresses[0].fullAddressLine;

            const address =
                selectedContractAddress || selectedContractorHome || selectedContractorPostal || personAddress;

            return address ? `${name}, ${address}` : name;
        }

        private async loadResidentialZone(id: System.Guid): Promise<void> {
            const zones = await this.residentialZoneResource.getByIds({
                ids: [id]
            }).$promise;
            this.residentialZone = zones && zones[0];
        }

        private loadChannels(): void {
            this.channels = getChannelTypes();
            const channelUnknown = {
                name: 'unknown',
                type: ChannelType.Unknown,
                title: 'N.v.t.'
            };
            this.channels.push(channelUnknown);

            let selectedChannelType = this.channelType || ChannelType.Unknown;
            const roleId = this.getRoleId();
            if (this.phoneService.hasActiveCall(roleId)) selectedChannelType = ChannelType.Phone;
            else if (this.chatService.hasActiveConversation(this.personId)) selectedChannelType = ChannelType.Chat;

            this.selectedChannel = this.channels.filter(x => x.type.toString() === selectedChannelType.toString())[0];
        }

        private addPasteEventListener(): void {
            window.addEventListener('paste', (event: Event) => {
                const clipboardEvent: ClipboardEvent = <ClipboardEvent>event;

                if (clipboardEvent.clipboardData.items) {
                    const item = clipboardEvent.clipboardData.items[0];
                    if (item) this.upload(item.getAsFile());
                }
            });
        }

        private async setIsPublic(): Promise<void> {
            if(!this.faqId){
                this.isPublic = false;
                return;
            }
            try{
                const faq = await this.faqResource.getById({id: this.faqId}).$promise;

                let publicApplicationIds: System.Guid[] = [];
                if (window &&
                    window.config &&
                    window.config.modules &&
                    window.config.modules.timeline &&
                    window.config.modules.timeline.publicApplicationIds) {
                        publicApplicationIds = window.config.modules.timeline.publicApplicationIds;
                    }

                if (faq &&
                    faq.applicationIds) {
                        this.isPublic = publicApplicationIds.some(id => faq.applicationIds.indexOf(id) >= 0);
                    }
                else {
                    this.isPublic = false;
                }
            }
            catch(err){
                this.isPublic = false;
            }
        }
    }
}
