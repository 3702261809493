namespace Umbrella {
    import Gender = Umbrella.Modules.Gender;

    @Pipe('Dashboard', 'salutation')
    class SalutationFilter {
        public transform(gender: string | number | Gender) {
            switch (gender) {
                case 'Male':
                case 0:
                case Gender.Male:
                    return 'dhr.';
                case 'Female':
                case 1:
                case Gender.Female:
                    return 'mevr.';
                default:
                    return '';
            }
        }
    }
}
