﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Window.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import DetailedCaseFlowModel = Umbrella.CaseFlow.DetailedCaseFlowModel;

    @Component('Dashboard', {
        selector: 'caseflow-timeline-item',
        templateUrl:
            '/CaseFlowProcessing/CaseflowTimelineItemComponent/CaseflowTimelineItem.html',
        bindings: {
            caseflowId: '<',
            personId: '<'
        }
    })
    @Inject('CaseFlowResource')
    class CaseflowTimelineItemComponent {
        public caseflowId: System.Guid;
        public personId: System.Guid;
        public caseflow: DetailedCaseFlowModel;

        constructor(private caseflowResource: CaseFlowResourceClass) {}

        public $onInit() {
            if (this.caseflowId) this.loadCaseflowDetails();
        }

        public async loadCaseflowDetails(): Promise<void> {
            if (this.hasPermissionsToSeeDetails()) {
                this.caseflow = await this.caseflowResource.getByIdDetailed({
                    id: this.caseflowId
                }).$promise;
            } else {
                const simpleModel = await this.caseflowResource.getById({
                    id: this.caseflowId
                }).$promise;
                this.caseflow = { ...simpleModel, steps: [] };
            }
        }

        public hasPermissionsToSeeDetails(): boolean {
            return !!window.user.permissions.viewDetailedCaseFlow;
        }
    }
}
