/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella {
    angular.module('Umbrella')
        .config(['$stateProvider', ($stateProvider: ng.ui.IStateProvider) => {
            $stateProvider
                .state('umbrella.windowsauth',
                    {
                        url: '/windows-auth',
                        data: { pageTitle: 'Windows authentication' },
                        views: {
                            'new@': { templateUrl: '/ExperimentalArea/WindowsAuthentication.html' }
                        },                        
                        onEnter: ['$state', ($state: ng.ui.IStateService) => {
                            Config.switchFeatureFlag('windowsAuthentication', true);
                        }]                        
                    });
        }]);
}