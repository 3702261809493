﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../ComponentRouteUtils.ts" />
/// <reference path="../../../Window.d.ts"/>
/// <reference path="../../customerservice.routeconfig.ts" />

namespace Umbrella.CustomerService.CustomerCard.Contracts {
    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('customerservice.customercard.contracts', {
                url: '/contracten',
                reloadOnSearch: false,
                data: {
                    tab: 'contracts',
                    authorize: ps => ps.customerCard && ps.contractRead
                },
                views: {
                    'tab@customerservice': {
                        template: '<customer-contracts state="state$ | async:this"></customer-contracts>',
                        controller: Umbrella.createComponentRouteController<
                            CustomerCardState,
                            CustomerContractsComponentState
                        >('CustomerCardStore', s => ({
                            person: s.personal && s.personal.person,
                            registrationInfo: s.registrationInfo && s.registrationInfo.registrations,
                            contracts: s.contractInfo && s.contractInfo.contracts,
                            selectedContract: s.contractInfo && s.contractInfo.selectedContract,
                            customer: s && s.customerInfo && s.customerInfo.customer,
                            loading: (s.personal && s.personal.loading && s.registrationInfo &&
                                s.registrationInfo.loading) || !s.contractInfo || s.contractInfo.loading
                        }))
                    },
                    'left@customerservice': {
                        template: '<customer-contract-filters state="state$ | async:this"></customer-contract-filters>',
                        controller: Umbrella.createComponentRouteController<
                            CustomerCardState,
                            CustomerContractFiltersComponentState
                        >('CustomerCardStore', s => ({
                            person: s.personal.person
                        }))
                    }
                }
            });
        }
    ]);
}
