﻿/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../Window.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    export interface CaseFlowOverviewStateParams
        extends ng.ui.IStateParamsService {
        id: System.Guid;
    }
    export interface CaseFlowStepOverviewStateParams
        extends ng.ui.IStateParamsService {
        id: System.Guid;
        caseId: System.Guid;
    }

    angular.module('Dashboard').config([
        '$stateProvider',
        '$urlRouterProvider',
        ($stateProvider: angular.ui.IStateProvider, $urlRouterProvider) => {
            $stateProvider.state('dashboard.caseflow', {
                url: '/zaken',
                redirectTo: 'dashboard.caseflow.overview',
                data: {
                    pageTitle: 'Zaken',
                    authorize: ps => ps.viewCaseFlow
                },
                views: {
                    overview: {
                        templateUrl:
                            '/CaseFlowProcessing/_Overview/CaseFlowOverview.html'
                    }
                }
            });
        }
    ]);
}
