/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../Window.d.ts"/>

namespace Umbrella.CustomerService {
    import FaqResourceClass = Modules.Knowledgebase.Faq.FaqResourceClass;
    import IndicatorEvaluationModel = Indicators.IndicatorEvaluationModel;
    import IndicatorSuppressionModel = Indicators.IndicatorSuppressionModel;
    import FaqModel = Modules.Knowledgebase.FaqModel;
    import IPromise = angular.IPromise;

    export interface IIndicatorEvaluationModelDetailed
        extends IndicatorEvaluationModel {
        faq: FaqModel;
    }

    @Service('Umbrella', 'IndicatorService')
    @Inject('IndicatorEvaluationResource', 'IndicatorResource')
    export class IndicatorService {
        constructor(
            private indicatorEvaluationResource: IIndicatorEvaluationResourceClass,
            private indicatorResource: IIndicatorResourceClass
        ) {}

        getActive(
            key: string,
            models: any[]
        ): IPromise<IndicatorEvaluationModel[]> {
            return this.indicatorEvaluationResource.active({ key }, models)
                .$promise;
        }

        setMuted(indicatorId: System.Guid, key: string, onmute: (id) => void) {
            this.indicatorResource
                .mute({ indicatorId, key } as IndicatorSuppressionModel)
                .$promise.then(() => onmute(indicatorId));
        }
    }
}
