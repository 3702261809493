/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Window.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard.Overview {
    angular.module('Dashboard').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('dashboard.caseflow.overview', {
                url: '/overzicht?page&size&filterforstatus&filterforcasetype&caseid',
                reloadOnSearch: false,
                data: {
                    pageTitle: 'Zaken',
                    authorize: ps => ps.viewCaseFlow
                },
                views: {
                    filters: {
                        template: '<caseflow-overview-filters state="state$ | async:this"></caseflow-overview-filters>',
                        controller: createComponentRouteController<
                            CaseFlowState,
                            CaseFlowOverviewFiltersComponentState
                        >('CaseFlowOverviewStore', s => ({
                            filters: s && s.overviewInfo && s.overviewInfo.filters
                        }))
                    },
                    list: {
                        template: '<caseflow-list state="state$ | async:this"></caseflow-list>',
                        controller: createComponentRouteController<CaseFlowState, Overview.CaseFlowListComponentState>(
                            'CaseFlowOverviewStore',
                            s => ({
                                cases:
                                    s &&
                                    s.overviewInfo &&
                                    s.overviewInfo &&
                                    s.overviewInfo.cases &&
                                    s.overviewInfo.cases.result,
                                loading: s && s.overviewInfo && s.overviewInfo.loading
                            })
                        )
                    },
                    details: {
                        templateUrl: 'CaseFlowProcessing/_Overview/CaseFlowOverviewNoCaseFlowSelected.html'
                    }
                }
            });
        }
    ]);
}
