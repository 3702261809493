﻿/// <reference path="../../Scripts/TypeScript/toastr/toastr.d.ts" />

namespace Umbrella {
    @Service('Umbrella', 'ToastMessageService')
    export class ToastMessageService {
        private defaultTimeOut = 3000;
        private errorTimeOut = 10000;
        private defaultOptions = {
            positionClass: 'toast-top-center',
            hideDuration: 100,
            timeOut: this.defaultTimeOut
        };

        constructor() {
            toastr.options = this.defaultOptions;
        }

        public info(text: string, duration: number = this.defaultTimeOut): JQuery {
            const options = { ...this.defaultOptions, timeOut: duration };
            return toastr.info(text, '', options);
        }

        public success(text: string, duration: number = this.defaultTimeOut): JQuery {
            const options = { ...this.defaultOptions, timeOut: duration };
            return toastr.success(text, '', options);
        }

        public warning(text: string, duration: number = this.defaultTimeOut): JQuery {
            const options = { ...this.defaultOptions, timeOut: duration };
            return toastr.warning(text, '', options);
        }

        public error(text: string, duration: number = this.errorTimeOut): JQuery {
            const options = { ...this.defaultOptions, timeOut: duration };
            return toastr.error(text, '', options);
        }

        public updating(text: string, duration: number = this.defaultTimeOut, options: ToastrOptions = {}): JQuery {
            const passedOptions = {
                ...this.defaultOptions,
                timeOut: duration,
                positionClass: 'toast-syncronize-container',
                toastClass: 'toast-syncronize',
                ...options
            };
            return toastr.info(text, '', passedOptions);
        }

        public clear(toast: JQuery): void {
            toastr.clear(toast);
        }

        public clearAll(): void {
            toastr.clear();
        }
    }
}
