namespace Umbrella {
    import CorrespondenceType = Umbrella.Modules.CorrespondenceType;

    @Pipe('Dashboard', 'correspondenceType')
    class CorrespondenceTypeFilter {
        public transform(correspondenceType: string | CorrespondenceType) {
            switch (correspondenceType) {
                case '1':
                case 'Mail':
                case 'mail':
                case Umbrella.Modules.CorrespondenceType.Mail:
                    return 'Per post';
                case '2':
                case 'Digital':
                case 'digital':
                case Umbrella.Modules.CorrespondenceType.Digital:
                    return 'Digitaal';
                default:
                    return 'Onbekend';
            }
        }
    }
}
