/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/angularjs/angular-ui-router.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />
/// <reference path="../../../Window.d.ts" />
/// <reference path="../../../Scope.d.ts" />
/// <reference path="../../../Modules/Colleagues/Colleague/ColleagueResourceProvider.ts" />
/// <reference path="../../../Top/PresenceHub.ts"/>

namespace CustomerService.ColleagueCard {
    import ColleagueModel = Umbrella.Modules.Colleagues.ColleagueModel;
    import IColleagueResourceClass = Umbrella.Modules.Colleagues.IColleagueResourceClass;
    import PresenceResource = Umbrella.IPresenceResource;
    import StatusModel = Umbrella.Modules.Colleagues.StatusModel;
    import colleagueStatusUpdated$ = Umbrella.colleagueStatusUpdated$;

    @Component('CustomerService', {
        selector: 'colleague-card-sidebar',
        templateUrl: '/CustomerService/_ColleagueCard/ColleagueCardSidebarComponent/ColleagueCardSidebar.html'
    })
    @Inject('$stateParams', 'ColleagueResource', 'PresenceResource')
    export class ColleagueCardSidebarComponent {
        public personId: System.Guid;
        public colleague: ColleagueModel.Detailed;
        public availabilityStatus: StatusModel;
        private presenceObserver: Rx.IDisposable;

        constructor(
            private $stateParams: { personId: System.Guid },
            private colleagueResource: IColleagueResourceClass,
            private presenceResource: PresenceResource
        ) {}

        $onInit() {
            this.personId = this.$stateParams.personId;

            this.loadColleague(this.$stateParams.personId);            
            this.observeAvailabilityStatus();
        }

        $onDestroy(): void {
            if (this.presenceObserver) this.presenceObserver.dispose();

            this.presenceObserver = null;
        }

        private async loadColleague(id: System.Guid): Promise<void> {
            this.colleague = await this.colleagueResource.getById({ id }).$promise;
            this.loadAvailabilityStatus(this.colleague.personId);
        }

        private async loadAvailabilityStatus(id: System.Guid): Promise<void> {
            const presence = await this.presenceResource.getStatusByIds({
                ids: [id]
            }).$promise;
            if (!presence || !presence.length) return;

            this.availabilityStatus = presence[0].status;
        }

        private observeAvailabilityStatus(): void {
            this.presenceObserver = colleagueStatusUpdated$
                .filter(x => !!x)
                .distinctUntilChanged()
                .subscribe(update => {
                    if (this.colleague && this.colleague.id === update.userId) this.availabilityStatus = update.status;
                });
        }
    }
}
