/// <reference path="../../ObservableStore.ts"/>
/// <reference path="../../RootStore.ts"/>
/// <reference path="../../SelectedColleagueOrFunctiongroup.d.ts"/>

namespace Umbrella.TaskHandling {
    import TaskTypeModel = Modules.Tasks.TaskTypeModel;
    import SelectedColleagueOrFunctiongroup = Modules.SelectedColleagueOrFunctiongroup;

    export namespace Overview {
        import TaskOverviewItemModel = Modules.Tasks.TaskOverviewItemModel;
        import TaskModel = Modules.Tasks.TaskModel;
        import TaskDiscussionActivityModel = Modules.Tasks.TaskDiscussionActivityModel;
        import ActivityModel = Modules.ActivityModel;
        import TaskActivityDto = Modules.Tasks.TaskActivityDto;

        export interface TasksLoadingEvent {
            readonly type: 'TasksLoadingEvent';
            readonly page: number;
            readonly pageSize: number;
        }

        export interface TasksLoadedEvent {
            readonly type: 'TasksLoadedEvent';
            readonly tasks: PagedItemsModel<TaskOverviewItemModel>;
        }

        export interface TasksModifiedEvent {
            readonly type: 'TasksModifiedEvent';
            readonly modifiedTasks: PagedItemsModel<TaskOverviewItemModel>;
        }

        export interface TasksItemModifiedEvent {
            type: 'TasksItemModifiedEvent';
            readonly task: TaskOverviewItemModel;
        }

        export interface TaskDetailsLoadingEvent {
            readonly type: 'TaskDetailsLoadingEvent';
        }

        export interface TaskDetailsLoadedEvent {
            readonly type: 'TaskDetailsLoadedEvent';
            readonly task: TaskModel | TaskModel.Detailed | TaskOverviewItemModel;
            readonly completedBy: Umbrella.Modules.Colleagues.ColleagueModel;
        }

        export interface TasksLoadFailedEvent {
            readonly type: 'TasksLoadFailedEvent';
            readonly error: any;
        }

        export interface TaskOverviewFiltersUpdatedEvent {
            readonly type: 'TaskOverviewFiltersUpdatedEvent';
            readonly filters: TaskOverviewFilters;
        }

        export interface TaskActivitiesLoadingEvent {
            readonly type: 'TaskActivitiesLoadingEvent';
        }

        export interface TaskActivitiesLoadedEvent {
            readonly type: 'TaskActivitiesLoadedEvent';
            readonly activities: ActivityModel[];
        }

        export interface LinkedTasksLoadingEvent {
            readonly type: 'LinkedTasksLoadingEvent';
        }

        export interface LinkedTasksLoadedEvent {
            readonly type: 'LinkedTasksLoadedEvent';
            readonly linkedTasks: TaskModel[];
        }

        export interface NewTaskActivityEvent {
            readonly type: 'NewTaskActivityEvent';
            readonly activity: any;
        }

        export interface TaskOverviewFilters {
            readonly query: string;
            readonly status: string;
            readonly deadline: string;
            readonly taskTypes: string[];
            readonly createdBy: SelectedColleagueOrFunctiongroup;
            readonly assignedTo: SelectedColleagueOrFunctiongroup;
        }

        export interface State {
            readonly loading: boolean;
            readonly error: any;
            readonly page: number;
            readonly pageSize: number;
            readonly tasks: PagedItemsModel<TaskOverviewItemModel>;
            readonly selectedTaskLoading: boolean;
            readonly selectedTask: any;
            readonly selectedTaskCompletedBy: Umbrella.Modules.Colleagues.ColleagueModel;
            readonly filters: TaskOverviewFilters;
            readonly activities: ActivityModel[];
            readonly linkedTasks: TaskModel[];
            readonly taskTypes: TaskTypeModel[];
        }
    }

    export type TaskOverviewEvent =
        | Overview.TasksLoadingEvent
        | Overview.TasksLoadedEvent
        | Overview.TasksModifiedEvent
        | Overview.TasksItemModifiedEvent
        | Overview.TaskDetailsLoadingEvent
        | Overview.TaskDetailsLoadedEvent
        | Overview.TasksLoadFailedEvent
        | Overview.TaskOverviewFiltersUpdatedEvent
        | Overview.TaskActivitiesLoadingEvent
        | Overview.TaskActivitiesLoadedEvent
        | Overview.LinkedTasksLoadingEvent
        | Overview.LinkedTasksLoadedEvent
        | Overview.NewTaskActivityEvent;

    function reduce(e: TaskOverviewEvent): ObservableStore.Reducer<Overview.State> {
        switch (e.type) {
            case 'TasksLoadingEvent':
                return s => ({
                    ...s,
                    loading: true
                });

            case 'TasksLoadedEvent':
                return s => ({
                    ...s,
                    loading: false,
                    tasks: {
                        ...s.tasks,
                        items: s.tasks && s.tasks.items ? [...s.tasks.items, ...e.tasks.items] : e.tasks.items,
                        page: e.tasks.page,
                        pageSize: e.tasks.pageSize,
                        total: e.tasks.total
                    },
                    error: null,
                    page: e.tasks.page,
                    pageSize: e.tasks.pageSize
                });

            case 'TasksModifiedEvent':
                return s => ({
                    ...s,
                    tasks: e.modifiedTasks
                });

            case 'TasksItemModifiedEvent':
                return s => ({
                    ...s,
                    tasks: {
                        ...s.tasks,
                        items:
                            s.tasks &&
                            s.tasks.items.map(x => {
                                if (x.id === e.task.id) {
                                    return {
                                        ...x,
                                        ...e.task
                                    };
                                }

                                return x;
                            })
                    }
                });

            case 'TaskDetailsLoadingEvent':
                return s => ({
                    ...s,
                    selectedTaskLoading: true,
                    selectedTask: null,
                    selectedTaskCompletedBy: null
                });

            case 'TaskDetailsLoadedEvent':
                return s => ({
                    ...s,
                    selectedTaskLoading: false,
                    selectedTask: e.task,
                    selectedTaskCompletedBy: e.completedBy
                });

            case 'TasksLoadFailedEvent':
                return s => ({
                    ...s,
                    error: e.error
                });

            case 'TaskOverviewFiltersUpdatedEvent':
                return s => ({
                    ...s,
                    tasks: {
                        items: [],
                        page: 0,
                        pageSize: s && s.tasks ? s.tasks.pageSize : 9,
                        total: s && s.tasks ? s.tasks.total : 0
                    },
                    filters: e.filters
                });

            case 'TaskActivitiesLoadingEvent':
                return s => ({
                    ...s,
                    activities: []
                });

            case 'TaskActivitiesLoadedEvent':
                return s => ({
                    ...s,
                    activities: e.activities
                });

            case 'LinkedTasksLoadingEvent':
                return s => ({
                    ...s,
                    linkedTasks: []
                });

            case 'LinkedTasksLoadedEvent':
                return s => ({
                    ...s,
                    linkedTasks: e.linkedTasks
                });

            case 'NewTaskActivityEvent':
                return s => ({
                    ...s,
                    activities: [...s.activities, e.activity]
                });
        }
    }

    export type TaskOverviewStore = ObservableStore.EventStore<Overview.State, TaskOverviewEvent>;

    export const taskOverviewStore: TaskOverviewStore = rootStore.map(lens<Overview.State>('taskOverview'), reduce);

    angular.module('TaskHandling').value('TaskOverviewStore', taskOverviewStore);
}
