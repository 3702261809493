﻿namespace Umbrella.Search {
    angular.module('Search').config([
        '$stateProvider',
        $stateProvider => {
            $stateProvider.state('search', {
                parent: 'root',
                abstract: true,
                views: {
                    'new@': {
                        templateUrl: '/Search/Search.html'
                    }
                }
            });
        }
    ]);
}
