/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../Scope.d.ts"/>
/// <reference path="../../../../Window.d.ts"/>

namespace Umbrella.CustomerService.UnitCard.Valuation {
    import IValuationResourceClass = Modules.Housing.Valuations.IValuationResourceClass;
    import ValuationRequestModel = Modules.Housing.Valuations.ValuationRequestModel;
    import SummarizedColleagueModel = Modules.Colleagues.SummarizedColleagueModel.WithFunctionGroup;
    import UnitModel = Modules.Housing.UnitModel;
    import RoleModel = Modules.RoleModel;

    export interface IRequestValuationPopupScope {
        description: string;
        valuationform: angular.IFormController;
        errors: boolean;
        unit: UnitModel;
        completedBy: SummarizedColleagueModel;
        waiting: boolean;

        save(): void;
        $modalSuccess(): void;
        getRole(): RoleModel;

        onColleagueOrFunctionGroupSelected(
            newSelection: SummarizedColleagueModel
        );
    }

    @Controller('CustomerService', 'RequestValuationPopupController')
    export class RequestValuationPopupController {
        static $inject = ['$scope', 'ValuationResource'];

        constructor(
            $scope: IRequestValuationPopupScope,
            private valuationResource: IValuationResourceClass
        ) {
            $scope.description = 'Verzoek tot woningwaardering';

            $scope.save = () => {
                if (!$scope.valuationform.$valid) {
                    return;
                }

                const request: ValuationRequestModel = {
                    completeBy: $scope.completedBy,
                    unitId: $scope.unit.id,
                    description: $scope.description
                };

                $scope.waiting = true;
                this.valuationResource
                    .createTask(request)
                    .$promise.then(
                        () => $scope.$modalSuccess(),
                        response => ($scope.errors = true)
                    )
                    .finally(() => ($scope.waiting = false));
            };

            $scope.onColleagueOrFunctionGroupSelected = (
                newSelection: SummarizedColleagueModel
            ) => {
                $scope.completedBy = newSelection;
            };
        }
    }
}
