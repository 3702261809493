/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella {
    angular.module('Umbrella').directive('elemReady', [
        '$parse',
        $parse => {
            return {
                restrict: 'A',
                link: (scope, elem, attrs) => {
                    const attributes = scope.$eval(attrs.elemReady);
                    const observer = new MutationObserver(() => {
                        scope.$eval(attributes.function);
                    });

                    observer.observe(elem[0], {
                        attributes: attributes.attribute,
                        childList: true,
                        subtree: true
                    });
                }
            };
        }
    ]);
}
