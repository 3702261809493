namespace Umbrella.CustomerService.CustomerCard.Contracts {
    import IContractResource = Modules.Housing.IContractResource;
    import ContractModel = Modules.Housing.ContractModel;
    import RoleModel = Modules.RoleModel;
    import OutstandingBalanceModel = Modules.Housing.OutstandingBalanceModel;

    @Service('CustomerService', 'CustomerCardContractInfoService')
    @Inject('CustomerCardStore', 'ContractResource')
    export class CustomerCardContractInfoService extends BaseStoreService<
        CustomerCardState,
        CustomerCardEvent,
        CustomerCardStore
    > {
        constructor(store: CustomerCardStore, private contractResource: IContractResource) {
            super(store);

            const personLoaded = (x: CustomerCardState) => x && x.personal && x.personal.person;
            store.state$
                .map(personLoaded)
                .distinctUntilChanged()
                .subscribe(person => {
                    this.loadOutstandingBalance(person);
                    this.loadActiveAssociatedContractors(person);
                });
        }

        public ensureLoaded(pageSize = 5, force = false): void {
            const state = this.getState();
            if (!state.contractInfo || force || state.contractInfo.selectedContract)
                this.loadContracts(0, pageSize, force, contracts => contracts);
        }

        public reload(skipReloadOfAssociatedContractors = false): void {
            const state = this.store.getState();
            if (!state.personal || !state.personal.person) return;

            const pageSize =
                state.contractInfo && state.contractInfo.contracts ? state.contractInfo.contracts.pageSize : 5;
            this.loadContracts(0, pageSize, true, contracts => contracts);

            this.loadOutstandingBalance(state.personal.person);

            if (!skipReloadOfAssociatedContractors) this.loadActiveAssociatedContractors(state.personal.person);
        }

        public filterByContract(contractId: System.Guid) {
            const state = this.getState();

            if (contractId === null) {
                this.resetContractFiltering();
                return;
            }

            if (
                state.contractInfo &&
                (!state.contractInfo.selectedContract || state.contractInfo.selectedContract !== contractId)
            )
                this.loadContract(contractId);
        }

        public resetContractFiltering(): void {
            this.emit({
                type: 'CustomerCardContractsFilteredEvent',
                contract: null
            });
        }

        public loadMore(): void {
            const state = this.store.getState();
            const pagedItems = state.contractInfo && state.contractInfo.contracts;

            this.loadContracts(pagedItems.page + 1, pagedItems.pageSize, false, contracts => ({
                items: pagedItems ? pagedItems.items.concat(contracts.items) : contracts.items,
                total: contracts.total,
                page: contracts.page,
                pageSize: contracts.pageSize
            }));
        }

        private loadContractsByCustomer(
            page: number,
            size: number,
            customerId: System.Guid,
            loaded: (a: PagedItemsModel<ContractModel.Detailed>) => PagedItemsModel<ContractModel.Detailed>
        ) {
            this.emit<Contracts.LoadingEvent>({
                type: 'CustomerCardContractsLoadingEvent',
                page,
                pageSize: size
            });

            this.contractResource
                .getPagedByRoleId({ id: customerId, page, pageSize: size })
                .$promise.then(contracts => {
                    const newContracts = loaded(contracts);
                    this.emit<Contracts.LoadedEvent>({
                        type: 'CustomerCardContractsLoadedEvent',
                        contracts: newContracts
                    });
                })
                .catch(e =>
                    this.emit<Contracts.LoadErrorEvent>({
                        type: 'CustomerCardContractsLoadErrorEvent',
                        error: e
                    })
                );
        }

        private loadContracts(
            page: number,
            size: number,
            force: boolean,
            loaded: (a: PagedItemsModel<ContractModel.Detailed>) => PagedItemsModel<ContractModel.Detailed>
        ) {
            const state = this.getState();

            if (!force) {
                if (
                    state.contractInfo &&
                    (state.contractInfo.loading ||
                        (state.contractInfo.contracts &&
                            state.contractInfo.contracts.page === page &&
                            state.contractInfo.contracts &&
                            state.contractInfo.contracts.pageSize === size))
                )
                    return;
            }

            if (state.personal && state.personal.person) {
                const customerId = state.personal.person.roles['customer'];
                if (!customerId) return;

                this.loadContractsByCustomer(page, size, customerId, loaded);
            } else {
                this.store.state$
                    .map(x => x && x.personal)
                    .filter(personal => !personal.loading && !personal.loadError)
                    .take(1)
                    .subscribe((p: Personal.State) =>
                        this.loadContractsByCustomer(page, size, p.person.roles['customer'], loaded)
                    );
            }
        }

        private loadContract(contractId: System.Guid) {
            const state = this.getState();
            if (state.personal.person && state.personal.person.roles['customer']) {
                this.emit({ type: 'CustomerCardContractsFilteringEvent' });

                this.contractResource
                    .getById({ id: contractId })
                    .$promise.then(contract => {
                        this.emit({
                            type: 'CustomerCardContractsFilteredEvent',
                            contract
                        });
                    })
                    .catch(e =>
                        this.emit({
                            type: 'CustomerCardContractsFilterErrorEvent',
                            error: e
                        })
                    );
            } else {
                this.store.state$
                    .map(x => x && x.personal)
                    .filter(personal => !personal.loading && !personal.loadError)
                    .take(1)
                    .subscribeOnCompleted(() => this.loadContract(contractId));
            }
        }

        private async loadActiveAssociatedContractors(person: PersonModel): Promise<void> {
            const customerId = person && person.roles['customer'];
            if (!customerId) return;

            const contractors = await this.contractResource.getActiveAssociatedContractors({ id: customerId }).$promise;
            if (!contractors) return;

            const orderedList = [
                ...contractors.filter(x => x.person.id === person.id),
                ...contractors.filter(x => x.person.id !== person.id)
            ];

            this.emit({
                type: 'CustomerCardContractorsLoadedEvent',
                contractors: orderedList
            });
        }

        private loadOutstandingBalance(person: PersonModel): void {
            const customerId = person && person.roles['customer'];

            if (!window.user || !window.user.permissions || !window.user.permissions.billRead) return;
            if (!customerId) return;

            this.contractResource
                .getOutstandingBalanceFor({ id: customerId })
                .$promise.then((balance: OutstandingBalanceModel) => {
                    this.emit({
                        type: 'CustomerCardOutstandingBalanceLoadedEvent',
                        outstandingBalance: balance
                    });
                });
        }
    }
}
