﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Window.d.ts" />
/// <reference path="../customerservice.routeconfig.ts" />

namespace Umbrella.CustomerService.ColleagueCard {
    export interface ColleagueCardStateParams {
        personId: System.Guid;
    }

    angular.module('Colleagues').config([
        '$stateProvider',
        '$urlRouterProvider',
        ($stateProvider, $urlRouterProvider) => {
            $stateProvider.state('customerservice.colleaguecard', {
                reloadOnSearch: false,
                url: '/collegakaart/:personId',
                redirectTo: () =>
                    window.config && window.config.facets && window.config.facets.calendar
                        ? 'customerservice.colleaguecard.calendar'
                        : 'customerservice.colleaguecard.activities',
                data: {
                    pageTitle: 'Collegakaart'
                },
                views: {
                    'right@customerservice': {
                        template: '<colleague-card-sidebar></colleague-card-sidebar>'
                    }
                }
            });
        }
    ]);
}
