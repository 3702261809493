/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard.Overview {
    angular.module('Dashboard').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('dashboard.caseflow.overview.details.contactmoments', {
                url: '/contactmomenten',
                reloadOnSearch: false,
                data: {
                    pageTitle: 'Contactmomenten',
                    authorize: ps => [ps.viewCaseFlow, ps.viewDetailedCaseFlow]
                },
                views: {
                    tabcontent: {
                        template:
                            '<caseflow-contact-moments state="state$ | async:this" class="reset-box-sizing"></caseflow-contact-moments>',
                        controller: Umbrella.createComponentRouteController<
                            CaseFlowState,
                            CaseFlowContactMomentsComponentState
                        >('CaseFlowOverviewStore', s => ({
                            caseHistory: s && s.caseInfo.caseHistory,
                            person: s && s.caseInfo.person
                         }))
                    }
                }
            });
        }
    ]);
}
