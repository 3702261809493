namespace Umbrella.CustomerService {
    import PaymentStatus = Modules.Payments.PaymentStatus;

    @Pipe('CustomerService', 'paymentStatus')
    class PaymentStatusFilter {
        public transform(val: PaymentStatus): string {
            switch (<PaymentStatus | string>val) {
                case 'New':
                case PaymentStatus.New:
                case 0:
                    return 'Nieuwe betaling';
                case 'Success':
                case PaymentStatus.Success:
                case 1:
                    return 'Betaling ontvangen';
                case 'Pending':
                case PaymentStatus.Pending:
                case 10:
                    return 'Betaling in afwachting';
                case 'Failed':
                case PaymentStatus.Failed:
                case 90:
                    return 'Betaling mislukt';
                case 'Cancelled':
                case PaymentStatus.Cancelled:
                case 91:
                    return 'Betaling afgebroken';
                case 'Expired':
                case PaymentStatus.Expired:
                case 92:
                    return 'Betaling verlopen';
                default:
                    return '';
            }
        }
    }
}
