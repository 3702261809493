﻿/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />

namespace Umbrella.CustomerService.CustomerCard {
    import PersonalPreferencesModel = Umbrella.Notifications.Preferences.PersonalPreferencesModel;

    export interface PersonalPreferenceParams {
        id: System.Guid;
    }

    export interface NotificationPersonalPreferencesResource {
        get(params: PersonalPreferenceParams): ng.resource.IResource<PersonalPreferencesModel>;
    }

    angular.module('CustomerService').service('NotificationPersonalPreferencesResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/notifications/preferences/:action/:id',
                {},
                {
                    get: {
                        method: 'GET',
                        action: '',
                        isArray: false,
                        params: { action: '', id: '@id' }
                    }
                }
            )
    ]);
}
