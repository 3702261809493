/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />

namespace Umbrella.Modules.Contacts {
    export interface IContactActivityResource {
        register(
            contact: RegisterContactModel
        ): ng.resource.IResource<ContactActivityModel>;
        delete(params: IdRequestParams): ng.resource.IResource<any>;

        assignChatConversation(
            chatContactMoment: ChatContactMoment
        ): ng.resource.IResource<ChatContactMoment>;
        assignMailConversation(
            mailContactMoment: MailContactMoment
        ): ng.resource.IResource<MailContactMoment>;
    }

    export class ChatContactMoment {
        constructor(
            public contactMomentId: System.Guid,
            public chatConversationId: System.Guid
        ) {}
    }

    export class MailContactMoment {
        constructor(
            public contactMomentId: System.Guid,
            public mailConversationId: System.Guid
        ) {}
    }

    angular.module('CustomerService').service('ContactActivityResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/activity/contact/:action',
                {},
                {
                    register: {
                        method: 'POST',
                        action: '',
                        isArray: false,
                        params: {}
                    },
                    delete: {
                        method: 'DELETE',
                        action: '',
                        isArray: false,
                        params: {}
                    },
                    assignChatConversation: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'assignChatConversation' }
                    },
                    assignMailConversation: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'assignMailConversation' }
                    }
                }
            )
    ]);
}
