﻿namespace Umbrella.Search {
    @Component('Search', {
        selector: 'search-spinner',
        templateUrl: '/Search/SearchSpinnerComponent/SearchSpinner.html',
        bindings: {
            searching: '<',
            description: '@'
        }
    })
    class SearchSpinnerComponent {}
}
