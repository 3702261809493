/// <reference path="../../../CustomerService/_CustomerCard/CustomerCardStore.ts"/>
/// <reference path="../../../Window.d.ts"/>

namespace Umbrella.Modules.Chat {
    import ActivityRegistrationService = Umbrella.CustomerService.CustomerCard.Activities.Registration.ActivityRegistrationService;
    import SelectedPanel = Umbrella.CustomerService.CustomerCard.Activities.Registration.SelectedPanel;
    import CreateCustomerModel = Umbrella.Modules.Customers.CreateCustomerModel;
    import ContactPhoneNumberModel = Umbrella.Modules.Telephony.ContactPhoneNumberModel;
    import ChatStore = Umbrella.ChatConversationHandling.ChatStore;
    import ChatService = Umbrella.ChatConversationHandling.ChatService;
    import ChatConversation = Umbrella.ChatConversationHandling.ChatConversation;
    import ChatState = Umbrella.ChatConversationHandling.ChatState;
    import MessageDirections = Umbrella.ChatConversationHandling.MessageDirections;
    import customerCardStore = Umbrella.CustomerService.CustomerCard.customerCardStore;
    import CustomerModel = Umbrella.Modules.Customers.CustomerModel;

    @Component('Chat', {
        selector: 'chat-bar',
        templateUrl: '/Modules/Chat/ChatBarComponent/ChatBar.html'
    })
    @Inject(
        '$state',
        'ChatStore',
        'ChatService',
        'ActivityRegistrationService',
        '$mdDialog',
        'ToastMessageService',
        'EmptyGuid',
        'PersonResource'
    )
    export class ChatBarComponent {
        enabled: boolean;
        conversations: any[] = [];
        conversationsStatistics: Umbrella.ChatConversationHandling.ConversationStatistics[] = [];
        isCustomerCardOpen = false;
        $close: () => void;
        person: PersonModel;

        public visibleChatsCount;
        public collapsedTabsOpened = false;

        private currentOpenedConversationId: System.Guid;
        private activeConversationId: System.Guid;
        private lastActiveConversationId: System.Guid;
        private personObserver: Rx.IDisposable;
        private conversationObserver: Rx.IDisposable;
        private activeConversationObserver: Rx.IDisposable;

        constructor(
            private $state: angular.ui.IStateService,
            private chatStore: ChatStore,
            private chatService: ChatService,
            private activityRegistrationService: ActivityRegistrationService,
            private $mdDialog,
            private toastMessageService: ToastMessageService,
            private emptyGuid: System.Guid,
            private personResource: PersonResource
        ) {
            this.personObserver = customerCardStore.state$
                .map(s => s && s.personal && s.personal.person && s.personal.person)
                .filter(person => person !== null)
                .distinctUntilChanged()
                .subscribe(person => {
                    this.person = person;
                });
        }

        public $onInit() {
            const hasConversations = (state: ChatState) => !!(state && state.conversations);
            const pickedUpConversations = (state: ChatState) => state.conversations.filter(x => !!x.id);

            this.conversationObserver = this.chatStore.state$
                .filter(hasConversations)
                .distinctUntilChanged()
                .subscribe(s => {
                    this.conversations = s.conversations.filter(x => !!x.id);
                    this.conversationsStatistics = s.conversationsStatistics;
                });

            this.activeConversationObserver = this.chatStore.state$
                .map(x => x && x.activeId)
                .distinctUntilChanged()
                .subscribe(conversationId => {
                    if (!conversationId || conversationId !== this.activeConversationId)
                        this.lastActiveConversationId = this.activeConversationId;

                    this.activeConversationId = conversationId;
                });
        }

        public getUserConversations() {
            return this.conversations.filter(x => x.operator && x.operator.id);
        }

        public $onDestroy() {
            if (this.personObserver) {
                this.personObserver.dispose();
                this.personObserver = null;
            }

            if (this.conversationObserver) {
                this.conversationObserver.dispose();
                this.conversationObserver = null;
            }

            if (this.activeConversationObserver) {
                this.activeConversationObserver.dispose();
                this.activeConversationObserver = null;
            }
        }

        public getPendingMessages(conversation: ChatConversation): number {
            if (this.activeConversationId === conversation.id) return 0;

            const stats = this.conversationsStatistics.find(s => s && s.id === conversation.id);
            const lastReadTime = stats ? stats.lastReadTime : new Date(2019, 1, 1);

            const numberOfPendingMessages =
                conversation.messages &&
                conversation.messages.filter(
                    message => message.direction === MessageDirections.toOperator && message.sentOn > lastReadTime
                );

            if (!numberOfPendingMessages) return 0;

            return numberOfPendingMessages.length;
        }

        public setAcwStatus = (conversation: ChatConversation) => {
            if (this.isConversationInACWStatus(conversation)) return;

            this.chatService.startAfterCallWork(conversation.id);
        };

        public detachOperator = (conversation: ChatConversation) => {
            if (this.isConversationInACWStatus(conversation)) return;

            this.chatService.detachOperator(conversation.id);
        };

        public linkCustomer = (conversation: ChatConversation, person: PersonModel) => {
            if (!conversation || !person || person.id === conversation.customer.id) return;

            this.chatService.link(conversation.id, person);
        };

        public unlinkCustomer = (conversation: ChatConversation) => {
            if (!conversation || conversation.isAnonymous || this.isConversationInACWStatus(conversation)) return;

            this.chatService.unlinkCustomer(conversation.id);
        };

        public createCustomer(conversation: ChatConversation) {
            const person: CreateCustomerModel = {
                customerId: this.emptyGuid,
                email: conversation.customer.email,
                firstName: conversation.customer.firstName,
                gender: null,
                insertion: '',
                lastName: conversation.customer.lastName,
                phoneNumber: '',
                externalId: null,
                agentInfoId: this.emptyGuid
            };

            this.$mdDialog
                .show({
                    template: '<relation-popup person="person"></relation-popup>',
                    targetEvent: null,
                    clickOutsideToClose: false,
                    preserveScope: true,
                    locals: { person },
                    controller: [
                        '$scope',
                        'person',
                        ($scope, person) => {
                            $scope.person = person;
                        }
                    ]
                })
                .then((customer: CustomerModel) => {
                    const errorMessage =
                        'Fout opgetreden tijdens koppelen van het gesprek. Open de relatiekaart om het nogmaals te proberen.';
                    if (!customer) {
                        this.toastMessageService.error(errorMessage);
                        return;
                    }

                    this.personResource
                        .getById({ id: customer.personId })
                        .$promise.then((person: PersonModel) => {
                            this.chatService.link(conversation.id, person);
                        })
                        .catch(() => {
                            this.toastMessageService.error(errorMessage);
                        });
                });
        }

        public openMenu(conversation: ChatConversation, $event) {
            $event.stopPropagation();

            this.isCustomerCardOpen = this.$state.current.name.indexOf('customerservice.customercard.') > -1;
            this.currentOpenedConversationId = conversation.id;

            /*this.chatService.chatBarMenuEvent(conversation.id, !conversation.isMenuOpened);*/
        }

        public closeMenu = (conversation: ChatConversation) => {
            this.currentOpenedConversationId = null;
        };

        public toggleMenu(conversation: ChatConversation, $event) {
            if (this.currentOpenedConversationId === conversation.id) this.closeMenu(conversation);
            else this.openMenu(conversation, $event);
        }

        public openConversation(conversation: ChatConversation) {
            if (!conversation) return;

            this.chatService.openConversation(conversation.id);

            /*
            if (isConversationPopupStatusNewOrMinimized) {
                this.toggleChatWindow(conversation);
            }*/
            if (conversation.customer && conversation.customer.id) {
                const initQuestion =
                    (conversation.messages && conversation.messages.length && conversation.messages[0].text) || '';
                this.navigateToCustomerCard(conversation.customer.id, initQuestion);
            }
        }

        public canCreateRelation(): boolean {
            return window.user.permissions.relationWrite;
        }

        private navigateToCustomerCard(customerId: System.Guid, question: string): void {
            const chatChannelName = ChannelType[ChannelType.Chat];

            this.$state.go('customerservice.customercard.timeline', {
                personId: customerId,
                selectedPanel: SelectedPanel.Registration,
                channel: chatChannelName,
                query: question
            });

            setTimeout(() => {
                this.activityRegistrationService.selectPanel(SelectedPanel.Registration);
                this.activityRegistrationService.search(question);
            });
        }

        public closeConversation = (id: System.Guid) => {
            this.chatService.endAfterCallWork(id);
        };

        public isActiveConversation(id: System.Guid): boolean {
            return id === this.activeConversationId;
        }

        public isActiveOrOpenedConversation(id: System.Guid): boolean {
            const isOrWasActiveId = this.activeConversationId || this.lastActiveConversationId;
            const isOpenedId = this.currentOpenedConversationId;

            const isActiveOrOpened = isOrWasActiveId ? isOrWasActiveId === id : isOpenedId === id;

            return isActiveOrOpened;
        }

        public reduceVisibleChats() {
            if (this.visibleChatsCount > 0) {
                this.visibleChatsCount--;
            }
            this.collapsedTabsOpened = false;
        }

        public increaseVisibleChats() {
            this.visibleChatsCount++;
            this.collapsedTabsOpened = false;
        }

        public toggleCollapsedTabs(forceClose?: boolean) {
            if (forceClose) {
                this.collapsedTabsOpened = false;
                return;
            }

            this.collapsedTabsOpened = !this.collapsedTabsOpened;
        }

        public moveChatInfront(conversation: ChatConversation) {
            // this.chatService.moveOperatorChatInfront(conversation.id);
            this.openConversation(conversation);
        }

        public updatePhoneNumber(conversation: ChatConversation) {
            /*this.personResource.getById({ id: conversation.customer.id }).$promise.then((person: PersonModel) => {
                const phoneNumber = this.createPhoneNumberModel(conversation.customer.phoneNumber);
                if (!person.contactDetails.phoneNumbers[0]) {
                    person.contactDetails.phoneNumbers.push(phoneNumber);
                } else {
                    person.contactDetails.phoneNumbers[1] = phoneNumber;
                }
                this.personResource.update(person).$promise.then(() => {
                    this.chatService.setCustomerPhoneNumberAsUpdated(conversation.id);
                });
            });*/
        }

        public canUpdatePhoneNumber(conversation: ChatConversation) {
            const personPhoneNumbers = this.person && this.person.contactDetails.phoneNumbers.map(p => p.original);

            return (
                conversation.providerType === 1 &&
                (!personPhoneNumbers || !personPhoneNumbers.find(n => n === conversation.customer.phoneNumber)) &&
                conversation.isAnonymous &&
                conversation.isLinked
            );
        }

        private isConversationInACWStatus = (conversation: ChatConversation): boolean =>
            Umbrella.ChatConversationHandling.isAcwConversation(conversation.status);

        private createPhoneNumberModel(phoneNumber: string): ContactPhoneNumberModel {
            return {
                original: phoneNumber,
                description: '',
                number: phoneNumber,
                hyphenated: '',
                type: Umbrella.Modules.Telephony.PhoneType.Unknown
            };
        }
    }
}
