/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    caseFlowHub: Umbrella.CaseFlowProcessing.CaseFlowHubProxy;
}

namespace Umbrella.CaseFlowProcessing {
    import Guid = System.Guid;
    import CaseFlowStepModel = Umbrella.Modules.CaseFlow.CaseFlowStepModel;

    interface TaskModel {
        id: Guid;
        referenceId: Guid;
    }

    export interface CaseFlowHubClient {
        stepFinished(caseFlowId: Guid);
        stepPickedUp(caseFlowId: Guid);
        stepTasksCreated(caseFlowId: Guid);
        stepTaskFinished(caseFlowId: Guid);
        updated(caseFlowId: Guid);
    }

    export interface CaseFlowHubProxy extends HubProxy {
        client: CaseFlowHubClient;
    }

    export interface CaseFlowStepFinishedEvent {
        caseFlowId: System.Guid;
    }

    export interface CaseFlowStepPickedUpEvent {
        caseFlowId: System.Guid;
    }

    export interface CaseFlowStepTasksCreatedEvent {
        caseFlowId: System.Guid;
    }

    export interface CaseFlowStepTaskFinishedEvent {
        caseFlowId: System.Guid;
    }

    export interface CaseFlowUpdatedEvent {
        caseFlowId: System.Guid;
    }

    export const caseFlowStepFinished$ = new Rx.Subject<
        CaseFlowStepFinishedEvent
    >();

    export const caseFlowStepPickedUp$ = new Rx.Subject<
        CaseFlowStepPickedUpEvent
    >();

    export const caseFlowStepTasksCreated$ = new Rx.Subject<
        CaseFlowStepTasksCreatedEvent
    >();

    export const caseFlowStepTaskFinished$ = new Rx.Subject<
        CaseFlowStepTaskFinishedEvent
    >();

    export const caseFlowUpdated$ = new Rx.Subject<
        CaseFlowUpdatedEvent
    >();

    angular
        .module('CaseFlow')
        .factory('CaseFlowHub', () => $.connection.caseFlowHub);

    Modules.onSignalRInitialized(() => {
        $.connection.caseFlowHub.client.stepFinished = (
            caseFlowId: Guid
        ) => caseFlowStepFinished$.onNext({ caseFlowId });

        $.connection.caseFlowHub.client.stepPickedUp = (
            caseFlowId: Guid
        ) => caseFlowStepPickedUp$.onNext({ caseFlowId });

        $.connection.caseFlowHub.client.stepTasksCreated = (
            caseFlowId: Guid,
        ) => caseFlowStepTasksCreated$.onNext({ caseFlowId });

        $.connection.caseFlowHub.client.stepTaskFinished = (
            caseFlowId: Guid,
        ) => caseFlowStepTaskFinished$.onNext({ caseFlowId });

        $.connection.caseFlowHub.client.updated = (
            caseFlowId: Guid
        ) => caseFlowUpdated$.onNext({ caseFlowId });
    });
}
