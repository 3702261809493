/// <reference path="../../../Scripts/TypeScript/rxjs/rx.lite.experimental.d.ts"/>

namespace Umbrella.CustomerService.CustomerCard {
    import BillModel = Modules.Payments.Billing.BillModel;
    import BillModelWithAddress = Modules.Payments.Billing.BillModel.BillModelWithAddress;
    import BillResource = Umbrella.CustomerService.CustomerCard.Bills.BillResource;
    import IContractResource = Modules.Housing.IContractResource;
    import ContractModel = Modules.Housing.ContractModel;
    import ContractFilterModel = Umbrella.Modules.Housing.ContractFilterModel;

    interface BillResponseModel {
        bills: PagedItemsModel<BillModelWithAddress>;
        hasMultipleContracts: boolean;
    }

    @Service('CustomerService', 'CustomerCardBillInfoService')
    @Inject('CustomerCardStore', 'BillResource', 'ContractResource')
    export class CustomerCardBillInfoService extends BaseStoreService<
        CustomerCardState,
        CustomerCardEvent,
        CustomerCardStore
    > {
        constructor(
            store: CustomerCardStore,
            private billResource: BillResource,
            private contractResource: IContractResource
        ) {
            super(store);
        }

        public ensureLoaded(pageSize = 20): void {
            const state = this.getState();
            if (!state.billInfo || !state.billInfo.contracts)
                this.loadContractFilters();

            if (!state.billInfo || state.billInfo.contractId)
                this.loadBills(0, pageSize, bills => bills);
        }

        public loadContractFilters(): void {
            const state = this.getState();
            if (state.personal && state.personal.person) {
                const customerId = state.personal.person.roles['customer'];
                if (!customerId) return;

                this.contractResource.getAllFilterValuesByRoleId({id: customerId})
                .$promise.then(data => {
                    this.emit({
                        type: 'CustomerCardBillsContractFilterLoaded',
                        contracts: data
                    });
                });
            } else {
                this.store.state$
                    .map(x => x && x.personal)
                    .filter(
                        personal => !personal.loading && !personal.loadError
                    )
                    .take(1)
                    .subscribe((p: Personal.State) =>
                        this.contractResource.getAllFilterValuesByRoleId({id: p.person.roles['customer']})
                            .$promise.then(data => {
                                this.emit({
                                    type: 'CustomerCardBillsContractFilterLoaded',
                                    contracts: data
                                });
                            })
                    );
            }
        }

        public reload(): void {
            const state = this.store.getState();
            if (!state.personal || !state.personal.person) return;

            const pageSize =
                state.billInfo && state.billInfo.bills
                    ? state.billInfo.bills.pageSize
                    : 20;
            this.loadBills(0, pageSize, bills => bills);
        }

        public filterByContract(contractId: System.Guid) {
            if (
                !this.getState().billInfo ||
                !this.getState().billInfo.contractId ||
                this.getState().billInfo.contractId !== contractId
            )
                this.filterBills(contractId, 0, 20, bills => bills);
        }

        public loadMore(): void {
            const state = this.store.getState();
            const pagedItems = state.billInfo && state.billInfo.bills;

            if (!state.billInfo.contractId) {
                this.loadBills(
                    pagedItems.page + 1,
                    pagedItems.pageSize,
                    bills => ({
                        items: pagedItems
                            ? pagedItems.items.concat(bills.items)
                            : bills.items,
                        total: bills.total,
                        page: bills.page,
                        pageSize: bills.pageSize
                    })
                );
            } else {
                this.filterBills(
                    state.billInfo.contractId,
                    pagedItems.page + 1,
                    pagedItems.pageSize,
                    bills => ({
                        items: pagedItems
                            ? pagedItems.items.concat(bills.items)
                            : bills.items,
                        total: bills.total,
                        page: bills.page,
                        pageSize: bills.pageSize
                    })
                );
            }
        }

        private loadBillsByPerson(
            page: number,
            pageSize,
            personId: System.Guid,
            loaded: ((
                a: PagedItemsModel<BillModel>
            ) => PagedItemsModel<BillModel>)
        ) {
            this.emit({
                type: 'CustomerCardBillsLoadingEvent',
                page,
                pageSize
            });

            this.billResource.getPagedLinkedToPersonByPersonId({
                    id: personId,
                    page,
                    pageSize
            }).$promise
                .then(
                    response =>
                        this.emit({
                            type: 'CustomerCardBillsLoadedEvent',
                            bills: loaded(response)
                        }),
                    error =>
                        this.emit({
                            type: 'CustomerCardBillsLoadErrorEvent',
                            error
                        })
                );
        }

        private loadBills(
            page: number,
            pageSize,
            loaded: ((
                a: PagedItemsModel<BillModelWithAddress>
            ) => PagedItemsModel<BillModelWithAddress>)
        ) {
            const state = this.getState();
            if (state.personal && state.personal.person) {
                const personId = state.personal.person.id;
                if (!personId) return;

                this.loadBillsByPerson(page, pageSize, personId, loaded);
            } else {
                this.store.state$
                    .map(x => x && x.personal)
                    .filter(
                        personal => !personal.loading && !personal.loadError
                    )
                    .take(1)
                    .subscribe((p: Personal.State) =>
                        this.loadBillsByPerson(
                            page,
                            pageSize,
                            p.person.id,
                            loaded
                        )
                    );
            }
        }

        private async filterBills(
            contractId: System.Guid,
            page: number,
            pageSize,
            loaded: ((
                a: PagedItemsModel<BillModelWithAddress>
            ) => PagedItemsModel<BillModelWithAddress>)
        ) {
            const state = this.getState();
            if (
                state.personal.person &&
                state.personal.person.roles['customer']
            ) {
                this.emit({
                    type: 'CustomerCardBillsFilteringEvent',
                    contractId,
                    page: 0,
                    pageSize: 20
                });

                const contract = await this.contractResource.getById({
                    id: contractId
                }).$promise;

                this.billResource
                    .getPagedByContractId({ id: contractId, page, pageSize })
                    .$promise.then(bills => {
                        const newBills = {
                            ...bills,
                            items: bills.items.map(b => ({
                                ...b,
                                fullAddress: contract.unit.fullAddressLine
                            }))
                        };
                        this.emit({
                            type: 'CustomerCardBillsFilteredEvent',
                            contractId,
                            bills: loaded(newBills)
                        });
                    })
                    .catch(e =>
                        this.emit({
                            type: 'CustomerCardBillsFilterErrorEvent',
                            error: e
                        })
                    );
            } else {
                this.store.state$
                    .map(x => x && x.personal)
                    .filter(
                        personal => !personal.loading && !personal.loadError
                    )
                    .take(1)
                    .subscribeOnCompleted(() =>
                        this.filterBills(contractId, page, pageSize, loaded)
                    );
            }
        }
    }
}
