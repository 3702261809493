/// <reference path="../../../Decorators.ts" />
/// <reference path="../../../Window.d.ts" />

namespace Umbrella.CustomerService.ColleagueCard {
    import IMenuTab = Modules.IMenuTab;
    import IStateMenuTab = Modules.IStateMenuTab;
    import ColleagueModel = Umbrella.Modules.Colleagues.ColleagueModel;

    @Component('CustomerService', {
        selector: 'colleaguecard-menu',
        template: `
            <div class="uc-cardmenu sectionmenu green"><menu-tabs tabs="vm.tabs" md-theme="umb-green"></menu-tabs></div>
        `,
        bindings: {
            colleague: '<'
        }
    })
    @Inject('$stateParams', 'ColleagueResource')
    class ColleagueCardMenuComponent {
        public tabs: IMenuTab[];
        public colleague: ColleagueModel.Detailed;

        public $onInit() {
            this.createTabs();
        }

        private createTabs() {
            this.tabs = [];
            if (window.config.facets.calendar)
                this.tabs.push({
                    title: 'agenda',
                    state: 'customerservice.colleaguecard.calendar'
                });

            if (window.user.permissions.customerCard) {
                this.tabs.push({
                    title: 'tijdlijn',
                    state: 'customerservice.colleaguecard.activities'
                });
                if (window.user.permissions.phoneViewColleagueHistory || (this.colleague && window.user.id === this.colleague.id)) {
                    this.tabs.push({
                        title: 'telefonie',
                        state: 'customerservice.colleaguecard.telephony'
                    });
                }
            }
        }

        private createTab(title: string, state: string): IStateMenuTab {
            return {
                title,
                state
            };
        }

        private addTab(tab: IStateMenuTab) {
            this.tabs.push(tab);
        }
    }
}
