/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/angularjs/angular-ui-router.d.ts"/>
/// <reference path="../../../../Modules/SelfService/Appointments/Appointment.d.ts"/>

namespace Umbrella.Modules.SelfService.Appointments {
    import AppointmentManagementModel = Umbrella.Modules.Planning.AppointmentManagementModel;

    @Component('SelfService', {
        selector: 'appointment-timeline-information',
        templateUrl:
            '/Modules/SelfService/Appointments/AppointmentTimelineComponent/AppointmentTimeline.html',
        bindings: {
            appointmentId: '<'
        }
    })
    @Inject('AppointmentResource')
    export class ApppointmentTimelineComponent {
        public appointmentId: System.Guid;
        public appointment: AppointmentManagementModel;
        constructor(private appointmentResource: AppointmentResourceClass) {}
        public $onInit() {
            if (this.appointmentId) {
                this.appointmentResource
                    .getById({ id: this.appointmentId })
                    .$promise.then(data => (this.appointment = data));
            }
        }
    }
}
