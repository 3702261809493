﻿/// <reference path="../../../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../../../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    contractHub: Umbrella.CustomerService.CustomerCard.Contracts.IContractHubProxy;
}

namespace Umbrella.CustomerService.CustomerCard.Contracts {
    export interface IContractHubProxy extends HubProxy {
        client: IContractHubClient;
        server: IContractHubServer;
    }

    export const contractHubOnContractUpdated$ = new Rx.Subject<System.Guid>();

    export interface IContractHubClient {
        contractUpdated(contractId: System.Guid);
    }

    export interface IContractHubServer {
        subscribe(id: System.Guid);
        unsubscribe(id: System.Guid);
    }    

    angular
        .module('CustomerService')
        .factory('ContractHub', () => $.connection.contractHub);

    Modules.onSignalRInitialized(() => {
        $.connection.contractHub.client.contractUpdated = contractId => {
            contractHubOnContractUpdated$.onNext(contractId);
        };
    });
}
