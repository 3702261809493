namespace Umbrella.CustomerService.CustomerCard.Activities.Registration {
    export interface IOnChannelSelectedParam {
        value: ChannelInfo;
    }

    @Component('Knowledgebase', {
        selector: 'card-knowledgebase-channel-selection-panel',
        templateUrl:
            '/CustomerService/_CustomerCard/_Activities/Registration/CardKnowledgebaseChannelSelectionPanelComponent/CardKnowledgebaseChannelSelectionPanel.html',
        bindings: {
            channels: '<',
            onChannelSelected: '&'
        }
    })
    class CardKnowledgebaseChannelSelectionPanelComponent {
        public channels: ChannelInfo[];
        public onChannelSelected: (params: IOnChannelSelectedParam) => void;

        public selectChannel(channel: ChannelInfo): void {
            this.onChannelSelected({ value: channel });
        }
    }
}
