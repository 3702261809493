/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Scope.d.ts"/>
/// <reference path="../../Window.d.ts"/>
/// <reference path="AccountResourceProvider.ts" />

namespace Umbrella.Modules.Accounts {
    import AccountModel = Umbrella.Accounts.AccountModel;

    @Service('Umbrella', 'AccountService')
    @Inject('$q', 'AccountResource', '$http')
    export class AccountService {
        constructor(
            private $q: ng.IQService,
            private accountResource: AccountResource,
            private $http: ng.IHttpService
        ) {}

        get(personId: System.Guid): ng.IPromise<AccountModel.Activation> {
            return this.accountResource.getByPersonId({ id: personId })
                .$promise;
        }

        create(
            personId: System.Guid,
            email: string
        ): ng.IHttpPromise<AccountModel.Activation> {
            if (!this.canCreate()) throw new Error('Cannot create account');

            return this.$http<AccountModel.Activation>({
                url: '/api/v1/account/' + personId,
                method: 'POST',
                params: { username: email }
            });
        }

        delete(personId: System.Guid): ng.IPromise<{}> {
            return this.accountResource.deleteAccount({ id: personId })
                .$promise;
        }

        startRecovery(email: string): ng.IPromise<{}> {
            if (!this.canRecover()) throw new Error('Cannot reject');

            const task = this.$q.defer();
            this.accountResource
                .startRecovery({ email })
                .$promise.then(
                    () => task.resolve(),
                    error => task.reject(error)
                );

            return task.promise;
        }

        canCreate(): boolean {
            return (
                window.config.modules.accounts.enabled &&
                window.user.permissions.accountCreate
            );
        }

        canRecover(): boolean {
            return (
                window.config.modules.accounts.enabled &&
                window.user.permissions.accountRecover
            );
        }

        isActive(account: AccountModel): boolean {
            return account && account.isActivated;
        }

        isInactive(account: AccountModel): boolean {
            return account && !account.isActivated && !account.isBlocked;
        }

        isRecoverable(account: AccountModel): boolean {
            return this.canRecover() && this.isActive(account);
        }

        block(
            personId: System.Guid
        ): ng.IPromise<Umbrella.Accounts.AccountModel.Activation> {
            return this.accountResource.block({ id: personId }).$promise;
        }

        unblock(
            personId: System.Guid
        ): ng.IPromise<Umbrella.Accounts.AccountModel.Activation> {
            return this.accountResource.unblock({ id: personId }).$promise;
        }
    }
}
