﻿namespace Umbrella.Modules.Mail {
    import MailModel = Umbrella.Mail.MailModel;
    import MailRecipientModel = Umbrella.Mail.MailRecipientModel;

    @Controller('Mail', 'ForwardMailPopupController')
    @Inject('$scope', 'OutgoingMailResource')
    export class ForwardMailPopupController {
        constructor(
            private $scope: ng.IScope,
            outgoingMailResource: IOutgoingMailResourceClass
        ) {
            $scope.to = '';
            jQuery.each($scope.mail.to, (i, a) => {
                $scope.to += (a.emailAddress.value || a.emailAddress) + '; ';
            });
            $scope.to = $scope.to.substring(0, $scope.to.length - 2);

            $scope.cc = '';
            jQuery.each($scope.mail.cc, (i, a) => {
                $scope.cc += (a.emailAddress.value || a.emailAddress) + '; ';
            });
            $scope.cc = $scope.cc.substring(0, $scope.cc.length - 2);

            $scope.bcc = '';
            jQuery.each($scope.mail.bcc, (i, a) => {
                $scope.bcc += (a.emailAddress.value || a.emailAddress) + '; ';
            });
            $scope.bcc = $scope.bcc.substring(0, $scope.bcc.length - 2);

            $scope.send = () => {
                $scope.waiting = false;
                outgoingMailResource
                    .resend({
                        id: $scope.mail.id,
                        to: $scope.to,
                        cc: $scope.cc,
                        bcc: $scope.bcc
                    })
                    .$promise.then(() => {
                        $scope.waiting = true;
                        $scope.$modalSuccess();
                    });
            };
        }
    }
}
