interface Date {
    clone(): Date;
    moveToDayOfWeek(dayOfWeek: number): Date;
    moveToFirstDayOfWeek(): Date;
    moveToLastDayOfWeek(): Date;
    moveToFirstDayOfMonth(): Date;
    moveToLastDayOfMonth(): Date;
    getMonthName(lang: string): string;
    getMonthNameByNumber(lang: string, number: number): string;
    getMonthNameShort(lang: string): string;
    getWeekNumber(): number;
    addHours(hours: number): Date;
    addDays(days: number): Date;
    addWeeks(weeks: number): Date;
    addMonths(months: number): Date;
    addMinutes(minutes: number): Date;
    addSeconds(seconds: number): Date;
    daysDifference(d2: Date): number;
    minutesDifference(d2: Date): number;
    businessDaysDifference(d2: Date): number;
    isWeekDay(): boolean;
    isValid(): boolean;
    toYYYYMMDDString(): string;
    midnight(): Date; 
    toDDMMYYYYString(): string;
}

const locale: any = {
    nl: {
        month_names: [
            'januari',
            'februari',
            'maart',
            'april',
            'mei',
            'juni',
            'juli',
            'augustus',
            'september',
            'oktober',
            'november',
            'december'
        ],
        month_names_short: [
            'jan',
            'feb',
            'maa',
            'apr',
            'mei',
            'jun',
            'jul',
            'aug',
            'sep',
            'okt',
            'nov',
            'dec'
        ],
        day_names: [
            'zondag',
            'maandag',
            'dinsdag',
            'woensdag',
            'donderdag',
            'vrijdag',
            'zaterdag'
        ]
    }
};

function pad(nr: number): string {
    if (nr < 10) return '0' + nr;
    return nr.toString();
}

/**
 * Clone a date
 * @returns a clone of the current date
 */
Date.prototype.clone = function() {
    return new Date(this.getTime());
};

/**
 * Moves to the specified day of the week in the current week
 *
 * @param dayOfWeek day of the week, 0 = sunday (first) to 6 = saturday (last)
 * @returns {}
 */
Date.prototype.moveToDayOfWeek = function(dayOfWeek) {
    if (dayOfWeek < 0 || dayOfWeek > 6)
        throw new Error('day of week must be between 0 and 6');

    const d = this.clone();
    d.setDate(this.getDate() - this.getDay() + dayOfWeek);
    return d;
};

/**
 * Moves a date to the monday of the current week
 *
 * @returns monday of the current week
 */
Date.prototype.moveToFirstDayOfWeek = function() {
    return this.moveToDayOfWeek(1);
};

/**
 * Moves a date to the next sunday after the current week
 *
 * @returns sunday after the current week
 */
Date.prototype.moveToLastDayOfWeek = function() {
    return this.moveToDayOfWeek(0).addDays(7);
};

Date.prototype.moveToFirstDayOfMonth = function() {
    return new Date(this.getFullYear(), this.getMonth(), 1);
};

Date.prototype.moveToLastDayOfMonth = function() {
    return new Date(this.getFullYear(), this.getMonth() + 1, 0);
};

Date.prototype.getMonthName = function(lang) {
    lang = lang && lang in locale ? lang : 'en';
    return locale[lang].month_names[this.getMonth()];
};

Date.prototype.getMonthNameByNumber = (lang, number) => {
    lang = lang && lang in locale ? lang : 'en';
    return locale[lang].month_names[number];
};

Date.prototype.getMonthNameShort = function(lang) {
    lang = lang && lang in locale ? lang : 'en';
    return locale[lang].month_names_short[this.getMonth()];
};

Date.prototype.getWeekNumber = function() {
    const d = this.clone();
    d.setHours(0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const d2: any = new Date(d.getFullYear(), 0, 1);
    return Math.ceil(((d - d2) / 8.64e7 + 1) / 7);
};

Date.prototype.addHours = function(h) {
    const d = this.clone();
    d.setTime(this.getTime() + h * 60 * 60 * 1000);
    return d;
};

Date.prototype.addMinutes = function(m) {
    const d = this.clone();
    d.setTime(this.getTime() + m * 60 * 1000);
    return d;
};

Date.prototype.addSeconds = function(s) {
    const d = this.clone();
    d.setTime(this.getTime() + s * 1000);
    return d;
};

Date.prototype.addDays = function(days) {
    const d = this.clone();
    d.setDate(this.getDate() + days * 1);
    return d;
};

Date.prototype.addWeeks = function(weeks) {
    const d = this.clone();
    d.setDate(this.getDate() + weeks * 7);
    return d;
};

Date.prototype.addMonths = function(months) {
    const n = this.getDate();
    const d = this.clone();
    d.setDate(1);
    d.setMonth(this.getMonth() + months);
    d.setDate(
        Math.min(n, new Date(this.getYear(), this.getMonth() + 1, 0).getDate())
    );
    return d;
};

Date.prototype.daysDifference = function(d2: any) {
    const d = Math.abs(this - d2);
    return Math.floor(d / (24 * 60 * 60 * 1000));
};

Date.prototype.minutesDifference = function(d2: any) {
    const d = Math.abs(this - d2);
    return Math.floor(d / (60 * 1000));
};

Date.prototype.businessDaysDifference = function(d2) {
    const d = this.daysDifference(d2);
    let t = d % 7;

    let w1: number, w2: number;
    if (this < d2) {
        w1 = this.getDay();
        w2 = d2.getDay();
    } else {
        w2 = this.getDay();
        w1 = d2.getDay();
    }
    if (w1 > w2) t -= 2;
    if ((w1 === 0 && w2 === 6) || w1 === 6) t--;
    return Math.abs(Math.floor(d / 7) * 5 + t);
};

Date.prototype.isValid = function() {
    return !isNaN(this.getTime());
};

Date.prototype.isWeekDay = function() {
    const day = this.getDay();
    return 0 < day && day < 6;
};

Date.prototype.toYYYYMMDDString = function() {
    return `${this.getFullYear()}-${pad(this.getMonth() + 1)}-${pad(
        this.getDate()
    )}`;
};

Date.prototype.toDDMMYYYYString = function() {
    return `${pad(this.getDate())}-${pad(this.getMonth() + 1)}-${this.getFullYear()}`;
};

/**
 * Get the current date (midnight)
 *
 * @returns midnight (00:00:00) for the current date
 */
Date.prototype.midnight = function() {
    const d = this.clone();
    d.setHours(0, 0, 0);
    return d;
};
