namespace Umbrella {
    angular.module('Umbrella').config([
        '$mdThemingProvider',
        ($mdThemingProvider: any) => {
            defineColorPalettes($mdThemingProvider);
            defineThemes($mdThemingProvider);

            $mdThemingProvider.alwaysWatchTheme(true);
        }
    ]);

    function defineThemes($mdThemingProvider: any) {
        $mdThemingProvider
            .theme('umb-purple')
            .primaryPalette('umb-purple')
            .accentPalette('grey');

        $mdThemingProvider
            .theme('umb-blue')
            .primaryPalette('umb-blue')
            .accentPalette('grey');

        $mdThemingProvider
            .theme('umb-green')
            .primaryPalette('umb-green')
            .accentPalette('grey');
    }

    function defineColorPalettes($mdThemingProvider: any) {
        $mdThemingProvider.definePalette('umb-green', {
            50: 'eaf5ea',
            100: 'c9e7cb',
            200: 'a6d7a8',
            300: '82c785',
            400: '67bb6a',
            500: '4caf50',
            600: '45a849',
            700: '3c9f40',
            800: '339637',
            900: '248627',
            A100: 'c5ffc7',
            A200: '92ff95',
            A400: '5fff64',
            A700: '46ff4b',
            contrastDefaultColor: 'light',
            contrastDarkColors: [50, 100, 200, 300, 400, 500, 'A100', 'A200', 'A400', 'A700'],
            contrastLightColors: ['600', '700', '800', '900']
        });
        $mdThemingProvider.definePalette('umb-blue', {
            50: 'e1f5fe',
            100: 'b3e5fc',
            200: '81d4fa',
            300: '4fc3f7',
            400: '29b6f6',
            500: '03a9f4',
            600: '03a2f3',
            700: '0298f1',
            800: '028fef',
            900: '017eec',
            A100: 'ffffff',
            A200: 'e0efff',
            A400: 'add4ff',
            A700: '93c7ff',
            contrastDefaultColor: 'light',
            contrastDarkColors: ['50', '100', '200', '300', '400', 'A100', 'A200', 'A400', 'A700'],
            contrastLightColors: ['500', '600', '700', '800', '900']
        });

        $mdThemingProvider.definePalette('umb-purple', {
            50: 'F8E6F8',
            100: 'EFC2EF',
            200: 'E499E4',
            300: 'D970D9',
            400: 'D051D0',
            500: 'C832C8',
            600: 'C22DC2',
            700: 'BB26BB',
            800: 'B41FB4',
            900: 'B41FB4',
            A100: 'FFD9FF',
            A200: 'FFA6FF',
            A400: 'FF73FF',
            A700: 'FF59FF',
            contrastDefaultColor: 'light',
            contrastDarkColors: ['50', '100', '200', '300', '400', 'A100', 'A200', 'A400', 'A700'],
            contrastLightColors: ['500', '600', '700', '800', '900']
        });
    }
}
