﻿/// <reference path="../../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    unitHub: Umbrella.CustomerService.UnitCard.IUnitHubProxy;
}

namespace Umbrella.CustomerService.UnitCard {
    import UnitModel = Modules.Housing.UnitModel;

    export interface IUnitHubProxy extends HubProxy {
        client: IUnitHubClient;
        server: IUnitHubServer;
    }

    export interface IUnitHubClient {
        updated(unitId: System.Guid);
        started(unitId: System.Guid);
    }
    export interface IUnitHubServer {
        startJitUpdate(unitId: System.Guid);
        leave(unitId: System.Guid);
    }

    angular
        .module('CustomerService')
        .factory('UnitHub', () => $.connection.unitHub);

    export const unitHubOnUpdated$ = new Rx.Subject<System.Guid>();
    export const unitHubOnStarted$ = new Rx.Subject<System.Guid>();

    Modules.onSignalRInitialized(() => {
        $.connection.unitHub.client.updated = unitId => {
            unitHubOnUpdated$.onNext(unitId);
        };

        $.connection.unitHub.client.started = unitId => {
            unitHubOnStarted$.onNext(unitId);
        };
    });
}
