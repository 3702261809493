/// <reference path="../Scripts/TypeScript/angularjs/angular-resource.d.ts" />

namespace Umbrella.CustomerService {
    import VendorModel = Umbrella.Modules.Vendor.VendorModel;
    import VendorEditModel = Umbrella.Modules.Vendor.VendorEditModel;

    export interface SearchParams extends PagedRequestParams {
        id: string;
        onlyWithWorkAreas?: boolean;
        expertiseIds: System.Guid[];
    }

    export interface VendorResource
        extends angular.resource.IResource<VendorModel> {}
    export interface VendorResourceClass
        extends angular.resource.IResourceClass<VendorResource> {
        getById(params: IdRequestParams): ng.resource.IResource<VendorModel>;
        getForEditingById(
            params: IdRequestParams
        ): ng.resource.IResource<VendorEditModel>;
        getByIds(
            params: IdsRequestParams
        ): ng.resource.IResource<VendorModel[]>;
        search(
            params: SearchParams
        ): ng.resource.IResource<SearchItemsModel<VendorModel>>;
        update(params: VendorModel): ng.resource.IResource<VendorModel>;
        createNewVendor(
            params: VendorModel
        ): ng.resource.IResource<VendorModel>;
    }

    angular.module('CustomerService').factory('VendorResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/vendor/:action/:id',
                {},
                {
                    getAll: {
                        method: 'GET',
                        isArray: false,
                        params: { page: 0, pageSize: 10 }
                    },
                    getAllWithWorkAreas: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'all',
                            id: 'withworkareas',
                            page: 0,
                            pageSize: 10
                        }
                    },
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getForEditingById: {
                        method: 'Get',
                        isArray: false,
                        params: { action: 'edit', id: '@id' }
                    },
                    getByIds: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'set' }
                    },
                    getRelatedAcquaintance: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'relatedacquaintance', id: '' }
                    },
                    createNewVendor: {
                        method: 'POST',
                        isArray: false,
                        params: {}
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: {}
                    },
                    search: {
                        method: 'GET',
                        params: {
                            action: 'search',
                            id: '@id',
                            page: 0,
                            pageSize: 10,
                            onlyWithWorkAreas: false
                        },
                        isArray: false
                    }
                }
            )
    ]);
}
