/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard.Overview {
    angular.module('Dashboard').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state(
                'dashboard.caseflow.overview.details.information',
                {
                    url: '/informatie',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Zaak informatie',
                        authorize: ps => [
                            ps.viewCaseFlow,
                            ps.viewDetailedCaseFlow
                        ]
                    },
                    views: {
                        tabcontent: {
                            template:
                                '<caseflow-information state="state$ | async:this"></caseflow-information>',
                            controller: Umbrella.createComponentRouteController<
                                CaseFlowState,
                                CaseFlowInformationComponentState
                            >('CaseFlowOverviewStore', s => ({
                                selfServiceReport:
                                    s &&
                                    s.caseInfo &&
                                    s.caseInfo.selfServiceReport
                            }))
                        }
                    }
                }
            );
        }
    ]);
}
