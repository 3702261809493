namespace Umbrella.Modules.Repairs {
    @Component('Repairs', {
        templateUrl:
            '/Modules/Repairs/PlanRepairRequestComponent/PlanRepairRequestComponent.html',
        selector: 'plan-repairrequest',
        bindings: {
            repairRequest: '<',
            onChange: '&'
        }
    })
    @Inject('PlanningResource')
    export class PlanRepairRequestComponent {
        repairRequest: RepairRequestModel;

        loading: boolean;

        planningFrom: Date;
        planningUntil: Date;
        planningOptions: AvailablePlanningModel;
        planningOption: PlanningOptionModel;
        planningError: any = null;
        onChange: (data: any) => void;
        loadNextWeekWhenUnavailable: boolean;

        constructor(private planningResource: IPlanningResource) {}

        private $onInit() {
            this.planningFrom = new Date().midnight().moveToFirstDayOfWeek();
            this.planningUntil = this.planningFrom.moveToLastDayOfWeek();
            this.loadNextWeekWhenUnavailable = true;
            this.loadPlanning();
        }

        private loadPlanning(): void {
            if (this.loading) return;

            this.loading = true;
            this.planningError = null;
            this.planningResource
                .getAvailablePlanningOptions({
                    repairRequestId: this.repairRequest.id,
                    from: this.planningFrom,
                    until: this.planningUntil,
                    external: false
                })
                .$promise.then(
                    planningOptions => {
                        this.loading = false;
                        if (
                            planningOptions.availablePlanningOptions.length ===
                                0 &&
                            !planningOptions.isLastPlannableWeek &&
                            this.loadNextWeekWhenUnavailable
                        ) {
                            this.nextWeek();
                        } else {
                            this.loadNextWeekWhenUnavailable = false;
                            this.planningOptions = planningOptions;
                        }
                    },
                    errorReason => {
                        this.planningError = errorReason;
                        this.loading = false;
                    }
                );
        }

        public nextWeek(): void {
            this.planningFrom = this.planningFrom.addDays(7);
            this.planningUntil = this.planningUntil.addDays(7);

            this.loadPlanning();
        }

        public hasNextWeek(): boolean {
            return (
                !this.loading &&
                this.planningOptions &&
                !this.planningOptions.isLastPlannableWeek
            );
        }

        public previousWeek(): void {
            this.planningFrom = this.planningFrom.addDays(-7);
            this.planningUntil = this.planningUntil.addDays(-7);

            this.loadPlanning();
        }

        public hasPreviousWeek(): boolean {
            return !this.loading && new Date() < this.planningFrom;
        }

        public selectPlanning(option: PlanningOptionModel) {
            this.onChange({ option });
        }
    }
}
