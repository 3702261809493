/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/angularjs/angular-file-upload.d.ts"/>
/// <reference path="../../../../Decorators.ts" />
/// <reference path="../EditCustomerDataComponent.ts"/>

namespace Umbrella.CustomerService.CustomerCard {
    import AgentFacetService = Umbrella.Modules.AgentFacetService;
    import CustomerModel = Umbrella.Modules.Customers.CustomerModel;
    import AddressModel = Umbrella.Modules.AddressModel;

    @Component('CustomerService', {
        selector: 'customer-personal-data',
        templateUrl: '/CustomerService/_CustomerCard/_Personal/CustomerPersonalDataComponent/CustomerPersonalData.html',
        bindings: {
            person: '<',
            customer: '<',
            personIsDeletable: '<',
            writableFields: '<'
        }
    })
    @Inject(
        '$state',
        'AgentFacetService',
        'ToastMessageService',
        'CustomerCardPersonInfoService',
        '$mdDialog',
        'AddressResource'
    )
    class CustomerPersonalDataComponent extends EditCustomerDataComponent {
        public user = window.user;
        public customer: CustomerModel;
        public addressLoading: boolean;
        public addressError: boolean;
        public yesterday: Date;
        public personIsDeletable: boolean;

        constructor(
            private $state: ng.ui.IStateService,
            agentFacetService: AgentFacetService,
            toastMessageService: ToastMessageService,
            personInfoService: CustomerCardPersonInfoService,
            private $mdDialog,
            private addressService: AddressResource
        ) {
            super(agentFacetService, toastMessageService, personInfoService);
        }

        public $onInit() {
            this.yesterday = new Date().addDays(-1);
        }

        public $onChanges(changes: { person: IBindingChange<PersonModel> }) {
            this.clonePerson();
        }

        public static isAddressRequired(address: AddressModel): boolean {
            return !!(address.zipCode || address.houseNumber || address.street || address.city);
        }

        public getAddress(address: AddressModel) {
            const isFacetAvailable =
                window.config &&
                window.config.facets &&
                window.config.facets.geography &&
                window.config.facets.geography[0] &&
                window.config.facets.geography[0].available;

            if (!isFacetAvailable) return;
            if (!address.zipCode || address.zipCode.length < 5 || address.houseNumber <= 0) return;

            this.addressLoading = true;
            this.addressError = false;

            this.addressService
                .get({
                    zipCode: address.zipCode,
                    houseNumber: address.houseNumber,
                    houseNumberSuffix: address.houseNumberExtension
                })
                .$promise.then((data: Umbrella.Modules.GetAddressModel[]) => {
                    this.addressError = false;

                    if (data.length === 0) {
                        address.street = '';
                        address.city = '';
                    } else {
                        const firstAddress = data[0];

                        address.street = firstAddress.street;
                        address.city = firstAddress.city;
                    }
                })
                .catch(() => {
                    this.addressError = true;

                    address.street = '';
                    address.city = '';
                })
                .finally(() => {
                    this.addressLoading = false;
                });
        }

        public showCountry(address: AddressModel): Boolean {
            return (
                address.country && address.country.toLowerCase() != 'nederland' && address.country.toLowerCase() != 'nl'
            );
        }

        public hasUpdateablePersonFields(): boolean {
            return (
                this.isAuthorizedToUpdateField(PersonFieldTypes.Initials) ||
                this.isAuthorizedToUpdateField(PersonFieldTypes.FirstName) ||
                this.isAuthorizedToUpdateField(PersonFieldTypes.Insertion) ||
                this.isAuthorizedToUpdateField(PersonFieldTypes.LastName) ||
                this.isAuthorizedToUpdateField(PersonFieldTypes.BirthDate) ||
                this.isAuthorizedToUpdateField(PersonFieldTypes.Gender) ||
                this.isAuthorizedToUpdateField(PersonFieldTypes.PostalAddress)
            );
        }

        public deletePerson(): void {
            const dialog = this.$mdDialog
                .confirm()
                .title(`Relatie verwijderen`)
                .htmlContent(`Weet je zeker dat je deze relatie wilt verwijderen?`)
                .ok('Verwijderen')
                .cancel('Annuleren');

            this.$mdDialog.show(dialog).then(_ =>
                this.personInfoService
                    .delete(this.person.id)
                    .then(() => {
                        this.toastMessageService.info(`Relatie succesvol verwijderd`);
                        this.$state.go('dashboard.tasks');
                    })
                    .catch(e => {
                        this.toastMessageService.error(`Relatie kan niet verwijderd worden. Probeer het nogmaals.`);
                    })
            );
        }
    }
}
