/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../Scope.d.ts"/>

namespace Umbrella.Modules.Cases {
    import Guid = System.Guid;
    import StatusFilter = Umbrella.Modules.StatusFilter;

    export interface ICaseHistoryResource
        extends CaseHistoryModel,
            angular.resource.IResource<CaseHistoryModel> {}
    export interface ISimpleCaseResource
        extends Umbrella.Modules.Customers.CaseModel,
            angular.resource.IResource<
                Umbrella.Modules.Customers.CaseModel
            > {}
    export interface ISimpleCaseV2Resource
        extends Umbrella.Modules.Customers.v2.CaseModel,
            angular.resource.IResource<
                Umbrella.Modules.Customers.v2.CaseModel
            > {}

    export interface DeleteMediaRequestParams extends IdRequestParams {
        mediaId: System.Guid;
    }

    export interface FilterHistoryParams extends PagedRequestWithIdParams {
        query: string;
        category: string;
        contractId: Guid;
        sssIds: System.Guid[];
        tagIds: System.Guid[];
        status: StatusFilter;
        channel: ChannelType;
    }

    export interface ICaseHistoryResourceClass
        extends angular.resource.IResourceClass<ICaseHistoryResource> {
        getById(params: IdRequestParams): ISimpleCaseResource;
        getByIdV2(params: IdRequestParams): ISimpleCaseV2Resource;
        getCaseMedia(params: IdRequestParams): ng.resource.IResource<Umbrella.Modules.Customers.caseMedia[]>;
        deleteMediaItem(params: DeleteMediaRequestParams): ISimpleCaseResource;
        getByContractId(
            params: PagedRequestWithIdParams
        ): ng.resource.IResource<PagedItemsModel<CaseHistoryModel>>;
        getByEmployeeId(
            params: PagedRequestWithIdParams
        ): ng.resource.IResource<PagedItemsModel<CaseHistoryModel>>;
        getBySubjectId(
            params: PagedRequestWithIdParams
        ): ng.resource.IResource<PagedItemsModel<CaseHistoryModel>>;
        getByUnitId(
            params: PagedRequestWithIdParams
        ): ng.resource.IResource<PagedItemsModel<CaseHistoryModel>>;
        getByComplexId(
            params: PagedRequestWithIdParams
        ): ng.resource.IResource<PagedItemsModel<CaseHistoryModel>>;
        getByPersonId(
            params: PagedRequestWithIdParams
        ): ng.resource.IResource<PagedItemsModel<CaseHistoryModel>>;
        filterByPersonIdAndFilters(
            params: FilterHistoryParams
        ): ng.resource.IResource<PagedItemsModel<CaseHistoryModel>>;
        startTimelineExport(params: IdRequestParams): ISimpleCaseResource;
        deleteItem(
            params: IdRequestParams,
            succes?: Function,
            error?: Function
        ): ng.resource.IResource<PagedItemsModel<CaseHistoryModel>>;
        restoreItem(
            params: IdRequestParams,
            succes?: Function,
            error?: Function
        ): ng.resource.IResource<PagedItemsModel<CaseHistoryModel>>;
    }

    angular.module('Umbrella').service('CaseHistoryResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/case/:action/:id/:subaction',
                {
                    action: '@action',
                    id: '@id'
                },
                {
                    getById: {
                        method: 'GET',
                        isArray: false
                    },
                    getByIdV2: {
                        url: 'api/v2/case/:action/:id',
                        method: 'GET',
                        isArray: false
                    },
                    getCaseMedia: {
                        url: 'api/v2/case/:action/:id',
                        method: 'GET',
                        isArray: true,
                        params: {
                            action: 'casemedia',
                            id: '@id'
                        }
                    },
                    deleteMediaItem: {
                        url: '/api/v1/case/:id/:action/:mediaId',
                        method: 'DELETE',
                        isArray: false,
                        params: {
                            action: 'deletemediaitem',
                            id: '@id',
                            mediaId: '@mediaId'
                        }
                    },
                    getByContractId: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'contract',
                            id: ''
                        }
                    },
                    getByEmployeeId: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'employee',
                            id: ''
                        }
                    },
                    getBySubjectId: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'role',
                            id: ''
                        }
                    },
                    getByUnitId: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'unit',
                            id: ''
                        }
                    },
                    getByComplexId: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'complex',
                            id: ''
                        }
                    },
                    getByPersonId: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'person',
                            id: ''
                        }
                    },
                    filterByPersonIdAndFilters: {
                        url: '/api/v1/case/person/:id/filters',
                        method: 'POST',
                        isArray: false
                    },
                    startTimelineExport: {
                        url: '/api/v1/case/person/:id/export',
                        method: 'POST',
                        isArray: false,
                    },
                    deleteItem: {
                        url: '/api/v1/case/:id/',
                        method: 'DELETE',
                        isArray: false,
                        params: {
                            id: '@id'
                        }
                    },
                    restoreItem: {
                        url: '/api/v1/case/:id/:action',
                        method: 'PUT',
                        isArray: false,
                        params: {
                            action: 'restore',
                            id: '@id'
                        }
                    }
                }
            )
    ]);
}
