namespace Umbrella.Modules {
    export interface IStateMenuTab {
        state: string;
        title: string;
    }

    export interface IMenuTab {
        state?: string;
        routeTail?: string;
        title: string;
    }

    @Component('Umbrella', {
        selector: 'menu-tabs',

        template: `
            <md-tabs md-selected="vm.selectedTabIndex" md-paginated>
                <md-tab
                    ng-repeat="tab in vm.tabs"
                    label="{{ tab.title }}"
                    ui-sref="{{ tab.state }}"
                    data-qa="{{ tab.state }}"
                ></md-tab>
            </md-tabs>
        `,
        bindings: {
            tabs: '<',
            cssClass: '@'
        }
    })
    @Inject('$state', '$scope')
    class MenuTabsComponent {
        public cssClass: string;
        public tabs: IStateMenuTab[];
        public selectedTabIndex: number;
        public name: string;

        constructor(private $state: ng.ui.IStateService, private $scope: any) {}

        public $onInit() {
            this.$scope.$watch(() => this.$state.current.name, () => {
                this.selectedTabIndex = this.getSelectedTabIndex();
            });
        }

        public isActiveTab(tab: IStateMenuTab): boolean {
            return this.$state.includes(tab.state);
        }

        private getSelectedTabIndex(): number {
            let index = 0;
            for (const tab of this.tabs) {
                if (this.isActiveTab(tab)) return index;

                index += 1;
            }

            return index;
        }
    }
}
