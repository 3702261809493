namespace Umbrella.ChatConversationHandling {
    @Pipe('Chat', 'chatConversationStatus')
    class ChatConversationStatusFilter {
        public transform(status: string | number) {
            switch (status) {
                case 'Pending':
                case 0:
                    return 'Nieuw';
                case 'Detached':
                case 1:
                    return 'Vrijgegeven';
                case 'Active':
                case 2:
                    return 'Opgepakt';
                case 'ACW':
                case 3:
                    return 'Nawerktijd';
                case 'Ended':
                case 4:
                    return 'Beëindigd';
            }

            return '';
        }
    }

    @Pipe('Chat', 'isPendingConversation')
    class ChatConversationPendingStatusFilter {
        public transform(status: string | number) {
            return isPendingConversation(status);
        }
    }

    @Pipe('Chat', 'isDetachedConversation')
    class ChatConversationDetachedStatusFilter {
        public transform(status: string | number) {
            return isDetachedConversation(status);
        }
    }

    @Pipe('Chat', 'isActiveConversation')
    class ChatConversationActiveStatusFilter {
        public transform(status: string | number) {
            return isActiveConversation(status);
        }
    }

    @Pipe('Chat', 'isAcwConversation')
    class ChatConversationAcwStatusFilter {
        public transform(status: string | number) {
            return isAcwConversation(status);
        }
    }

    @Pipe('Chat', 'isEndedConversation')
    class ChatConversationEndedStatusFilter {
        public transform(status: string | number) {
            return isEndedConversation(status);
        }
    }

    @Pipe('Chat', 'isActiveOrAcwConversation')
    class ChatConversationActiveOrAcwStatusFilter {
        public transform(status: string | number) {
            return isActiveOrAcwConversation(status);
        }
    }

    @Pipe('Chat', 'isAcwOrEndedConversation')
    class ChatConversationAcwOrEndedStatusFilter {
        public transform(status: string | number) {
            return isAcwOrEndedConversation(status);
        }
    }
}
