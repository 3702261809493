/// <reference path="../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../Modules/Umbrella/SignalR.ts" />
/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.CustomerService {
    angular.module('CustomerService').run([
        '$injector',
        ($injector: ng.auto.IInjectorService) => {
            $injector.get('CustomerCardCustomerInfoService');
            $injector.get('OfflineService');

            Modules.onSignalRInitialized(() => {
                // Loading services as background listeners with injector because otherwise SignalR hubs are unavailable
                $injector.get('PersonJitService');
                $injector.get('ActivityJitService');
                $injector.get('ContractJitService');
                $injector.get('BillJitService');
                $injector.get('UnitJitService');
                $injector.get('UnitActivityJitService');
                $injector.get('UnitContractJitService');
                $injector.get('UnitValuationJitService');
                $injector.get('ChatService');
            });
        }
    ]);
}
