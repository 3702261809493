/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella {
    angular.module('Umbrella').directive('mdToggle', () => {
        return {
            link(scope, element) {
                element.on('click', () => {
                    let ulElement = element[0].getElementsByClassName(
                        'md-toggle'
                    );
                    let liElements = ulElement[0].getElementsByTagName('li')
                        .length;
                    let liElementHeight = ulElement[0].getElementsByTagName(
                        'li'
                    )[0].clientHeight;

                    if (ulElement[0].clientHeight > 0) {
                        ulElement[0].setAttribute('style', 'height: 0;');
                    } else {
                        ulElement[0].setAttribute(
                            'style',
                            'height:' + liElements * liElementHeight + 'px'
                        );
                    }
                });
            }
        };
    });
}
