namespace Umbrella.Modules.Colleagues {
    @Pipe('Colleagues', 'availabilityDescription')
    class AvailabilityDescriptionFilter {
        public transform(available) {
            switch (available) {
                case 'Away':
                    return 'Afwezig';
                case 'Available':
                    return 'Beschikbaar';
                case 'Busy':
                    return 'Bezet';
                default:
                    return 'Niet bekend';
            }
        }
    }
}
