﻿namespace Umbrella.KCC.CaseFlowDashboard {
    @Component('Dashboard', {
        selector: 'finish-caseflow-step-task-popup',
        templateUrl:
            '/CaseFlowProcessing/_Overview/_Details/_Steps/FinishCaseFlowStepTaskPopupComponent/FinishCaseFlowStepTaskPopup.html',
        bindings: {
            $close: '&',
            $dismiss: '&',
            outcomes: '<'
        }
    })
    @Inject('$mdDialog')
    class FinishCaseFlowStepTaskPopupComponent {
        public outcomes: Umbrella.CaseFlow.CaseFlowStepTaskModel.CaseFlowStepTaskOutcome[];
        public outcomeId?: System.Guid;

        constructor(private $mdDialog) {}

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        public confirm(): void {
            this.$mdDialog.hide(this.outcomeId);
        }

        public onOutcomeSelected(value: System.Guid) {
            this.outcomeId = value;
        }

        public needsOutcome(): boolean {
            return this.outcomes && this.outcomes.length > 0;
        }

        public canClose(): boolean {
            return !this.needsOutcome() || !!this.outcomeId;
        }
    }
}
