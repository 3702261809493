namespace Umbrella.Modules.Telephony {
    @Pipe('Telephony', 'isAuthLoginCapable')
    class IsAuthLoginCapableFilter {
        public transform(
            capability: Umbrella.Telephony.PhoneCapabilities | string
        ): boolean {
            if (!capability) return false;

            switch (capability.toString().toLowerCase()) {
                case '4':
                case 'authlogin':
                    return true;
                default:
                    return false;
            }
        }
    }
}
