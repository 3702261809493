namespace Umbrella.Modules.Telephony {
    @Pipe('Telephony', 'phoneCallStatus')
    class PhoneCallStatusFilter {
        public transform(
            input: Umbrella.Telephony.PhoneCallStatus | string
        ): string {
            if (!input) return '';

            switch (input.toString().toLowerCase()) {
                case '6':
                case '106':
                case 'onhold':
                case 'tryresume':
                    return 'orange';
                default:
                    return 'green';
            }
        }
    }
}
