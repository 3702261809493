/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../Scope.d.ts"/>
/// <reference path="../../../Decorators.ts" />

namespace Umbrella.CustomerService {
    import CaseHistoryModel = Modules.CaseHistoryModel;
    import ConfigResourceClass = Modules.IConfigResourceClass;

    @Component('CustomerService', {
        selector: 'activity-timeline',
        templateUrl: '/CustomerService/ActivityTimeline/ActivityTimelineComponent/ActivityTimeline.html',
        bindings: {
            cases: '<',
            loading: '<',
            disableRegistrationOfFollowUpActivities: '<',
            onLoadMore: '&',
            disableActivityButton: '<'
        }
    })
    @Inject('$scope', 'ConfigResource')
    export class ActivityTimelineComponent {
        public cases: PagedItemsModel<CaseHistoryModel>;
        public loading: boolean;
        public config: TimelineConfig;
        public disableRegistrationOfFollowUpActivities: boolean;

        constructor(private $scope: ng.IScope, private configResource: ConfigResourceClass) {}

        $onInit() {
            this.config = window.config && window.config.modules && window.config.modules.timeline;
        }

        $onChanges(bindings: { cases: IBindingChange<PagedItemsModel<CaseHistoryModel>> }) {
            if (bindings.cases && bindings.cases.currentValue && bindings.cases.previousValue) {
                this.trackCaseChanges(bindings.cases);
            }
        }

        public onLoadMore: () => void;

        public loadMore() {
            this.onLoadMore();
        }

        private trackCaseChanges(cases: IBindingChange<PagedItemsModel<CaseHistoryModel>>): void {
            if (this.isFirstCaseChanged(cases.currentValue, cases.previousValue)) {
                const firstCaseId = cases.currentValue.items[0].case.id;
                const selector = '.activity-item.' + firstCaseId;

                this.$scope.$emit('ScrollToCommand', { selector });
            }
        }

        private isFirstCaseChanged(
            currentCases: PagedItemsModel<CaseHistoryModel>,
            prevCases: PagedItemsModel<CaseHistoryModel>
        ): boolean {
            if (!currentCases || !prevCases) return false;

            const currentHasItems = currentCases.items && currentCases.items.length > 0;
            const prevHasItems = prevCases.items && prevCases.items.length > 0;

            if (!currentHasItems || !prevHasItems) return false;

            const firstCurrentCase = currentCases.items[0].case;
            const firstPrevCase = prevCases.items[0].case;

            return firstCurrentCase.id !== firstPrevCase.id;
        }
    }
}
