namespace Umbrella.CustomerService.UnitCard.Valuation {
    export class HousingValuationDirective implements angular.IDirective {
        restrict = 'EA';
        templateUrl =
            '/CustomerService/_UnitCard/_Valuation/HousingValuationDirective/HousingValuation.html';
        replace = true;
        transclude = true;
        controller = HousingValuationController;
        scope = {
            unit: '='
        };

        static factory(): angular.IDirectiveFactory {
            return () => new HousingValuationDirective();
        }
    }
    angular
        .module('CustomerService')
        .directive('housingValuation', HousingValuationDirective.factory());
}
