﻿/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import MediaModel = Umbrella.Media.MediaModel;
    import DetailedCaseFlowModel = Umbrella.CaseFlow.DetailedCaseFlowModel;
    import CaseModel = Umbrella.Modules.Customers.v2.CaseModel;
    import ICaseHistoryResourceClass = Umbrella.Modules.Cases.ICaseHistoryResourceClass;

    export interface CaseFlowDetailsComponentState {
        case: DetailedCaseFlowModel;
        caseHistory: CaseModel;
        person: PersonModel;
        mediaIds: System.Guid[];
    }

    @Component('Dashboard', {
        selector: 'caseflow-details',
        templateUrl: '/CaseFlowProcessing/_Overview/_Details/_Steps/CaseFlowDetailsComponent/CaseFlowDetails.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('$stateParams', 'CaseHistoryResource')
    class CaseFlowDetailsComponent {
        public state: CaseFlowDetailsComponentState;

        public id: System.Guid;
        public filename: string;
        public media: Umbrella.Media.MediaModel;
        public actionUrl: string;

        constructor(
            private $stateParams: CaseFlowOverviewStateParams,
            private caseHistoryResource: ICaseHistoryResourceClass
        ) {
            this.id = $stateParams.id;
        }

        public $onInit() {
            this.media = {
                id: null,
                type: null,
                title: '',
                created: null,
                isDocument: null,
                isImage: null,
                isLink: null,
                lastModified: null,
                mediaCategory: null,
            };
        }

        public $onChanges() {
            this.actionUrl = this.state.case
                ? Config.makeAbsoluteApiUrl('/api/v1/case/media/' + this.state.case.umbrellaCaseId)
                : '';
        }

        public deleteMediaItem(mediaItem: MediaModel): void {
            this.caseHistoryResource
                .deleteMediaItem({
                    id: this.state.caseHistory.id,
                    mediaId: mediaItem.id
                });
        }
    }
}
