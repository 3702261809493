/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.CustomerCard.Registrations {
    import RegistrationModel = Umbrella.HousingAllocation.Registrations.Detailed;
    import Activity = Umbrella.HousingAllocation.Registration.RegistrationActivityModel;
    import ActivityType = Umbrella.HousingAllocation.Registration.RegistrationActivityModelType;
    import OptionStatus = Umbrella.HousingAllocation.Propositions.PropositionOptionStatus;
    import RegistrationType = Umbrella.HousingAllocation.Registrations.RegistrationType;
    import AgentFacetService = Umbrella.Modules.AgentFacetService;

    @Component('CustomerService', {
        selector: 'registration-timeline-item',
        templateUrl:
            '/CustomerService/_CustomerCard/_Registrations/RegistrationTimelineItemComponent/RegistrationTimelineItem.html',
        bindings: {
            registration: '<'
        }
    })
    @Inject(
        'AgentFacetService',
        'CustomerCardRegistrationInfoService',
        'ToastMessageService'
    )
    class RegistrationTimelineItemComponent {
        public showDetails: boolean;
        public showActivities: boolean;
        public user = window.user;
        public registration: RegistrationModel;
        public registrationClone: RegistrationModel;
        public activities: Activity[];
        private editMode: boolean;
        private updating: boolean;

        constructor(
            private agentFacetService: AgentFacetService, 
            private customerCardRegistrationInfoService: CustomerCardRegistrationInfoService,
            private toastMessageService: ToastMessageService
            ) {
            this.showDetails = false;
            this.showActivities = false;
        }

        public $onChanges(changes: {
            registration: IBindingChange<RegistrationModel>;
        }) {
            if (changes.registration.currentValue) {
                this.activities = this.getActivities();
            }
        }

        public getRegistrationType(
            type:
                | RegistrationType
                | number
                | string
        ): string {
            switch (type) {
                case RegistrationType.Housing:
                case "Housing":
                case 1:
                    return "Woning";
                case RegistrationType.Garage:
                case "Garage":
                case 2:
                    return "Garage";
                case RegistrationType.Parking:
                case "Parking":
                case 3:
                    return "Parkeergelegenheid";
            }

            return type.toString();
        }

        public hasUpdateableRegistrationFields(): boolean {
            return this.agentFacetService.hasUpdateableRegistrationFields();
        }
        public enterEditMode() {
            this.cloneRegistration();
            this.editMode = true;
        }
        public cancelAndLeaveEditMode(): void {
            this.registrationClone = null;
            this.editMode = false;
        }
        public fieldEditable(fieldName: string): boolean {
            return this.editMode && this.agentFacetService.isErpFieldUpdateable(fieldName);
        }

        public saveAndLeaveEditMode() {
            if (this.updating) return;

            this.updating = true;
            this.registration = this.registrationClone;
            const updateRegistrationModel = { 
                id: this.registrationClone.id, 
                personId: null, 
                grossIncome: this.registrationClone.grossIncome, 
                numberOfChildren: this.registrationClone.numberOfChildren, 
                numberOfPeople: this.registrationClone.numberOfPeople
            }

            this.customerCardRegistrationInfoService
                .update(updateRegistrationModel)
                .then(() => {

                    this.editMode = false;
                    this.toastMessageService.success(
                        'Wijzigingen zijn succesvol opgeslagen.'
                    );
                })
                .catch(() => {
                    this.toastMessageService.error(
                        'Fout opgetreden bij het opslaan van de wijzigingen.'
                    );
                })
                .finally(() => (this.updating = false));
        }
        public delete() {
            if (this.registration.registrationNumber != '') {
                this.toastMessageService.error(
                    'Inschrijving met een registratienummer kan niet worden verwijderd');
                return;
            }
            this.customerCardRegistrationInfoService
                .delete({ id: this.registration.id })
                .then(() => {
                    this.toastMessageService.success(
                        'Inschrijving is verwijderd.'
                    );
                    this.customerCardRegistrationInfoService.reload();
                })
                .catch(() => {
                    this.toastMessageService.error(
                        'Fout opgetreden bij het verwijderen van de inschrijving.'
                    );
                });
        }

        public cloneRegistration(): void {
            this.registrationClone = JSON.parse(JSON.stringify(this.registration));
        }
        public toggleDetails() {
            this.showDetails = !this.showDetails;
        }

        public toggleActivities() {
            this.showActivities = !this.showActivities;
        }

        public getActivityType(activity: Activity): string {
            return activity.type.toString();
        }

        private getOptionStatus(status: OptionStatus | number): string {
            return status.toString();
        }

        public getActivities(): Activity[] {
            let options = [];
            let reactions = [];

            if (this.registration.options) {
                options = this.registration.options.map(option => ({
                    title: option.proposition.name,
                    type: ActivityType.Option,
                    creationDate: option.creationDate,
                    statusText: option.statusText
                }));
            }
            if (this.registration.reactions) {
                reactions = this.registration.reactions.map(reaction => ({
                    title: reaction.advertisementTitle,
                    type: ActivityType.Reaction,
                    creationDate: reaction.createdTime,
                    statusText: reaction.statusText,
                    rank: reaction.rank
                }));
            }

            return options.concat(reactions).sort(this.byCreationDateDesc);
        }

        private byCreationDateDesc(left: Activity, right: Activity): number {
            if (left.creationDate < right.creationDate) return -1;
            if (left.creationDate > right.creationDate) return 1;
            return 0;
        }
    }
}
