﻿/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../TaskOverviewService.ts"/>

namespace Umbrella.TaskHandling {
    angular.module('TaskHandling').config([
        '$stateProvider',
        $stateProvider => {
            $stateProvider.state('dashboard.tasks.overview.details.messages', {
                url: '/berichten',
                reloadOnSearch: false,
                data: {
                    pageTitle: 'Taak berichten',
                    authorize: p => p.viewTask || p.editTask
                },
                views: {
                    tabcontent: {
                        template:
                            '<task-messages class="h-full block" state="state$ | async:this"></task-messages>',
                        controller: Umbrella.createComponentRouteController<
                            Overview.State,
                            TaskMessagesComponentState
                        >('TaskOverviewStore', s => ({
                            activities: s && s.activities
                        }))
                    }
                }
            });
        }
    ]);
}
