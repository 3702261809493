/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts" />

interface IClassAnnotationDecorator {
    (target: any): void;
    (t: any, key: string, index: number): void;
}

function getModule(moduleOrName: string | ng.IModule) {
    return typeof moduleOrName === 'string'
        ? angular.module(moduleOrName)
        : moduleOrName;
}

interface IComponentOptions extends ng.IComponentOptions {
    selector: string;
}

const selectorToCamelCase = (s: string) =>
    s.replace(/(\-[a-z])/g, (match: string) =>
        match.toUpperCase().replace('-', '')
    );

function Component(
    moduleOrName: string | ng.IModule,
    options: IComponentOptions
) {
    return (controller: Function) => {
        const module = getModule(moduleOrName);
        const selector =
            options.selector && selectorToCamelCase(options.selector);
        if (options.controllerAs === undefined) options.controllerAs = 'vm';
        module.component(selector, angular.extend(options, { controller }));
    };
}

function Service(moduleOrName: string | ng.IModule, serviceName: string) {
    return (svc: Function) => {
        const module = getModule(moduleOrName);
        module.service(serviceName, svc);
    };
}

function attachInjects(target: any, ...args: any[]): any {
    (target.$inject || []).forEach((item: string, index: number) => {
        target.prototype[(item.charAt(0) === '$' ? '$' : '$$') + item] =
            args[index];
    });
    return target;
}

type IInjectAnnotation = (...args: any[]) => IClassAnnotationDecorator;

function Inject(...args: string[]): IClassAnnotationDecorator {
    return (target: any, key?: string, index?: number): void => {
        if (index && angular.isNumber(index)) {
            target.$inject = target.$inject || [];
            target.$inject[index] = args[0];
        } else {
            target.$inject = args;
        }
    };
}

function Controller(moduleOrName: string | ng.IModule, controllerName: string) {
    return (target: Function) => {
        const module = getModule(moduleOrName);
        module.controller(controllerName, target);
    };
}

interface IRouteOptions extends ng.ui.IState {
    pageTitle?: string;
    redirectTo?: string;
}

function Route(
    moduleOrName: string | ng.IModule,
    stateName: string,
    config: IRouteOptions,
    authorize?: (userPermissions: Umbrella.PermissionsModel) => boolean
) {
    return (target: Function) => {
        const module = getModule(moduleOrName);
        module.config([
            '$stateProvider',
            ($stateProvider: angular.ui.IStateProvider) => {
                config.data = config.data || {};
                config.data.authorize = authorize;
                config.data.pageTitle =
                    config.pageTitle || config.data.pageTitle;
                $stateProvider.state(stateName, config);
            }
        ]);
    };
}

function Pipe(moduleOrName: string | ng.IModule, name: string) {
    return C => {
        function filter() {
            const args = Array.prototype.slice.call(arguments);
            const Ctor = C.bind.apply(C, [null].concat(args));
            const pipeObject = new Ctor();
            return pipeObject.transform.bind(pipeObject);
        }

        const annotatedFunction: (string | Function)[] = C.$inject || [];
        annotatedFunction.push(filter);
        const module = getModule(moduleOrName);
        module.filter(name, annotatedFunction);
    };
}
