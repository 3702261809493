namespace Umbrella.CustomerService.CustomerCard.Bills {
    import CustomerCardContractInfoService = Umbrella.CustomerService.CustomerCard.Contracts.CustomerCardContractInfoService;

    @Service('CustomerService', 'BillJitService')
    @Inject(
        'CustomerCardStore',
        'BillHub',
        'CustomerCardBillInfoService',
        'CustomerCardContractInfoService'
    )
    export class BillJitService {
        constructor(
            store: CustomerCardStore,
            private billHub: IBillHubProxy,
            private billInfoService: CustomerCardBillInfoService,
            private contractInfoService: CustomerCardContractInfoService
        ) {
            const getPersonId = (s: CustomerCardState) => s && s.personal && s.personal.person && s.personal.person.id;
            const isSelected = (s: CustomerCardState, personId: Guid) => getPersonId(s) === personId;

            billHubOnUpdated$
                .filter(personId => isSelected(store.getState(), personId))
                .debounce(() => Rx.Observable.timer(5000))
                .subscribe(_ => this.reload());

            store.state$
                .map(getPersonId)
                .distinctUntilChanged()
                .flatMapLatest(Modules.waitUntilSignalRConnected)
                .debounce(() => Rx.Observable.timer(1000))
                .subscribe(personId => this.subscribe(personId));                

            store.event$
                .where(e => e.type === 'CustomerCardPersonUnloadedEvent')
                .subscribe(e => this.unsubscribe(getPersonId(store.getState())));
        }

        private subscribe(personId: Guid) {
            this.billHub.server.subscribe(personId);
        }

        private reload() {
            this.billInfoService.reload();
            this.contractInfoService.reload(true);
        }

        private unsubscribe(personId: Guid) {
            this.billHub.server.unsubscribe(personId);
        }
    }
}
