﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Decorators.ts" />

namespace Umbrella.Search.Colleagues {
    angular.module('Search').config([
        '$stateProvider',
        '$urlRouterProvider',
        $stateProvider => {
            $stateProvider.state('search.colleagues', {
                reloadOnSearch: true,
                url: '/collegas?query',
                data: {
                    pageTitle: 'Collegas',
                    authorize: ps => ps.customerCard
                },
                views: {
                    'center@search': {
                        templateUrl:
                            '/Search/_Colleagues/ColleagueOverview.html',
                        controller: ColleagueOverviewController
                    }
                }
            });
        }
    ]);
}
