namespace Umbrella.Modules.Colleagues {
    @Pipe('Colleagues', 'presenceIcon')
    class PresenceIconFilter {
        public transform(stateType: StateType | string | number) {
            switch (stateType) {
                case StateType.Available:
                case 'Available':
                case 1:
                    return 'check_circle'; // V green
                case StateType.Unavailable:
                case 'Unavailable':
                case 2:
                    return 'remove_circle'; // - red
                case StateType.Busy:
                case 'Busy':
                case 3:
                    return 'brightness_1'; // O red
                case StateType.Offline:
                case 'Offline':
                case 4:
                    return 'radio_button_unchecked'; // O white
                // case StateType.Away:
                case 'Away':
                case 5:
                    return 'schedule'; // L yellow
                // case StateType.Unknown:
                case 'Unknown':
                case 6:
                    return 'help_outline'; // ? white
                // case StateType.ACW:
                case 'ACW':
                case 7:
                    return 'schedule'; // L red
                // case StateType.DND:
                case 'DND':
                case 8:
                    return 'remove_circle'; // - red
                default:
                    return 'help_outline';
            }
        }
    }
}
