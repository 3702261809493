/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.CustomerService.CustomerCard.Activities {
    angular.module('CustomerService').directive('resizeOnChange', [
        '$window',
        ($window: string) => {
            return {
                restrict: 'AE',
                scope: {
                    setFunction: '&'
                },
                link: (scope, element) => {
                    scope.callSetHeightColleagueColumn = () => {
                        setHeightColleagueColumn();
                    };
                    scope.setFunction({ setHeightColleagueColumn: scope.callSetHeightColleagueColumn });

                    angular.element($window).bind('resize', () => {
                        setHeightColleagueColumn();
                    });

                    function setHeightColleagueColumn(): void {
                        if (element[0]) {
                            const suggestedColleaguesWithExpertise =
                                element[0].children[element[0].children.length - 1];
                            suggestedColleaguesWithExpertise.setAttribute(
                                'style',
                                'height:' + returnSuggestedColleaguesWithExpertiseHeight() + 'px'
                            );
                        }
                    }

                    function returnSuggestedColleaguesWithExpertiseHeight(): number {
                        if (element[0]) {
                            let transferColleaguesHeight = element[0].clientHeight;
                            const transferColleaguesChildElements = <[HTMLElement]>element[0].children;
                            for (let i = 0; i < transferColleaguesChildElements.length - 1; i++) {
                                transferColleaguesHeight -= Math.ceil(transferColleaguesChildElements[i].clientHeight);
                            }
                            return transferColleaguesHeight - 20;
                        }
                        return 0;
                    }
                }
            };
        }
    ]);
}
