namespace Umbrella.Modules.Housing {
    import IResource = ng.resource.IResource;

    interface ISearchParams extends PagedRequestParams {
        query?: string;
    }

    export interface IHousingTypeResourceClass {
        new (model: HousingTypeModel);
        getById(params: IdRequestParams): IResource<HousingTypeModel>;
        getAll(): IResource<PagedItemsModel<HousingTypeModel>>;
        search(
            params: PagedRequestParams
        ): IResource<PagedItemsModel<HousingTypeModel>>;

        save(model: HousingTypeModel): IResource<{}>;
        update(model: HousingTypeModel): IResource<{}>;
        delete(params: IdRequestParams): IResource<{}>;
    }

    angular.module('Housing').service('HousingTypeResource', [
        '$resource',
        ($resource: ng.resource.IResourceService) =>
            $resource(
                '/api/v1/housingtype/:action/:id',
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getAll: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'all', id: '' }
                    },
                    search: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'search', id: '@id' }
                    },
                    save: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'add', id: '' }
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: { action: 'update', id: '' }
                    },
                    delete: {
                        method: 'DELETE',
                        isArray: false,
                        params: { action: 'delete', id: '@id' }
                    }
                }
            )
    ]);
}
