/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.UnitCard {
    import UnitModel = Modules.Housing.UnitModel;
    import AnnouncementResourceClass = Umbrella.Modules.Announcements.AnnouncementResourceClass;
    import AnnouncementModel = Umbrella.Modules.Announcements.AnnouncementModel;

    export interface UnitAnnouncementsComponentState {
        readonly loading: boolean;
        readonly unit: UnitModel;
    }

    @Component('CustomerService', {
        selector: 'unit-announcements',
        templateUrl:
            'CustomerService/_UnitCard/_Announcements/UnitAnnouncementsComponent/UnitAnnouncements.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('$stateParams', 'AnnouncementResource')
    class UnitAnnouncementsComponent {
        public state: UnitAnnouncementsComponentState;
        public announcements: PagedItemsModel<AnnouncementModel>;
        public loadingAnnouncements: boolean;
        private page = 0;

        constructor(
            private $stateParams: UnitCardStateParams,
            private announcementResource: AnnouncementResourceClass
        ) {}

        public $onInit() {
            this.loadAnnouncements();
        }

        public loadMore(): void {
            this.loadAnnouncements(this.page + 1);
        }

        private async loadAnnouncements(
            page = 0,
            pageSize = 10
        ): Promise<void> {
            const unitId =
                (this.state && this.state.unit && this.state.unit.id) ||
                this.$stateParams.unitId;
            if (!unitId || this.loadingAnnouncements) return;

            this.loadingAnnouncements = true;
            this.page = page;
            const announcements = await this.announcementResource.byUnitId({
                id: unitId,
                page,
                pageSize
            }).$promise;
            if (announcements) {
                this.announcements =
                    page === 0
                        ? announcements
                        : {
                              ...this.announcements,
                              page,
                              items: this.announcements.items.concat(
                                  announcements.items
                              )
                          };
            }
            this.loadingAnnouncements = false;
        }
    }
}
