/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard.Overview {
    import caseSelected$ = Umbrella.CaseHandling.caseSelected$;
    export interface CaseFlowListComponentState {
        cases: PagedItemsModel<Umbrella.CaseFlow.CaseFlowOverviewModel>;
        loading: boolean;
    }

    @Component('Dashboard', {
        selector: 'caseflow-list',
        templateUrl: '/CaseFlowProcessing/_Overview/_List/CaseFlowListComponent/CaseFlowList.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('$state', '$location', '$stateParams', 'CaseFlowOverviewService')
    class CaseFlowListComponent {
        public state: CaseFlowListComponentState;
        public page = this.$stateParams.page || 0;
        public pageSize = this.$stateParams.pageSize || 9;
        public navPage = this.page + 1;
        public selectedId: System.Guid;
        public width = window.innerWidth;

        constructor(
            private $state: ng.ui.IStateService,
            private $location: ng.ILocationService,
            private $stateParams: CaseFlowOverviewStateParams,
            private caseFlowOverviewService: CaseFlowOverviewService
        ) {
            this.selectedId = this.$stateParams.id;
        }

        public pageChanged(newPage): void {
            this.page = newPage;
            const query = this.$location.search();
            this.$location.search({ ...query, page: this.page });
        }

        public async requestMoreCases(pageSize: number) {
            await this.caseFlowOverviewService.load(pageSize);
        }

        public openItem(id: System.Guid): void {
            this.selectedId = id;
            this.$state.go('.details', { id });
        }
    }
}
