/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.UnitCard.Activities {
    @Service('CustomerService', 'UnitActivityJitService')
    @Inject('UnitCardStore', 'UnitActivitiesService', 'UnitActivityHub', 'ToastMessageService')
    export class UnitActivityJitService {
        private message: any;

        constructor(
            store: UnitCardStore,
            service: Umbrella.CustomerService.UnitCard.Activities.UnitActivitiesService,
            private unitActivityHub: IUnitActivityHubProxy,
            private toastMessageService: ToastMessageService
        ) {
            const getUnitId = (state: UnitCardState) => state && state.id;

            unitActivityHubOnUpdated$
                .filter(unitId => getUnitId(store.getState()) === unitId)
                .subscribe(() => {
                    service.ensureLoaded(undefined, true);
                    this.clearToastMessage();
                });

            unitActivityHubOnStarted$
                .filter(unitId => getUnitId(store.getState()) === unitId)
                .subscribe(() => {
                    if (this.message) {
                        return;
                    }

                    this.message = this.toastMessageService.updating('Tijdlijn gegevens', 60000, {
                        onHidden: () => {
                            this.message = null;
                        }
                    });

                    setTimeout(() => {
                        if (this.message) {
                            this.message = null;
                        }
                    }, 60000);
                });

            store.state$
                .map(getUnitId)
                .distinctUntilChanged()
                .flatMapLatest(Modules.waitUntilSignalRConnected)
                .debounce(() => Rx.Observable.timer(1000))
                .subscribe(unitId => this.subscribe(unitId));

            store.event$.where(e => e.type === 'UnitCardUnloadedEvent').subscribe(_ => this.unsubscribe(getUnitId(store.getState())));
        }

        private subscribe(unitId: System.Guid) {
            if (unitId) {
                this.unitActivityHub.server.subscribe(unitId);
            }
            this.clearToastMessage();
        }

        private unsubscribe(unitId: System.Guid) {
            if (unitId) {
                this.unitActivityHub.server.unsubscribe(unitId);
            }
            this.clearToastMessage();
        }

        private clearToastMessage() {
            if (this.message) {
                this.toastMessageService.clear(this.message);
                this.message = null;
            }
        }
    }
}
