/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../dashboard.chat.routeconfig.ts"/>
/// <reference path="../../../Chat.d.ts"/>

namespace Umbrella.ChatConversationHandling {
    import ConversationStatus = Umbrella.ChatConversationHandling.ConversationStatus;

    export interface ChatConversationListComponentState {
        enabled: boolean;
        registered: boolean;
        loading: boolean;
        conversations: ChatOverviewItemModel[];
    }

    @Component('Chat', {
        selector: 'chat-conversation-list',
        templateUrl:
            '/ChatConversationHandling/_Overview/_List/ChatConversationListComponent/ChatConversationList.html',
        bindings: {
            state: '<'
        }
    })
    class ChatConversationListComponent {
        public state: ChatConversationListComponentState;
        public selectedId: System.Guid;

        selectConversation(conversationId: System.Guid) {
            this.selectedId = conversationId;
        }
    }
}
