﻿namespace Umbrella.CustomerService {
    import IResource = angular.resource.IResource;
    import SelfServiceScenarioModel = Modules.SelfService.SelfServiceScenarioModel;
    import ImpersonationResponseModel = Umbrella.Modules.SelfService.ImpersonationResponseModel;

    export interface NameRequestParams {
        id: string;
    }

    export interface SelfServiceScenarioResourceClass
        extends SelfServiceScenarioModel,
            IResource<SelfServiceScenarioModel> {}

    export interface SelfServiceScenarioResource
        extends angular.resource.IResourceClass<SelfServiceScenarioResourceClass> {
        getAllByTagId(params: IdRequestParams): ng.resource.IResource<SelfServiceScenarioResourceClass[]>;
        getById(params: IdRequestParams): ng.resource.IResource<SelfServiceScenarioModel.Detailed>;
        getByIds(params: IdsRequestParams): ng.resource.IResource<SelfServiceScenarioModel.Detailed[]>;
        getAll(): ng.resource.IResource<SelfServiceScenarioModel[]>;
        getByName(params: NameRequestParams): ng.resource.IResource<SelfServiceScenarioModel.Detailed>;
        impersonate(params: {
            id: System.Guid;
            personId: System.Guid;
            channelType: string;
            caseId?: System.Guid;
        }): ng.resource.IResource<ImpersonationResponseModel>;
        getActiveByPersonId(params: {
            personId: System.Guid;
        }): ng.resource.IResource<SelfServiceScenarioModel.Detailed[]>;
    }

    angular.module('SelfService').service('SelfServiceScenarioResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) => {
            const prefix = '/api/v1/selfservice/scenario/';

            return $resource(
                prefix,
                {},
                {
                    getById: {
                        url: prefix + ':id',
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getByIds: {
                        url: prefix + 'getbyids/:id',
                        method: 'GET',
                        isArray: true,
                        params: { id: '@id' }
                    },
                    getByName: {
                        url: prefix + ':id',
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getAllByTagId: {
                        url: prefix + 'forThesaurusTag/:id',
                        method: 'GET',
                        isArray: true,
                        params: { id: '@id' }
                    },
                    getAll: {
                        method: 'GET',
                        isArray: true,
                        params: {}
                    },
                    getActiveByPersonId: {
                        url: prefix + 'active/person/:personId',
                        method: 'GET',
                        isArray: true,
                        params: {}
                    },
                    impersonate: {
                        url: prefix + ':id/impersonate/:personId/:channelType',
                        method: 'POST',
                        isArray: false,
                        params: {
                            id: '@id',
                            personId: '@personId',
                            channelType: '@channelType',
                            caseId: '@caseId'
                        }
                    }
                }
            );
        }
    ]);
}
