/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.CustomerCard.Sidebar {
    import ContractModel = Modules.Housing.ContractModel;
    import OutstandingBalanceModel = Modules.Housing.OutstandingBalanceModel;
    import ContractorModel = Modules.Housing.ContractorModel;

    export interface SidebarPanelsState {
        contractorPanelCollapsed: boolean;
        contractPanelCollapsed: boolean;
        contactPanelCollapsed: boolean;
    }

    export interface PersonIndicatorEvaluationModel {
        accountNumber?: string;
        contract?: Umbrella.Modules.Housing.SimpleContractModel;
        customerNumber?: string;
        externalUrl?: string;
        active?: boolean;
        age?: number;
        characteristic?: number;
        correspondenceType?: Umbrella.Modules.CorrespondenceType;
        email?: string;
        homeAddress?: Umbrella.Modules.AddressModel;
        id?: System.Guid;
        isAggressive?: boolean;
        legalPerson?: Umbrella.Modules.LegalPersonModel;
        mobile?: string;
        name?: string;
        naturalPerson?: Umbrella.Modules.NaturalPersonModel;
        personId?: System.Guid;
        phone?: string;
        phoneNumber?: string;
        phoneNumber2?: string;
        postalAddress?: Umbrella.Modules.AddressModel;
        properties?: any;
        type?: string;
        contactDetails?: Umbrella.PersonContactDetailsModel;
        // correspondenceType: Umbrella.Modules.CorrespondenceType;
        // id: System.Guid;
        // isAggressive: boolean;
        isExcludedFromSingleService?: boolean;
        // name: string;
        roles?: System.Collections.Generic.KeyValuePair<string, System.Guid>[];
        // type: string;
        hasArrears?: boolean;
        hasPaymentArrangement?: boolean;
        hasUnpaidBills?: boolean;
        isBailiffCase?: boolean;
        outstandingBalance?: number;
    }

    export interface CustomerCardSidebarComponentState {
        loading: boolean;
        person: PersonModel;
        account: Accounts.AccountModel;
        customer: Modules.Customers.CustomerModel;
        contracts: PagedItemsModel<ContractModel.Detailed>;
        contractors: ContractorModel[];
        outstandingBalance: OutstandingBalanceModel;
        panelState: SidebarPanelsState;
    }

    export interface PersonIndicatorModel {
        Account: Accounts.AccountModel;
        Person: PersonIndicatorEvaluationModel;
    }

    export interface CustomerIndicatorModel extends PersonIndicatorModel {
        Unit: Modules.Housing.UnitModel.Extended;
    }

    export interface ContractIndicatorModel {
        Contract: ContractModel;
    }

    @Component('CustomerService', {
        selector: 'customer-card-sidebar',
        templateUrl: '/CustomerService/_CustomerCard/Sidebar/CustomerCardSidebarComponent/CustomerCardSideBar.html',
        bindings: { stateStream: '<' }
    })
    @Inject('CustomerCardSidebarService')
    class CustomerCardSidebarComponent {
        public stateStream: Rx.Subject<CustomerCardSidebarComponentState>;
        public state: CustomerCardSidebarComponentState;
        public indicatorModels: (PersonIndicatorModel | CustomerIndicatorModel | ContractIndicatorModel)[];
        private stateObserver: Rx.IDisposable;

        constructor(private customerCardSidebarService: CustomerCardSidebarService) {}

        public $onInit() {
            const personHasRoles = (x: CustomerCardSidebarComponentState) => !!(x && x.person && x.person.roles);
            const isCustomer = (x: CustomerCardSidebarComponentState) =>
                !!(x && x.person && x.person.roles['customer']);
            const allPersonDataLoaded = (x: CustomerCardSidebarComponentState) => !!(x && x.person && x.account);
            const isOutstandingBalanceLoadedOrNoFinancialPermissions = (x: CustomerCardSidebarComponentState) =>
                !window.user.permissions.billRead || (window.user.permissions.billRead && x.outstandingBalance);
            const allCustomerDataLoaded = (x: CustomerCardSidebarComponentState) =>
                !!(
                    allPersonDataLoaded(x) &&
                    x.contractors &&
                    x.contracts &&
                    isOutstandingBalanceLoadedOrNoFinancialPermissions(x)
                );

            this.stateObserver = this.stateStream
                .filter(personHasRoles)
                .filter(x => (isCustomer(x) ? allCustomerDataLoaded(x) : allPersonDataLoaded(x)))
                .distinctUntilChanged()
                .debounce(1000) //prevent lots of indicator requests because of model changes during loading the customer card
                .subscribe(state => {
                    this.state = {
                        ...state,
                        panelState:
                            this.state && this.state.panelState // HACK: To prevent delay of 1 second for state change
                                ? { ...this.state.panelState }
                                : state.panelState && { ...state.panelState }
                    };
                    if (isCustomer(state)) this.mapCustomerStateToIndicatorModel(state);
                    else this.mapPersonStateToIndicatorModel(state);
                });
        }

        public $onDestroy() {
            if (this.stateObserver) this.stateObserver.dispose();

            this.stateObserver = null;
        }

        public toggleContractorPanel(): void {
            this.customerCardSidebarService.toggleContractorPanel();
            this.state.panelState.contractorPanelCollapsed = !this.state.panelState.contractorPanelCollapsed; // HACK: To prevent delay of 1 second for state change
        }

        public toggleContractPanel(): void {
            this.customerCardSidebarService.toggleContractPanel();
            this.state.panelState.contractPanelCollapsed = !this.state.panelState.contractPanelCollapsed; // HACK: To prevent delay of 1 second for state change
        }

        public toggleContactPanel(): void {
            this.customerCardSidebarService.toggleContactPanel();
            this.state.panelState.contactPanelCollapsed = !this.state.panelState.contactPanelCollapsed; // HACK: To prevent delay of 1 second for state change
        }

        public isAuthorizedForContactPersons(): boolean {
            return window.user.permissions.contactPersonRead;
        }

        private mapCustomerStateToIndicatorModel(state: CustomerCardSidebarComponentState) {
            const person = {};
            const customer = {};
            const contract = state.contracts.items.length && state.contracts.items[0];
            const unit = contract && this.isActiveContract(contract) && contract.unit;
            const hasBailiffCase = state.contracts.items.filter(x => x.bailiffCase).length > 0;
            const hasArrears = state.contracts.items.filter(x => x.hasArrears).length > 0;
            const hasPaymentArrangement = state.contracts.items.filter(x => x.hasPaymentArrangement).length > 0;
            const hasUnpaidBills = state.contracts.items.filter(x => x.hasUnpaidBills).length > 0;

            const isPropertiesProperty = key => key.toLowerCase() === 'properties';
            Object.keys(state.person || {})
                .filter(key => !isPropertiesProperty(key))
                .map(x => (person[x] = state.person[x]));
            Object.keys(state.customer || {})
                .filter(key => !isPropertiesProperty(key))
                .map(x => (customer[x] = state.customer[x]));

            const customerProperties = state.customer && state.customer.properties ? state.customer.properties : {};
            const personProperties = state.person && state.person.properties ? state.person.properties : {};
            const properties = Object.assign(customerProperties, personProperties);

            const contractIndicatorModels = state.contracts.items
                .filter(contract => this.isActiveContract(contract))
                .map(contract => ({ Contract: { ...contract } }));
            const personIndicatorModel = {
                Account: {
                    ...state.account
                },
                Person: {
                    ...customer,
                    ...person,
                    ...{ properties },
                    ...state.outstandingBalance,
                    hasBailiffCase,
                    hasArrears,
                    hasPaymentArrangement,
                    hasUnpaidBills
                },
                Unit: {
                    ...unit
                }
            };

            this.indicatorModels = [personIndicatorModel, ...contractIndicatorModels];
        }

        private isActiveContract(contract: ContractModel) {
            return contract.status.toString() === 'Active' || contract.status === 1;
        }

        private mapPersonStateToIndicatorModel(state: CustomerCardSidebarComponentState) {
            const personIndicatorModel = {
                Account: {
                    ...state.account
                },
                Person: {
                    ...state.person
                }
            };

            this.indicatorModels = [personIndicatorModel];
        }
    }
}
