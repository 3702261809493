/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />

namespace Umbrella.Modules.Knowledgebase.TargetGroup {
    export interface ITargetGroupResource
        extends angular.resource.IResource<TargetGroupModel.Edit> {
        id: System.Guid;
        name: string;
        criteria: Umbrella.Modules.Knowledgebase.TargetGroupCriterionModel[];
    }
    export interface TargetGroupResourceClass
        extends angular.resource.IResourceClass<ITargetGroupResource> {
        getById(params: IdRequestParams): ITargetGroupResource;
        getByIds(params: { ids: System.Guid }): TargetGroupModel[];
        getAllForPersonId(params: IdRequestParams): TargetGroupModel[];
        getAll(): TargetGroupModel[];
        getAllPaged(
            params: PagedRequestParams,
            succes?: Function,
            error?: Function
        ): angular.resource.IResource<PagedItemsModel<TargetGroupModel>>;
        update(model: TargetGroupModel.Edit): ITargetGroupResource;
    }
    angular.module('Knowledgebase').service('TargetGroupResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/targetgroups/:action/:id',
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getAllForPersonId: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'forperson', id: '@id' }
                    },
                    getAll: {
                        method: 'GET',
                        isArray: true,
                        params: {}
                    },
                    getAllPaged: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'paged' }
                    },
                    getByIds: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'set' }
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: { id: '@id' }
                    }
                }
            )
    ]);
}
