namespace Umbrella.Modules.Repairs.Card {
    import Guid = System.Guid;
    import AnswerModel = SelfService.Models.AnswerModel;
    import IUnitResource = Housing.Units.IUnitResource;
    import UnitActivitiesService = Umbrella.CustomerService.UnitCard.Activities.UnitActivitiesService;

    export enum RepairRequestStep {
        AddDefects,
        Plan,
        Submitted
    }

    export interface IRepairRequestTabController {
        currentStep: RepairRequestStep;
        unit: Housing.UnitModel;
        repairRequest: RepairRequestModel;
        waiting: boolean;

        addItem(): void;
        removeItem(item: RepairRequestItemModel): void;
        plan(planningOption: PlanningOptionModel);

        isAddDefectsStep(): boolean;
        isPlanStep(): boolean;
        isSubmitted(): boolean;

        submit(): void;
        submitWithoutPlanning(): void;
        canSubmit(): boolean;

        startNewRepairRequest(): void;
    }

    @Controller('Housing', 'RepairRequestTabController')
    @Inject(
        '$stateParams',
        '$timeout',
        'UnitResource',
        'RepairRequestResource',
        'CreateDialog',
        'UnitActivitiesService'
    )
    export class RepairRequestTabController implements IRepairRequestTabController {
        waiting: boolean;
        currentStep: RepairRequestStep;
        unit: Housing.UnitModel;
        repairRequest: RepairRequestModel = null;
        planningOption: PlanningOptionModel = null;

        constructor(
            protected $stateParams: ng.ui.IStateParamsService,
            private $timeout: ng.ITimeoutService,
            private unitResource: IUnitResource,
            private repairRequestResource: IRepairRequestResourceClass,
            private createDialog,
            private unitActivitiesService: UnitActivitiesService
        ) {
            this.loadUnit();

            this.currentStep = RepairRequestStep.AddDefects;
            this.waiting = false;
        }

        protected loadUnit(): void {
            this.unitResource.getById({ id: this.$stateParams.unitId }).$promise.then(unit => (this.unit = unit));
        }

        public isAddDefectsStep(): boolean {
            return this.currentStep === RepairRequestStep.AddDefects;
        }

        public isPlanStep(): boolean {
            return this.currentStep === RepairRequestStep.Plan;
        }

        public isSubmitted(): boolean {
            return this.currentStep === RepairRequestStep.Submitted;
        }

        public addItem(): void {
            this.createDialog(
                '/Modules/Repairs/Card/Tabs/RepairRequest/AddDefectPopup.html',
                {
                    id: 'addDefectPopup',
                    backdrop: true,
                    modalClass: 'popup green popup_650',
                    backdropClass: 'popup-backdrop',
                    successCallback: (
                        answerPath: AnswerModel[],
                        defect: DefectModel,
                        actionSpecification: ActionSpecificationModel,
                        remark: string,
                        vendorId: Guid,
                        collectiveObjectId: Guid
                    ) => {
                        this.createItem(answerPath, defect, remark, vendorId, collectiveObjectId);
                    }
                },
                { unit: this.unit }
            );
        }

        public needsPlanning(): boolean {
            return (
                this.repairRequest !== null &&
                this.repairRequest.items.reduce((x, value) => x || value.isPlannable, false)
            );
        }

        public hasPlanning(): boolean {
            return !!this.planningOption;
        }

        public goToPlanningStep(): void {
            this.currentStep = RepairRequestStep.Plan;
        }

        public canSubmit(): boolean {
            return (
                this.repairRequest &&
                this.repairRequest.items.length > 0 &&
                (!this.needsPlanning() || this.hasPlanning()) &&
                this.repairRequest.isInProgress
            );
        }

        protected createItem(answerPath: AnswerModel[], defect: DefectModel, remark: string, vendorId: Guid, collectiveObjectId: Guid): void {
            this.repairRequestResource
                .createItemAnonymous({
                    answerPath: answerPath.map(answer => answer.id),
                    defectId: defect.id,
                    media: <Guid[]>[],
                    repairRequestId: this.repairRequest ? this.repairRequest.id : null,
                    remarks: remark,
                    unitId: this.unit.id,
                    serviceFundId: null,
                    vendorId,
                    collectiveObjectId
                })
                .$promise.then(repairRequest => (this.repairRequest = repairRequest));
        }

        public removeItem(item: RepairRequestItemModel): void {
            this.repairRequestResource
                .removeItem({
                    id: this.repairRequest.id,
                    defectId: item.id
                })
                .$promise.then(repairRequest => (this.repairRequest = repairRequest));
        }

        public plan(planningOption: PlanningOptionModel): void {
            this.repairRequestResource
                .plan({
                    id: this.repairRequest.id,
                    option: planningOption,
                    planningImpossible: false
                }
            ).$promise.then(() => this.planningOption = planningOption);
        }

        public submit(): void {
            this.waiting = true;

            this.repairRequestResource
                .submit({
                    id: this.repairRequest.id,
                    channelType: ChannelType.Umbrella,
                    phone: '',
                    email: ''
                })
                .$promise.then(repairRequest => {
                    this.currentStep = RepairRequestStep.Submitted;
                    this.repairRequest = repairRequest;
                    this.$timeout(() => {
                        this.reloadUnitCardActivities();
                    }, 1000);
                })
                .finally(() => (this.waiting = false));
        }

        public submitWithoutPlanning(): void {
            this.waiting = true;

            this.repairRequestResource
                .plan({
                    id: this.repairRequest.id,
                    option: null,
                    planningImpossible: true
                })
                .$promise.then(_ => {
                    this.submit();
                })
                .finally(() => (this.waiting = false));
        }

        public reloadUnitCardActivities(): void {
            this.unitActivitiesService.reload();
        }

        public startNewRepairRequest(): void {
            this.repairRequest = null;
            this.currentStep = RepairRequestStep.AddDefects;
        }
    }

    angular.module('Housing').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) =>
            $stateProvider.state('customerservice.unitcard.tab.repairrequest', {
                reloadOnSearch: false,
                url: '/reparatieverzoek',
                data: {
                    pageTitle: 'Reparatieverzoek',
                    tab: 'repairrequest',
                    authorize: ps => ps.anonymousRepairRequestWrite
                },
                views: {
                    'tab@customerservice': {
                        templateUrl: '/Modules/Repairs/Card/Tabs/RepairRequest/Tab.html'
                    }
                }
            })
    ]);
}
