namespace Umbrella.Modules.SelfService {
    export interface SelfServiceReportGetAllRequestParams {
        startDate: Date;
        endDate: Date;
        page?: number;
        pageSize?: number;
        query?: string;
        scenarioId?: System.Guid;
        failed?: boolean;
        status?: string;
    }

    export interface SelfServiceReportResource
        extends Umbrella.Modules.SelfService.SelfServiceReportModel,
            angular.resource.IResource<
                Umbrella.Modules.SelfService.SelfServiceReportModel
            > {
        getAll(
            params: SelfServiceReportGetAllRequestParams
        ): PagedItemsModel<SelfServiceReportResource>;
        getById(params: IdRequestParams): SelfServiceReportResource;
        getByCaseId(params: IdRequestParams): SelfServiceReportResource;
        getByFlowStateId(params: IdRequestParams): angular.resource.IResource<Umbrella.Modules.SelfService.Custom.CustomSelfServiceReportModel>;
    }

    angular.module('SelfService').service('SelfServiceReportResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/selfservice/report/:action/:id',
                {},
                {
                    getById: { method: 'GET', isArray: false },
                    getAll: {
                        method: 'GET',
                        isArray: false,
                        params: { startDate: '@startDate', endDate: '@endDate' }
                    },
                    getByCaseId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'bycase', id: '@caseId' }
                    },
                    getByFlowStateId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'byflow', id: '@flowId' }
                    }
                }
            )
    ]);
}
