﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />

namespace Umbrella.CustomerService.CustomerCard {
    import GetAddressModel = Umbrella.Modules.GetAddressModel;

    export interface AddressParams {
        zipCode: string;
        houseNumber: number;
        houseNumberSuffix: string;
    }

    export interface AddressResource {
        get(params: AddressParams): ng.resource.IResource<GetAddressModel[]>;
    }

    angular.module('CustomerService').service('AddressResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/address/:action',
                {},
                {
                    get: {
                        method: 'GET',
                        action: '',
                        isArray: true,
                        params: { action: 'find' }
                    }
                }
            )
    ]);
}
