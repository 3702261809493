namespace Umbrella.TaskHandling {
    import TaskModel = Umbrella.Modules.Tasks.TaskModel;
    import RoleModel = Umbrella.Modules.RoleModel;

    @Pipe('TaskHandling', 'taskTitle')
    class TaskTitleFilter {
        public transform(task: TaskModel) {
            if (task === null) return '';

            let title = '';
            if (task.taskType.category.toString() === 'CaseFlow' && task.relatedPerson)
                title += `${task.subject} - ${this.getTitleForRelatedPerson(task.relatedPerson)}`;

            if (
                task.subject.length > 0 &&
                task.relatedPerson &&
                task.relatedPerson.naturalPerson &&
                task.relatedPerson.naturalPerson.isDeceased
            )
                title += `Erven van ${task.subject}`;

            if (task.subject.length > 0) title += task.subject;

            if (task.relatedPerson) title += this.getTitleForRelatedPerson(task.relatedPerson);

            if (task.taskType.category.toString() === 'Error' && task.properties) {
                title = this.addPropertyToTitle(title, task.properties.application);
                title = this.addPropertyToTitle(title, task.properties.notificationName);
                title = this.addPropertyToTitle(title, task.properties.consumerName);
            }

            return title;
        }

        private addPropertyToTitle(title: string, property: string): string {
            if (!property) return title;
            if (title.length == 0) return `[${property}]`;

            title += ` [${property}]`;

            return title;
        }

        private getTitleForRelatedPerson(role: RoleModel): string {
            let preText = '';
            if (role.naturalPerson && role.naturalPerson.isDeceased) preText = 'Erven van';

            if (role && role.homeAddress && role.homeAddress.lines && role.homeAddress.lines.length > 0)
                return `${preText} ${role.name}, ${role.homeAddress.lines}`;

            if (role && role.postalAddress && role.postalAddress.lines && role.postalAddress.lines.length > 0)
                return `${preText} ${role.name}, ${role.postalAddress.lines}`;

            if (role) return `${preText} ${role.name}`;

            return '';
        }
    }
}
