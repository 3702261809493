namespace Umbrella.Modules.Mail {
    export interface IGetMailCountParams {
        filterforstatus: MailMessageStatus;
    }

    export declare class MailMessageResource {
        getMailCount(
            params: IGetMailCountParams
        ): ng.resource.IResource<CountModel>;
    }

    angular.module('Mail').service('MailMessageResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/mailmessage/:action/:id',
                {},
                {
                    getById: {
                        method: 'GET',
                        params: { id: '@id' },
                        isArray: false
                    },
                    getByIdWithCustomer: {
                        method: 'GET',
                        params: { action: 'GetWithCustomer', id: '@id' },
                        isArray: false
                    },
                    getMailsWithCustomer: {
                        method: 'GET',
                        params: {
                            action: 'GetMailsWithCustomer',
                            page: 0,
                            pageSize: 10
                        },
                        isArray: false
                    },
                    getMailCount: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'CountByStatus' }
                    },
                    getMails: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'GetMails', page: 0, pageSize: 10 }
                    },
                    getMailsByRoleId: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'GetMailsByRoleId',
                            page: 0,
                            pageSize: 10
                        }
                    },
                    pickUp: {
                        method: 'PUT',
                        isArray: false,
                        params: { id: '@id', action: 'pickUp' }
                    },
                    finish: {
                        method: 'PUT',
                        isArray: false,
                        params: { id: '@id', action: 'finish' }
                    },
                    delete: {
                        method: 'PUT',
                        isArray: false,
                        params: { id: '@id', action: 'delete' }
                    },
                    attachCustomerToMailMessage: {
                        method: 'PUT',
                        isArray: false,
                        params: {
                            mailMessageId: '@mailMessageId',
                            CustomerId: '@customerId',
                            action: 'AttachCustomerToMailMessage'
                        }
                    }
                }
            )
    ]);
}
