if (typeof $.signalR !== 'function') {
    throw new Error(
        'SignalR: SignalR is not loaded. Please ensure jquery.signalR-x.js is referenced before ~/signalr/js.'
    );
}

var signalR = $.signalR;

function makeProxyCallback(hub, callback) {
    return function() {
        // Call the client hub method
        callback.apply(hub, $.makeArray(arguments));
    };
}

function registerHubProxies(instance, shouldSubscribe) {
    var key, hub, memberKey, memberValue;

    for (key in instance) {
        if (instance.hasOwnProperty(key)) {
            hub = instance[key];

            if (!hub.hubName) {
                // Not a client hub
                continue;
            }

            // Loop through all members on the hub and find client hub functions to subscribe/unsubscribe
            for (memberKey in hub.client) {
                if (hub.client.hasOwnProperty(memberKey)) {
                    memberValue = hub.client[memberKey];

                    if (!$.isFunction(memberValue)) {
                        // Not a client hub function
                        continue;
                    }
                    if(shouldSubscribe) {
                        hub.on.call(hub, memberKey, makeProxyCallback(hub, memberValue));
                    } else {
                        hub.off.call(hub, memberKey, null);
                    }
                }
            }
        }
    }
}

$.hubConnection.prototype.createHubProxies = function() {
    var proxies = {};
    this.starting(function() {
        // Register the hub proxies as subscribed
        // (instance, shouldSubscribe)
        registerHubProxies(proxies, true);

        this._registerSubscribedHubs();
    }).disconnected(function() {
        // Unsubscribe all hub proxies when we "disconnect".  This is to ensure that we do not re-add functional call backs.
        // (instance, shouldSubscribe)
        registerHubProxies(proxies, false);
    });

    proxies['activityHub'] = this.createHubProxy('activityHub');
    proxies['activityHub'].client = {};
    proxies['activityHub'].server = {
        subscribe: function(id) {
            return proxies['activityHub'].invoke.apply(
                proxies['activityHub'],
                $.merge(['Subscribe'], $.makeArray(arguments))
            );
        },
        unsubscribe: function(id) {
            return proxies['activityHub'].invoke.apply(
                proxies['activityHub'],
                $.merge(['Unsubscribe'], $.makeArray(arguments))
            );
        }
    };

    proxies['allPhonesHub'] = this.createHubProxy('allPhonesHub');
    proxies['allPhonesHub'].client = {};
    proxies['allPhonesHub'].server = {
        deregister: function() {
            return proxies['allPhonesHub'].invoke.apply(
                proxies['allPhonesHub'],
                $.merge(['Deregister'], $.makeArray(arguments))
            );
        },

        getAllPhones: function() {
            return proxies['allPhonesHub'].invoke.apply(
                proxies['allPhonesHub'],
                $.merge(['GetAllPhones'], $.makeArray(arguments))
            );
        },

        logoutMultiple: function(userIds) {
            return proxies['allPhonesHub'].invoke.apply(
                proxies['allPhonesHub'],
                $.merge(['LogoutMultiple'], $.makeArray(arguments))
            );
        },

        register: function() {
            return proxies['allPhonesHub'].invoke.apply(
                proxies['allPhonesHub'],
                $.merge(['Register'], $.makeArray(arguments))
            );
        }
    };

    proxies['announcementHub'] = this.createHubProxy('announcementHub');
    proxies['announcementHub'].client = {};
    proxies['announcementHub'].server = {};

    proxies['billHub'] = this.createHubProxy('billHub');
    proxies['billHub'].client = {};
    proxies['billHub'].server = {
        subscribe: function(id) {
            return proxies['billHub'].invoke.apply(
                proxies['billHub'],
                $.merge(['Subscribe'], $.makeArray(arguments))
            );
        },
        unsubscribe: function(id) {
            return proxies['billHub'].invoke.apply(
                proxies['billHub'],
                $.merge(['Unsubscribe'], $.makeArray(arguments))
            );
        }
    };

    proxies['caseFlowHub'] = this.createHubProxy('caseFlowHub');
    proxies['caseFlowHub'].client = {};
    proxies['caseFlowHub'].server = {};

    proxies['contractHub'] = this.createHubProxy('contractHub');
    proxies['contractHub'].client = {};
    proxies['contractHub'].server = {
        subscribe: function(id) {
            return proxies['contractHub'].invoke.apply(
                proxies['contractHub'],
                $.merge(['Subscribe'], $.makeArray(arguments))
            );
        },
        unsubscribe: function(id) {
            return proxies['contractHub'].invoke.apply(
                proxies['contractHub'],
                $.merge(['Unsubscribe'], $.makeArray(arguments))
            );
        }
    };

    proxies['personHub'] = this.createHubProxy('personHub');
    proxies['personHub'].client = {};
    proxies['personHub'].server = {
        startJitUpdate: function(id) {
            return proxies['personHub'].invoke.apply(
                proxies['personHub'],
                $.merge(['StartJitUpdate'], $.makeArray(arguments))
            );
        },
        subscribe: function(id) {
            return proxies['personHub'].invoke.apply(
                proxies['personHub'],
                $.merge(['Subscribe'], $.makeArray(arguments))
            );
        },
        unsubscribe: function(id) {
            return proxies['personHub'].invoke.apply(
                proxies['personHub'],
                $.merge(['Unsubscribe'], $.makeArray(arguments))
            );
        }
    };

    proxies['phoneHub'] = this.createHubProxy('phoneHub');
    proxies['phoneHub'].client = {};
    proxies['phoneHub'].server = {
        dial: function(number) {
            return proxies['phoneHub'].invoke.apply(proxies['phoneHub'], $.merge(['Dial'], $.makeArray(arguments)));
        },

        endAfterCallWork: function() {
            return proxies['phoneHub'].invoke.apply(
                proxies['phoneHub'],
                $.merge(['EndAfterCallWork'], $.makeArray(arguments))
            );
        },

        endAfterCallWorkAndLeaveQueue: function() {
            return proxies['phoneHub'].invoke.apply(
                proxies['phoneHub'],
                $.merge(['EndAfterCallWorkAndLeaveQueue'], $.makeArray(arguments))
            );
        },

        enterQueue: function() {
            return proxies['phoneHub'].invoke.apply(
                proxies['phoneHub'],
                $.merge(['EnterQueue'], $.makeArray(arguments))
            );
        },

        forwardCall: function(connectionId) {
            return proxies['phoneHub'].invoke.apply(
                proxies['phoneHub'],
                $.merge(['ForwardCall'], $.makeArray(arguments))
            );
        },

        holdCall: function(connectionId) {
            return proxies['phoneHub'].invoke.apply(proxies['phoneHub'], $.merge(['HoldCall'], $.makeArray(arguments)));
        },

        leaveQueue: function() {
            return proxies['phoneHub'].invoke.apply(
                proxies['phoneHub'],
                $.merge(['LeaveQueue'], $.makeArray(arguments))
            );
        },

        login: function(number, password) {
            return proxies['phoneHub'].invoke.apply(proxies['phoneHub'], $.merge(['Login'], $.makeArray(arguments)));
        },

        logout: function() {
            return proxies['phoneHub'].invoke.apply(proxies['phoneHub'], $.merge(['Logout'], $.makeArray(arguments)));
        },

        logoutMultipleByUserIds: function(userIds) {
            return proxies['phoneHub'].invoke.apply(
                proxies['phoneHub'],
                $.merge(['LogoutMultipleByUserIds'], $.makeArray(arguments))
            );
        },

        notReady: function() {
            return proxies['phoneHub'].invoke.apply(proxies['phoneHub'], $.merge(['NotReady'], $.makeArray(arguments)));
        },

        pickup: function(connectionId) {
            return proxies['phoneHub'].invoke.apply(proxies['phoneHub'], $.merge(['Pickup'], $.makeArray(arguments)));
        },

        ready: function() {
            return proxies['phoneHub'].invoke.apply(proxies['phoneHub'], $.merge(['Ready'], $.makeArray(arguments)));
        },

        releaseCall: function(connectionId) {
            return proxies['phoneHub'].invoke.apply(
                proxies['phoneHub'],
                $.merge(['ReleaseCall'], $.makeArray(arguments))
            );
        },

        reportUserReconnected: function() {
            return proxies['phoneHub'].invoke.apply(
                proxies['phoneHub'],
                $.merge(['ReportUserReconnected'], $.makeArray(arguments))
            );
        },

        resumeCall: function(connectionId) {
            return proxies['phoneHub'].invoke.apply(
                proxies['phoneHub'],
                $.merge(['ResumeCall'], $.makeArray(arguments))
            );
        },

        setPhoneRichPresenceState: function(phoneRichPresenceState) {
            return proxies['phoneHub'].invoke.apply(
                proxies['phoneHub'],
                $.merge(['SetPhoneRichPresenceState'], $.makeArray(arguments))
            );
        },

        setRoleForCall: function(phoneCallRegistrationId, roleId) {
            return proxies['phoneHub'].invoke.apply(
                proxies['phoneHub'],
                $.merge(['SetRoleForCall'], $.makeArray(arguments))
            );
        },

        transferDirect: function(number, connectionId) {
            return proxies['phoneHub'].invoke.apply(
                proxies['phoneHub'],
                $.merge(['TransferDirect'], $.makeArray(arguments))
            );
        },

        unsetRoleForCall: function(phoneCallRegistrationId) {
            return proxies['phoneHub'].invoke.apply(
                proxies['phoneHub'],
                $.merge(['UnsetRoleForCall'], $.makeArray(arguments))
            );
        }
    };

    proxies['presenceHub'] = this.createHubProxy('presenceHub');
    proxies['presenceHub'].client = {};
    proxies['presenceHub'].server = {};

    proxies['shellHub'] = this.createHubProxy('shellHub');
    proxies['shellHub'].client = {};
    proxies['shellHub'].server = {
        complete: function(line) {
            return proxies['shellHub'].invoke.apply(proxies['shellHub'], $.merge(['Complete'], $.makeArray(arguments)));
        },

        execute: function(line) {
            return proxies['shellHub'].invoke.apply(proxies['shellHub'], $.merge(['Execute'], $.makeArray(arguments)));
        },

        login: function(outputDebugInfo) {
            return proxies['shellHub'].invoke.apply(proxies['shellHub'], $.merge(['Login'], $.makeArray(arguments)));
        },

        toggleOutPutDebugInfo: function(outputDebugInfo) {
            return proxies['shellHub'].invoke.apply(
                proxies['shellHub'],
                $.merge(['ToggleOutPutDebugInfo'], $.makeArray(arguments))
            );
        }
    };

    proxies['taskHub'] = this.createHubProxy('taskHub');
    proxies['taskHub'].client = {};
    proxies['taskHub'].server = {};

    proxies['unitActivityHub'] = this.createHubProxy('unitActivityHub');
    proxies['unitActivityHub'].client = {};
    proxies['unitActivityHub'].server = {
        subscribe: function(id) {
            return proxies['unitActivityHub'].invoke.apply(
                proxies['unitActivityHub'],
                $.merge(['Subscribe'], $.makeArray(arguments))
            );
        },
        unsubscribe: function(id) {
            return proxies['unitActivityHub'].invoke.apply(
                proxies['unitActivityHub'],
                $.merge(['Unsubscribe'], $.makeArray(arguments))
            );
        }
    };

    proxies['unitContractHub'] = this.createHubProxy('unitContractHub');
    proxies['unitContractHub'].client = {};
    proxies['unitContractHub'].server = {
        subscribe: function(id) {
            return proxies['unitContractHub'].invoke.apply(
                proxies['unitContractHub'],
                $.merge(['Subscribe'], $.makeArray(arguments))
            );
        },
        unsubscribe: function(id) {
            return proxies['unitContractHub'].invoke.apply(
                proxies['unitContractHub'],
                $.merge(['Unsubscribe'], $.makeArray(arguments))
            );
        }
    };

    proxies['unitHub'] = this.createHubProxy('unitHub');
    proxies['unitHub'].client = {};
    proxies['unitHub'].server = {
        startJitUpdate: function(id) {
            return proxies['unitHub'].invoke.apply(
                proxies['unitHub'],
                $.merge(['StartJitUpdate'], $.makeArray(arguments))
            );
        },
        subscribe: function(id) {
            return proxies['unitHub'].invoke.apply(
                proxies['unitHub'],
                $.merge(['Subscribe'], $.makeArray(arguments))
            );
        },
        unsubscribe: function(id) {
            return proxies['unitHub'].invoke.apply(
                proxies['unitHub'],
                $.merge(['Unsubscribe'], $.makeArray(arguments))
            );
        }
    };

    proxies['unitValuationHub'] = this.createHubProxy('unitValuationHub');
    proxies['unitValuationHub'].client = {};
    proxies['unitValuationHub'].server = {
        subscribe: function(id) {
            return proxies['unitValuationHub'].invoke.apply(
                proxies['unitValuationHub'],
                $.merge(['Subscribe'], $.makeArray(arguments))
            );
        },
        unsubscribe: function(id) {
            return proxies['unitValuationHub'].invoke.apply(
                proxies['unitValuationHub'],
                $.merge(['Unsubscribe'], $.makeArray(arguments))
            );
        }
    };

    return proxies;
};

signalR.hub = $.hubConnection('/signalr', {
    useDefaultPath: false
});
$.extend(signalR, signalR.hub.createHubProxies());
