﻿/// <reference path="../../../../Window.d.ts" />

namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowModel = Umbrella.CaseFlow.CaseFlowModel;
    import PersonResource = Umbrella.PersonResource;

    export interface EditCaseFlowComponentState {
        case: CaseFlowModel;
    }

    @Component('Dashboard', {
        selector: 'edit-caseflow',
        templateUrl: '/CaseFlowProcessing/_Overview/_Edit/EditCaseFlowComponent/EditCaseFlow.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('$state', 'CaseFlowOverviewService', 'ToastMessageService', 'PersonResource')
    class EditCaseFlowComponent {
        public updating: boolean;
        public state: EditCaseFlowComponentState;
        public assignedToList: Umbrella.CaseFlow.AssignmentModel[] = [];

        constructor(
            private $state: ng.ui.IStateService,
            private caseFlowOverviewService: CaseFlowOverviewService,
            private toastMessageService: ToastMessageService,
            private personResource: PersonResource
        ) {}

        public $onInit() {
            this.loadAssignedToList();
        }

        public async searchAssignedTo(query = ''): Promise<void> {
            const pagedList = await this.personResource.search({ page: 0, pageSize: 20, id: query, roles: 'employee' })
                .$promise;

            this.assignedToList = pagedList.items.map(t => {
                return {
                    id: t.roles['employee'],
                    name: t.name,
                    type: Umbrella.CaseFlow.CaseFlowAssignmentType.Employee
                };
            });
        }

        public async update(c: CaseFlowModel): Promise<void> {
            this.updating = true;
            await this.caseFlowOverviewService
                .update({
                    id: c.id,
                    ownerModel: c.ownerModel
                })
                .then(() => {
                    this.toastMessageService.success('Zaak bijgewerkt');
                    this.$state.go('dashboard.caseflow.overview.details.steps', { id: c.id });
                })
                .catch(response => this.toastMessageService.error('Zaak kon niet bijgewerkt worden'));

            this.updating = false;
        }

        private async loadAssignedToList(): Promise<void> {
            await this.searchAssignedTo((this.state.case.ownerModel && this.state.case.ownerModel.name) || '');
        }
    }
}
