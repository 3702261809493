﻿/// <reference path="../../../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../../../Modules/Umbrella/SignalR.ts" />

interface SignalR {
    activityHub: Umbrella.CustomerService.CustomerCard.Activities.IActivityHubProxy;
}

namespace Umbrella.CustomerService.CustomerCard.Activities {
    export interface IActivityHubProxy extends HubProxy {
        client: IActivityHubClient;
        server: IActivityHubServer;
    }

    export const activityHubOnUpdated$ = new Rx.Subject<System.Guid>();
    export const activityHubOnStarted$ = new Rx.Subject<System.Guid>();

    export interface IActivityHubClient {
        updated(personId: System.Guid);
        started(personId: System.Guid);
    }

    export interface IActivityHubServer {
        subscribe(personId: System.Guid);
        unsubscribe(personId: System.Guid);
    }    

    angular
        .module('CustomerService')
        .factory('ActivityHub', () => $.connection.activityHub);

    Modules.onSignalRInitialized(() => {
        $.connection.activityHub.client.updated = personId =>
            activityHubOnUpdated$.onNext(personId);
        $.connection.activityHub.client.started = personId =>
            activityHubOnStarted$.onNext(personId);
    });
}
