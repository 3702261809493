/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/chartjs/chartjs.d.ts"/>

namespace Umbrella {
    interface IScope extends ng.IScope {
        chartConfiguration: Chart.ChartConfiguration;
    }

    angular.module('Umbrella').directive('umbrellaChart', () => {
        function generateChart(element: ng.IAugmentedJQuery, configuration: Chart.ChartConfiguration): Chart {
            if (!configuration) return null;

            const canvas = angular.element(document.createElement('canvas'));
            element.append(canvas);
            const ctx = (<HTMLCanvasElement>canvas[0]).getContext('2d');

            return new Chart(ctx, configuration);
        }
        function updateChart(chart: Chart, configuration: Chart.ChartConfiguration): void {
            if (!configuration) return;

            chart.config = configuration;
            chart.update();
        }
        return {
            scope: {
                chartConfiguration: '<'
            },
            link(scope: IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) {
                let chart: Chart;

                scope.$watch('chartConfiguration', (newValue, oldValue) => {
                    if (chart) {
                        updateChart(chart, scope.chartConfiguration);
                    } else {
                        chart = generateChart(element, scope.chartConfiguration) || chart;
                    }
                });
            }
        };
    });
}
