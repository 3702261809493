namespace Umbrella {
    import IActivityResourceClass = Umbrella.Modules.IActivityResourceClass;
    import CustomerCardActivityInfoService = Umbrella.CustomerService.CustomerCard.Activities.CustomerCardActivityInfoService;

    @Component('Umbrella', {
        selector: 'restore-activity-timeline-item',
        templateUrl: '/CustomerService/ActivityTimeline/ActivityTimelineItemComponent/RestoreActivityTimelineItem.html',
        bindings: {
            isCase: '<',
            id: '<'
        }
    })
    @Inject(
        '$mdDialog',
        'ToastMessageService',
        'CustomerCardActivityInfoService')

    export class RestoreActivityTimelineItemComponent {

        public isCase: boolean;
        public reason = '';
        public id: Guid;

        constructor(
            private $mdDialog,
            private toastMessageService: ToastMessageService,
            private customerCardActivityInfoService : CustomerCardActivityInfoService
        ) {
        }

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        public restoreCase(): void {
            this.customerCardActivityInfoService.restoreCase(this.id);
                this.$mdDialog.hide();
        }

        public restoreActivity(): void {
            this.customerCardActivityInfoService.restoreActivity(this.id);
                this.$mdDialog.hide();
        }
    }
}
