/// <reference path="../../../Window.d.ts"/>

namespace Umbrella.Modules.Accounts {
    import AccountModel = Umbrella.Accounts.AccountModel;

    @Component('CustomerService', {
        selector: 'card-account-details',
        templateUrl: '/Modules/Accounts/CardAccountDetailsComponent/CardAccountDetails.html',
        bindings: {
            person: '<',
            onCreated: '&',
            onCreateFailed: '&',
            onRecovered: '&',
            onRecoverFailed: '&'
        }
    })
    @Inject('AccountService', 'ToastMessageService', '$mdDialog')
    class CardAccountDetailsComponent {
        public person: PersonModel;
        public account: AccountModel.Activation;
        public onCreated: () => void;
        public onCreateFailed: () => void;
        public onRecovered: () => void;
        public onRecoverFailed: () => void;
        public loading = true;

        public isBlockingAccount = false;
        public isUnblockingAccount = false;
        private creatingAccount = false;
        private recoveringAccount = false;
        private createEnabled: boolean;
        private createDisabledReason: string;
        public accountActivationVerificationMethod: string;

        constructor(
            private accountService: AccountService,
            private ToastMessageService: ToastMessageService,
            private $mdDialog
        ) {}

        public $onInit(): void {
            this.loading = true;
            this.createEnabled = true; // TODO
            if (!this.createEnabled)
                this.createDisabledReason =
                    'Account aanmaken niet mogelijk, want zowel woonadres als postadres is niet bekend voor deze klant.';

            this.accountService
                .get(this.person.id)
                .then(account => {
                    // hack to recognize NoContent http result
                    if (account && account.id !== undefined) this.setAccount(account);
                })
                .finally(() => (this.loading = false));

            this.setAccountActivationVerificationMethod();
        }

        public isEnabled(): boolean {
            return window.config.modules.accounts.enabled;
        }
        
        public canCreate(): boolean {
            return this.createEnabled && this.accountService.canCreate();
        }

        public isRecoveryEnabled(): boolean {
            return this.accountService.isRecoverable(this.account);
        }

        public isCreatingAccount(): boolean {
            return this.creatingAccount;
        }

        public isRecoveringAccount(): boolean {
            return this.recoveringAccount;
        }

        public isActive(): boolean {
            return this.account && this.accountService.isActive(this.account);
        }

        public isInactive(): boolean {
            return this.account && this.accountService.isInactive(this.account);
        }

        public hasDisableReason(): boolean {
            return !this.createEnabled && !!this.createDisabledReason;
        }

        public getStatus(): string {
            if (this.account.isExpired && this.account.isBlocked) return 'Geblokkeerd en Verlopen';
            if (this.account.isExpired) return 'Verlopen';
            if (this.account.isBlocked) return 'Geblokkeerd';
            if (this.isActive()) return 'Actief';
            return 'Niet actief';
        }

        public createAccount(): void {
            this.creatingAccount = true;

            const email =
                this.person &&
                this.person.contactDetails &&
                this.person.contactDetails.emailAddresses &&
                this.person.contactDetails.emailAddresses.length &&
                this.person.contactDetails.emailAddresses[0].email;
            this.accountService
                .create(this.person.id, email)
                .then(response => {
                    this.setAccount(response.data);
                    this.onCreated();
                })
                .catch(e => this.onCreateFailed())
                .finally(() => (this.creatingAccount = false));
        }

        public startRecovery(): void {
            this.recoveringAccount = true;
            this.accountService
                .startRecovery(this.account.email)
                .then(() => this.onRecovered())
                .catch(() => {
                    this.onRecoverFailed();
                })
                .finally(() => (this.recoveringAccount = false));
        }

        public isBusy(): boolean {
            return (
                this.loading ||
                this.isBlockingAccount ||
                this.isUnblockingAccount ||
                this.creatingAccount ||
                this.recoveringAccount
            );
        }

        public canBlockAccount(): boolean {
            return this.account && !this.account.isBlocked && window.user.permissions.blockAccount;
        }

        public blockAccount(): void {
            this.isBlockingAccount = true;
            this.accountService
                .block(this.account.personId)
                .then(acc => this.setAccount(acc))
                .finally(() => (this.isBlockingAccount = false));
        }

        public canUnblockAccount(): boolean {
            return this.account && this.account.isBlocked && window.user.permissions.blockAccount;
        }

        public unblockAccount(): void {
            this.isUnblockingAccount = true;
            this.accountService
                .unblock(this.account.personId)
                .then(acc => this.setAccount(acc))
                .finally(() => (this.isUnblockingAccount = false));
        }

        public deleteAccount(personId: System.Guid): void {
            const dialog = this.$mdDialog
                .confirm()
                .title('Account verwijderen')
                .textContent('Weet je zeker dat je dit account wilt verwijderen?')
                .ariaLabel('Confirm')
                .ok('Ja')
                .cancel('Nee')
                .theme('umb-green');

            this.$mdDialog.show(dialog).then(() =>
                this.accountService
                    .delete(personId)
                    .then(() => {
                        this.account = null;
                        this.ToastMessageService.success('Account successvol verwijderd');
                    })
                    .catch(Error => this.ToastMessageService.error('Fout opgetreden tijdens verwijderen account'))
            );
        }

        private setAccount(account: AccountModel.Activation): void {
            this.account = account;
        }

        private setAccountActivationVerificationMethod() {
            if (window &&
                window.config &&
                window.config.modules &&
                window.config.modules.accounts &&
                window.config.modules.accounts.accountActivationVerificationMethod) {
                    this.accountActivationVerificationMethod = window.config.modules.accounts.accountActivationVerificationMethod;
                }
            else {
                this.accountActivationVerificationMethod = 'notAvailable';
            }
        }
    }
}
