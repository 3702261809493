namespace Umbrella.Modules.Customers {
    export interface IProspectResource
        extends angular.resource.IResource<ProspectModel> {}

    export interface ProspectResourceClass
        extends angular.resource.IResourceClass<IProspectResource> {
        provisionAccounts(): ng.resource.IResource<void>;
        deleteProspect(param: IdRequestParams): ng.resource.IResource<any>;
    }

    angular.module('CustomerService').service('ProspectResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) => {
            return $resource(
                '/housingallocation/api/v1/prospect/:action/:id',
                {},
                {
                    provisionAccounts: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'provisionAccounts' }
                    },
                    deleteProspect: {
                        method: 'DELETE',
                        isArray: false,
                        params: { action: 'delete', id: '@id' }
                    }
                }
            );
        }
    ]);
}
