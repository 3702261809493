/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../Decorators.ts" />
/// <reference path="../../../../Modules/Accounts/AccountService.ts" />

namespace Umbrella.CustomerService {
    import AgentFacetService = Modules.AgentFacetService;
    import EditCustomerDataComponent = Umbrella.CustomerService.CustomerCard.EditCustomerDataComponent;
    import AccountService = Modules.Accounts.AccountService;
    import AccountModel = Accounts.AccountModel;
    import CustomerCardPersonInfoService = CustomerService.CustomerCard.CustomerCardPersonInfoService;

    interface PhoneType {
        name: string;
        value: string;
    }
    @Component('CustomerService', {
        selector: 'customer-contact-data',
        templateUrl: '/CustomerService/_CustomerCard/_Personal/CustomerContactDataComponent/CustomerContactData.html',
        bindings: {
            person: '<',
            writableFields: '<'
        }
    })
    @Inject('AgentFacetService', 'ToastMessageService', 'CustomerCardPersonInfoService', 'AccountService')
    class CustomerContactDataComponent extends EditCustomerDataComponent {
        public person: PersonModel;
        private account: AccountModel.Activation;
        public selectedValue: string;
        public phoneTypes: PhoneType[] = [
            { name: 'Thuis', value: 'Home' },
            { name: 'Mobiel', value: 'Mobile' },
            { name: 'Werk', value: 'Work' },
            { name: 'Bij geen gehoor', value: 'WhenBusy' },
            { name: 'Onbekend', value: 'Unknown' }
        ];

        constructor(
            protected agentFacetService: AgentFacetService,
            toastMessageService: ToastMessageService,
            personInfoService: CustomerCardPersonInfoService,
            private accountService: AccountService
        ) {
            super(agentFacetService, toastMessageService, personInfoService);
        }

        public $onChanges = (bindings: { person: IBindingChange<PersonModel> }) => {
            if (bindings.person && bindings.person.currentValue) {
                this.clonePerson(); //necessary to update normalized phone field (PhoneModel.Number) after a modification
                if (this.person.roles['customer'] || this.person.roles['prospect']) {
                    this.accountService.get(this.person.id).then(account => (this.account = account));
                }
            }
        };

        public phoneNumberContainsText(phoneNr: string) {
            return !/([^0-9- +()]+)([^0-9 ]{6,10})/.test(phoneNr);
        }
        public getSelectedValue(value: string) {
            return this.phoneTypes.find(x => x.name == value).value || Umbrella.Modules.Telephony.PhoneType.Unknown;
        }
        public extractTextFromPhoneNumber(phoneNr: string) {
            const regex = /([0-9- +()]+)([0-9 ]{6,10})(.*)/;
            const groups = phoneNr.match(regex);

            if (!groups || groups.length < 4) {
                return '';
            } else {
                return groups[3];
            }
        }

        public isLinkedToAccount(email: string): boolean {
            if (!this.account || !email) return false;

            return this.account.isActivated && this.account.email === email;
        }

        public saveAndLeaveEditMode() {
            if (!this.validatePhoneType()) return;

            return super.saveAndLeaveEditMode();
        }

        private onlyUnique(value, index, self): boolean {
            return self.indexOf(value) === index;
        }

        private validatePhoneType(): boolean {
            if (this.isPhoneTypeUpdateable()) {
                const phoneTypes = [];
                if (this.personClone.contactDetails && this.personClone.contactDetails.phoneNumbers) {
                    this.personClone.contactDetails.phoneNumbers.forEach(x => {
                        if (x.original.length > 0) {
                            phoneTypes.push(x.type);
                        } else {
                            x.type = Umbrella.Modules.Telephony.PhoneType.Unknown;
                        }
                    });

                    if (
                        phoneTypes.some(type => !this.isAllowedPhoneType(type))
                    ) {
                        this.toastMessageService.error('Een niet ondersteund telefoontype geselecteerd.');
                        return false;
                    }

                    if (
                        !this.allowsMultipleTypesOfTheSameTelephoneNumber() &&
                        phoneTypes.length !== phoneTypes.filter(this.onlyUnique).length
                    ) {
                        this.toastMessageService.error('Niet mogelijk om meerdere dezelfde telefoontypes op te slaan.');
                        return false;
                    }

                    return true;
                }
                return true;
            }
            return true;
        }
    }
}
