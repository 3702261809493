namespace Umbrella.CustomerService.UnitCard.Activities {
    import UnitModel = Modules.Housing.UnitModel;
    import FaqModel = Modules.Knowledgebase.FaqModel;
    import ActivityRegistrationService = Umbrella.CustomerService.CustomerCard.Activities.Registration.ActivityRegistrationService;

    export interface UnitActivitiesComponentState {
        unit: UnitModel;
        activityInfo: Activities.State;
    }

    export const openUnitCardKnowledgebase$ = new Rx.Subject<boolean>();

    @Component('CustomerService', {
        selector: 'unit-activities',
        templateUrl: 'CustomerService/_UnitCard/_Activities/UnitActivitiesComponent/UnitActivities.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('$stateParams', '$mdDialog', 'UnitActivitiesService', 'ActivityRegistrationService', '$location')
    class UnitActivitiesComponent {
        public state: UnitActivitiesComponentState;
        public permissions: PermissionsModel;
        public faqActions: any;
        public displayKnowledgebase: boolean;
        private knowledgebaseSubscription: Rx.Disposable;

        constructor(
            private $stateParams: UnitCardStateParams,
            private $mdDialog,
            private unitActivitiesService: UnitActivitiesService,
            private activityRegistrationService: ActivityRegistrationService,
            private $location: ng.ILocationService
        ) {
            this.knowledgebaseSubscription = openUnitCardKnowledgebase$
                .filter(x => !!x)
                .subscribe(_ => {
                    this.openKnowledgebase();
                });
        }

        public $onInit() {
            this.permissions = window.user.permissions;
            this.setFaqActionDefinitions();

            if (this.$stateParams.createtask) {
                this.openKnowledgebase();
                this.$location.search({});
            }
        }

        public $onDestroy() {
            if (this.knowledgebaseSubscription) this.knowledgebaseSubscription.dispose();
        }

        public loadMoreActivities(): void {
            this.unitActivitiesService.loadMore();
        }

        public registerTask(faq: FaqModel): void {
            if (!this.$stateParams.unitId || !faq || !faq.mainTag) return;

            this.$mdDialog
                .show({
                    template: `<register-task-popup residential-zone-id="${this.$stateParams.unitId}" faq-id="${
                        faq.id
                    }" tag-id="${
                        faq.mainTag.id
                    }" channel-type="${this.activityRegistrationService.getSelectedChannelType()}"></register-task-popup>`,
                    targetEvent: null,
                    clickOutsideToClose: false
                })
                .then(() => {
                    this.closeKnowledgebase();
                    this.unitActivitiesService.reload();
                });
        }

        public openKnowledgebase(): void {
            this.displayKnowledgebase = true;
        }

        public closeKnowledgebase(): void {
            this.displayKnowledgebase = false;
        }

        public registerAnnouncement(): void {
            if (!this.$stateParams.unitId) return;

            this.$mdDialog.show({
                template: `<register-announcement-popup residential-zone-id="${
                    this.$stateParams.unitId
                }"></register-announcement-popup>`,
                targetEvent: null,
                clickOutsideToClose: false
            });
        }

        public setFaqActionDefinitions(): any {
            this.faqActions = [
                {
                    name: 'Taak aanmaken',
                    primary: true,
                    onExecute: (faq: FaqModel) => this.registerTask(faq)
                }
            ];
        }
    }
}
