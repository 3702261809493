/// <reference path="Onboarding.d.ts" />

namespace Umbrella.Modules.Onboarding {
    import IResource = ng.resource.IResource;
    import RelationModel = Umbrella.Onboarding.relationModel;

    export interface IOnboardingResource {
        getRelationByExternalId(params: {
            externalId: string;
        }): IResource<RelationModel>;
    }
    angular.module('Onboarding').service('OnboardingResource', [
        '$resource',
        $resource =>
            $resource(
                '/api/v1/onboarding/:action/:id',
                {},
                {
                    getRelationByExternalId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'getRelationByExternalId' }
                    }
                }
            )
    ]);
}
