/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.CustomerCard.Contracts {
    @Service('CustomerService', 'ContractJitService')
    @Inject('CustomerCardStore', 'ContractHub', 'ToastMessageService', 'CustomerCardContractInfoService')
    export class ContractJitService {
        constructor(
            store: CustomerCardStore,
            private contractHub: IContractHubProxy,
            private toastMessageService: ToastMessageService,
            contractInfoService: CustomerCardContractInfoService
        ) {
            const getPersonId = (s: CustomerCardState) => s && s.personal && s.personal.person && s.personal.person.id;
            const containsContractId = (s: CustomerCardState, contractId) => {
                if (s && s.contractInfo && s.contractInfo.contracts && s.contractInfo.contracts.items && s.contractInfo.contracts.items) {
                    return s.contractInfo.contracts.items.some(x => x.id === contractId);
                }

                return false;
            };

            contractHubOnContractUpdated$
                .filter(contractId => containsContractId(store.getState(), contractId))
                .subscribe(_ => contractInfoService.reload());

            store.state$
                .map(getPersonId)
                .distinctUntilChanged()
                .flatMapLatest(Modules.waitUntilSignalRConnected)
                .debounce(() => Rx.Observable.timer(1000))
                .subscribe(personId => this.subscribe(personId));

            store.event$
                .where(e => e.type === 'CustomerCardPersonUnloadedEvent')
                .subscribe(_ => this.unsubscribe(getPersonId(store.getState())));
        }

        private subscribe(personId: Guid) {
            if (personId) {
                this.contractHub.server.subscribe(personId);
            }
        }

        private unsubscribe(personId: Guid) {
            this.contractHub.server.unsubscribe(personId);
        }
    }
}
