/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="Window.d.ts"/>
/// <reference path="TaskHandling/_Overview/TaskOverviewStore.ts"/>
/// <reference path="CaseFlowProcessing/CaseFlowOverviewStore.ts"/>

namespace Umbrella {
    import CaseFlowOverviewService = Umbrella.KCC.CaseFlowDashboard.CaseFlowOverviewService;
    import TaskOverviewService = Umbrella.TaskHandling.TaskOverviewService;

    angular.module('Umbrella').config([
        '$stateProvider',
        '$urlRouterProvider',
        ($stateProvider: ng.ui.IStateProvider, $urlRouterProvider: ng.ui.IUrlRouterProvider) => {
            const getDefaultState = (ps: PermissionsModel) =>
                ps.viewTask || ps.editTask ? 'tasks.overview' : ps.viewCaseFlow ? 'caseflow.overview' : 'announcements';

            const transitionToDefaultTab = ($state: ng.ui.IStateService) => {
                session$
                    .skipWhile(x => !x.user || !x.user.permissions)
                    .take(1)
                    .map(s => 'dashboard.' + getDefaultState(s.user.permissions))
                    .subscribe(name => {
                        $state.transitionTo(name);
                    });
            };

            $urlRouterProvider
                .when('', ['$state', ($state: ng.ui.IStateService) => transitionToDefaultTab($state)])
                .when('/', ['$state', ($state: ng.ui.IStateService) => transitionToDefaultTab($state)])
                .when('/dashboard', ['$state', ($state: ng.ui.IStateService) => transitionToDefaultTab($state)])
                .when('/dashboard/tasks', [
                    '$injector',
                    '$location',
                    ($injector, $location: ng.ILocationService) => {
                        // Rule to preserve URL backwards compatiblity with previous versions
                        const params = $location.search();
                        return (
                            '/dashboard/taken/overzicht/' +
                            (params && params.hasOwnProperty('taskid') ? params.taskid : '')
                        );
                    }
                ])
                .rule(($injector, $location: ng.ILocationService) => {
                    // Rule to route url's properly regardless of absence or presence of trailing slash in url
                    const path = $location.url();

                    if (path[path.length - 1] === '/' || path.indexOf('/?') > -1) {
                        if (path.indexOf('?') > -1) {
                            return path.replace('/?', '?');
                        } else {
                            return path.substr(0, path.length - 1);
                        }
                    }

                    return undefined;
                });

            $stateProvider.state('dashboard', {
                url: '/dashboard',
                parent: 'root',
                abstract: true,
                redirectTo: 'dashboard.tasks',
                views: {
                    'new@': {
                        templateUrl: '/Modules/Dashboard/Dashboard.html',
                        controller: 'DashboardController'
                    }
                },
                onEnter: [
                    'TaskOverviewService',
                    'CaseFlowOverviewService',
                    (taskOverviewService: TaskOverviewService, caseflowOverviewService: CaseFlowOverviewService) => {
                        if (window.user.permissions.viewTask) {
                            taskOverviewService.initializeFilters();
                            taskOverviewService.ensureLoaded();
                        }

                        if (window.user.permissions.viewCaseFlow) {
                            caseflowOverviewService.initializeFilters();
                            caseflowOverviewService.setToListView();
                            caseflowOverviewService.ensureLoaded();
                        }
                    }
                ]
            });
        }
    ]);
}
