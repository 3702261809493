namespace Umbrella.Modules.Announcements {
    export interface AnnouncementResource
        extends angular.resource.IResource<AnnouncementModel> {}

    export interface AnnouncementResourceClass
        extends angular.resource.IResourceClass<AnnouncementResource> {
        getOverview(
            params: PagedRequestParams
        ): angular.resource.IResource<PagedItemsModel<AnnouncementModel>>;
        getCount(): angular.resource.IResource<CountModel>;
        search(
            params: PagedRequestWithIdParams
        ): angular.resource.IResource<PagedItemsModel<AnnouncementModel>>;
        getItem(id: IdRequestParams): AnnouncementResource;
        byUnitId(
            id: PagedRequestWithIdParams
        ): angular.resource.IResource<PagedItemsModel<AnnouncementModel>>;
        byComplexId(
            id: PagedRequestWithIdParams
        ): angular.resource.IResource<PagedItemsModel<AnnouncementModel>>;
        byContractId(
            id: PagedRequestWithIdParams
        ): angular.resource.IResource<PagedItemsModel<AnnouncementModel>>;
    }

    angular.module('Umbrella').service('AnnouncementResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) => {
            return $resource(
                '/api/v1/announcement/:action/:subaction/:id',
                {},
                {
                    getOverview: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'overview', id: '' }
                    },
                    getCount: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'overview', subaction: 'count', id: '' }
                    },
                    search: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'overview', subaction: 'search', id: '@id' }
                    },
                    getItem: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    save: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'admin' },
                        transformRequest: (data: string) => {
                            return JSON.stringify(data);
                        }
                    },
                    byUnitId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'byUnit', id: '@id' }
                    },
                    byComplexId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'byComplex', id: '@id' }
                    },
                    byContractId: {
                        methid: 'GET',
                        isArray: false,
                        params: { action: 'contract', id: '@id' }
                    }
                }
            );
        }
    ]);
}
