namespace Umbrella.Modules.Customers {
    import Detailed = Umbrella.Modules.Customers.CustomerModel.Detailed;

    export interface ICustomerResource {
        getById(params: {
            id: System.Guid;
        }): ng.resource.IResource<CustomerModel>;
        getByIds(params: {
            ids: System.Guid[]
        }): ng.resource.IResource<CustomerModel>;
        getByPersonId(params: {
            id: System.Guid;
        }): ng.resource.IResource<CustomerModel>;
        getAllByMail(params: { mail: string }): ng.resource.IResource<any>;
        getDetailed(params: {
            id: System.Guid;
        }): ng.resource.IResource<Detailed>;
        add(model: CreateCustomerModel): ng.resource.IResource<CustomerModel>;
    }

    angular.module('CustomerService').factory('CustomerResource', [
        '$resource',
        ($resource: ng.resource.IResourceService) =>
            $resource(
                '/api/v1/customer/:action/:id/:route/:mail/',
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getByIds: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'set' }
                    },
                    getByPersonId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'byPerson', id: '@id' }
                    },
                    getByEmail: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'byEmail', email: '@email', id: '' }
                    },
                    getDetailed: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id', route: 'detailed' }
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: {}
                    },
                    add: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'add' }
                    },
                    getAll: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'all' }
                    },
                    getAllByMail: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'byMail', mail: '@mail' }
                    },
                    provisionAccounts: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'provisionAccounts' }
                    }
                }
            )
    ]);
}
