/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Scope.d.ts"/>
/// <reference path="../../Window.d.ts"/>

namespace Umbrella.Modules.Colleagues {
    @Service('Colleagues', 'EmployeeService')
    export class EmployeeService {
        public isAssignedToFunctionGroup(employee, functiongroupId: System.Guid) {
            if (!employee || !employee.functionGroups || !functiongroupId) return false;

            let isInGroup = false;
            angular.forEach(employee.functionGroups, value => {
                if (!isInGroup) {
                    if (value.id === functiongroupId) isInGroup = true;
                }
            });

            return isInGroup;
        }

        public isAuthorized(permissionName) {
            if (window.user.permissions[permissionName] !== undefined) return true;
            return false;
        }
    }
}
