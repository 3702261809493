namespace Umbrella.ChatConversationHandling {
    import WaitingStatisticsModel = Umbrella.Chat.WaitingStatisticsModel;

    const averageQueueLength = 3;
    const longQueueLength = averageQueueLength * 2;
    const averageQueueTime = 30;
    const longQueueTime = 60;

    @Component('Chat', {
        selector: 'chat-waiting-stats',
        templateUrl: '/Modules/Chat/ChatWaitingStatsComponent/ChatWaitingStats.html'
    })
    @Inject('ChatResource', 'ChatStore', 'ToastMessageService')
    export class ChatWaitingStatsComponent {
        public waitingCustomersCount = 0;
        public maxWaitingTimeSeconds = 0;
        public averageWaitingPickupTime = 0;
        public averageWaitingReplyTime = 0;
        public activeChatConversations = 0;

        public chatEnabled = false;
        public displayPanel = false;
        private chatEnabledObservable: Rx.IDisposable;
        private chatStatisticsObservable: Rx.IDisposable;

        constructor(private chatResource: ChatResource, private chatStore: ChatStore, private toastMessageService: ToastMessageService,) {}

        $onInit() {
            const isEnabledAndRegistered = (state: ChatState) =>
                state && state.enabled && state.registration && state.registration.status === RegisterStatus.Registered;

            this.chatEnabledObservable = this.chatStore.state$
                .map(isEnabledAndRegistered)
                .distinctUntilChanged()
                .subscribe((isEnabled: boolean) => {
                    this.chatEnabled = isEnabled;
                });

            let isRetrying = false;
            this.chatStatisticsObservable = Rx.Observable.interval(5000)
                .startWith(0)
                .filter(x => this.chatEnabled)
                .flatMapLatest(() =>
                    Rx.Observable.fromPromise(<any>this.chatResource.getWaitingStats().$promise).take(1)
                )
                .tapOnError((error) => {
                    if (!isRetrying) {
                        this.toastMessageService.warning(
                            'Trying to reconnect to chat...',
                            3600000
                        );

                        isRetrying = true;
                    }

                    return error;
                })
                .retryWhen(errors => errors.delay(10000))
                .subscribe((stats: WaitingStatisticsModel) => {
                    if (isRetrying) {
                        this.toastMessageService.success(
                            'Reconnected to chat.',
                            3600000
                        );
                        
                        isRetrying = false;
                    }

                    this.chatEnabled = true;

                    this.waitingCustomersCount = stats.waitingCustomersCount;
                    this.maxWaitingTimeSeconds = stats.maxWaitingTimeSeconds;
                    this.averageWaitingPickupTime = stats.averageWaitingPickupTime;
                    this.averageWaitingReplyTime = stats.averageWaitingReplyTime;
                    this.activeChatConversations = stats.activeChatConversations;
                });
        }

        $onDestroy() {
            if (this.chatStatisticsObservable) this.chatStatisticsObservable.dispose();
            if (this.chatEnabledObservable) this.chatEnabledObservable.dispose();
        }

        public isQueueLengthAboveAverage() {
            return this.waitingCustomersCount > averageQueueLength;
        }

        public isQueueLengthLong() {
            return this.waitingCustomersCount > longQueueLength;
        }

        public isQueueTimeAboveAverage() {
            return !this.isQueueTimeLong() && this.maxWaitingTimeSeconds > averageQueueTime;
        }

        public isQueueTimeLong() {
            return this.maxWaitingTimeSeconds > longQueueTime;
        }

        public togglePanel(): void {
            this.displayPanel = !this.displayPanel;
        }
    }
}
