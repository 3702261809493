namespace Umbrella.Modules.Chat {
    @Component('Chat', {
        selector: 'chat-stars-rating',
        templateUrl: '/Modules/Chat/ChatStarsRatingComponent/ChatStarsRating.html',
        bindings: {
            rating: '<'
        }
    })
    export class ChatStarsRatingComponent {
        public inactiveStars: number[];
        public activeStars: number[];

        public $onChanges(changes: any) {
            if (changes && changes.rating && changes.rating.currentValue) {
                let rating = changes.rating.currentValue;
                if (rating > 5) {
                    rating = 5;
                }

                if (rating < 0) {
                    rating = 0;
                }
                this.activeStars = new Array(rating);
                this.inactiveStars = new Array(5 - rating);
            }
        }
    }
}
