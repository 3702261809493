/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Scope.d.ts"/>
/// <reference path="../../Window.d.ts" />

namespace Umbrella.Modules.Dashboard {
    export interface IDashboardMenuScope {
        oninit: any;
        croute: any;
        stateisloading: boolean;
    }

    @Controller('Dashboard', 'DashboardController')
    @Inject('$rootScope', '$scope', '$state')
    class DashboardController {
        constructor(
            public $rootScope: IUmbrellaRootScope,
            private $scope: IDashboardMenuScope,
            public $state: ng.ui.IStateService
        ) {
            $scope.oninit = this.$onInit();
            $scope.croute = this.getThemeForDashboardModule();
            $scope.stateisloading = false;

            $rootScope.$on('$routeUpdate', function() {
                this.countResults();
                $scope.croute = this.getThemeForDashBoardModule();
            });

            $rootScope.$on(
                '$stateChangeStart',
                (
                    event: ng.IAngularEvent,
                    toState: ng.ui.IState,
                    toParams: ng.ui.IStateParamsService,
                    fromState: ng.ui.IState,
                    fromParams: ng.ui.IStateParamsService
                ) => {
                    if (toState.redirectTo !== fromState.name) {
                        $scope.croute = this.getThemeForDashboardModule();
                        $scope.stateisloading = true;
                    }
                }
            );

            $rootScope.$on(
                '$stateChangeSuccess',
                (
                    event: ng.IAngularEvent,
                    toState: ng.ui.IState,
                    toParams: ng.ui.IStateParamsService,
                    fromState: ng.ui.IState,
                    fromParams: ng.ui.IStateParamsService
                ) => {
                    $scope.croute = this.getThemeForDashboardModule();
                    $scope.stateisloading = false;
                }
            );

            $rootScope.$on(
                '$stateChangeError',
                (
                    event: ng.IAngularEvent,
                    toState: ng.ui.IState,
                    toParams: ng.ui.IStateParamsService,
                    fromState: ng.ui.IState,
                    fromParams: ng.ui.IStateParamsService,
                    error
                ) => {
                    $scope.croute = this.getThemeForDashboardModule();
                    $scope.stateisloading = false;
                }
            );
        }

        public $onInit(): void {
            this.$scope.croute = this.getThemeForDashboardModule();
        }

        private getThemeForDashboardModule() {
            if (this.$state.includes('dashboard.tasks') || this.$state.includes('dashboard.tasks.*')) {
                return { class: 'green', iscase: false };
            } else if (this.$state.includes('dashboard.caseflow') || this.$state.includes('dashboard.caseflow.*')) {
                return { class: 'purple', iscase: false };
            } else {
                return { class: 'blue', iscase: false };
            }
        }
    }
}
