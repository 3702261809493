namespace Umbrella.Modules {
    @Pipe('Umbrella', 'addressLine')
    class AddressLineFilter {
        public transform(a: AddressModel) {
            const addressLine =
                (a.street ? a.street + ' ' : '') +
                (a.houseNumber ? a.houseNumber : '') +
                (a.houseNumberExtension
                    ? this.getHousenumberExtension(a.houseNumberExtension)
                    : '');
            return addressLine.trim().length > 1 ? addressLine : '';
        }

        private getHousenumberExtension = (ext: string) =>
            ext ? (/^[\-*]/.test(ext) ? '' : ' ') + ext : '';
    }
}
