﻿namespace Umbrella.Modules.Mail {
    import SimplifiedMailModel = Umbrella.Mail.SimplifiedMailModel;
    import MailRecipientModel = Umbrella.Mail.MailRecipientModel;
    import Attachment = Umbrella.Mail.MailModel.Attachment;

    export interface ViewMailPopupScope {
        mail: SimplifiedMailModel;
        attachments: Attachment[];
        hideForwardButton: boolean;
        previewUrl: string;
        attachmentUrls: { [id: string]: string };
        $modalSuccess(): void;
        getPreviewUrl(mail: SimplifiedMailModel): string;
        getAttachmentDownloadUrl(mail: SimplifiedMailModel, attachment: Attachment): string;
        getAddressList(addresses: MailRecipientModel[]): string;
        forwardMail(mail: SimplifiedMailModel): void;
        isSystemUser(): boolean;
    }

    @Controller('Mail', 'ViewMailPopupController')
    @Inject('$scope', '$sce', 'OutgoingMailResource', 'CreateDialog')
    export class ViewMailPopupController {
        constructor(
            private $scope: ViewMailPopupScope,
            private $sce: ng.ISCEService,
            private outgoingMailResource: IOutgoingMailResourceClass,
            createDialog
        ) {
            $scope.forwardMail = mail => {
                createDialog(
                    '/Modules/Umbrella/Admin/Mail/ForwardMailPopup.html',
                    {
                        id: 'forwardMailPopup',
                        backdrop: true,
                        modalClass: 'popup',
                        backdropClass: 'popup-backdrop',
                        controller: 'ForwardMailPopupController'
                    },
                    {
                        mail
                    }
                );
            };

            $scope.isSystemUser = () => {
                return !!window.user.permissions.shell;
            };            
        }

        public $onInit() {
            this.loadAttachments();
            this.$scope.previewUrl = this.getPreviewUrl(this.$scope.mail);
        }

        private loadAttachments(): void {
            this.$scope.attachmentUrls = {};
            this.outgoingMailResource.get({ id: this.$scope.mail.id }).$promise.then(data => {
                this.$scope.attachments = data.attachments;
                for (const attachment of this.$scope.attachments) {
                    this.$scope.attachmentUrls[attachment.contentId] = this.getAttachmentDownloadUrl(
                        this.$scope.mail,
                        attachment
                    );
                }
            });
        }

        private getAttachmentDownloadUrl(mail: SimplifiedMailModel, attachment: Attachment) {
            return this.$sce.trustAsResourceUrl(
                Config.makeAbsoluteApiUrl(`/api/v1/mail/outgoing/${mail.id}/attachment/${attachment.contentId}`)
            );
        }

        private getPreviewUrl(mail: SimplifiedMailModel) {
            const url = mail
                ? this.$sce.trustAsResourceUrl(Config.makeAbsoluteApiUrl(`/api/v1/mail/outgoing/${mail.id}`))
                : '';
            return url;
        }
    }
}
