namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowStep = Umbrella.CaseFlow.CaseFlowModel.CaseFlowStep;
    @Pipe('CaseFlow', 'pickedUpStep')
    class PickedUpStepFilter {
        public transform(input: CaseFlowStep[]): CaseFlowStep {
            if (!input) return undefined;

            const steps = input.filter(this.isStepPickedUp);
            return steps.length > 0 ? steps[0] : null;
        }

        public isStepPickedUp(step: CaseFlowStep): boolean {
            return step.pickedUpOn !== null && step.finishedOn === null;
        }
    }
}
