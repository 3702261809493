﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Scope.d.ts"/>

namespace Umbrella.CustomerService.ComplexCard {
    import ComplexModel = Umbrella.Modules.Housing.ComplexModel;
    import UnitModel = Umbrella.Modules.Housing.UnitModel;
    import IComplexResource = Umbrella.Modules.Housing.IComplexResource;
    import IUnitResource = Umbrella.Modules.Housing.Units.IUnitResource;

    export interface ComplexCardSidebarScope {
        loading: boolean;
        complex: ComplexModel;
        units: UnitModel.WithCustomerAndContract[];
        firstunit: UnitModel.WithCustomerAndContract;
    }

    @Controller('CustomerService', 'ComplexCardSidebarController')
    @Inject(
        '$scope',
        '$stateParams',
        '$location',
        '$state',
        'ComplexResource',
        'UnitResource'
    )
    export class ComplexCardSidebarController {
        private complexId: System.Guid;

        constructor(
            protected $scope: ComplexCardSidebarScope,
            $stateParams,
            $location,
            protected $state,
            protected complexResource: IComplexResource,
            protected unitResource: IUnitResource
        ) {
            this.complexId = $stateParams.complexId;

            this.loadComplexData();
            this.loadUnits();
        }

        private async loadComplexData(): Promise<void> {
            this.$scope.loading = true;
            this.$scope.complex = await this.complexResource.getById({
                id: this.complexId
            }).$promise;
            this.$scope.loading = false;
        }

        protected async loadUnits(): Promise<void> {
            this.$scope.units = await this.unitResource.getAllByComplexId({
                id: this.complexId
            }).$promise;
            this.$scope.firstunit = this.$scope.units.length
                ? this.$scope.units[0]
                : null;
        }
    }
}
