/// <reference path="../../../../../Decorators.ts" />

namespace Umbrella.TaskHandling {
    @Pipe('TaskHandling', 'propertiesCount')
    class PropertiesCountFilter {
        public transform(input): number {
            if (!input) return 0;

            return Object.keys(input).length;
        }
    }
}
