﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.UnitCard.Valuation {
    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) =>
            $stateProvider.state('customerservice.unitcard.tab.valuation', {
                url: '/woningwaardering',
                reloadOnSearch: false,
                data: {
                    tab: 'Woningwaardering',
                    authorize: ps => ps.customerCard
                },
                views: {
                    'tab@customerservice': {
                        template: '<unit-valuation></unit-valuation>'
                    }
                },
                onEnter: [
                    '$stateParams',
                    'UnitCardService',
                    (
                        $stateParams: UnitCardStateParams,
                        unitCardService: UnitCardService
                    ) => {
                        unitCardService.loadValuations();
                    }
                ]
            })
    ]);
}
