namespace Umbrella.Modules.SelfService {
    @Controller('SelfService', 'RepairRequestController')
    @Inject('$scope', '$stateParams', 'RepairRequestResource')
    class RepairRequestController {
        constructor($scope, $stateParams, RepairRequestResource) {
            $scope.report = RepairRequestResource.getDetailed({
                id: $stateParams.id
            });
        }
    }
}
