namespace Umbrella.Modules.Colleagues {
    import FunctionGroupModel = Knowledgebase.FunctionGroupModel;
    import IResource = ng.resource.IResource;

    export interface IdTagRequestParams extends IdRequestParams {
        tagId: System.Guid;
    }

    export interface IFunctionGroupResourceClass {
        getAll(params: PagedRequestParams): IResource<PagedItemsModel<FunctionGroupModel>>;
        getById(params: IdRequestParams): IResource<FunctionGroupModel>;
        getByIds(params: IdsRequestParams): IResource<FunctionGroupModel[]>;
        save(data: FunctionGroupModel): IResource<FunctionGroupModel>;
        update(data: FunctionGroupModel | IResource<FunctionGroupModel>): IResource<FunctionGroupModel>;
        delete(id: IdRequestParams): IResource<{}>;
        getFunctiongroupsByTagId(params: PagedRequestWithIdParams): IResource<PagedItemsModel<FunctionGroupModel>>;
        getPagedByTagId(params: PagedRequestWithIdParams): IResource<PagedItemsModel<FunctionGroupModel>>;
        find(params: IdRequestParams): IResource<PagedItemsModel<FunctionGroupModel>>;
        updateForTag(data: UpdateFunctionGroupsOfThesaurusTagModel): IResource<{}>;
        getByUnitAndTagId(params: IdTagRequestParams): IResource<FunctionGroupModel>;
    }

    angular.module('Colleagues').service('FunctionGroupResource', [
        '$resource',
        ($resource: ng.resource.IResourceService) =>
            $resource(
                '/api/v1/functiongroup/:action/:id',
                {},
                {
                    getAll: {
                        method: 'GET',
                        isArray: false,
                        params: { action: '', page: 0, pageSize: 10 }
                    },
                    getByUnitAndTagId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'byunit', id: '' }
                    },
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { action: '', id: '' }
                    },
                    getByIds: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'byIds', id: '' }
                    },
                    save: {
                        method: 'POST',
                        isArray: false,
                        params: { action: '' }
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: { action: '', id: '@id' }
                    },
                    delete: {
                        method: 'DELETE',
                        isArray: false,
                        params: { action: '', id: '@id' }
                    },
                    getFunctiongroupsByTagId: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'functiongroupsbytag',
                            id: '@id',
                            page: 0,
                            pageSize: 10
                        }
                    },
                    getPagedByTagId: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'pagedbytag',
                            id: '@id',
                            page: 0,
                            pageSize: 10
                        }
                    },
                    find: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'find',
                            id: '@id',
                            page: 0,
                            pageSize: 10
                        }
                    },
                    updateForTag: {
                        method: 'PUT',
                        isArray: false,
                        params: { action: 'updateForTag' }
                    }
                }
            )
    ]);
}
