/// <reference path="../../node_modules/keycloak-js/dist/keycloak.d.ts" />

namespace Umbrella.KeycloakAuth {

    export let keycloak: Keycloak.KeycloakInstance = null;

    function startSession(): void {
        const headers = {
            'Authorization': `Bearer ${keycloak.token}`
        };

        $.ajax(Config.makeAbsoluteApiUrl('api/v1/system/session'), {
            dataType: 'json',
            headers: headers,
            cache: false,
            success: (x: SessionState) =>
                session$.onNext({
                    ...x,
                    accessToken: keycloak.token
                }),
            error: (jqXHR, textStatus, errorThrown) =>
                session$.onNext({
                    user: null,
                    accessToken: null,
                    config: null,
                    error: {
                        name: 'NotAuthorized',
                        message: `Status: ${textStatus || '(null)'}. Error: ${errorThrown || '(null)'}`,
                        stack: ''
                    }
                })
        });
    }

    const startKeycloak = async function(cfg: Config.Config) {
        console.debug(cfg.keycloak);
        const KeycloakCtor = (<any>window).kc;
        keycloak = new KeycloakCtor(cfg.keycloak);

        const keycloakInitOptions: Keycloak.KeycloakInitOptions = {
            scope: "openid suite-user identity-provider-user",
            pkceMethod: "S256",
            responseMode: "query",
            redirectUri: `${document.location.origin}${document.location.pathname}${document.location.hash || '#/dashboard/taken/overzicht'}`,
            onLoad: "login-required",
            timeSkew: 0,
            checkLoginIframe: false
          };

        const succeeded = await keycloak.init(keycloakInitOptions);
        if (succeeded) {
            console.debug('logged in!');
            console.debug(keycloak.token);
            startSession();

            setInterval(async () => {
                try {
                    const refreshed = await keycloak.updateToken(70);

                    if (refreshed) {
                        session$.onNext({
                            ...session$.getValue(),
                            accessToken: keycloak.token
                        }),
                        console.debug('Token refreshed');
                    } else {
                        console.warn('Token not refreshed');
                    }
                } catch {
                    console.error('Failed to refresh token');
                }
            }, 50000)
        } else {
            console.error('Failed to login', succeeded);
        }
    }

    Umbrella.Config.configStore.state$
            .map(state => {
                return state && state.config;
            })
            .skipWhile(cfg => {
                return !(cfg && cfg.keycloak);
            })
            .take(1)
            .subscribe(cfg => startKeycloak(cfg));
}