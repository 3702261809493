namespace Umbrella.Modules.SelfService {
    angular
        .module('SelfService')
        .filter('selfServiceStatusName', function() {
            return function(input) {
                switch (input) {
                    case 1:
                    case 'Online':
                    default:
                        return 'Online';
                    case 10:
                    case 'Offline':
                        return 'Gepland offline';
                    case 11:
                    case 'KccOnly':
                        return 'Alleen beschikbaar voor KCC medewerkers';
                    case 12:
                    case 'AdministratorsOnly':
                        return 'Alleen beschikbaar voor KCC beheerders';
                    case 20:
                    case 'Faulted':
                        return 'Offline';
                }
            };
        })
        .filter('selfServiceStatusClass', function() {
            return function(input) {
                switch (input) {
                    case 1:
                    case 'Online':
                    default:
                        return 'green';
                    case 11:
                    case 'KccOnly':
                    case 12:
                    case 'AdministratorsOnly':
                        return 'orange';
                    case 10:
                    case 'Offline':
                    case 20:
                    case 'Faulted':
                        return 'red';
                }
            };
        })
        .filter('complaintStatus', function() {
            return function(input) {
                switch (input) {
                    case 'Pending':
                        return 'Wachtend';
                    case 'Submitted':
                        return 'Ingediend door klant';
                    case 'InProgress':
                        return 'In behandeling';
                    case 'Finished':
                        return 'Afgehandeld';
                    case 'Canceled':
                        return 'Geannuleerd';
                }

                return 'Onbekend (' + input + ')';
            };
        })
        .filter('complimentStatus', function() {
            return function(input) {
                switch (input) {
                    case 'Pending':
                        return 'Wachtend';
                    case 'Submitted':
                        return 'Ingediend door klant';
                    case 'InProgress':
                        return 'In behandeling';
                    case 'Finished':
                        return 'Afgehandeld';
                    case 'Canceled':
                        return 'Geannuleerd';
                }

                return 'Onbekend (' + input + ')';
            };
        })
        .filter('disturbanceStatus', function() {
            return function(input) {
                switch (input) {
                    case 'Pending':
                        return 'Wachtend';
                    case 'Submitted':
                        return 'Ingediend door klant';
                    case 'InProgress':
                        return 'In behandeling';
                    case 'Finished':
                        return 'Afgehandeld';
                    case 'Canceled':
                        return 'Geannuleerd';
                }

                return 'Onbekend (' + input + ')';
            };
        })
        .filter('houseAdjustmentStatus', function() {
            return function(input) {
                switch (input) {
                    case 'Pending':
                        return 'Wachtend';
                    case 'Submitted':
                        return 'Ingediend door klant';
                    case 'InProgress':
                        return 'In behandeling';
                    case 'Finished':
                        return 'Afgehandeld';
                    case 'Canceled':
                        return 'Geannuleerd';
                }

                return 'Onbekend (' + input + ')';
            };
        })
        .filter('selfServiceReportStatus', function() {
            return function(input) {
                switch (input) {
                    case 'All':
                        return 'Alle';
                    case 'InProgress':
                        return 'Gewijzigd';
                    case 'Closed':
                        return 'Geannuleerd';
                    case 'Submitted':
                        return 'Ingediend door klant';
                }

                return 'Onbekend (' + input + ')';
            };
        })
        .filter('repairRequestStatus', function() {
            return function(input) {
                switch (input) {
                    case 'Pending':
                        return 'Wachtend';
                    case 'Prepared':
                        return 'Voorbereid';
                    case 'Submitted':
                        return 'Ingediend door klant';
                    case 'Accepted':
                        return 'Geaccepteerd / in behandeling';
                    case 'Finished':
                        return 'Afgehandeld';
                    case 'Locked':
                        return 'Gefixeerd';
                    case 'Canceled':
                        return 'Geannuleerd';
                }

                return 'Onbekend (' + input + ')';
            };
        })
        .filter('repairRequestItemStatus', function() {
            return function(input) {
                switch (input) {
                    case 'Pending':
                        return 'In behandeling';
                    case 'Finished':
                        return 'Afgerond';
                    case 'Canceled':
                        return 'Geannuleerd';
                }

                return 'Onbekend (' + input + ')';
            };
        })
        .filter('selfServiceStatusSwitchClass', function() {
            return function(input) {
                switch (input) {
                    case 1:
                    case 'Online':
                        return 'greenbutton';
                    case 10:
                    case 'Offline':
                    case 11:
                    case 'KccOnly':
                    case 12:
                    case 'AdministratorsOnly':
                        return 'orangebutton';
                    case 20:
                    case 'Faulted':
                        return 'redbutton';
                    default:
                        return 'greenbutton';
                }
            };
        });
}
