namespace Umbrella.CustomerService.CustomerCard.Activities.Registration {
    import ChannelType = Umbrella.Modules.ChannelType;
    import SelfServiceScenarioModel = Umbrella.Modules.SelfService.SelfServiceScenarioModel;
    import FaqModel = Umbrella.Modules.Knowledgebase.FaqModel;
    import ThesaurusTagModel = Umbrella.Modules.Knowledgebase.ThesaurusTagModel;
    import TaskTypeModel = Umbrella.Modules.Tasks.TaskTypeModel;
    import TargetGroupModel = Umbrella.Modules.Knowledgebase.TargetGroupModel;
    import ContactDirection = Modules.Contacts.ContactDirection;

    export interface TagInfo {
        readonly loading: boolean;
        readonly tags: ThesaurusTagModel[];
    }

    export interface FaqConcept {
        readonly id?: System.Guid;
        readonly saving: boolean;
        readonly question: string;
        readonly answer: string;
    }

    export interface FaqInfo {
        readonly loading: boolean;
        readonly faqs: PagedItemsModel<FaqModel>;
        readonly faqConcept: FaqConcept;
    }

    export interface NewContact {
        description: string;
        roleId: System.Guid;
        caseId: System.Guid;
        contractId: System.Guid;
        notifyClient: boolean;
        direction: ContactDirection.Inbound;
        isPublic: boolean;
    }

    export interface ContactRegistrationInfo {
        saving: boolean;
        error: any;
        contact: NewContact;
    }

    export interface NewTask {
        subject: string;
        description: string;
        contractId: System.Guid;
        roleId: System.Guid;
        caseId: System.Guid;
        completeById: System.Guid;
        fromId: System.Guid;
        taskType: TaskTypeModel;
        endDate: Date;
        mediaIds: System.Guid[];
    }

    export interface TaskRegistrationInfo {
        saving: boolean;
        error: any;
        task: NewTask;
    }

    export enum SelectedPanel {
        Registration,
        Sss,
        None
    }

    export interface TargetGroupInfo {
        loading: boolean;
        targetGroups: TargetGroupModel[];
    }

    export interface ActivityRegistrationState {
        readonly selectedPanel: SelectedPanel;
        readonly selectedChannelType: ChannelType;
        readonly selectedTag: ThesaurusTagModel;
        readonly selectedFaq: FaqModel;
        readonly selectedTargetGroup: TargetGroupModel;
        readonly selectedFollowUpQuestionId: System.Guid;
        readonly selectedScenario: SelfServiceScenarioModel;
        readonly query: string;
        readonly scenarios: SelfServiceScenarioModel.Detailed[];
        readonly tagInfo: TagInfo;
        readonly faqInfo: FaqInfo;
        readonly contactRegistrationInfo: ContactRegistrationInfo;
        readonly taskRegistrationInfo: TaskRegistrationInfo;
        readonly targetGroupInfo: TargetGroupInfo;
    }
}
