﻿namespace Umbrella.Search {
    @Component('Search', {
        selector: 'search-status',
        templateUrl: '/Search/SearchStatusComponent/SearchStatus.html',
        bindings: {
            searchquery: '@',
            results: '<',
            searching: '<',
            singular: '@',
            plural: '@'
        }
    })
    class SearchStatusComponent {}
}
