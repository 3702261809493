﻿/// <reference path="../Decorators.ts" />
/// <reference path="../Window.d.ts" />
/// <reference path="../TaskHandling/module.ts"/>
/// <reference path="../Top/TopSearchComponent/TopSearchComponent.ts"/>
/// <reference path="../TaskHandling/_Overview/TaskOverviewService.ts"/>
/// <reference path="../deviceModeMethods.ts" />
/// <reference path="../TaskHandling/_Overview/TaskOverviewComponent.ts" />
/// <reference path="../InfiniteListComponent/InfiniteListComponent.ts" />

namespace Umbrella {
    export const toggleNavigationIcon$ = new Rx.Subject();

    import taskSelected$ = Umbrella.TaskHandling.taskSelected$;
    import caseSelected$ = Umbrella.CaseHandling.caseSelected$;
    import selectFromSearch$ = Umbrella.Modules.selectFromSearch$;
    import TaskOverviewService = Umbrella.TaskHandling.TaskOverviewService;
    import showTaskDetailView$ = Umbrella.TaskHandling.showTaskDetailView$;
    import switchDeviceMode = Umbrella.switchDeviceMode;
    import toggleTaskListScroll = Umbrella.Shared.toggleTaskListScroll$;
    import isTaskListFilterViewEnabled = Umbrella.TaskHandling.isTaskListFilterViewEnabled$;
    import isCustomerFilterViewEnabled = Umbrella.CustomerService.isCustomerFilterViewEnabled$;
    import toggleAdditionalInformationView = Umbrella.CustomerService.toggleAdditionalInformationView$;

    @Component('CustomerService', {
        selector: 'responsive-navigation',
        templateUrl: '/ResponsiveNavigationComponent/ResponsiveNavigation.html',
        bindings: {
            displayAdditionalInformation: '<'
        }
    })
    @Inject('TaskOverviewService')
    class ResponsiveNavigationComponent {
        icon: string;
        classValue: string;
        toggleValue: boolean;
        private navigationIconSubscription: Rx.Disposable;
        additionalInformationAvailable = false;
        responsiveFiltersAvailable = false;
        customerTimelineFiltersAvailable = false;
        listFilterOverlay = false;
        private showTaskDetailViewObserver: Rx.IDisposable;
        public displayAdditionalInformation: boolean;

        constructor(private taskOverviewService: TaskOverviewService) {}

        public $onInit() {
            this.subscribeEvents();
            this.setIcon();
            this.observeTaskUpdates();
            this.showTaskDetailViewObserver = showTaskDetailView$.subscribe(value => {
                this.toggleValue = value;
            });
        }

        public $onDestroy() {
            if (this.showTaskDetailViewObserver) {
                this.showTaskDetailViewObserver.dispose();
                this.showTaskDetailViewObserver = null;
            }
            if (this.navigationIconSubscription) {
                this.navigationIconSubscription.dispose();
                this.navigationIconSubscription = null;
            }
        }

        private subscribeEvents() {
            this.navigationIconSubscription = toggleNavigationIcon$.subscribe(icon => {
                this.toggleIcon(icon);
            });
        }

        public isSurfaceTab(): boolean {
            return !!(window.device && window.device.surface());
        }

        public toggleDeviceMode(): void {
            switchDeviceMode();
        }

        public focusSearchInput() {
            document.getElementById('searchQueryInput').focus();
        }

        private observeTaskUpdates(): void {
            caseSelected$
                .filter(classValue => !!classValue)
                .subscribe(classValue => {
                    this.toggleView();
                });

            taskSelected$
                .filter(classValue => !!classValue)
                .subscribe(classValue => {
                    this.toggleView();
                });

            selectFromSearch$.subscribe(classValue => {
                this.showListFilterOverlay(classValue);
                this.toggleView();
            });
        }

        public removeTaskDetailView() {
            this.taskOverviewService.setDisplayTaskDetailView(false);
        }

        public setIcon() {
            setTimeout(() => {
                if (document.getElementsByClassName('additional-information').length > 0) {
                    this.additionalInformationAvailable = true;
                }
                if (document.getElementsByClassName('responsive-view-filters').length > 0) {
                    this.responsiveFiltersAvailable = true;
                }
                if (document.getElementsByClassName('customer-timeline-filters').length > 0) {
                    if (
                        document.getElementsByClassName('customer-timeline-filters')[0].children[0].children.length > 0
                    ) {
                        this.customerTimelineFiltersAvailable = true;
                    }
                }
            }, 250);
        }

        public toggleIcon(iconValue) {
            if (iconValue === this.icon) {
                this.icon = '';
            } else {
                this.icon = iconValue;
            }
        }

        public toggleView() {
            this.toggleIcon('');
            this.removeOverlay();
            this.icon = '';
        }

        public removeOverlay() {
            if (
                document.getElementsByTagName('body')[0].classList.contains('ismobile') &&
                document.getElementsByClassName('responsive-view-filters').length
            ) {
                document
                    .getElementsByClassName('responsive-view-filters')[0]
                    .setAttribute('style', 'display:none !important');
                this.listFilterOverlay = false;
                if (document.getElementsByClassName('active-icon').length)
                    document.getElementsByClassName('active-icon')[0].classList.remove('active-icon');
            }
        }

        public toggleAdditionalInformation() {
            toggleAdditionalInformationView.onNext(null);
        }

        public showListFilterOverlay(classValue) {
            if (this.classValue && this.classValue !== classValue) {
                const additionalInformationX = angular.element(document.getElementsByClassName(this.classValue));
                additionalInformationX[0].setAttribute('style', 'display:none !important');
                const additionalInformation = angular.element(document.getElementsByClassName(classValue));
                additionalInformation[0].setAttribute('style', 'display:flex !important');
                this.listFilterOverlay = true;
            } else {
                if (this.listFilterOverlay === false) {
                    const additionalInformationX = angular.element(document.getElementsByClassName(classValue));
                    additionalInformationX[0].setAttribute('style', 'display:flex !important');
                    this.listFilterOverlay = true;
                } else {
                    const additionalInformationX = angular.element(document.getElementsByClassName(classValue));
                    additionalInformationX[0].setAttribute('style', 'display:none !important');
                    this.listFilterOverlay = false;
                }
            }

            this.classValue = classValue;
        }

        public toggleSearchView() {
            isSearchActive$.onNext(null);
            this.toggleIcon('search');
        }

        public isTaskListFilterViewEnabled(): void {
            isTaskListFilterViewEnabled.onNext(null);
            toggleTaskListScroll.onNext(null);
        }

        public isCustomerFilterViewEnabled(): void {
            isCustomerFilterViewEnabled.onNext(null);
        }

        public resetDisplayElement(): void {
            angular.element(document.getElementsByClassName('header-top')).removeAttr('style');
            angular.element(document.getElementsByClassName('responsive-view-filters')).removeAttr('style');
            this.toggleIcon('');
            this.listFilterOverlay = false;
        }

        public displayDownloadButton(): boolean {
            return !!window.deferredPrompt;
        }

        public downloadApp(): void {
            window.deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            window.deferredPrompt.userChoice.then(choiceResult => {
                window.deferredPrompt = null;
            });
        }
    }
}
