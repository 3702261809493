﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import MediaModel = Umbrella.Media.MediaModel;
    import MediaLinkModel = Umbrella.Media.MediaLinkModel;

    @Component('Dashboard', {
        selector: 'case-flow-media-items',
        templateUrl:
            '/CaseFlowProcessing/CaseFlowMediaItemsComponent/CaseFlowMediaItems.html',
        bindings: {
            media: '<',
            theme: '@',
        }
    })
    class CaseFlowMediaItemsComponent {
        public media: MediaModel[];
        public theme = 'purple';

        public getAbsoluteApiUrl(mediaItem: MediaModel): string {
            if (!mediaItem) return '';

            const relativeUrl = mediaItem.isLink
                ? (<MediaLinkModel>mediaItem).url
                : Config.makeAbsoluteApiUrl(
                      `api/v1/media/view/${mediaItem.id}`
                  );

            return relativeUrl;
        }
    }
}
