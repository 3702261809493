﻿namespace Umbrella.Search.Customers {
    import AddressModel = Umbrella.Modules.AddressModel;
    import PersonSearchModel = Umbrella.Modules.PersonSearchModel;

    @Controller('Search', 'CustomerOverviewController')
    @Inject('$scope', '$location', 'PersonResource')
    class CustomerOverviewController {
        constructor($scope, private $location: ng.ILocationService, private personResource: PersonResource) {
            const pageSize = 50;
            const searchParamName = 'query';

            $scope.searchQuery = $location.search()[searchParamName] || '';
            $scope.searching = false;

            // Function that displays the search result box, or hides it if no results are present.
            const showCustomers = results => {
                let i;

                if ($scope.page === 0) {
                    $scope.persons = results;
                } else {
                    for (i = 0; i < results.items.length; i++) {
                        $scope.persons.items.push(results.items[i]);
                    }
                }

                $scope.searching = false;
            };

            $scope.clear = () => {
                $scope.persons = [];
                $scope.searching = false;
            };

            // Execute search query using the current $scope.searchQuery
            $scope.search = append => {
                if (append) {
                    $scope.page++;
                } else {
                    $scope.page = 0;
                }

                if ($scope.searchQuery) {
                    $scope.searching = true;

                    let param = $scope.searchQuery;

                    if (param.charAt(param.length - 1) === '.') {
                        param = param.slice(0, -1);
                    }

                    personResource.search({
                            id: param,
                            page: $scope.page,
                            pageSize: pageSize,
                            roles: 'customer,prospect'
                        })
                        .$promise.then(results => {
                            let p = $scope.searchQuery;

                            if (p.charAt(p.length - 1) === '.') {
                                p = p.slice(0, -1);
                            }                            

                            if (param.toLowerCase() === p.toLowerCase()) {
                                showCustomers(results);
                            }
                        });
                } else {
                    $scope.clear();
                }
            };

            $scope.loadMore = () => {
                if ($scope.persons && $scope.persons.items && $scope.persons.items.length !== $scope.persons.total)
                    $scope.search(true);
            };

            $scope.getAddress = (addresses: any) => {
                if (!addresses) return '';                  
                if(addresses.length > 1 && $scope.searchQuery) {   
                    const q = $scope.searchQuery.split(" ").map(word => word.toLowerCase());                                  
                    addresses.forEach(address => {
                        address.hits = q.map(e => address.fullAddressLine.toLowerCase().indexOf(e)).filter(r => r > -1).length;                       
                    })
                    
                    const result = addresses.sort((a,b) => b.hits-a.hits)[0];
                    return result.fullAddressLine || `${result.addressLine}, ${result.zipCode} ${result.city}`; 
                } else {
                    return addresses.reduce((previousValue: AddressModel, currentValue: AddressModel) => {
                        if(currentValue.type && (currentValue.type.toString() === 'Home' || currentValue.type.toString() === '0'))
                            return currentValue.fullAddressLine || `${currentValue.addressLine}, ${currentValue.zipCode} ${currentValue.city}`
                        if(!previousValue && currentValue.type && (currentValue.type.toString() === 'Postal' || currentValue.type.toString() === '1'))    
                            return currentValue.fullAddressLine || `${currentValue.addressLine}, ${currentValue.zipCode} ${currentValue.city}`
                        else    
                            return previousValue 
                    }, '')
                }
            };

            $scope.openCustomerCard = (customer: PersonSearchModel) => {
                if (!customer || !(customer.type === 'natural' || customer.type === 'legal')) return;

                const personId = customer && customer.id;
                // NOTE: USE $LOCATION SERVICE INSTEAD OF $STATE SERVICE FOR NAVIGATION TO PREVENT BLOCKING RENDERING ISSUE WHEN YOU SWITCH VERY FAST FROM ADVANCED SEARCH RESULTS VIEW TO A CUSTOMER CARD AFTER A PAGE REFRESH
                this.$location.path(`/klantenkaart/${personId}`);
            };

            $scope.clear();
            $scope.search(false);
        }
    }
}
