﻿/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../../Decorators.ts" />

namespace Umbrella.TaskHandling {
    import TaskModel = Modules.Tasks.TaskModel;

    export interface LinkedTasksComponentState {
        task: TaskModel.Detailed;
        linkedTasks: TaskModel[];
    }

    @Component('TaskHandling', {
        selector: 'linked-tasks',
        templateUrl:
            '/TaskHandling/_Overview/_Details/_LinkedTasks/LinkedTasksComponent/LinkedTasks.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('ActivityResource')
    class LinkedTasksComponent {
        public state: LinkedTasksComponentState;
    }
}
