angular.module('SelfService').factory('DecisionResource', [
    '$resource',
    function($resource) {
        'use strict';

        return $resource(
            '/api/v1/decision/:action/:id',
            {},
            {
                getAll: {
                    url: '/api/v1/decision/:id/children',
                    method: 'GET',
                    isArray: true,
                    params: { id: '@id' }
                },
                getRoot: {
                    url: '/api/v1/:scenario/decision/:action/:id',
                    method: 'GET',
                    isArray: false,
                    params: { action: 'root', id: '', scenario: '' }
                },
                delete: {
                    method: 'DELETE',
                    isArray: false,
                    params: { action: '', id: '@id' }
                },
                update: {
                    method: 'PUT',
                    isArray: false,
                    params: { action: '', id: '' }
                }
            }
        );
    }
]);
