/// <reference path="../../../../Window.d.ts" />

namespace Umbrella.TaskHandling {
    import TaskModel = Modules.Tasks.TaskModel;
    import ITaskResourceClass = Modules.Tasks.ITaskResourceClass;
    import LinkAnonymousTaskModel = Modules.Tasks.LinkAnonymousTaskModel;
    import PersonSearchModel = Umbrella.Modules.PersonSearchModel;
    import IContractResource = Modules.Housing.IContractResource;
    import ContractFilterModel = Modules.Housing.ContractFilterModel;

    export interface LinkTaskComponentState {
        task: TaskModel.Detailed;
    }

    @Component('TaskHandling', {
        selector: 'link-task',
        templateUrl:
            '/TaskHandling/_Overview/_Link/LinkTaskComponent/LinkTask.html',
        bindings: {
            state: '<'
        }
    })
    @Inject(
        '$state',
        'TaskOverviewService',
        'TaskResource',
        'ToastMessageService',
        'PersonResource',
        'ContractResource'
    )
    class LinkTaskComponent {
        public state: TaskInformationComponentState;
        public updating: boolean;
        public searchingPersons: boolean;
        public searchingContracts: boolean;
        public linkAnonymousTaskModel: LinkAnonymousTaskModel;
        public persons: PersonSearchModel[] = [];
        public selectedPerson: PersonSearchModel;
        public contracts: ContractFilterModel[] = [];
        public selectedContract: ContractFilterModel;

        constructor(
            private $state: ng.ui.IStateService,
            private taskOverviewService: TaskOverviewService,
            private taskResource: ITaskResourceClass,
            private toastMessageService: ToastMessageService,
            private personResource: PersonResource,
            private contractResource: IContractResource
        ) {}

        public search = (query) => {
            if (!query || !query.length) {
                this.persons = [];
                return;
            }

            this.searchingPersons = true;
            this.personResource.search({ id: query, roles: 'customer,prospect,vendor' }).$promise
                .then(model => {
                    this.persons = model.items;
                })
                .finally(() => {
                    this.searchingPersons = false;
                });
            };

        public setPerson = (person: PersonSearchModel) => this.selectedPerson = person;

        public setContract = (contract: ContractFilterModel) => this.selectedContract = contract;

        public clearSelected = () => {
            this.selectedPerson = null;
            this.contracts = [];
            this.selectedContract = null;
        }

        public async getContracts(person: PersonSearchModel) {
            if (!person || !person.roles || Object.keys(person.roles).some(k => k !== 'customer')) return;

            this.searchingContracts = true;

            this.contracts = await this.contractResource.getAllFilterValuesByRoleId({
                id: person.roles['customer']
            }).$promise
                .finally(() => {
                    this.searchingContracts = false;
                });
        }

        public linkTask() {
            this.updating = true;

            if (!this.state || !this.state.task || !this.state.task.id) return;
            if (!this.selectedPerson || !this.selectedPerson.id) return;

            this.linkAnonymousTaskModel = {
                taakId: this.state.task.id,
                personId: this.selectedPerson.id,
                contractId: this.selectedContract && this.selectedContract.id
            };

            this.taskResource
                .linkAnonymousTask(this.linkAnonymousTaskModel).$promise
                .then(() => {
                    this.taskOverviewService
                        .update(this.state.task)
                        .then(() => {
                            this.toastMessageService.success('Taak succesvol gekoppeld');
                            this.$state.go(
                                'dashboard.tasks.overview.details.information',
                                { id: this.state.task.id }
                            );
                        })
                        .catch(response =>
                            this.$state.go(
                                'dashboard.tasks.overview.details.information',
                                { id: this.state.task.id }
                            )
                        )
                        .finally(() => (this.updating = false));
                })
                .catch(response =>
                    this.toastMessageService.error(
                        'Taak kan niet gekoppeld worden. Probeer het nogmaals.'
                    )
                )
                .finally(() => (this.updating = false));
        }

        public renderPersonName(person: PersonSearchModel) {
            return [person.name, person.contactDetails.addresses.length && person.contactDetails.addresses[0].fullAddressLine].filter(x => !!x).join(' - ');
        }
    }
}
