namespace Umbrella.CustomerService.CustomerCard.Activities.Timeline {
    import debounce = Umbrella.Helpers.debounce;
    import ActivityCategoryModel = Modules.ActivityCategoryModel;
    import ContractFilterModel = Modules.Housing.ContractFilterModel;
    import IContractResource = Modules.Housing.IContractResource;
    import ThesaurusTagModel = Umbrella.Modules.Knowledgebase.ThesaurusTagModel;
    import SelfServiceScenarioModel = Umbrella.Modules.SelfService.SelfServiceScenarioModel;
    import StatusFilter = Umbrella.Modules.StatusFilter;
    import ChannelType = Umbrella.Modules.ChannelType;

    export interface CustomerTimelineFiltersComponentState {
        person: PersonModel;
        query: string;
        activityCategories: ActivityCategoryModel[];
        selectedCategory: string;
        selectedContract: Guid;
        allScenarios: SelfServiceScenarioModel[];
        rootTags: ThesaurusTagModel[];
    }

    @Component('CustomerService', {
        selector: 'customer-timeline-filters',
        templateUrl:
            '/CustomerService/_CustomerCard/_Activities/Timeline/CustomerTimelineFiltersComponent/CustomerTimelineFilters.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('$state', 'ContractResource', 'CustomerCardActivityInfoService', 'CustomerCardAnnouncementInfoService')
    class CustomerTimelineFiltersComponent {
        public state: CustomerTimelineFiltersComponentState;
        public searchQuery: string;
        public selectedCategory: string;
        public displayedContracts: ContractFilterModel[] = [];
        public selectedContract: ContractFilterModel;
        public isOnCustomerCardAnnouncements: boolean;
        public selectedTagName: string;
        public selectedScenarioTitle: string;
        private loadingContracts: boolean;
        public selectedStatusFilter: number;
        public statusFilter: StatusFilter;
        public selectedChannelFilter: string;
        private channelFilters: { value: string; key: any }[];

        constructor(
            private $state: ng.ui.IStateService,
            private contractResource: IContractResource,
            private activityInfoService: CustomerCardActivityInfoService,
            private announcementInfoService: CustomerCardAnnouncementInfoService
        ) {}

        public $onInit() {
            this.selectedCategory = this.state && this.state.selectedCategory;
            this.searchQuery = this.state && this.state.query;

            this.filterByStatus(StatusFilter.Active);

            const cardAnnouncementsState = 'customercard.announcements';
            this.isOnCustomerCardAnnouncements = this.$state.current.name.includes(cardAnnouncementsState);

            this.channelFilters = Object.keys(ChannelType)
                .filter(x => !(parseInt(x) >= 0))
                .filter(
                    function(e) {
                        return this.indexOf(e) < 0;
                    },
                    ['Unknown', 'Umbrella', 'Facebook', 'Tweet']
                )
                .map(value => ({ key: ChannelType[value], value }));
        }

        public $onChanges() {
            const customerId = this.getCustomerId();
            const contractsLoaded = this.displayedContracts.length > 0;
            if (customerId && !contractsLoaded && !this.loadingContracts) {
                this.loadingContracts = true;
                this.loadContracts(customerId);
            }
        }

        public filterByQuery = debounce(
            (query: string) => {
                this.searchQuery = query;
                this.activityInfoService.filterByQuery(query);
            },
            1000,
            false
        );

        public searchContract = debounce(
            (query: string) => {
                const customerId = this.getCustomerId();
                if (!customerId) return;

                this.loadContracts(customerId, query);
            },
            500,
            false
        );

        public filterByContract(contract: ContractFilterModel) {
            this.activityInfoService.filterByContract(contract && contract.id);
            this.announcementInfoService.filterByContractId(contract && contract.id);
        }

        public filterByCategory(category: ActivityCategoryModel): void {
            this.selectedCategory = category && category.name;
            this.activityInfoService.filterByActivityCategory(category && category.name);
        }

        public filterByTag(tag: ThesaurusTagModel): void {
            this.selectedTagName = tag && tag.name;
            this.activityInfoService.filterByTagId(tag && tag.id);
        }

        public filterByScenario(scenario: SelfServiceScenarioModel): void {
            this.selectedScenarioTitle = scenario && scenario.title;
            this.activityInfoService.filterByScenarioId(scenario && scenario.id);
        }

        public filterByStatus(selectedStatusFilter: StatusFilter): void {
            this.selectedStatusFilter = selectedStatusFilter;
            this.activityInfoService.filterByStatus(selectedStatusFilter);
        }

        public filterByChannel(selectedChannelFilter: string): void {
            this.selectedChannelFilter = selectedChannelFilter;
            this.activityInfoService.filterByChannel(selectedChannelFilter);
        }

        public getStatusFilterDescription(statusFilter: StatusFilter): string {
            switch (+statusFilter) {
                case Umbrella.Modules.StatusFilter.Active:
                    return 'Actief';
                case Umbrella.Modules.StatusFilter.All:
                    return 'Alles';
                case Umbrella.Modules.StatusFilter.Deleted:
                    return 'Verwijderd';
                default:
                    throw new Error('Value not implemented');
            }
        }

        public getChannelFilterDescription(channelFilter: string): string {
            switch (+channelFilter) {
                case Umbrella.Modules.ChannelType.Unknown:
                    return 'Unknown';
                case Umbrella.Modules.ChannelType.Phone:
                    return 'Telefoongesprek';
                case Umbrella.Modules.ChannelType.Visit:
                    return 'Huisbezoek';
                case Umbrella.Modules.ChannelType.Desk:
                    return 'Baliebezoek';
                case Umbrella.Modules.ChannelType.Email:
                    return 'E-mail';
                case Umbrella.Modules.ChannelType.Post:
                    return 'Post';
                case Umbrella.Modules.ChannelType.Social:
                    return 'Social media';
                case Umbrella.Modules.ChannelType.Tweet:
                    return 'Tweet';
                case Umbrella.Modules.ChannelType.Facebook:
                    return 'Facebook';
                case Umbrella.Modules.ChannelType.Web:
                    return 'Klantportaal';
                case Umbrella.Modules.ChannelType.Chat:
                    return 'Chat';
                case Umbrella.Modules.ChannelType.Sms:
                    return 'Sms';
                case Umbrella.Modules.ChannelType.Umbrella:
                    return 'Umbrella';
                case Umbrella.Modules.ChannelType.Survey:
                    return 'Enquête';
                case Umbrella.Modules.ChannelType.Backoffice:
                    return 'Backoffice';
                default:
                    throw new Error('Value not implemented');
            }
        }

        private getCustomerId(): System.Guid {
            return this.state && this.state.person && this.state.person.roles['customer'];
        }

        public getStatusFilters(): string[] {
            return Object.keys(StatusFilter).filter(x => parseInt(x) >= 0 && parseInt(x) < 2);
        }

        public getChannelFilters(): { value: string; key: string }[] {
            return this.channelFilters;
        }

        private loadContracts(customerId: System.Guid, query?: string): void {
            this.contractResource
                .getFilterValuesByRoleId({
                    id: customerId,
                    page: 0,
                    pageSize: 255,
                    searchQuery: query
                })
                .$promise.then(data => {
                    this.displayedContracts = data.items;
                });
        }

        public isAutorizedToFilterOnIsDeleted = (): boolean => window.user.permissions.caseReadDeleted;

        public closeFilter() {
            if (
                document.getElementsByTagName('body')[0].classList.contains('ismobile') &&
                document.getElementsByClassName('responsive-view-filters').length
            ) {
                document
                    .getElementsByClassName('responsive-view-filters')[0]
                    .setAttribute('style', 'display:none !important');
                if (document.getElementsByClassName('active-icon').length)
                    document.getElementsByClassName('active-icon')[0].classList.remove('active-icon');
            }
        }
    }
}
