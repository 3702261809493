﻿namespace Umbrella.CustomerService.CustomerCard.Bills {
    import BillPaymentModel = Modules.Payments.Billing.BillPaymentModel;
    import Detailed = Modules.Payments.Billing.BillModel.Detailed;

    export interface BillResource {
        getPagedByContractId(
            param: PagedRequestWithIdParams
        ): ng.resource.IResource<PagedItemsModel<Detailed>>;
        getPagedLinkedToPersonByPersonId(
            param: PagedRequestWithIdParams
        ): ng.resource.IResource<PagedItemsModel<Detailed>>;
        getAllPaymentsByBillId(
            param: IdRequestParams
        ): ng.resource.IResource<BillPaymentModel.Detailed[]>;
    }

    angular.module('CustomerService').service('BillResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) => {
            return $resource(
                '/api/v1/bill/:action/:id/:subaction',
                {},
                {
                    getPagedByContractId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'bycontract', id: '@id' }
                    },
                    getPagedLinkedToPersonByPersonId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'linkedtoperson', id: '@id' }
                    },
                    getAllPaymentsByBillId: {
                        method: 'GET',
                        isArray: true,
                        params: { id: '@id', subaction: 'payments' }
                    }
                }
            );
        }
    ]);
}
