namespace Umbrella.Modules {
    import ChatService = Umbrella.ChatConversationHandling.ChatService;
    import ChannelType = Modules.ChannelType;

    interface ChannelRestoreInfo {
        channelType: ChannelType;
        userId: System.Guid;
    }

    @Service('Umbrella', 'RestoreChannelService')
    @Inject('LocalStorageService', 'ChatService')
    export class RestoreChannelService {
        private localStorageKey = 'RestoreChannelData';

        constructor(private localStorageService: LocalStorageService, private chatService: ChatService) {}

        public saveChannel(channelType: ChannelType): void {
            const info: ChannelRestoreInfo = {
                channelType: channelType,
                userId: window.user.id
            };

            this.localStorageService.store(this.buildKey(window.user.id), info);
        }

        public removeChannelInfo(): void {
            this.localStorageService.remove(this.buildKey(window.user.id));
        }

        public restoreSavedChannel(): void {
            const channelInfo = <ChannelRestoreInfo>this.localStorageService.get(this.buildKey(window.user.id));
            if (channelInfo) {
                if (channelInfo.channelType === ChannelType.Chat) {
                    this.chatService.registerOperator();
                }
            }
        }

        private buildKey(userId: System.Guid): string {
            return `${this.localStorageKey}${userId}`;
        }
    }
}
