﻿namespace Umbrella.Modules.Housing {
    @Pipe('Housing', 'isActiveContract')
    class IsActiveContractFilter {
        public transform(contract: SimpleContractModel) {
            if (!contract || !contract.status) return false;

            return (
                !contract.endDate &&
                new Date(contract.startDate.toString()) < new Date()
            );
        }
    }
}
