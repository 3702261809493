/// <reference path="../../../Window.d.ts"/>

namespace Umbrella.Modules.Announcements {
    @Component('Umbrella', {
        selector: 'announcement-panel',
        templateUrl:
            '/Modules/Announcements/AnnouncementPanelComponent/AnnouncementPanel.html'
    })
    @Inject('AnnouncementResource', 'LocalStorageService')
    class AnnouncementPanelComponent {
        public announcements: AnnouncementModel[] = [];
        public displayPanel = false;
        public lastReadOn: Date;

        private LAST_READ = 'AnnouncementPanelLastReadOn';
        private createdSubscription: Rx.IDisposable;
        private modifiedSubscription: Rx.IDisposable;
        private deletedSubscription: Rx.IDisposable;

        constructor(
            private announcementResource: AnnouncementResourceClass,
            private localStorageService: LocalStorageService
        ) {}

        public async $onInit(): Promise<void> {
            const storedDate = this.localStorageService.get(this.LAST_READ);
            if (storedDate) this.lastReadOn = new Date(storedDate);

            await this.loadAnnouncements();
            this.subscribeEvents();
        }

        public $onDestroy() {
            this.unsubscribeEvents();
        }

        public togglePanel(): void {
            this.displayPanel = !this.displayPanel;
            if (this.displayPanel)
                this.localStorageService.store(this.LAST_READ, Date.now());
            else this.lastReadOn = new Date();
        }

        public toggleWhenOpen(): void {
            if (this.displayPanel) this.togglePanel();
        }

        public hasReadBefore(announcement: AnnouncementModel): boolean {
            if (!this.lastReadOn) return false;

            return (
                new Date(announcement.created).getTime() <
                this.lastReadOn.getTime()
            );
        }

        public nrOfUnreadAnnouncements(): number {
            if (!this.announcements || !this.announcements.length) return 0;

            return !this.lastReadOn
                ? this.announcements.length
                : this.announcements.filter(x => !this.hasReadBefore(x)).length;
        }

        private async loadAnnouncements(): Promise<void> {
            const result = await this.announcementResource.getOverview({
                page: 0,
                pageSize: 10
            }).$promise;
            if (result && result.items) this.announcements = result.items;
        }

        private subscribeEvents(): void {
            const isGeneralAnnouncement = (x: AnnouncementModel) =>
                x &&
                (!x.personIds || !x.personIds.length) &&
                (!x.residentialZoneIds || !x.residentialZoneIds.length);
            const isInPublicationPeriod = (x: AnnouncementModel) =>
                x &&
                (!x.startDate || x.startDate < new Date()) &&
                (!x.endDate || x.endDate > new Date());

            this.createdSubscription = announcementHubOnCreated$
                .filter(isGeneralAnnouncement)
                .filter(isInPublicationPeriod)
                .subscribe(announcement => this.addAnnouncement(announcement));

            this.modifiedSubscription = announcementHubOnUpdated$.subscribe(
                announcement => this.loadAnnouncements()
            );
            this.deletedSubscription = announcementHubOnDeleted$.subscribe(
                announcement => this.loadAnnouncements()
            );
        }

        private unsubscribeEvents(): void {
            if (this.createdSubscription) {
                this.createdSubscription.dispose();
                this.createdSubscription = null;
            }

            if (this.modifiedSubscription) {
                this.modifiedSubscription.dispose();
                this.modifiedSubscription = null;
            }

            if (this.deletedSubscription) {
                this.deletedSubscription.dispose();
                this.deletedSubscription = null;
            }
        }

        private addAnnouncement(announcement: AnnouncementModel): void {
            this.announcements = [announcement, ...this.announcements];
        }
    }
}
