/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.UnitCard.Contracts {
    function mapState(s: UnitCardState): UnitContractsComponentState {
        return {
            unit: s && s.unit,
            contracts: s && s.contractInfo && s.contractInfo.contracts,
            loading: !s || !s.unit || !s.contractInfo || s.contractInfo.loading
        };
    }

    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('customerservice.unitcard.tab.contracts', {
                url: '/contracten',
                reloadOnSearch: false,
                data: {
                    tab: 'Object gegevens',
                    authorize: ps => ps.customerCard && ps.contractRead
                },
                views: {
                    'tab@customerservice': {
                        template:
                            '<unit-contracts state="state$ | async:this"></unit-contracts>',
                        controller: createComponentRouteController(
                            'UnitCardStore',
                            mapState
                        )
                    }
                }
            });
        }
    ]);
}
