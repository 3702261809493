﻿namespace Umbrella.Modules.Housing {
    export interface IComplexResource {
        getById(
            params: IdRequestParams
        ): ng.resource.IResource<Modules.Housing.ComplexModel>;
        search(
            params: { id: string; page: number; pageSize: number },
            succes?: (data: any, headers: any) => void,
            error?: (reason: any) => void
        ): angular.IPromise<PagedItemsModel<Modules.Housing.ComplexModel>>;
    }

    angular.module('Housing').service('ComplexResource', [
        '$resource',
        $resource => {
            return $resource(
                '/api/v1/complex/:action/:id',
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    search: {
                        nethod: 'GET',
                        isArray: false,
                        params: {
                            action: 'search',
                            id: '',
                            page: 0,
                            pageSize: 20
                        }
                    }
                }
            );
        }
    ]);
}
