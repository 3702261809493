/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../../Scope.d.ts"/>
/// <reference path="../../../../../Window.d.ts" />
/// <reference path="../../../../../CustomerService/ChannelInfo.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowStep = Umbrella.CaseFlow.CaseFlowModel.CaseFlowStep;
    import getChannelTypes = Umbrella.CustomerService.getChannelTypes;
    import ChannelInfo = Umbrella.CustomerService.ChannelInfo;
    import ImpersonationService = Umbrella.CustomerService.CustomerCard.ImpersonationService;

    export interface CaseFlowStepSelfServiceScenarioPopupComponent extends IPopupScope {
        caseId: System.Guid;
        caseFlowId: System.Guid;
        step: Umbrella.CaseFlow.CaseFlowModel.CaseFlowStep;
        person: PersonModel;
        personId: System.Guid;
        scenarioId: System.Guid;
        waiting: boolean;

        selectChannel(channelType: ChannelInfo): void;
        cancel(): void;
    }

    @Component('CaseFlow', {
        selector: 'caseflow-step-self-service-scenario-popup',
        templateUrl:
            '/CaseFlowProcessing/_Overview/_Details/_Steps/CaseFlowStepSelfServiceScenariosComponent/CaseFlowStepSelfServiceScenarioPopup.html',
        bindings: {
            step: '<',
            caseId: '<',
            caseFlowId: '<',
            person: '<',
            personId: '<',
            scenarioId:'<',
        }
    })
    @Inject('$mdDialog', 'ImpersonationService', '$state')
    export class CaseFlowStepSelfServiceScenarioPopupComponent {
        public caseId: System.Guid;
        public caseFlowId: System.Guid;
        public step: CaseFlowStep;
        public waiting: boolean;
        public person: PersonModel;
        public personId: System.Guid;
        public scenarioId: System.Guid;
        public channels: ChannelInfo[];
        public selectedChannel: ChannelInfo;

        constructor(
            private $mdDialog,
            private impersonationService: ImpersonationService,
            public $state: ng.ui.IStateService
        ) {}

        public $onInit() {
            this.loadChannels();
        }

        public selectChannel(channelInfo: ChannelInfo) {
            if (!channelInfo || !(this.person || this.personId) || !this.scenarioId || !this.caseId || !this.caseFlowId)
                return;

            this.impersonationService.start(
                this.scenarioId,
                this.person && this.person.id || this.personId,
                channelInfo.type,
                this.caseId,
                this.caseFlowId,
                this.$state.current.name.indexOf('dashboard.caseflow') >= 0
            );
            this.$mdDialog.hide();
        }

        private loadChannels(): void {
            this.channels = getChannelTypes();
        }

        public cancel(): void {
            this.$mdDialog.cancel();
        }
    }
}
