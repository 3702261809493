/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.TaskHandling {
    angular.module('TaskHandling').config([
        '$stateProvider',
        '$urlRouterProvider',
        ($stateProvider: ng.ui.IStateProvider, $urlRouterProvider: ng.ui.IUrlRouterProvider) => {
            $stateProvider
                .state('dashboard.oldtasks', {
                    url: '/dashboard/tasks?page&pageSize',
                    redirectTo: 'dashboard.tasks.overview'
                })
                .state('dashboard.tasks', {
                    url: '/taken',
                    redirectTo: 'dashboard.tasks.overview',
                    data: { pageTitle: 'Taken' },
                    views: {
                        overview: {
                            template: '<task-overview></task-overview>'
                        }
                    }
                });
        }
    ]);
}
