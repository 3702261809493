namespace Umbrella {
    @Pipe('Umbrella', 'isMobilePhone')
    class IsMobilePhoneFilter {
        private mobilePhonesStart = ['06', '+316', '00316', '316'];
        public transform(phoneNumber: string) {
            for (const startNum of this.mobilePhonesStart) {
                if (phoneNumber.startsWith(startNum)) {
                    return true;
                }
            }

            return false;
        }
    }
}
