/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard.Overview {
    angular.module('Dashboard').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('dashboard.caseflow.overview.details.documents', {
                url: '/documenten',
                reloadOnSearch: false,
                data: {
                    pageTitle: 'Zaakinformatie',
                    authorize: ps => [ps.viewCaseFlow, ps.viewDetailedCaseFlow]
                },
                views: {
                    tabcontent: {
                        template:
                            '<caseflow-documents state="state$ | async:this" class="reset-box-sizing"></caseflow-documents>',
                        controller: Umbrella.createComponentRouteController<
                            CaseFlowState,
                            CaseFlowDocumentsComponentState
                        >('CaseFlowOverviewStore', s => ({
                            case: s && s.caseInfo && s.caseInfo.caseflow,
                            caseHistory: s && s.caseInfo.caseHistory,
                            mediaIds: s && s.caseInfo.media && s.caseInfo.media.map(m => m.mediaId),
                            isUploadingDocument: s && s.caseInfo && s.caseInfo.isUploadingDocument
                        }))
                    }
                }
            });
        }
    ]);
}
