/// <reference path="../../../Decorators.ts" />

namespace Umbrella.CustomerService {
    @Component('CustomerService', {
        selector: 'card-loading-indicator',
        templateUrl:
            '/CustomerService/_CustomerCard/CardLoadingIndicatorComponent/CardLoadingIndicator.html'
    })
    class CustomerLoadingIndicatorComponent {}
}
