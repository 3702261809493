﻿namespace Umbrella.CustomerService.CustomerCard.Activities.Registration {
    import MediaResourceProvider = Modules.MediaResourceProvider;
    import MediaModel = Umbrella.Media.MediaModel;
    import SimpleFaqModel = Modules.Knowledgebase.SimpleFaqModel;
    import AdminMediaModel = Umbrella.Media.AdminMediaModel;

    @Component('Knowledgebase', {
        selector: 'faq-media',
        bindings: {
            faq: '<'
        },
        templateUrl:
            '/CustomerService/_CustomerCard/_Activities/Registration/FaqMediaComponent/FaqMedia.html'
    })
    @Inject('MediaResourceProvider')
    class FaqMediaComponent {
        public mediaLoading: boolean;
        public media: MediaModel[];

        constructor(protected mediaResource: MediaResourceProvider) {}

        private $onChanges(bindings: { faq: IBindingChange<SimpleFaqModel> }) {
            if (
                bindings.faq &&
                bindings.faq.currentValue !== bindings.faq.previousValue
            ) {
                this.loadMediaItems(bindings.faq.currentValue);
            }
        }

        private loadMediaItems(faq: SimpleFaqModel) {
            if (!faq || !faq.mediaWithVisibility || !faq.mediaWithVisibility.length) return;

            this.mediaLoading = true;

            const ids = faq.mediaWithVisibility.map(x => x.mediaId);

            this.mediaResource
                .getByIdsV2({ ids })
                .$promise.then(mediaObjects => {
                    this.media = mediaObjects as MediaModel[];
                })
                .finally(() => {
                    this.mediaLoading = false;
                });
        };
    }
}
