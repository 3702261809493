﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.TaskHandling.Overview {
    import TaskTypeCategory = Umbrella.Modules.Tasks.TaskTypeCategory;

    @Pipe('TaskHandling', 'isErrorType')
    class IsErrorTypeFilter {
        public transform(taskTypeCategory: TaskTypeCategory | string): boolean {
            if (!taskTypeCategory) return false;

            switch (taskTypeCategory.toString().toLowerCase()) {
                case 'error':
                case '3':
                    return true;
                default:
                    return false;
            }
        }
    }
}
