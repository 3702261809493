/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="ChatService.ts"/>

namespace Umbrella.ChatConversationHandling {
    angular.module('Chat').config([
        '$stateProvider',
        ($stateProvider: ng.ui.IStateProvider) => {
            $stateProvider.state('dashboard.chat', {
                url: '/chat',
                redirectTo: 'dashboard.chat.overview',
                data: {
                    pageTitle: 'Chat',
                    authorize: ps => ps.viewChatConversation
                },
                views: {
                    overview: {
                        templateUrl: '/ChatConversationHandling/_Overview/ChatOverview.html'
                    }
                }
            });
        }
    ]);
}
