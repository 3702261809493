﻿/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../../Window.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard.CaseFlowOverview {
    import TaskModel = Umbrella.Modules.Tasks.TaskModel;
    import TaskStatus = Umbrella.Modules.Tasks.TaakStatus;
    import CaseFlowModel = Umbrella.CaseFlow.CaseFlowModel;
    import SummarizedColleagueModel = Umbrella.Modules.Colleagues.SummarizedColleagueModel;
    import CaseFlowOverviewService = Umbrella.KCC.CaseFlowDashboard.CaseFlowOverviewService;
    import CaseFlowStepTaskOutcome = Umbrella.CaseFlow.CaseFlowStepTask.CaseFlowStepTaskOutcome;
    import IColleagueResourceClass = Umbrella.Modules.Colleagues.IColleagueResourceClass;
    import ColleagueModel = Umbrella.Modules.Colleagues.ColleagueModel;

    export interface CaseStepTask {
        id?: System.Guid;
        isOptional: boolean;
        subject: string;
        completeBy?: SummarizedColleagueModel.WithFunctionGroup;
        status?: TaskStatus | string;
        pickedUp?: Date;
        finished?: Date;
        umbrellaTaskId?: System.Guid;
        outcome?: CaseFlowStepTaskOutcome;
        outcomes?: CaseFlowStepTaskOutcome[];
        completedById?: System.Guid;
    }

    @Component('Dashboard', {
        selector: 'case-flow-step-tasks',
        templateUrl: '/CaseFlowProcessing/_Overview/_Details/_Steps/CaseFlowStepTasksComponent/CaseFlowStepTasks.html',
        bindings: {
            caseFlowId: '=',
            step: '<',
            renderCheckbox: '<'
        }
    })
    @Inject('$mdDialog', 'TaskResource', 'CaseFlowOverviewService', 'ToastMessageService', 'ColleagueResource')
    class CaseFlowStepTasksComponent {
        public tasks: CaseStepTask[];
        public caseFlowId: System.Guid;
        private step: CaseFlowModel.CaseFlowStep;
        private colleages: ColleagueModel[] = [];

        constructor(
            private $mdDialog,
            private taskResource: Umbrella.Modules.Tasks.ITaskResourceClass,
            private caseFlowService: CaseFlowOverviewService,
            private toastSvc: ToastMessageService,
            private colleagueResource: IColleagueResourceClass
        ) {}

        public $onChanges = (bindings: { step: IBindingChange<CaseFlowModel.CaseFlowStep> }) => {
            if (bindings.step && bindings.step.currentValue) this.loadStepTasks();
        };

        public async loadStepTasks(): Promise<void> {
            this.tasks = [];

            const stepTasks = this.step && this.step.tasks;
            if (!stepTasks || !stepTasks.length) return;

            const taskIds = stepTasks.map(t => t.umbrellaTaskId).filter(id => id !== null);
            const detailedTasks = taskIds.length ? await this.taskResource.getByIds({ ids: taskIds }).$promise : [];

            this.loadColleagues(detailedTasks);

            const list: CaseStepTask[] = [];
            for (const stepTask of this.step.tasks) {
                const detailedTask = this.filterTask(detailedTasks, stepTask.umbrellaTaskId);
                if (detailedTask) {
                    list.push({
                        id: stepTask.id,
                        completeBy: detailedTask.completeBy,
                        finished: detailedTask.finished,
                        isOptional: stepTask.isOptional,
                        pickedUp: detailedTask.pickedUp,
                        status: detailedTask.status,
                        subject: detailedTask.subject,
                        umbrellaTaskId: stepTask.umbrellaTaskId,
                        outcome: stepTask.outcome,
                        outcomes: stepTask.outcomes,
                        completedById: detailedTask.completedById
                    });
                } else {
                    list.push({
                        id: stepTask.id,
                        isOptional: stepTask.isOptional,
                        subject: stepTask.title
                    });
                }
            }

            this.tasks = list;
        }

        private loadColleagues(data: Umbrella.Modules.Tasks.TaskModel[]) {
            if (!data) return;

            const ids = data.map(x => x.completedById).filter(x => x !== null);

            if (ids.length > 0) {
                this.colleagueResource
                    .getByIds({
                        ids
                    })
                    .$promise.then(peoples => {
                        peoples.forEach(p => (this.colleages[p.id.toString()] = p));
                    });
            }
        }

        public finishTask(task: CaseStepTask): void {
            this.$mdDialog
                .show({
                    template:
                        '<finish-caseflow-step-task-popup md-theme="umb-purple" outcomes="outcomes"></finish-caseflow-step-task-popup>',
                    locals: {
                        outcomes: task.outcomes
                    },
                    controller: [
                        '$scope',
                        'outcomes',
                        ($scope, outcomes) => {
                            $scope.outcomes = outcomes;
                        }
                    ],
                    clickOutsideToClose: false
                })
                .then((result: System.Guid) => {
                    this.completeTask(task, result);
                });
        }

        private completeTask(task: CaseStepTask, outcomeId?: System.Guid) {
            this.caseFlowService
                .finishTask(task.id, outcomeId)
                .then(() => {
                    task.status = Umbrella.Modules.Tasks.TaakStatus.Afgerond;
                })
                .catch(_ => this.toastSvc.error('Fout opgetreden tijdens afronden van de taak'));
        }

        private filterTask(tasks: TaskModel[], umbrellaTaskId: System.Guid): TaskModel {
            const selection = tasks.filter(task => task.id === umbrellaTaskId);
            return selection.length > 0 ? selection[0] : null;
        }

        isNewTask(task: CaseStepTask): boolean {
            return (
                task.status === 'Nieuw' || task.status === Umbrella.Modules.Tasks.TaakStatus.Nieuw || task.status === 0
            );
        }

        isPickedUpTask(task: CaseStepTask): boolean {
            return (
                task.status === 'Opgepakt' ||
                task.status === Umbrella.Modules.Tasks.TaakStatus.Opgepakt ||
                task.status === 1
            );
        }

        isFinishedTask(task: CaseStepTask): boolean {
            return (
                task.status === 'Afgerond' ||
                task.status === Umbrella.Modules.Tasks.TaakStatus.Afgerond ||
                task.status === 2
            );
        }
    }
}
