namespace Umbrella.Modules.Chat {
    export class DraggableDirective implements angular.IDirective {
        restrict = 'C';
        link = function(scope, element) {
            let previousPosition = localStorage.getItem('cmp');

            if (previousPosition) {
                //prevent flickering of the modal in different positions
                element.hide();
                setTimeout(function() {
                    element.css(JSON.parse(previousPosition));
                    element.show();
                }, 0);
            }

            element.draggable({
                stop(event, ui) {
                    let position = element.position();
                    localStorage.setItem('cmp', JSON.stringify(position));
                }
            });
        };
        static factory(): angular.IDirectiveFactory {
            return () => new DraggableDirective();
        }
    }

    angular
        .module('Chat')
        .directive('draggableModal', DraggableDirective.factory());
}
