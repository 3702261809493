namespace Umbrella {
    export interface RouteScope<T> {
        state$: Rx.Observable<T>;
    }

    export function createComponentRouteController<
        TStoreState,
        TComponentState
    >(storeName, mapState: (store: TStoreState) => TComponentState) {
        return [
            '$scope',
            storeName,
            (
                $scope: RouteScope<TComponentState>,
                store: ObservableStore.Store<TStoreState>
            ) => {
                $scope.state$ = store.state$
                    .map(mapState)
                    .distinctUntilChanged();
            }
        ];
    }
}
