﻿namespace Umbrella.CustomerService {
    @Pipe('CustomerService', 'taskActivitySubTitle')
    class TaskActivitySubTitleFilter {
        public transform(activity): string {
            if (!activity) return '';

            const activityName = activity.type
                .replace('Activity', '')
                .toLowerCase();
            switch (activityName) {
                case 'taakaangemaakt':
                case 'taskcreated':
                    return activity.description && activity.description.length
                        ? activity.description
                        : activity.task.description;
                case 'taakdiscussie':
                case 'taskdiscussion':
                    return activity.message;
                case 'taakvoltooid':
                case 'taskfinished':
                    return activity.task && activity.task.outcome
                        ? `Uitkomst: ${activity.task.outcome.title}`
                        : (activity.completeReason || '');
                case 'taakovergenomen':
                case 'tasktakenover':
                    return activity.reason;
                case 'taakgeweigerd':
                case 'taskrejected':
                    return activity.rejectReason || '';
                case 'taakverwijderd':
                case 'taskdeleted':
                    return activity.deleteReason || '';
                default:
                    return '';
            }
        }
    }
}
