/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../ComponentRouteUtils.ts"/>
/// <reference path="../../../CaseFlowOverviewStore.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard.Overview {
    angular.module('Dashboard').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('dashboard.caseflow.overview.details.publicmessages', {
                url: '/publicmessages',
                reloadOnSearch: false,
                data: {
                    pageTitle: 'Zaak publieke berichten',
                    authorize: ps => [ps.viewCaseFlow, ps.viewDetailedCaseFlow]
                },
                views: {
                    tabcontent: {
                        template: '<caseflow-public-messages state="state$ | async:this"></caseflow-public-messages>',
                        controller: Umbrella.createComponentRouteController<
                            CaseFlowState,
                            CaseFlowPublicMessagesComponentState
                        >('CaseFlowOverviewStore', s => ({
                            publicMessages: s && s.caseInfo && s.caseInfo.publicMessages
                        }))
                    }
                }
            });
        }
    ]);
}
