/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.UnitCard {
    import UnitModel = Modules.Housing.UnitModel;
    import ContractModel = Modules.Housing.ContractModel;

    export interface UnitCardSidebarComponentState {
        unit: UnitModel;
        contracts: PagedItemsModel<ContractModel>;
    }

    @Component('CustomerService', {
        selector: 'unitcard-sidebar',
        templateUrl: '/CustomerService/_UnitCard/Sidebar/UnitCardSideBarComponent/UnitCardSideBar.html',
        bindings: {
            state: '<'
        }
    })
    class UnitCardSideBarComponent {
        public state: UnitCardSidebarComponentState;
        public indicatorModel: { unit: UnitModel };

        public $onChanges(bindings: { state: IBindingChange<UnitCardSidebarComponentState> }) {
            const currentState = bindings && bindings.state && bindings.state.currentValue;

            if (!currentState || !currentState.contracts || !currentState.unit) return;

            this.indicatorModel = {
                unit:
                    currentState &&
                    currentState.contracts.items.some(contract => this.isActiveContract(contract)) &&
                    currentState.unit
            };
        }

        public isAuthorizedForContactPersons(): boolean {
            return window.user.permissions.contactPersonRead;
        }

        private isActiveContract(contract: ContractModel) {
            return contract.status.toString() === 'Active' || contract.status === 1;
        }
    }
}
