/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../Scope.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import CompletionState = Umbrella.Modules.Tasks.CompletionState;
    import CancelationStatusModel = Umbrella.CaseFlow.CancelationStatusModel;
    export interface ICaseFlowResource
        extends Umbrella.CaseFlow.CaseFlowModel,
            angular.resource.IResource<Umbrella.CaseFlow.CaseFlowModel> {}
    export interface IDetailedCaseFlowResource
        extends Umbrella.CaseFlow.DetailedCaseFlowModel,
            angular.resource.IResource<Umbrella.CaseFlow.DetailedCaseFlowModel> {}

    export interface CaseFlowSearchPagedRequestParams {
        page?: number;
        pageSize?: number;
        query?: string;
        type?: string;
        completion?: string | CompletionState;
        statusses?: string[];
        deadlineBefore?: Date;
        ownerRoleId?: System.Guid;
        executorRoleId?: System.Guid;
    }

    export interface CaseFlowStepsSearchPagedRequestParams {
        query?: string;
        completion?: string | CompletionState;
        page?: number;
        pageSize?: number;
        status?: string;
        deadlineBefore?: Date;
    }

    export interface CaseFlowResourceClass
        extends Umbrella.CaseFlow.CaseFlowModel, angular.resource.IResource<Umbrella.CaseFlow.CaseFlowModel> {
        getByTaskId(params: IdRequestParams): IDetailedCaseFlowResource;
        getByUmbrellaCaseId(params: IdRequestParams): ICaseFlowResource;
        getById(params: IdRequestParams): ICaseFlowResource;
        getByIdDetailed(params: IdRequestParams): IDetailedCaseFlowResource;
        update(model: Umbrella.CaseFlow.EditCaseFlowModel): ICaseFlowResource;
        finishStep(params: IdRequestParams): ng.resource.IResource<boolean>;
        pickUpStep(model: Umbrella.CaseFlow.PickupCaseFlowStepModel): ng.resource.IResource<boolean>;
        cancel(
            params: IdRequestParams,
            model: Umbrella.CaseFlow.CancelCaseFlowStepModel
        ): ng.resource.IResource<boolean>;
        search(
            params: CaseFlowSearchPagedRequestParams
        ): ng.resource.IResource<SearchResultsModel<Umbrella.CaseFlow.CaseFlowOverviewModel>>;
        searchGroupedByOwner(
            params: CaseFlowSearchPagedRequestParams
        ): ng.resource.IResource<SearchResultsModel<Umbrella.CaseFlow.CaseFlowOverviewModel>>;
        getTaskById(params: IdRequestParams): angular.resource.IResource<Umbrella.CaseFlow.CaseFlowStepTaskModel>;
        getByUmbrellaTaskId(
            params: IdRequestParams
        ): angular.resource.IResource<Umbrella.CaseFlow.CaseFlowStepTaskModel>;
        finishTask(
            params: IdRequestParams,
            data: Umbrella.CaseFlow.FinishCaseFlowStepTaskModel
        ): angular.resource.IResource<void>;
        addMessage(params: IdRequestParams, message: Umbrella.CaseFlow.CaseFlowAddMessageModel): ng.resource.IResource<Umbrella.CaseFlow.CaseFlowMessageModel>;
        getMessages(params: IdRequestParams): ng.resource.IResource<Umbrella.CaseFlow.CaseFlowMessageModel[]>;
        addPublicMessage(params: IdRequestParams, message: Umbrella.CaseFlow.CaseFlowAddMessageModel): ng.resource.IResource<Umbrella.CaseFlow.CaseFlowMessageModel>;
        getPublicMessages(params: IdRequestParams): ng.resource.IResource<Umbrella.CaseFlow.CaseFlowMessageModel[]>;
        getCancelationStatuses(): angular.resource.IResource<CancelationStatusModel[]>;
    }

    angular.module('CaseFlow').service('CaseFlowResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/caseflow/:action/:subaction/:id',
                {},
                {
                    getById: { method: 'GET', isArray: false },
                    getByIdDetailed: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'detailed', id: '@id' }
                    },
                    getByTaskId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'bytask', id: '@id' }
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getByUmbrellaCaseId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'byumbrellacaseid', id: '@id' }
                    },
                    finishStep: {
                        method: 'PUT',
                        isArray: false,
                        params: { action: 'finishstep', id: '@id' }
                    },
                    pickUpStep: {
                        method: 'PUT',
                        isArray: false,
                        params: { action: 'pickupstep', id: '@id' }
                    },
                    cancel: {
                        method: 'PUT',
                        isArray: false,
                        params: { action: 'cancel', id: '@id' }
                    },
                    search: {
                        method: 'GET',
                        isArray: false,
                        params: { action: '', id: '' }
                    },
                    searchGroupedByOwner: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'searchgroupedbyowner', id: '' }
                    },
                    searchSteps: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'steps', id: '' }
                    },
                    getTaskById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id', action: 'gettask' }
                    },
                    getByUmbrellaTaskId: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id', action: 'byumbrellataskid' }
                    },
                    finishTask: {
                        method: 'PUT',
                        isArray: false,
                        params: { action: 'finishtask', id: '@id' }
                    },
                    addMessage: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'message', id: '@id' },
                        transformRequest: (data: Umbrella.CaseFlow.CaseFlowAddMessageModel, headersGetter) => {
                            return JSON.stringify(data);
                        }
                    },
                    getMessages: {
                        method: 'GET',
                        isArray: true,
                        params: { id: '@id', action: 'messages' }
                    },
                    addPublicMessage: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'message', subaction: 'public', id: '@id' },
                        transformRequest: (data: Umbrella.CaseFlow.CaseFlowAddMessageModel, headersGetter) => {
                            return JSON.stringify(data);
                        }
                    },
                    getPublicMessages: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'messages', subaction: 'public', id: '@id' }
                    },
                    getCancelationStatuses: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'cancelationstatuses' }
                    }
                }
            )
    ]);
}
