/// <reference path="../Scripts/TypeScript/angularjs/angular-resource.d.ts" />
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService {
    import IResource = ng.resource.IResource;
    import OutgoingSmsModel = Umbrella.Sms.OutgoingSmsModel;

    export interface SendSmsParams {
        phone: string;
        content: string;
    }

    export interface OutgoingSmsResource {
        send(model: SendSmsParams): IResource<OutgoingSmsModel>;
    }

    angular.module('Umbrella').service('OutgoingSmsResource', [
        '$resource',
        ($resource: ng.resource.IResourceService) =>
            $resource(
                '/api/v1/sms/outgoing/:action/:id',
                {},
                {
                    send: {
                        method: 'POST',
                        isArray: false,
                        transformRequest: (data: string, headersGetter) => {
                            return JSON.stringify(data);
                        }
                    }
                }
            )
    ]);
}
