﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.TaskHandling {
    angular.module('TaskHandling').config([
        '$stateProvider',
        '$urlRouterProvider',
        ($stateProvider: ng.ui.IStateProvider, $urlRouterProvider: ng.ui.IUrlRouterProvider) => {
            $urlRouterProvider.when(
                '/dashboard/taken/overzicht/:id?page&query&status&deadline&taskTypes&createdById&assignedToId',
                '/dashboard/taken/overzicht/:id/informatie?page&query&status&deadline&taskTypes&createdById&assignedToId'
            );

            $stateProvider
                .state('dashboard.oldtasks.details', {
                    url: '/{id:(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})}',
                    redirectTo: 'dashboard.tasks.overview.details.information'
                })
                .state('dashboard.tasks.overview.details', {
                    url: '/{id:(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})}',
                    data: {
                        pageTitle: 'Taak details',
                        authorize: p => p.viewTask
                    },
                    views: {
                        'details@dashboard.tasks': {
                            template: '<task state="state$ | async:this"></task>',
                            controller: Umbrella.createComponentRouteController<
                                Overview.State,
                                TaskDetailsComponentState
                            >('TaskOverviewStore', s => ({
                                task: s && s.selectedTask,
                                loading: s && s.selectedTaskLoading,
                                activities: s && s.activities,
                                linkedTasks: s && s.linkedTasks
                            }))
                        }
                    },
                    params: {
                        showTaskDetailView: null
                    },
                    onEnter: [
                        '$stateParams',
                        'TaskOverviewService',
                        ($stateParams: any, taskOverviewService: TaskOverviewService) => {
                            if (!$stateParams.showTaskDetailView && window.device.mobile()) {
                                taskOverviewService.setDisplayTaskDetailView(false);
                            } else {
                                taskOverviewService.setDisplayTaskDetailView(true);
                            }
                            taskOverviewService.select($stateParams.id);
                        }
                    ],
                    onExit: [
                        '$stateParams',
                        'TaskOverviewService',
                        ($stateParams: any, taskOverviewService: TaskOverviewService) => {
                            if ($stateParams.showTaskDetailView && window.device.mobile()) {
                                taskOverviewService.setDisplayTaskDetailView(false);
                            }
                        }
                    ]
                });
        }
    ]);
}
