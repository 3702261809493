namespace Umbrella.Modules {
    import debounce = Umbrella.Helpers.debounce;

    angular.module('Umbrella').directive('conversationBarTabControl', [
        () => {
            function recalculateHiddenTabs(
                scope: ng.IScope,
                container: ng.IAugmentedJQuery
            ) {
                if (!container || container.length < 1) return;

                setContainerWidth(container);

                const containerEl = container[0];
                const isOverflowing =
                    containerEl.scrollHeight > containerEl.clientHeight ||
                    containerEl.scrollWidth > containerEl.clientWidth;
                if (isOverflowing) {
                    scope.decrease();
                    return;
                }

                const children = container.children();
                let occupiedWidth = 0;

                angular.forEach(children, (tab: Element, key) => {
                    occupiedWidth += tab.clientWidth;
                });

                const containerWidth = Math.ceil(container.width());
                const hasSpaceForMore = containerWidth - 200 > occupiedWidth;

                if (hasSpaceForMore) {
                    scope.increase();
                }
            }

            function evaluateChanges(
                element: ng.IAugmentedJQuery,
                tabsCount: number
            ) {
                const windowWidth = angular.element(window).width();

                return (
                    element[0].childNodes.length +
                    element.width() +
                    element[0].scrollWidth +
                    windowWidth +
                    tabsCount
                );
            }

            function setContainerWidth(container: ng.IAugmentedJQuery) {
                if (!container || container.length < 1) return;

                container.children().hide();
                container.css('width', 'unset');

                const width = container.width();

                container.css('width', width + 'px');
                container.children().show();
            }

            return {
                priority: 1,
                scope: {
                    increase: '&',
                    decrease: '&',
                    tabsCount: '<'
                },
                restrict: 'A',
                link(scope: ng.IScope, element: ng.IAugmentedJQuery, attr) {
                    // setTimeout(, 0);
                    element.ready(() => recalculateHiddenTabs(scope, element));
                    scope.$watch(
                        (scope: ng.IScope) => {
                            return evaluateChanges(element, scope.tabsCount);
                        },
                        (newValue, oldValue, scope: ng.IScope) => {
                            if (newValue !== oldValue)
                                debounce(
                                    () => recalculateHiddenTabs(scope, element),
                                    50,
                                    true
                                )();
                        }
                    );
                }
            };
        }
    ]);
}
