﻿/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../TaskOverviewService.ts"/>

namespace Umbrella.TaskHandling {
    angular.module('TaskHandling').config([
        '$stateProvider',
        $stateProvider => {
            $stateProvider.state(
                'dashboard.tasks.overview.details.information',
                {
                    url: '/informatie',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Taak informatie',
                        authorize: p => p.viewTask || p.editTask
                    },
                    views: {
                        tabcontent: {
                            template:
                                '<task-information class="h-full block" state="state$ | async:this"></task-information>',
                            controller: Umbrella.createComponentRouteController<
                                Overview.State,
                                TaskInformationComponentState
                            >('TaskOverviewStore', s => ({
                                task: s && s.selectedTask
                            }))
                        }
                    }
                }
            );
        }
    ]);
}
