namespace Umbrella.Modules.Announcements {
    @Pipe('Umbrella', 'safeHrefs')
    class SafeHrefsFilter {
        public transform(html: string) {
            return html
                ? html.replace(
                      '<a href',
                      '<a rel="nofollow" target="_blank" href'
                  )
                : '';
        }
    }
}
