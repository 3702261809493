/// <reference path="..\..\..\..\Decorators.ts" />
/// <reference path="..\..\..\..\Scripts/TypeScript/umbrella/umbrella.d.ts" />

namespace Umbrella.CustomerService.CustomerCard.Sidebar {
    import ContractModel = Modules.Housing.ContractModel;
    import WorkAreaModel = Modules.SelfService.Models.WorkAreaModel.ContactPerson;
    import IWorkAreaResourceClass = Umbrella.CustomerService.IWorkAreaResourceClass;

    @Component('CustomerService', {
        selector: 'card-sidebar-contact-persons',
        bindings: {
            collapsed: '<',
            contracts: '<',
            onToggle: '&'
        },
        templateUrl:
            '/CustomerService/_CustomerCard/Sidebar/CardSidebarContactPersonsComponent/CardSidebarContactPersons.html'
    })
    @Inject('WorkAreaResource', 'PhoneService')
    class CardSidebarContactPersonsComponent {
        public collapsed: boolean;
        public contracts: PagedItemsModel<ContractModel>;
        public workareas: { [index: string]: WorkAreaModel[] };
        public workAreaGroups: {
            [areaId: string]: { [groupName: string]: WorkAreaModel[] };
        };

        public onToggle: () => void;

        constructor(public workAreaResource: IWorkAreaResourceClass, public phoneService) {}

        public $onInit() {
            if (this.contracts && this.contracts.total) this.loadWorkAreasForActiveContracts(this.contracts);
        }

        public callNr(nr: string): void {
            this.phoneService.callNr(nr);
        }

        public getResidentialZoneName(id: string): string {
            const contract = this.contracts.items.filter(x => x.unit.id === id)[0];
            return contract ? contract.unit.addressLine : '';
        }

        public getResourceCount(): number {
            let total = 0;
            for (const groupKey in this.workAreaGroups) {
                const group = this.workAreaGroups[groupKey];
                if (group) {
                    for (const workAreaKey in group) {
                        total += group[workAreaKey].length;
                    }
                }
            }

            return total;
        }

        private loadWorkAreasForActiveContracts(contracts: PagedItemsModel<ContractModel>) {
            const activeContracts = contracts.items.filter(x => this.isActiveContract(x));
            if (!activeContracts.length) return;

            const unitIds = activeContracts.map(x => x.unit.id);
            const onlyUnique = (value, index, self) => self.indexOf(value) === index;
            const uniqueUnitIds = unitIds.filter(onlyUnique).slice(0, 5);
            if (!uniqueUnitIds.length) return;

            this.workAreaResource.getAllForResidentialZones({ ids: uniqueUnitIds }).$promise.then(workareas => {
                this.workareas = workareas;
                const workAreaGroups: {
                    [areaId: string]: {
                        [groupName: string]: WorkAreaModel[];
                    };
                } = {};

                uniqueUnitIds.forEach(areaId => {
                    const areaGroups: {
                        [groupName: string]: WorkAreaModel[];
                    } = {};

                    const workAreas = workareas && workareas[areaId.toString()];
                    workAreas.forEach(item => {
                        const groupName = item.expertise.description || item.expertise.name;
                        areaGroups[groupName] = areaGroups[groupName] || [];
                        areaGroups[groupName].push(item);
                    });
                    workAreaGroups[areaId.toString()] = areaGroups;
                });

                this.workAreaGroups = workAreaGroups;
            });
        }

        private isActiveContract(contract: ContractModel) {
            return contract.status.toString() === 'Active' || contract.status === 1;
        }
    }
}
