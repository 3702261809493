/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../../Window.d.ts"/>
/// <reference path="../../../../../CustomerService/ChannelInfo.ts"/>

namespace Umbrella.CustomerService.CustomerCard.Activities.Registration {
    import SelfServiceScenarioModel = Modules.SelfService.SelfServiceScenarioModel;
    import ChannelType = Modules.ChannelType;
    import getChannelTypes = Umbrella.CustomerService.getChannelTypes;
    import ChannelInfo = Umbrella.CustomerService.ChannelInfo;

    @Component('Knowledgebase', {
        selector: 'card-knowledgebase-selfservice',
        templateUrl:
            '/CustomerService/_CustomerCard/_Activities/Registration/CardKnowledgebaseSelfserviceComponent/CardKnowledgebaseSelfservice.html',
        bindings: {
            person: '<',
            selectedChannel: '<',
            selfServiceScenarios: '<',
            onClose: '&'
        }
    })
    @Inject(
        'ActivityRegistrationService',
        'ImpersonationService',
        'ToastMessageService'
    )
    class CardKnowledgebaseComponent {
        public selfServiceScenarios: SelfServiceScenarioModel.Detailed[];
        public person: PersonModel;
        public selectedChannel: ChannelType;
        public channels: ChannelInfo[];
        public onClose: () => void;

        constructor(
            private activityRegistrationService: ActivityRegistrationService,
            private impersonationService: ImpersonationService,
            private toastMessageService: ToastMessageService
        ) {}

        public $onInit() {
            this.loadChannels();
        }

        public resetAndClose() {
            this.activityRegistrationService.clearChannel();
            this.onClose();
        }

        public isChannelSelected = () =>
            this.selectedChannel !== ChannelType.Unknown;

        public isSssSelectionPanelVisible = () =>
            this.isChannelSelected() && this.selfServiceScenarios;

        public startSelfserviceScenario(
            scenario: SelfServiceScenarioModel.Detailed
        ) {
            const isCustomerOrProspect =
                this.person.roles['customer'] || this.person.roles['prospect'];
            if (isCustomerOrProspect) {
                const channelInfo = this.getSelectedChannel();
                const channelType = (channelInfo && channelInfo.type) || null;
                this.impersonationService.start(
                    scenario.id,
                    this.person.id,
                    channelType,
                    null,
                    null,
                    null
                );
            }
        }

        public getChannels(): ChannelInfo[] {
            return getChannelTypes();
        }

        public clearChannel() {
            this.activityRegistrationService.clearChannel();
        }

        public selectChannel(channel: ChannelInfo) {
            this.activityRegistrationService.selectChannel(channel.type);
            this.activityRegistrationService.loadRootTags();
        }

        public getSelectedChannel(): ChannelInfo {
            return this.channels
                .filter(
                    x =>
                        ChannelType[x.type] ===
                        ChannelType[this.selectedChannel]
                )
                .shift();
        }

        private loadChannels(): void {
            this.channels = getChannelTypes();
        }
    }
}
