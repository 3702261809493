/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowStatus = Umbrella.CaseFlow.CaseFlowStatus;

    @Pipe('Dashboard', 'caseFlowStatus')
    class CaseFlowStatusFilter {
        public transform(status: CaseFlowStatus) {
            if (!status) return '';

            switch (status.toString().toLowerCase()) {
                case 'pickedup':
                case '0':
                    return 'In behandeling';
                case 'finished':
                case '1':
                    return 'Afgerond';
                case 'cancelled':
                case 'canceled':
                case '2':
                    return 'Geannuleerd';
                case 'submitted':
                case '4':
                    return 'Ontvangen';
                default:
                    return '';
            }
        }
    }
}
