namespace Umbrella {
    export declare class SynchronizedObjectResource {
        byEntityAndId(params: any): ng.resource.IResource<SynchronizedObjectModel[]>;
    }

    angular.module('Umbrella').service('SynchronizedObjectResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/synchronizedobjects/entity/:entitytype/:entityid',
                {},
                {
                    byEntityAndId: {
                        method: 'GET',
                        isArray: true,
                        params: { entitytype: '@entitytype', entityid: '@entityid' }
                    }
                }
            )
    ]);
}
