/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService {
    import IResource = ng.resource.IResource;

    export interface PagedRequestWithIdAndCategoryParams extends PagedRequestWithIdParams {
        category?: string;
        sortDesc: boolean;
    }

    export interface CustomerSatifactionScoreGetModel {
        id: Guid;
        personId: Guid;
        createdOn: Date;
        externalReportUrl: string;
        category: string;
        score: number;
    }

    export interface CustomerSatisfactionCategoryGetModel {
        category: string;
        count: number;
    }

    export interface ICustomerSatisfactionResource {
        getById(params: IdRequestParams): IResource<CustomerSatifactionScoreGetModel>;
        getByPersonId(
            params: PagedRequestWithIdAndCategoryParams
        ): IResource<PagedItemsModel<CustomerSatifactionScoreGetModel>>;
        getCategoriesForPersonId(params: IdRequestParams): IResource<CustomerSatisfactionCategoryGetModel[]>;
    }

    angular.module('CustomerService').service('CustomerSatisfactionResource', [
        '$resource',
        $resource =>
            $resource(
                '/api/v1/customersatisfaction/scores/:action/:id',
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getByPersonId: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id', action: 'byPerson' }
                    },
                    getCategoriesForPersonId: {
                        url: '/api/v1/customersatisfaction/scores/categories/byPerson/:id',
                        method: 'GET',
                        isArray: true,
                        params: { id: '@id' }
                    }
                }
            )
    ]);
}
