﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    angular.module('Dashboard').config([
        '$stateProvider',
        $stateProvider => {
            $stateProvider.state('dashboard.caseflow.overview.edit', {
                url:
                    '/edit/{id:(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})}',
                reloadOnSearch: false,
                data: {
                    pageTitle: 'Zaak bewerken'
                },
                views: {
                    'details@dashboard.caseflow': {
                        template: '<edit-caseflow state="state$ | async:this"></edit-caseflow>',
                        controller: Umbrella.createComponentRouteController<CaseFlowState, EditCaseFlowComponentState>(
                            'CaseFlowOverviewStore',
                            s => ({
                                case: s && s.caseInfo.caseflow
                            })
                        )
                    }
                },
                resolve: {
                    zaak: [
                        '$stateParams',
                        'CaseFlowOverviewService',
                        (
                            $stateParams: CaseFlowOverviewStateParams,
                            caseFlowOverviewService: CaseFlowOverviewService
                        ) => {
                            return caseFlowOverviewService.loadByCaseFlowId($stateParams.id);
                        }
                    ]
                }
            });
        }
    ]);
}
