namespace Umbrella.Modules {
    import IScope = angular.IScope;

    export class IfUmbrellaAuthDirective implements angular.IDirective {
        priorit = -1;
        restrict = 'A';
        link = (scope: IScope, element, attr) => {
            let perm = attr.ifUmbrellaAuth;
            let user = window.user;

            if (!perm) return;

            if (perm.indexOf('!') === -1) {
                if (!user || !user.permissions[perm]) {
                    element.hide();
                }
            } else {
                if (user.permissions[perm.replace('!', '')]) {
                    element.hide();
                }
            }
        };

        static factory(): angular.IDirectiveFactory {
            return () => new IfUmbrellaAuthDirective();
        }
    }

    angular
        .module('Umbrella')
        .directive('ifUmbrellaAuth', IfUmbrellaAuthDirective.factory());
}
