namespace Umbrella.Search.Units {
    import UnitModel = Umbrella.Modules.Housing.UnitModel;

    export interface UnitSearchParams extends PagedRequestParams {
        id: string;
    }
    export interface IUnitSearchResource
        extends angular.resource.IResource<SearchItemsModel<UnitModel>>,
            SearchItemsModel<UnitModel> {}
    export interface IUnitSearchClass
        extends angular.resource.IResourceClass<IUnitSearchResource> {
        units(
            params: UnitSearchParams,
            succes?: Function,
            error?: Function
        ): angular.IPromise<SearchItemsModel<UnitModel>>;
    }

    angular.module('Search').factory('UnitSearchResource', [
        '$resource',
        ($resource: ng.resource.IResourceService) =>
            $resource(
                '/api/v1/unit/search/:id',
                {},
                {
                    units: {
                        method: 'GET',
                        params: { id: '', page: 0, pageSize: 10 },
                        isArray: false
                    }
                }
            )
    ]);
}
