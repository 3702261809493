namespace Umbrella.Modules.Chat {

    @Pipe('Chat', 'detachUrl')
    class DetachUrlFilter {
        public transform(message: string) {
            if (!message) return '';
            if (!message.includes('<a')) { return message; }

            const domparser = new DOMParser();
            const htmlMessage = domparser.parseFromString(message, 'text/html');

            const links = htmlMessage.getElementsByTagName('a');

            for (let i = links.length; i > 0; i--) {
                if (links[i - 1].href) {
                    links[i - 1].insertAdjacentHTML('afterend', ' ' + links[i - 1].href);
                }
            }

            return htmlMessage.getElementsByTagName('body')[0].innerHTML.toString();
        }
    }
}