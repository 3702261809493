namespace Umbrella.Modules.Telephony {
    @Pipe('Telephony', 'isQueueEnabledStatus')
    class IsQueueEnabledStatusFilter {
        public transform(
            input: Umbrella.Telephony.PhoneQueueStatus | string
        ): boolean {
            if (!input) return false;

            switch (input.toString().toLowerCase()) {
                case '2':
                case 'available':
                case '1':
                case 'unavailable':
                    return true;
                default:
                    return false;
            }
        }
    }
}
