import UpdateRegistrationModel = Umbrella.HousingAllocation.Registrations.UpdateRegistrationModel;
namespace Umbrella.CustomerService.CustomerCard {
    @Service('CustomerService', 'CustomerCardRegistrationInfoService')
    @Inject('CustomerCardStore', 'RegistrationsResource')
    export class CustomerCardRegistrationInfoService extends BaseStoreService<
        CustomerCardState,
        CustomerCardEvent,
        CustomerCardStore
    > {
        constructor(
            store: CustomerCardStore,
            private registrationsResource: Registrations.RegistrationsResource
        ) {
            super(store);
        }

        public ensureLoaded(personId?:Guid): void {
            const state = this.getState();
            if (!state.registrationInfo && this.isHousingAllocationAvailable()) this.loadRegistrations(personId);
        }

        public update(model: UpdateRegistrationModel) {
            const state = this.getState();

            model.personId = state.personal.person.id;
               const action = this.registrationsResource
                    .update(model);

            return action.$promise;
        }

        public delete(registrationId:Guid) {
            const state = this.getState();
            const action = this.registrationsResource
                .delete(registrationId);

            return action.$promise;
        }

        public reload() {
            this.loadRegistrations();
        }

        private loadRegistrations(personId?:Guid) {
            const state = this.getState();
            const id = personId || state.personal.person;
            if (id) {
                this.emit({ type: 'CustomerCardRegistrationsLoadingEvent' });

                this.registrationsResource
                    .getDetailedRegistrationsByPerson({
                        id
                    })
                    .$promise.then(reg =>
                        this.emit({
                            type: 'CustomerCardRegistrationsLoadedEvent',
                            registrations: reg
                        })
                    )
                    .catch(e => {
                        if (e.status === 404)
                            this.emit({
                                type: 'CustomerCardRegistrationsLoadedEvent',
                                registrations: []
                            });
                        else
                            this.emit({
                                type: 'CustomerCardRegistrationsLoadErrorEvent',
                                error: e
                            });
                    });
            } else {
                this.store.state$
                    .map(x => x && x.personal)
                    .filter(
                        personal => !personal.loading && !personal.loadError
                    )
                    .take(1)
                    .subscribeOnCompleted(() => this.loadRegistrations());
            }
        }

        private isHousingAllocationAvailable = () =>
        window.config &&
        window.config.facets &&
        window.config.facets.housingAllocation &&
        window.config.facets.housingAllocation[0] &&
        window.config.facets.housingAllocation[0].available;
    }
}
