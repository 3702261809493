﻿/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../Scope.d.ts"/>
/// <reference path="../Decorators.ts" />

namespace Umbrella.CustomerService {
    angular.module('CustomerService').config([
        '$stateProvider',
        '$urlRouterProvider',
        (
            $stateProvider: angular.ui.IStateProvider,
            $urlRouterProvider: angular.ui.IUrlRouterProvider
        ) => {
            $stateProvider.state('customerservice', {
                url: '',
                parent: 'root',
                redirectTo: 'dashboard.tasks.overview',
                views: {
                    'new@': {
                        template:
                        '<customer-service state="state$ | async:this"></customer-service>',
                    controller: Umbrella.createComponentRouteController<
                        TopBarState,
                        CustomerServiceComponentState
                    >('TopBarStore', s => ({
                        impersonationInfo: s && s.impersonationInfo
                    }))
                    }
                }
            });
        }
    ]);
}
