/// <reference path="../../../../ComponentRouteUtils.ts" />
/// <reference path="../../../../Decorators.ts" />

namespace Umbrella.CustomerService.CustomerCard.Personal {
    import RegistrationModel = Umbrella.HousingAllocation.Registrations.Detailed;

    export interface CustomerDetailsComponentState {
        person: PersonModel;
        writableFields: WritablePersonDataFieldsModel;
        loading: boolean;
        customer: Umbrella.Modules.Customers.CustomerModel.Detailed;
        registrationInfo: RegistrationModel[];
        personIsDeletable: boolean;
    }

    @Component('CustomerService', {
        selector: 'customer-details',
        bindings: { state: '<' },
        templateUrl: '/CustomerService/_CustomerCard/_Personal/CustomerDetailsComponent/CustomerDetails.html'
    })
    class CustomerDetailsComponent {
        public state: CustomerDetailsComponentState;
    }
}
