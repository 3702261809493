/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella {
    angular.module('Umbrella').directive('umbrellaEmail', [
        () => {
            return {
                scope: false,
                replace: false,
                transclude: true,
                template:
                    '<span class="key"><ng-transclude></ng-transclude></span>' +
                    '<span class="value"><email-address-popup-menu email-address="emailAddress"></email-address-popup-menu></span>',
                restrict: 'A',
                bindings: {
                    umbrellaEmail: '<'
                },
                link: (scope, el, attr) => {
                    let emailAddress: string;
                    if (el.is('a')) {
                        const aHref = el.attr('href');

                        if (aHref) {
                            emailAddress = aHref;
                        }
                    } else if (el.has('a')) {
                        const aHref = el.find('a').attr('href');

                        if (aHref) {
                            emailAddress = aHref;
                        }
                    }

                    if (!emailAddress) {
                        emailAddress = el.text();
                    }

                    scope.emailAddress = emailAddress.replace('mailto:', '');
                }
            };
        }
    ]);
}
