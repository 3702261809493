namespace Umbrella.Modules {
    @Pipe('Umbrella', 'deepLink')
    class DeepLinkFilter {
        public transform(deepLinkLabel: string): string {
            if (deepLinkLabel || deepLinkLabel !== '')
                return '<i class="zmdi zmdi-link deeplink-icon" ng-if="item.deepLinkUrl.length" title="Is een deeplink"></i>';

            return '';
        }
    }
}
