/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="Window.d.ts"/>

import './Content/Stylesheets/_all.less';
import './Content/css/abstract.css';
import './Content/css/good.css';
import './Content/css/elements.css';
import './Content/new_ui/app.css';

const usedModules = [
    'ngResource',
    'ngCookies',
    'ngAria',
    'ngSanitize',
    'ngAnimate',
    'ngUpload',
    'ngResize',
    'ngMaterial',
    'perfect_scrollbar',
    'ui.router',
    'ui.router.state.events',
    'ngGreensockDraggable',
    'ngFileUpload',
    'ngMaterialDatePicker',
    'angular-click-outside',
    'angularUUID2'
];

function defineModule(name: string, deps: string[]) {
    usedModules.push(name);
    angular.module(name, deps);
}

// Empty out module.js files and modify bundle to include module.js first
defineModule('Authorization', ['ngResource']);
defineModule('CaseHandling', ['ngResource']);
defineModule('CaseFlow', ['ngResource']);
defineModule('Chat', ['ngResource']);
defineModule('Colleagues', ['ngResource']);
defineModule('CustomerService', ['ngResource', 'webStorageModule']);
defineModule('Dashboard', ['ngResource']);
defineModule('Housing', ['ngResource']);
defineModule('Onboarding', ['ngResource']);
defineModule('Knowledgebase', ['ngResource']);
defineModule('Mail', ['ngResource']);
defineModule('Repairs', ['ngResource', 'Housing']);
defineModule('Sandbox', ['ngResource']);
defineModule('Search', ['ngResource']);
defineModule('SelfService', ['ngResource']);
defineModule('TaskHandling', ['ngResource']);
defineModule('Telephony', ['ngResource']);

angular.module('Umbrella', usedModules);

angular.module('Umbrella').config([
    '$mdDateLocaleProvider',
    ($mdDateLocaleProvider: any) => {
        $mdDateLocaleProvider.firstDayOfWeek = 1;
    }
]);

import 'polyfills.js';
import './Modules/Umbrella/Hubs.js';
import 'Umbrella.js';

angular
    .element(document)
    .ready(() => angular.bootstrap(document, ['Umbrella'], { strictDi: true }));

// import 'ServiceWorker/register.ts';
