/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />

namespace Umbrella.Modules.Repairs {
    import IResource = ng.resource.IResource;

    export interface IRepairRequestRuleResource
        extends RepairRequestRuleModel.Edit {
        $update(): ng.IPromise<IRepairRequestRuleResource>;
        $save(): ng.IPromise<IRepairRequestRuleResource>;
        $delete(): ng.IPromise<IRepairRequestRuleResource>;
    }

    export interface SearchParams extends PagedRequestParams {
        id?: string;
        actionType?: string;
    }

    export interface IRepairRequestRuleResourceClass {
        new (model: RepairRequestRuleModel.Edit): IRepairRequestRuleResource;
        getById(
            params: IdRequestParams,
            success?: (model: IRepairRequestRuleResource) => void
        ): IResource<RepairRequestRuleModel.Edit>;
        getAllPaged(
            params: PagedRequestParams
        ): IResource<PagedItemsModel<RepairRequestRuleModel>>;
        search(
            params: SearchParams
        ): IResource<PagedItemsModel<RepairRequestRuleModel.Edit>>;
        save(
            model: RepairRequestRuleModel.Edit
        ): IResource<RepairRequestRuleModel.Edit>;
        update(
            model: RepairRequestRuleModel.Edit
        ): IResource<RepairRequestRuleModel.Edit>;
        delete(params: IdRequestParams);
        getPropertyConditions(): IResource<
            Configuration.PropertyConditionElementModel[]
        >;
    }

    angular.module('Repairs').service('RepairRequestRuleResource', [
        '$resource',
        $resource =>
            $resource(
                '/api/v1/repairrequestrule/:action/:id',
                {},
                {
                    getAllPaged: {
                        method: 'GET',
                        isArray: false,
                        params: { action: '', page: 0, size: 10 }
                    },
                    save: {
                        method: 'POST',
                        isArray: false,
                        params: {}
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: {}
                    },
                    delete: {
                        method: 'DELETE',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getById: {
                        url: '/api/v1/repairrequestrule/:id',
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    search: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'search',
                            id: '@id',
                            actionType: '@actionType',
                            page: 0,
                            pageSize: 10
                        }
                    },
                    getPropertyConditions: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'propertyconditions' }
                    }
                }
            )
    ]);
}
