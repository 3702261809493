/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.UnitCard.Valuation {
    @Service('CustomerService', 'UnitValuationJitService')
    @Inject('UnitCardStore', 'UnitValuationHub', 'UnitCardService', 'ToastMessageService')
    export class UnitValuationJitService {
        private message: any;

        constructor(
            store: UnitCardStore,
            private unitValuationHub: IUnitValuationHubProxy,
            private unitCardService: UnitCardService,
            private toastMessageService: ToastMessageService
        ) {
            const getUnitId = (state: UnitCardState) => state && state.id;

            unitValuationHubOnUpdated$
                .filter(unitId => getUnitId(store.getState()) === unitId)
                .subscribe(_ => {
                    if (store.getState().valuation) unitCardService.loadValuations(true);

                    this.clearToastMessage();
                });

            unitValuationHubOnJitStarted$
                .filter(unitId => getUnitId(store.getState()) === unitId)
                .subscribe(_ => {
                    this.clearToastMessage();
                    this.message = this.toastMessageService.updating('Woningwaardering', 60000);
                });

            store.state$
                .map(getUnitId)
                .distinctUntilChanged()
                .flatMapLatest(Modules.waitUntilSignalRConnected)
                .debounce(() => Rx.Observable.timer(1000))
                .subscribe(unitId => this.subscribe(unitId));

            store.event$.where(e => e.type === 'UnitCardUnloadedEvent').subscribe(_ => this.unsubscribe(getUnitId(store.getState())));
        }

        private subscribe(unitId: System.Guid)
        {
            this.clearToastMessage();
            if (unitId) {
                this.unitValuationHub.server.subscribe(unitId);
            }
        }


        private unsubscribe(unitId: System.Guid)
        {
            if (unitId) {
                this.unitValuationHub.server.unsubscribe(unitId);
            }
            this.clearToastMessage();
        }

        private clearToastMessage() {
            if (this.message) {
                this.toastMessageService.clear(this.message);
                this.message = null;
            }
        }
    }
}
