﻿'use strict';

angular.module('Umbrella').factory('CardAdditionalFieldResource', [
    '$resource',
    $resource =>
        $resource(
            '/api/v1/cardadditionalfield/:action/:id',
            {},
            {
                getAll: {
                    method: 'GET',
                    isArray: true,
                    params: {}
                },
                getPaged: {
                    method: 'GET',
                    isArray: false,
                    params: { action: 'paged', page: 0, pageSize: 15 }
                },
                search: {
                    method: 'GET',
                    isArray: false,
                    params: {
                        action: 'search',
                        query: '',
                        page: 0,
                        pageSize: 15
                    }
                },
                getFrames: {
                    method: 'GET',
                    isArray: true,
                    params: { action: 'frames' }
                },
                getBlocks: {
                    method: 'GET',
                    isArray: true,
                    params: { action: 'blocks' }
                },
                getPositions: {
                    method: 'GET',
                    isArray: true,
                    params: { action: 'positions' }
                },
                getById: {
                    method: 'GET',
                    isArray: false,
                    params: { id: '@id' }
                },
                delete: {
                    method: 'DELETE',
                    isArray: false,
                    params: { id: '@id' }
                },
                save: {
                    method: 'POST',
                    isArray: false,
                    params: { id: '@id' }
                },
                update: {
                    method: 'PUT',
                    isArray: false,
                    params: { id: '@id' }
                }
            }
        )
]);
