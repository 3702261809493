/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella {
    angular.module('Umbrella').directive('removeAndroidKeyboardOnScroll', () => {
        return {
            link(scope, element) {
                element[0].addEventListener('ps-scroll-y', () => {
                    if (document.activeElement instanceof HTMLElement) {
                        document.activeElement.blur();
                    }
                });
            }
        };
    });
}
