namespace Umbrella.CustomerService.UnitCard.Valuation {
    import UnitModel = Modules.Housing.UnitModel;

    @Component('CustomerService', {
        selector: 'unit-valuation',
        templateUrl:
            'CustomerService/_UnitCard/_Valuation/UnitValuationComponent/UnitValuation.html'
    })
    @Inject('UnitCardStore')
    class CustomerValuationComponent {
        public unit: UnitModel;
        private subscription: Rx.IDisposable;

        constructor(private unitCardStore: UnitCardStore) {}

        public $onInit() {
            this.subscription = this.unitCardStore.state$.subscribe(x => {
                this.unit = x.unit;
            });
        }

        public $onDestroy() {
            this.subscription.dispose();
        }
    }
}
