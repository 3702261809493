/// <reference path="./../node_modules/bugsnag-js/types/global.d.ts" />

namespace Umbrella {
    import ConfigState = Umbrella.Config.ConfigState;
    import ConfigEvent = Umbrella.Config.ConfigEvent;

    angular.module('Umbrella').config([
        '$provide',
        $provide => {
            Config.configStore.state$
                .skipWhile(x => !x.config)
                .take(1)
                .subscribe(x => {
                    const bugsnagApiKey = x.config && x.config.bugsnagApiKey;

                    if (!bugsnagApiKey || bugsnagApiKey === 'none') return;
                    let bugsnagConfig: any = {
                        apiKey: bugsnagApiKey
                    };

                    const versionMeta = document.querySelector(
                        'meta[name=version]'
                    );
                    if (versionMeta) {
                        bugsnagConfig = {
                            ...bugsnagConfig,
                            appVersion: versionMeta.getAttribute('content')
                        };
                    }
                    const bugsnagClient = bugsnag(bugsnagConfig);

                    $provide.decorator('$exceptionHandler', [
                        '$delegate',
                        $delegate => {
                            return (exception, cause) => {
                                $delegate(exception, cause);
                                bugsnagClient.notify(exception, {
                                    beforeSend: report => {
                                        report.updateMetaData('angular', {
                                            cause
                                        });
                                    }
                                });
                            };
                        }
                    ]);
                });
        }
    ]);
}
