﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../Window.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard.Workload {
    angular.module('Dashboard').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('dashboard.caseflow.workload.list', {
                url: '/overzicht',
                data: {
                    pageTitle: 'Workload overzicht',
                    authorize: ps => ps.viewCaseFlow
                },
                views: {
                    list: {
                        template:
                            '<case-flow-workload class="h-full w-full block" state="state$ | async:this"></case-flow-workload>',
                        controller: createComponentRouteController<
                            CaseFlowState,
                            Workload.CaseFlowWorkloadComponentState
                        >('CaseFlowOverviewStore', s => ({
                            cases:
                                s &&
                                s.overviewInfo &&
                                s.overviewInfo &&
                                s.overviewInfo.cases &&
                                s.overviewInfo.cases.result
                        }))
                    }
                }
            });
        }
    ]);
}
