namespace Umbrella.CustomerService {
    import CaseStatus = Modules.CaseStatus;

    @Pipe('CustomerService', 'casestatus')
    class CaseStatusFilter {
        public transform(val: CaseStatus): string {
            if (isNaN(val)) {
                val = this.convert(val.toString());
            }
            switch (val) {
                case CaseStatus.Submitted:
                    return 'ingediend';
                case CaseStatus.Closed:
                    return 'gesloten';
                case CaseStatus.InProgress:
                    return 'in behandeling';
                default:
                    return '';
            }
        }

        private convert(val: string): number {
            let convertedValue: CaseStatus = -1;
            switch (val.toLowerCase()) {
                case 'submitted':
                    convertedValue = CaseStatus.Submitted;
                    break;
                case 'closed':
                    convertedValue = CaseStatus.Closed;
                    break;
                case 'inprogress':
                    convertedValue = CaseStatus.InProgress;
                    break;
            }

            return convertedValue;
        }
    }
}
