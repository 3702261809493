namespace Umbrella.Modules.Telephony {
    @Pipe('Telephony', 'phoneStatusIcon')
    class PhoneStatusIconFilter {
        public transform(status: number | string) {
            switch (status) {
                case 'Unavailable':
                case 1:
                    return 'phone_paused';
                case 'Available':
                case 2:
                    return 'phone_in_talk';
                case 'Busy':
                case 4:
                    return 'phone_locked';
                default:
                    return 'phone';
            }
        }
    }
}
