/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.Modules.Knowledgebase {
    export function tagWithParents(tag: BaseTagModel): BaseTagModel[] {
        const tags = [];

        while (tag) {
            tags.unshift(tag);
            tag = tag.parent;
        }

        return tags;
    }

    angular
        .module('Knowledgebase')
        .config([
            '$stateProvider',
            '$urlRouterProvider',
            function($stateProvider, $urlRouterProvider) {
                'use strict';

                $urlRouterProvider.when('/kennisbank', '/kennisbank/thesaurus');

                $stateProvider.state('knowledgebase', {
                    url: '/kennisbank',
                    parent: 'root',
                    views: {
                        'left@': {
                            templateUrl:
                                '/Modules/Knowledgebase/KnowledgebaseFilters.html'
                        }
                    }
                });
            }
        ])
        .filter('isPublished', function() {
            return function(input) {
                if (input && input.toLowerCase() === 'published')
                    return 'gepubliceerd';

                return 'niet gepubliceerd';
            };
        })
        .directive('smartlabelnamevalidation', [
            function() {
                return {
                    require: 'ngModel',
                    link(scope, element, attrs, modelCtrl) {
                        modelCtrl.$parsers.push(function(inputValue) {
                            const transformedInput = inputValue.replace(
                                /[^a-zA-Z0-9\-_\.]/g,
                                ''
                            );

                            if (transformedInput !== inputValue) {
                                modelCtrl.$setViewValue(transformedInput);
                                modelCtrl.$render();
                            }

                            return transformedInput;
                        });
                    }
                };
            }
        ]);
}
