namespace Umbrella.Modules.Chat {
    import ChatConversationModel = ChatConversationHandling.ChatConversation;
    import ChatMessageModel = ChatConversationHandling.ChatMessage;
    import ChatResource = Umbrella.ChatConversationHandling.ChatResource;

    @Component('Chat', {
        selector: 'chat-timeline-conversation',
        templateUrl: '/Modules/Chat/ChatTimelineConversationComponent/ChatTimelineConversation.html',
        bindings: {
            conversationId: '<'
        }
    })
    @Inject('ChatResource', 'CreateDialog')
    export class ChatTimelineConversationComponent {
        public conversationId: System.Guid;
        public conversation: ChatConversationModel;
        public messages: ChatMessageModel[] = [];
        public rating: number;
        public initialMessage: ChatMessageModel;

        constructor(private chatResource: ChatResource, private createDialog: any) {}

        public $onInit() {
            if (this.conversationId) this.loadConversation(this.conversationId);
        }

        private loadConversation(id: System.Guid) {
            this.chatResource.getById({ id }).$promise.then((conversation: ChatConversationModel) => {
                if (conversation) {
                    this.rating = conversation.rating;
                    this.conversation = conversation;
                }
            });
        }

        public openChatHistoryPopup() {
            this.createDialog(
                '/Modules/Chat/ChatConversationHistoryModal/ChatConversationHistoryModal.html',
                {
                    id: 'chatConversationPopup',
                    modalClass: 'popup green popup_650'
                },
                {
                    conversationId: this.conversationId
                }
            );
        }
    }
}
