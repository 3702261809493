namespace Umbrella {
    @Pipe('Umbrella', 'roleName')
    class RoleNameFilter {
        public transform(input: string) {
            if (!input || !input.length) return '';

            switch (input.toLowerCase()) {
                case 'customer':
                    return 'huurder';
                case 'colleague':
                    return 'collega';
                case 'vendor':
                    return 'leverancier';
                case 'prospect':
                    return 'woningzoekende';
                default:
                    return '';
            }
        }
    }
}
