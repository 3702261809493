/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />
/// <reference path="../../Scope.d.ts" />

namespace Umbrella.Modules.Repairs {
    import IResource = ng.resource.IResource;
    import Guid = System.Guid;

    export interface IGetAvailablePlanningOptionsParams {
        repairRequestId: Guid;
        from: Date;
        until: Date;
        external: boolean;
    }

    export interface IPlanningResource {
        getAvailablePlanningOptions(
            params: IGetAvailablePlanningOptionsParams
        ): IResource<AvailablePlanningModel>;
    }

    angular.module('Repairs').service('PlanningResource', [
        '$resource',
        $resource => {
            let prefix = '/api/v1/planning/repairrequest';
            return $resource(
                prefix,
                {},
                {
                    getAvailablePlanningOptions: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            repairRequestId: '@repairRequestId',
                            from: '@from',
                            until: '@until',
                            external: '@external'
                        }
                    }
                }
            );
        }
    ]);
}
