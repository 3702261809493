/// <reference path="..\..\..\..\Decorators.ts" />
/// <reference path="..\..\..\..\Scripts/TypeScript/umbrella/umbrella.d.ts" />

namespace Umbrella.CustomerService {
    @Component('CustomerService', {
        selector: 'card-sidebar-vendor-info',
        bindings: {
            person: '<'
        },
        templateUrl:
            '/CustomerService/_CustomerCard/Sidebar/CardSidebarVendorInfoComponent/CardSidebarVendorInfo.html'
    })
    @Inject('PhoneService')
    class CardSidebarVendorInfoComponent {
        constructor(public phoneService) {}

        public person: PersonModel;

        public callNr(nr: string): void {
            this.phoneService.callNr(nr);
        }
    }
}
