namespace Umbrella.Modules.Mail {
    import IResource = ng.resource.IResource;
    import SimplifiedMailModel = Umbrella.Mail.SimplifiedMailModel;
    import MailModel = Umbrella.Mail.MailModel;

    export interface ISearchParams extends PagedRequestParams {
        query?: string;
        type?: string;
        maand?: number;
    }

    export interface IOutgoingMailResourceClass {
        search(
            params: ISearchParams
        ): IResource<SearchResultsModel<Umbrella.Mail.SimplifiedMailModel>>;
        get(params: { id: System.Guid }): IResource<Umbrella.Mail.MailModel>;
        resend(
            resendMailRequest: Umbrella.Mail.ResendMailRequestModel
        ): IResource<void>;
        getAttachment(params: {
            mailId: System.Guid;
            attachmentContentId: string;
        }): ng.IPromise<MailModel.Attachment>;
    }

    angular.module('Umbrella').service('OutgoingMailResource', [
        '$resource',
        ($resource: ng.resource.IResourceService) =>
            $resource(
                '/api/v1/mail/outgoing/:action/:id',
                {},
                {
                    get: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    search: {
                        method: 'GET',
                        isArray: false
                    },
                    resend: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'resend', id: '@id' }
                    },
                    getAttachment: {
                        url:
                            '/api/v1/mail/outgoing/:mailId/attachment/:attachmentContentId',
                        method: 'GET',
                        isArray: false,
                        params: {
                            mailId: '@mailId',
                            attachmentContentId: '@attachmentContentId'
                        }
                    } /*,
                    addAnonymousMail: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'addAnonymousMail' }
                    },
                    renderMail: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'renderMail', message: '@message' }
                    }*/

                    /*sendTestMail: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'sendtestmail' }
                    }*/
                }
            )
    ]);
}
