namespace Umbrella.Modules.Telephony {
    export interface IQuickDialResource
        extends angular.resource.IResource<QuickDialModel> {}

    export interface QuickDialResource
        extends angular.resource.IResourceClass<IQuickDialResource> {
        getById(params: PagedRequestWithIdParams): IQuickDialResource;
        getAll(): angular.resource.IResource<QuickDialModel[]>;
        getAllActive(): angular.resource.IResource<QuickDialModel[]>;
        search(
            params: ResourceSearchParams
        ): angular.resource.IResource<PagedItemsModel<QuickDialModel>>;
        update(model: QuickDialModel): IQuickDialResource;
        updateOrder(ids: System.Guid[]): void;
    }

    angular.module('Telephony').service('QuickDialResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) => {
            return $resource(
                '/api/v1/quickdial/:action/:id',
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getAll: {
                        method: 'GET',
                        isArray: true,
                        params: { action: '', id: '' }
                    },
                    getAllActive: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'active' }
                    },
                    search: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'search' }
                    },
                    save: {
                        method: 'POST',
                        isArray: false,
                        params: { action: '', id: '' }
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: { action: '', id: '' }
                    },
                    delete: {
                        method: 'DELETE',
                        isArray: false,
                        params: { action: '', id: '@id' }
                    },
                    updateOrder: {
                        method: 'Post',
                        isArray: true,
                        params: { action: 'updateOrder', id: '' }
                    }
                }
            );
        }
    ]);
}
