/// <reference path="../../Modules/Colleagues/Colleague/ColleagueHelpers.ts" />

namespace Umbrella.Search.Colleagues {
    import EmailAddressModel = Umbrella.EmailAddressModel; 
    import PersonSearchModel = Umbrella.Modules.PersonSearchModel;

    export interface IColleagueOverviewControllerScope {
        page: number;
        size: number;
        searchQuery: string;
        searching: boolean;
        persons: IPersonResults;
        clear(): void;
        loadMore(): void;
        getEmailAdress(emails: EmailAddressModel[]): string;
        navigate(id: string): void;
    }

    export interface IPersonResults {
        items: PersonSearchModel[];
        total: number;
    }

    @Inject('$scope', '$stateParams', 'PersonResource')
    export class ColleagueOverviewController {
        constructor(
            $scope: IColleagueOverviewControllerScope,
            $stateParams: angular.ui.IStateParamsService,
            private personResource: PersonResource
        ) {
            $scope.page = 0;
            $scope.size = 50;
            $scope.searchQuery = $stateParams['query'] || '';
            $scope.persons = { items: [], total: 0 };

            $scope.clear = () => this.searchCustomers($scope, null, null);

            $scope.loadMore = () => {
                if (this.hasToShowMore($scope))
                    this.searchCustomers(
                        $scope,
                        personResource,
                        this.getSearchParam($scope.searchQuery)
                    );
            };

            $scope.getEmailAdress = (emails: EmailAddressModel[]) : string => Umbrella.Modules.Colleagues.getColleagueEmail(emails);

            if ($scope.searchQuery)
                this.searchCustomers(
                    $scope,
                    personResource,
                    $scope.searchQuery
                );
            else $scope.clear();
        }

        hasToShowMore($scope: IColleagueOverviewControllerScope): boolean {
            return (
                $scope.persons &&
                $scope.persons.items &&
                $scope.persons.items.length >= $scope.size
            );
        }

        getSearchParam(search: string) {
            return search.charAt(search.length - 1) === '.'
                ? search.slice(0, -1)
                : search;
        }

        searchCustomers(
            $scope: IColleagueOverviewControllerScope,
            personResource: PersonResource,
            searchQuery: string
        ) {
            if (
                $scope.persons.items.length === 0 ||
                $scope.persons.items.length < $scope.persons.total
            ) {
                $scope.searching = true;
                if (!searchQuery) {
                    this.clearResults($scope);
                    return;
                }

                personResource.search({
                    id: searchQuery,
                    page: $scope.page,
                    pageSize: $scope.size,
                    roles: 'employee'
                }).$promise.then((results) => {
                        if (results.items.length !== 0) {
                            this.showCustomers($scope, results);
                            $scope.page++;
                        } else {
                            $scope.searching = false;
                        }
                    }
                );
            } else {
                $scope.searching = false;
            }
        }        

        clearResults($scope: IColleagueOverviewControllerScope) {
            $scope.page = 0;
            $scope.searching = false;
            this.showCustomers($scope, { 
                items: [],
                total: 0,
                page: 0,
                pageSize: 50 
            });
        }

        showCustomers(
            $scope: IColleagueOverviewControllerScope,
            results: PagedItemsModel<PersonSearchModel>
        ) {
            if ($scope.page === 0) $scope.persons = { items: [], total: 0 };
            
            $scope.persons.items = results.items;
            $scope.persons.total = results.total;
            $scope.searching = false;
        }
    }
}
