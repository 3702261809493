/// <reference path="../../../../../SelectedColleagueOrFunctiongroup.d.ts"/>
/// <reference path="../../../../../Top/PhoneService.ts"/>
/// <reference path="../../../../../ChatConversationHandling/ChatService.ts"/>

namespace Umbrella.CustomerService.CustomerCard.Activities.Registration {
    import IContractResource = Modules.Housing.IContractResource;
    import ContractFilterModel = Modules.Housing.ContractFilterModel;
    import IContactActivityResource = Modules.Contacts.IContactActivityResource;
    import ContractModel = Modules.Housing.ContractModel;
    import RoleModel = Modules.RoleModel;
    import NewContact = Umbrella.CustomerService.CustomerCard.Activities.Registration.NewContact;
    import ChannelType = Modules.ChannelType;
    import PhoneService = Modules.Telephony.PhoneService;
    import ChatService = Umbrella.ChatConversationHandling.ChatService;
    import ContactDirection = Modules.Contacts.ContactDirection;
    import FaqResourceClass = Umbrella.Modules.Knowledgebase.Faq.FaqResourceClass;

    @Component('CustomerService', {
        selector: 'register-contact-activity-popup',
        templateUrl:
            '/CustomerService/_CustomerCard/_Activities/Registration/RegisterContactActivityPopupComponent/RegisterContactActivityPopup.html',
        bindings: {
            caseId: '@',
            contractId: '@',
            selectedChannelType: '@',
            selectedTagId: '@',
            selectedFaqId: '@',
            personId: '@',
            selectedTargetGroupId: '@',
            selectedFollowUpQuestionId: '@',
            mailId: '@',
            disableDirectionSelection: '<'
        }
    })
    @Inject(
        '$mdDialog',
        'EmptyGuid',
        'ContactActivityResource',
        'ContractResource',
        'PersonResource',
        'ToastMessageService',
        'ActivityRegistrationService',
        'PhoneService',
        'ChatService',
        'LocalStorageService',
        'Upload',
        'FaqResource'
    )
    class RegisterContactActivityPopupComponent {
        // bindings
        public caseId: System.Guid;
        private contractId: System.Guid;
        private selectedChannelType: ChannelType;
        private selectedFaqId: System.Guid;
        private selectedTagId: System.Guid;
        private personId: System.Guid;
        private selectedTargetGroupId: System.Guid;
        private selectedFollowUpQuestionId: System.Guid;
        public disableDirectionSelection: boolean;

        public activity: NewContact;
        public allContracts: ContractFilterModel[] = [];
        public displayedContracts: ContractFilterModel[] = [];
        public selectedContract: ContractFilterModel;
        public contractors: RoleModel[] = [];
        public selectedContractor: RoleModel;
        public customerId: System.Guid;
        public isSaving = false;
        public channels: ChannelInfo[];
        public selectedChannel: ChannelInfo;
        private roleId: System.Guid;
        private person: PersonModel;
        private mailId: Guid;
        private readonly LAST_SELECTED_DIRECTION = 'lastSelectedDirection';
        private media: Umbrella.Media.MediaModel[] = [];
        public isUploading: boolean;
        private isPublic: boolean;

        constructor(
            private $mdDialog,
            private emptyGuid: System.Guid,
            private contactActivityResource: IContactActivityResource,
            private contractResource: IContractResource,
            private personResource: PersonResource,
            private toastMessageService: ToastMessageService,
            private activityRegistrationService: ActivityRegistrationService,
            private phoneService: PhoneService,
            private chatService: ChatService,
            private localStorageService: LocalStorageService,
            private uploadService: any,
            private faqResource: FaqResourceClass
        ) {}

        public $onInit() {
            this.init();
        }

        public canChangeDirectionForSelectedChannelType(): boolean {
            const channelType = this.getSelectedChannelType();

            switch (channelType) {
                case ChannelType.Visit:
                case ChannelType.Chat:
                case ChannelType.Desk:
                    return false;
                default:
                    return true;
            }
        }

        public resetDirectionForInboundChannelTypes(): void {
            if (!this.canChangeDirectionForSelectedChannelType()) this.activity.direction = ContactDirection.Inbound;
        }

        private async init() {
            await this.loadPerson(this.personId);

            await this.setIsPublic();

            this.activity = {
                description: '',
                caseId: this.caseId,
                contractId: this.contractId,
                roleId: this.roleId,
                notifyClient: false,
                direction: this.disableDirectionSelection
                    ? ContactDirection.Inbound
                    : this.localStorageService.get(this.LAST_SELECTED_DIRECTION) || ContactDirection.Inbound,
                isPublic: this.isPublic
            };

            this.loadChannels();

            if (this.customerId) this.loadContracts(this.customerId);

            this.addPasteEventListener();

            this.isSaving = false;
        }

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        public save(): void {
            if (this.isSaving) return;
            this.isSaving = true;

            this.activity.roleId = (this.selectedContractor && this.selectedContractor.id) || this.roleId;
            this.activity.contractId = this.selectedContract && this.selectedContract.id;

            const req: Modules.Contacts.RegisterContactModel = {
                caseId: this.activity.caseId,
                channelType: this.getSelectedChannelType(),
                tagId: this.selectedTagId || this.emptyGuid,
                faqId: this.selectedFaqId || this.emptyGuid,
                targetGroupId: this.selectedTargetGroupId || null,
                followUpQuestionId: this.selectedFollowUpQuestionId || null,
                contractId: this.activity.contractId,
                roleId: this.activity.roleId,
                description: this.activity.description,
                notifyClient: this.activity.notifyClient,
                mailId: this.mailId || null,
                direction: this.canChangeDirectionForSelectedChannelType()
                    ? this.activity.direction
                    : ContactDirection.Inbound,
                mediaIds: this.media.map(m => m.id),
                isPublic: this.activity.isPublic
            };

            this.contactActivityResource
                .register(req)
                .$promise.then(activity => {
                    this.toastMessageService.success('Contactmoment vastgelegd');

                    this.localStorageService.store(this.LAST_SELECTED_DIRECTION, req.direction);

                    if (
                        !activity.role ||
                        (!this.phoneService.hasActiveCall(activity.role.id) &&
                            !this.chatService.hasActiveConversation(activity.role.personId)
                        )
                    )
                        this.activityRegistrationService.selectChannel(req.channelType);

                    this.$mdDialog.hide(activity);
                })
                .catch(e => {
                    if (window.clientConfig && window.clientConfig.debug) console.log(e);
                    this.toastMessageService.error('Fout opgetreden tijdens vastleggen contactmoment');
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isSaving = false;
                    }, 200);
                });
        }

        public searchContract(query: string) {
            this.displayedContracts = this.allContracts.filter(
                x => x.addressLine.toLowerCase().indexOf(query.toLowerCase()) > -1
            );
        }

        public selectContract(contract: ContractFilterModel): void {
            if (!contract) {
                this.selectedContract = null;
                this.contractors = [];
                return;
            }

            this.selectedContract = contract;
            this.loadContractors(contract.id);
        }

        public upload = (file: any) => {
            const url = Config.makeAbsoluteApiUrl('/api/v2/media?mediaCategory=TaskMedia');
            if (file) {
                this.isUploading = true;
                file.upload = this.uploadService
                    .upload({
                        url,
                        data: { file }
                    })
                    .then(result => {
                        this.isUploading = false;
                        this.media.push(result.data);
                        this.toastMessageService.success('Bestand is succesvol toegevoegd');
                    })
                    .catch(() => {
                        this.isUploading = false;
                        this.toastMessageService.error('Bestand toevoegen is mislukt');
                    });
            }
        };

        private async loadContracts(customerId: System.Guid): Promise<void> {
            const contractList = await this.contractResource.getAllFilterValuesByRoleId({
                id: customerId,
            }).$promise;
            this.displayedContracts = this.allContracts = contractList;

            const preselectedContract = this.contractId
                ? this.allContracts.filter(x => x.id === this.contractId)[0] ||
                  (await this.getContract(this.contractId))
                : !this.selectedContract && this.allContracts.filter(x => this.isActiveOrFutureContract(x))[0];

            if (preselectedContract)
                this.selectContract(this.allContracts.filter(x => x.id === preselectedContract.id)[0]);
        }

        private isActiveOrFutureContract(contract: ContractFilterModel): boolean {
            switch (contract.status.toString().toLowerCase()) {
                case 'active':
                case '1':
                case 'future':
                case '2':
                    return true;
                default:
                    return false;
            }
        }

        private async loadContractors(contractId: System.Guid): Promise<void> {
            this.contractors = await this.contractResource.getContacts({
                id: contractId
            }).$promise;
            this.selectedContractor = this.contractors.filter(x => x.id === this.customerId)[0];
        }

        private async getContract(contractId: System.Guid): Promise<ContractModel> {
            const contract = await this.contractResource.getById({
                id: contractId
            }).$promise;
            return contract;
        }

        private loadChannels(): void {
            this.channels = getChannelTypes();

            let selectedChannelType = this.selectedChannelType || ChannelType.Unknown;
            if (this.phoneService.hasActiveCall(this.roleId)) selectedChannelType = ChannelType.Phone;
            else if (this.chatService.hasActiveConversation(this.personId)) selectedChannelType = ChannelType.Chat;

            this.selectedChannel = this.channels
                .filter(x => x.type.toString() === selectedChannelType.toString())
                .shift();
        }

        private getSelectedChannelType(): ChannelType {
            return (this.selectedChannel && this.selectedChannel.type) || ChannelType.Unknown;
        }

        private async loadPerson(personId: System.Guid): Promise<void> {
            if (!personId) return;

            this.person = await this.personResource.getById({ id: personId }).$promise;
            this.customerId = this.person && this.person.roles['customer'];
            this.roleId =
                this.person &&
                (this.person.roles['customer'] ||
                    this.person.roles['occupant'] ||
                    this.person.roles['vendor'] ||
                    this.person.roles['prospect']);
        }

        private addPasteEventListener(): void {
            window.addEventListener('paste', (event: Event) => {
                const clipboardEvent: ClipboardEvent = <ClipboardEvent> event;
            
                if(clipboardEvent.clipboardData.items) {
                    const item = clipboardEvent.clipboardData.items[0];
                    if(item)
                        this.upload(item.getAsFile());
                }
            });            
        }

        private async setIsPublic(): Promise<void> {
            if(!this.selectedFaqId){
                this.isPublic = false;
                return;
            }
            try{
                const faq = await this.faqResource.getById({id: this.selectedFaqId}).$promise;
                let publicApplicationIds: System.Guid[] = [];
                    if (window &&
                        window.config &&
                        window.config.modules &&
                        window.config.modules.timeline &&
                        window.config.modules.timeline.publicApplicationIds) {
                            publicApplicationIds = window.config.modules.timeline.publicApplicationIds;
                        }

                    if (faq &&
                        faq.applicationIds) {
                            this.isPublic = publicApplicationIds.some(id => faq.applicationIds.indexOf(id) >= 0);
                        }
                    else {
                        this.isPublic = false;
                    }
            }
            catch(err){
                this.isPublic = false;
            }
        }
    }
}
