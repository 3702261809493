/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../CustomerService/OutgoingSmsResource.ts"/>
/// <reference path="../../../../Window.d.ts"/>
/// <reference path="../../../../ComponentRouteUtils.ts" />

namespace Umbrella.CustomerService.CustomerCard.Activities {
    import FaqModel = Modules.Knowledgebase.FaqModel;
    import ThesaurusTagModel = Umbrella.Modules.Knowledgebase.ThesaurusTagModel;
    import ActivityRegistrationService = Umbrella.CustomerService.CustomerCard.Activities.Registration.ActivityRegistrationService;
    import ChatStore = Umbrella.ChatConversationHandling.ChatStore;
    import ChatMessage = Umbrella.ChatConversationHandling.ChatMessage;
    import KnowledgebaseSuggestionsInput = Umbrella.CustomerService.KnowledgebaseSuggestionsInput;
    import RegistrationModel = Umbrella.HousingAllocation.Registrations.Detailed;

    export const openKnowledgebase$ = new Rx.Subject<boolean>();
    export const openSelfServicePanel$ = new Rx.Subject<boolean>();

    export interface CustomerActivitiesComponentState {
        loading: boolean;
        person: PersonModel;
        customer: Modules.Customers.CustomerModel.Detailed;
        announcementInfo: Announcements.State;
        registrationInfo: RegistrationModel[];
        activityInfo: Activities.State;
        activityRegistrationInfo: Registration.ActivityRegistrationState;
        selfServiceScenarios: Modules.SelfService.SelfServiceScenarioModel.Detailed[];
    }

    @Component('CustomerService', {
        selector: 'customer-activities',
        templateUrl: '/CustomerService/_CustomerCard/_Activities/CustomerActivitiesComponent/CustomerActivities.html',
        bindings: { state: '<' }
    })
    @Inject(
        '$location',
        '$stateParams',
        '$mdDialog',
        'CreateDialog',
        'CustomerCardAnnouncementInfoService',
        'CustomerCardActivityInfoService',
        'CustomerCardPersonInfoService',
        'ActivityRegistrationService',
        'ChatStore'
    )
    class CustomerActivitiesComponent {
        public state: CustomerActivitiesComponentState;
        public knowledgebaseActions: any;
        public knowledgebaseInitialized: boolean;
        public suggestionsQuery: KnowledgebaseSuggestionsInput;
        public personHasRoles: boolean;
        private chatSubscription: Rx.Disposable;
        private knowledgebaseSubscription: Rx.Disposable;
        private sssSubscription: Rx.Disposable;
        private currentPerson: PersonModel;

        constructor(
            private $location: ng.ILocationService,
            private $stateParams: CustomerActivitiesStateParams,
            private $mdDialog,
            private createDialog,
            private announcementInfoService: CustomerCardAnnouncementInfoService,
            private activityInfoService: CustomerCardActivityInfoService,
            private personInfoService: CustomerCardPersonInfoService,
            private activityRegistrationService: ActivityRegistrationService,
            private chatStore: ChatStore
        ) {
            this.knowledgebaseSubscription = openKnowledgebase$
                .filter(x => !!x)
                .filter(_ => !this.isContactRegistrationPanelSelected())
                .subscribe(_ => {
                    this.openKnowledgebase();
                });

            this.sssSubscription = openSelfServicePanel$
                .filter(x => !!x)
                .filter(_ => !this.isSssRegistrationPanelSelected())
                .subscribe(_ => {
                    this.openSssRegistrationPanel();
                });
        }

        public $onInit() {
            this.createKnowledgebaseActionDefinitions();

            if (this.$stateParams.openKnowledgebase) this.openKnowledgebase();
            else if (this.$stateParams.openSelfServicePanel) this.openSssRegistrationPanel();

            this.hasRoles();
        }

        public $onChanges(bindings: { state: IBindingChange<CustomerActivitiesComponentState> }) {
            if (!bindings.state || !bindings.state.currentValue || !bindings.state.currentValue.person) return;

            const hasPersonChanged = bindings.state.currentValue.person.id !== bindings.state.previousValue.person;
            if (hasPersonChanged) {
                this.checkForPersonChatActivity(bindings.state.currentValue.person);
                this.hasRoles();
            }
        }

        $onDestroy() {
            if (this.chatSubscription) this.chatSubscription.dispose();
            if (this.knowledgebaseSubscription) this.knowledgebaseSubscription.dispose();
            if (this.sssSubscription) this.sssSubscription.dispose();
        }

        public loadMoreAnnouncements(): void {
            this.announcementInfoService.loadMore();
        }

        public loadMoreActivities(): void {
            this.activityInfoService.loadMore();
        }

        public checkForPersonChatActivity(person: PersonModel) {
            if (!person) return;

            if (this.chatSubscription) this.chatSubscription.dispose();

            const personId = person.id;
            const hasStateOrConversations = x => x && x.operatorConversations;
            const personHasActiveChat = x => x.filter(c => c.customer.id === personId).length > 0;
            const chatWithPerson = x => x.find(c => c.customer.id === personId);
            const hasMessages = x => x.messages && x.messages.length > 0;

            /*this.chatSubscription = this.chatStore.state$
                .filter(hasStateOrConversations)
                .map(x => x.operatorConversations)
                .filter(personHasActiveChat)
                .map(chatWithPerson)
                .filter(hasMessages)
                .map(x => x.messages)
                .subscribe(messages => {
                    this.generateSuggestionsQueryBasedOnChatMessaged(messages);
                });*/
        }

        public createKnowledgebaseActionDefinitions(): any {
            this.knowledgebaseActions = [];

            if (window.user.permissions.createTask)
                this.knowledgebaseActions.push({
                    name: 'Taak aanmaken',
                    onExecute: (faq: FaqModel, tag: ThesaurusTagModel) => this.registerTask(faq, tag)
                });

            this.knowledgebaseActions.push({
                name: 'Doorschakelen',
                onExecute: (faq: FaqModel, tag: ThesaurusTagModel) => this.launchTransferPopup(faq, tag)
            });

            if (window.user.permissions.caseCreate)
                this.knowledgebaseActions.push({
                    name: 'Contact vastleggen',
                    primary: true,
                    onExecute: (faq: FaqModel, tag: ThesaurusTagModel, targetGroupId?: Guid) =>
                        this.registerContactActivity(faq, tag, targetGroupId)
                });
        }

        public registerTask(faq: FaqModel, tag: ThesaurusTagModel): void {
            if (!faq || !faq.mainTag) return;

            const person = this.personInfoService.getSelectedPerson();
            if (!person) return;

            this.$mdDialog
                .show({
                    template: `<register-task-popup person-id="${person.id}" faq-id="${faq.id}" tag-id="${
                        faq.mainTag.id
                    }" channel-type="${this.activityRegistrationService.getSelectedChannelType()}"></register-task-popup>`,
                    targetEvent: null,
                    clickOutsideToClose: false
                })
                .then(task => {
                    this.activityRegistrationService.taskRegistered(task);
                    this.activityInfoService.reload();
                    this.knowledgebaseInitialized = false;
                });
        }

        public registerContactActivity(faq: FaqModel, tag: ThesaurusTagModel, targetGroupId?: Guid): void {
            if (!faq || !faq.mainTag) return;

            const person = this.personInfoService.getSelectedPerson();
            if (!person) return;

            this.$mdDialog
                .show({
                    template: `<register-contact-activity-popup
                        person-id="${person.id}"
                        selected-channel-type="${this.activityRegistrationService.getSelectedChannelType() || 0}"
                        selected-faq-id="${faq.id}"
                        selected-tag-id="${(tag && tag.id) || faq.mainTag.id}"
                        selected-target-group-id="${targetGroupId ||
                            this.activityRegistrationService.getSelectedTargetgroupId() ||
                            ''}"
                        selected-follow-up-question-id="${this.activityRegistrationService.getSelectedFollowUpQuestionId() ||
                            ''}">
                    </register-contact-activity-popup>`,
                    targetEvent: null,
                    clickOutsideToClose: false
                })
                .then(activity => {
                    this.activityRegistrationService.contactActivityRegistered(activity);
                    this.activityInfoService.reload();
                    this.knowledgebaseInitialized = false;
                });
        }

        public launchTransferPopup(faq: FaqModel, tag: ThesaurusTagModel) {
            if (!faq) return;

            const person = this.personInfoService.getSelectedPerson();
            const customerId = person && person.roles['customer'];

            this.createDialog(
                '/CustomerService/_CustomerCard/_Activities/Registration/TransferPopup/TransferPopup.html',
                {
                    id: 'transferPopup',
                    modalClass: 'popup green popup_1350',
                    successCallback: () => (this.knowledgebaseInitialized = false)
                },
                {
                    faq,
                    tagId: (tag && tag.id) || faq.mainTag.id,
                    customerId
                }
            );
        }

        public hasRoles() {
            const person = this.personInfoService.getSelectedPerson();
            if(person)
                this.personHasRoles = Object.keys(person.roles).length > 0;

        }

        public toggleSssRegistrationPanel(): void {
            this.state.activityRegistrationInfo.selectedPanel === Registration.SelectedPanel.Sss
                ? this.closeSssRegistrationPanel()
                : this.openSssRegistrationPanel();
        }

        public toggleContactRegistrationPanel(): void {
            this.state.activityRegistrationInfo.selectedPanel === Registration.SelectedPanel.Registration
                ? this.closeKnowledgebase()
                : this.openKnowledgebase();

            if (!this.knowledgebaseInitialized) this.knowledgebaseInitialized = true;
        }

        public isContactRegistrationPanelSelected(): boolean {
            const isActive =
                this.state.activityRegistrationInfo.selectedPanel === Registration.SelectedPanel.Registration;
            if (isActive && !this.knowledgebaseInitialized) this.knowledgebaseInitialized = true;

            return isActive;
        }

        public isSssRegistrationPanelSelected(): boolean {
            return this.state.activityRegistrationInfo.selectedPanel === Registration.SelectedPanel.Sss;
        }

        public closeKnowledgebase(): void {
            this.activityRegistrationService.selectPanel(Registration.SelectedPanel.None);
        }

        private openKnowledgebase(): void {
            this.activityRegistrationService.selectPanel(Registration.SelectedPanel.Registration);
        }

        private openSssRegistrationPanel(): void {
            this.activityRegistrationService.selectPanel(Registration.SelectedPanel.Sss);
        }

        private closeSssRegistrationPanel(): void {
            this.activityRegistrationService.selectPanel(Registration.SelectedPanel.None);
        }

        private generateSuggestionsQueryBasedOnChatMessaged(messages: ChatMessage[]) {
            if (!messages || messages.length < 1) return;

            const query = messages
                .filter(x => x.direction === 1)
                .map(x => x.text)
                .join(' ');

            const basedOn = KnowledgebaseSuggestionsBasedOn.Chat;

            this.suggestionsQuery = {
                query,
                basedOn
            };
        }
    }
}
