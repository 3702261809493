namespace Umbrella {
    import Gender = Umbrella.Modules.Gender;

    @Pipe('Dashboard', 'gender')
    class GenderFilter {
        public transform(gender: string | number | Gender) {
            switch (gender) {
                case 'Male':
                case 0:
                case Gender.Male:
                    return 'Man';
                case 'Female':
                case 1:
                case Gender.Female:
                    return 'Vrouw';
                default:
                    return 'Onbekend';
            }
        }
    }
}
