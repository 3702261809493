namespace Umbrella.CustomerService {
    import Detailed = Modules.Payments.Billing.BillModel.Detailed;

    @Component('CustomerService', {
        selector: 'bill-timeline',
        templateUrl:
            '/CustomerService/_CustomerCard/_Bills/BillTimelineComponent/BillTimeline.html',
        bindings: {
            bills: '<',
            loading: '<',
            displayAddress: '<',
            onLoadMore: '&'
        }
    })
    class BillTimelineComponent {
        public bills: PagedItemsModel<Detailed>;
        public loading: boolean;
        public onLoadMore: () => void;

        public loadMore() {
            this.onLoadMore();
        }
    }
}
