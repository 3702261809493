/// <reference path="../../../Decorators.ts" />
/// <reference path="../../../Top/PresenceHub.ts"/>

namespace Umbrella.CustomerService.ColleagueCard {
    import ColleagueModel = Umbrella.Modules.Colleagues.ColleagueModel;
    import StatusModel = Umbrella.Modules.Colleagues.StatusModel;
    import PresenceResource = Umbrella.IPresenceResource;

    @Component('CustomerService', {
        selector: 'colleague-header',
        templateUrl:
            '/CustomerService/_ColleagueCard/ColleagueHeaderComponent/ColleagueHeader.html',
        bindings: {
            colleague: '<'
        }
    })
    @Inject('PresenceResource')
    class ColleagueHeaderComponent {
        public colleague: ColleagueModel.Detailed;
        public availabilityStatus: StatusModel;
        private presenceObserver: Rx.IDisposable;

        constructor(private presenceResource: PresenceResource) {}

        public $onInit() {
            if (!this.colleague) return;

            this.loadAvailabilityStatus(this.colleague.personId);
            this.observeAvailabilityStatus();
        }

        public $onDestroy() {
            if (this.presenceObserver) this.presenceObserver.dispose();

            this.presenceObserver = null;
        }

        private async loadAvailabilityStatus(id: System.Guid): Promise<void> {
            const presence = await this.presenceResource.getStatusByIds({
                ids: [id]
            }).$promise;
            if (!presence || !presence.length) return;

            this.availabilityStatus = presence[0].status;
        }

        private observeAvailabilityStatus(): void {
            this.presenceObserver = colleagueStatusUpdated$
                .filter(x => !!x)
                .distinctUntilChanged()
                .subscribe(update => {
                    if (this.colleague && this.colleague.id === update.userId)
                        this.availabilityStatus = update.status;
                });
        }
    }
}
