/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.Modules.Chat {
    angular.module('Chat').directive('chatAutoScroller', [
        '$timeout',
        $timeout => {
            function animateScrolling(
                element: ng.IAugmentedJQuery,
                speed = 50
            ) {
                if (!element || element.length < 1) return;

                if (speed < 1) {
                    element.stop();
                    element.scrollTop(element[0].scrollHeight);
                    return;
                }

                element.stop().animate(
                    {
                        scrollTop: element[0].scrollHeight
                    },
                    speed
                );
            }
            return {
                restrict: 'A',
                scope: {
                    chatAutoScroller: '@'
                },
                priority: -1,
                link: (
                    scope: ng.IScope,
                    el: ng.IAugmentedJQuery,
                    attr: ng.IAttributes
                ) => {
                    if (!el || el.length < 1) return;

                    animateScrolling(el, 0);
                    $timeout(() => {
                        animateScrolling(el, 0);

                        el.on('DOMNodeInserted', e => {
                            if (angular.element(e.target).hasClass('message')) {
                                $timeout(() => {
                                    animateScrolling(el, 1000);
                                });
                                return;
                            }

                            if (
                                angular
                                    .element(e.target)
                                    .hasClass('notification')
                            ) {
                                $timeout(() => {
                                    animateScrolling(el, 0);
                                });
                                return;
                            }
                        });
                    });
                }
            };
        }
    ]);
}
