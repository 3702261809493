/// <reference path="../../ChatConversationHandling/ChatStore.ts" />

namespace Umbrella.TopBar {
    import ImpersonationService = CustomerService.CustomerCard.ImpersonationService;
    import ActivityRegistrationService = CustomerService.CustomerCard.Activities.Registration.ActivityRegistrationService;
    import TaskTypeModel = Umbrella.Modules.Tasks.TaskTypeModel;

    @Component('Umbrella', {
        selector: 'impersonation-controls',
        templateUrl: '/Top/ImpersonationControlsComponent/ImpersonationControls.html',
        bindings: {
            impersonationInfo: '<'
        }
    })
    @Inject('$mdDialog', 'ImpersonationService', 'ActivityRegistrationService')
    class AvailabilityControlsComponent {
        public impersonationInfo: Impersonation.State;
        public waiting: boolean;

        constructor(
            private $mdDialog,
            private impersonationService: ImpersonationService,
            private activityRegistrationService: ActivityRegistrationService
        ) {}

        public leaveImpersonationMode(): void {
            this.waiting = true;
            this.impersonationService.leaveAndReturn();

            var portalUrl = this.impersonationService.getPortalUrl();
            if(portalUrl)
                $('#selfServiceIFrame').attr('src', portalUrl + '/account/logout');
            else
                $('#selfServiceIFrame').attr('src', 'about:blank');
        }

        public launchTaskRegistrationPopup(): void {
            const personId =
                this.impersonationInfo && this.impersonationInfo.person && this.impersonationInfo.person.id;
            const tagId =
                this.impersonationInfo &&
                this.impersonationInfo.scenario &&
                this.impersonationInfo.scenario.thesaurusTagId;
            if (!personId || !tagId) return;

            this.$mdDialog.show({
                template: `<register-task-popup person-id="${personId}" tag-id="${tagId}" channel-type="${this.activityRegistrationService.getSelectedChannelType()}"></register-task-popup>`,
                targetEvent: null,
                clickOutsideToClose: false
            });
        }

        public switchFullScreen(): void {
            const isFullScreen = this.impersonationService.isFullScreen();

            if (isFullScreen) this.impersonationService.switchOffFullScreen();
            else this.impersonationService.switchOnFullScreen();
        }
    }
}
