﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Window.d.ts"/>

namespace Umbrella.TaskHandling.Overview {
    import TaskModel = Umbrella.Modules.Tasks.TaskModel;
    import TaskService = Umbrella.Modules.Tasks.TaskService;
    import SummarizedColleagueModel = Modules.Colleagues.SummarizedColleagueModel;
    import ColleagueType = Modules.Colleagues.ColleagueType;

    @Pipe('TaskHandling', 'canModify')
    class CanModifyFilter {
        public transform(task: TaskModel, employee: SummarizedColleagueModel) {
            if (!task || !employee) return false;
            if (
                task.finished ||
                task.status.toString() === 'Afgerond' ||
                task.status.toString() === '2'
            )
                return false;
            if (
                task.status.toString() === 'Verwijderd' ||
                task.status.toString() === '3'
            )
                return false;

            if (window.user.permissions.editTask) return true;
            if (task.createdBy && task.createdBy.id === employee.id)
                return true;

            return false;
        }
    }
}
