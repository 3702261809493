namespace Umbrella.Modules.Colleagues {
    @Pipe('Colleagues', 'isAway')
    class IsAwayFilter {
        public transform(available: Availability | string | number) {
            switch (available) {
                case Availability.Away:
                case 'Away':
                case 2:
                    return true;
                default:
                    return false;
            }
        }
    }
}
