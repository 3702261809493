/// <reference path="../../Config/Config.ts" />
/// <reference path="../../Decorators.ts" />

namespace Umbrella {
    @Pipe('Umbrella', 'managementPortalPath')
    class ManagementPortalPathFilter {
        public transform(id: System.Guid) {
            if (!id) return '';

            return Config.makeManagementPortalPath(id);
        }
    }
}