import EvaluationModel = Umbrella.Indicators.IndicatorEvaluationModel;
import GetActiveIndicatorsModel = Umbrella.Indicators.GetActiveIndicatorsModel;
import ResourceService = angular.resource.IResourceService;
import Resource = angular.resource.IResource;
import ResourceClass = angular.resource.IResourceClass;
import Dictionary = Umbrella.Indicators.Dictionary;

namespace Umbrella.CustomerService {
    export interface IIndicatorEvaluationResource
        extends Resource<EvaluationModel>,
            EvaluationModel {}
    export interface IIndicatorEvaluationResourceClass
        extends ResourceClass<IIndicatorEvaluationResource> {
        active(
            { key: string },
            models: any[]
        ): ng.resource.IResource<EvaluationModel[]>;
        indicatorsForMultipleKeys(
            models: GetActiveIndicatorsModel[]
        ): ng.resource.IResource<Dictionary<EvaluationModel[]>>;
    }
    angular.module('Umbrella').service('IndicatorEvaluationResource', [
        '$resource',
        ($resource: ResourceService) =>
            $resource(
                '/api/v1/indicator/evaluation/:key',
                { key: '@key' },
                {
                    active: {
                        method: 'PUT',
                        isArray: true,
                        params: { key: '@key' }
                    },
                    indicatorsForMultipleKeys: {
                        url: '/api/v1/indicator/evaluation',
                        method: 'PUT',
                        isArray: false
                    }
                }
            )
    ]);
}
