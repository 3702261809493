/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.Sandbox.Dashboard {
    angular.module('Sandbox').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider
                .state('sandbox.dashboard', {
                    url: '/dashboard',
                    reloadOnSearch: false,
                    redirectTo: 'sandbox.dashboard.buttons',
                    data: {
                        pageTitle: 'Sandbox - Dashboard',
                        authorize: p => p.shell
                    },
                    views: {
                        'left@sandbox': {
                            templateUrl: '/Sandbox/_Dashboard/Navigation.html'
                        }
                    }
                })
                .state('sandbox.dashboard.buttons', {
                    url: '/buttons',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Dashboard buttons',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_Dashboard/Buttons.html'
                        }
                    }
                })
                .state('sandbox.dashboard.datepicker', {
                    url: '/datepicker',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Dashboard datepicker',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_Dashboard/DatePicker.html'
                        }
                    }
                })
                .state('sandbox.dashboard.dialog', {
                    url: '/dialog',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Dashboard dialog',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': { component: 'dialogComponent' }
                    }
                })
                .state('sandbox.dashboard.icons', {
                    url: '/icons',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Dashboard icons',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_Dashboard/Icons.html'
                        }
                    }
                })
                .state('sandbox.dashboard.input', {
                    url: '/input',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Dashboard input',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_Dashboard/Input.html'
                        }
                    }
                })
                .state('sandbox.dashboard.list', {
                    url: '/list',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Dashboard list',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_Dashboard/List.html'
                        }
                    }
                })
                .state('sandbox.dashboard.menu', {
                    url: '/menu',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Dashboard menu',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_Dashboard/Menu.html'
                        }
                    }
                })
                .state('sandbox.dashboard.pagination', {
                    url: '/pagination',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Dashboard pagination',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_Dashboard/Pagination.html'
                        }
                    }
                })
                .state('sandbox.dashboard.select', {
                    url: '/select',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Dashboard select',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': { component: 'selectComponent' }
                    }
                })
                .state('sandbox.dashboard.statuslabel', {
                    url: '/statuslabel',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Dashboard status label',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_Dashboard/StatusLabel.html'
                        }
                    }
                })
                .state('sandbox.dashboard.tabs', {
                    url: '/tabs',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Dashboard tabs',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_Dashboard/Tabs.html'
                        }
                    }
                })
                .state('sandbox.dashboard.tooltip', {
                    url: '/tooltip',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Dashboard tooltip',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_Dashboard/Tooltip.html'
                        }
                    }
                });
        }
    ]);
}
