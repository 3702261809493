/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../../../Scope.d.ts"/>
/// <reference path="../../../../../Window.d.ts"/>
/// <reference path="../../../../../Top/PresenceHub.ts"/>
/// <reference path="../../../../../Modules/Colleagues/Colleague/ColleagueHelpers.ts" />

namespace Umbrella.CustomerService {
    import PersonSearchModel = Umbrella.Modules.PersonSearchModel;
    import SummarizedColleagueModel = Modules.Colleagues.SummarizedColleagueModel;
    import PhoneService = Modules.Telephony.PhoneService;
    import ICalendarResourceClass = Modules.Colleagues.ICalendarResourceClass;
    import AppointmentModel = Modules.Colleagues.Calendar.AppointmentModel;
    import FaqModel = Modules.Knowledgebase.FaqModel;
    import IColleagueResource = Umbrella.Modules.Colleagues.IColleagueResourceClass;
    import CustomerCardStore = Umbrella.CustomerService.CustomerCard.CustomerCardStore;
    import FunctionGroupModel = Umbrella.Modules.Knowledgebase.FunctionGroupModel;
    import PresenceResource = Umbrella.IPresenceResource;
    import StatusUpdateModel = Umbrella.Modules.Colleagues.StatusUpdateModel;
    import TaskTypeCategory = Modules.Tasks.TaskTypeCategory;
    import ActivityRegistrationService = Umbrella.CustomerService.CustomerCard.Activities.Registration.ActivityRegistrationService;
    import IContractResource = Modules.Housing.IContractResource;
    import ContractFilterModel = Modules.Housing.ContractFilterModel;

    interface SummarizedColleagueModelWithStatus extends SummarizedColleagueModel {
        status?: Umbrella.Modules.Colleagues.StatusModel;
    }

    export interface TransferPopupControllerScope extends IUmbrellaRootScope {
        colleague: SummarizedColleagueModelWithStatus;
        caseId: System.Guid;
        contractId: System.Guid;
        functionGroups: FunctionGroupModel[];
        functionGroupsDoneLoading: boolean;
        colleaguesWithExpertise: SummarizedColleagueModelWithStatus[];
        contracts: ContractFilterModel[];
        selectedContract: ContractFilterModel;
        selectedDay: Date;
        appointments: AppointmentModel[];
        faq: FaqModel;
        tagId: System.Guid;

        reloadCalendar(day: Date): void;
        setColleague(colleague: SummarizedColleagueModel);
        setPerson(colleague: PersonSearchModel);
        callNr(phoneNr: string): void;
        isCalendarAvailable(): boolean;
        setUnit(value): void;
    }

    @Controller('CustomerService', 'TransferPopupController')
    @Inject(
        '$scope',
        '$mdDialog',
        'PhoneService',
        'CalendarResource',
        'ColleagueResource',
        'CustomerCardStore',
        'PresenceResource',
        'ActivityRegistrationService',
        'ContractResource'
    )
    export class TransferPopupController {
        private nrOfDaysToLoad = 8;
        private presenceObserver: Rx.IDisposable;

        constructor(
            private $scope: TransferPopupControllerScope,
            private $mdDialog,
            phoneService: PhoneService,
            private calendarResource: ICalendarResourceClass,
            private colleagueResource: IColleagueResource,
            private customerCardStore: CustomerCardStore,
            private presenceResource: PresenceResource,
            private activityRegistrationService: ActivityRegistrationService,
            private contractResource: IContractResource
        ) {
            $scope.colleague = null;
            $scope.start = new Date();
            $scope.colleaguesWithExpertise = [];
            $scope.selectedDay = new Date();

            $scope.reloadCalendar = (day: Date) => {
                this.$scope.selectedDay = day;
                if (this.$scope.colleague) this.loadCalendar(this.$scope.colleague.id, day, this.nrOfDaysToLoad);
            };

            $scope.setColleague = colleague => {
                if (!colleague) return;

                $scope.colleague = { ...colleague, status: null };
                this.loadPresence([colleague]);
                this.loadCalendar(colleague.id, new Date().midnight(), this.nrOfDaysToLoad);
                this.loadFunctionGroups(colleague.id);
            };

            $scope.setPerson = person => {
                if (!person) return;
                const colleague: SummarizedColleagueModel = {
                    department: person.department,
                    id: person.roles['employee'],
                    personId: person.id,
                    job: person.jobTitle,
                    name: person.name || `${person['firtsName']} ${person['lastName']}`,
                    email: Umbrella.Modules.Colleagues.getColleagueEmail(person.contactDetails.emailAddresses),
                    phoneNumber:
                        person.contactDetails.phoneNumbers.length > 0
                            ? person.contactDetails.phoneNumbers[0].number
                            : '',
                    phoneNumber2:
                        person.contactDetails.phoneNumbers.length > 1
                            ? person.contactDetails.phoneNumbers[1].number
                            : '',
                    function: '',
                    pictureBlobId: null
                };
                $scope.colleague = { ...colleague, status: null };
                this.loadPresence([colleague]);
                this.loadCalendar(colleague.id, new Date().midnight(), this.nrOfDaysToLoad);
                this.loadFunctionGroups(colleague.id);
            };

            $scope.callNr = (phoneNr: string) => {
                phoneService.callNr(phoneNr);
                $scope.$modalCancel();
            };

            $scope.isCalendarAvailable = () => {
                return this.isCalendarFacetAvailable();
            };

            $scope.launchTaskRegistrationPopup = () => {
                const faqId = (this.$scope.faq && this.$scope.faq.id) || '';
                const tagId = this.$scope.tagId || '';
                const caseId = this.$scope.caseId || '';
                const contractId = this.$scope.contractId || '';

                this.customerCardStore.state$
                    .map(x => x && x.personal && x.personal.person && x.personal.person.id)
                    .filter(x => !!x)
                    .take(1)
                    .subscribe((personId: System.Guid) => {
                        const firstFunctionGroup =
                            $scope.functionGroups && $scope.functionGroups.length && $scope.functionGroups[0];
                        const template = `<register-task-popup person-id="${personId}" faq-id="${faqId}" tag-id="${tagId}" case-id="${caseId}" contract-id="${contractId}" assign-to="assignTo" task-type="${
                            TaskTypeCategory.Terugbellen
                        }" channel-type="${this.activityRegistrationService.getSelectedChannelType()}"></register-task-popup>`;
                        this.$mdDialog.show({
                            template,
                            locals: {
                                assignTo: firstFunctionGroup || $scope.colleague
                            },
                            controller: [
                                '$scope',
                                'assignTo',
                                ($scope, assignTo) => {
                                    $scope.assignTo = assignTo;
                                }
                            ],
                            targetEvent: null,
                            clickOutsideToClose: false
                        });

                        $scope.$modalCancel();
                    });
            };

            $scope.selectContract = (contract: ContractFilterModel) => {
                const tagId = this.$scope.tagId || (this.$scope.faq && this.$scope.faq.mainTag.id);
                const unitId = contract && contract.unitId;
                this.loadColleaguesWithExpertise(tagId, unitId);
            };

            $scope.setDirectiveFunction = setHeightColleagueColumn => {
                $scope.setHeightColleagueColumn = setHeightColleagueColumn;
            };

            this.initialize();
        }

        public $onDestroy() {
            if (this.presenceObserver) {
                this.presenceObserver.dispose();
                this.presenceObserver = null;
            }
        }

        private initialize(): void {
            if (this.$scope.customerId) this.loadContractsAndDetermineExpertiseByFirstContract(this.$scope.customerId);
            else {
                const tagId = this.$scope.tagId || (this.$scope.faq && this.$scope.faq.mainTag.id);
                this.loadColleaguesWithExpertise(tagId);
            }

            this.observePresence();
        }

        private observePresence(): void {
            this.presenceObserver = colleagueStatusUpdated$
                .filter(x => !!x)
                .distinctUntilChanged()
                .subscribe(update => {
                    if (this.$scope.colleague && this.$scope.colleague.personId === update.userId)
                        this.$scope.colleague.status = update.status;

                    const colleague = this.$scope.colleaguesWithExpertise.filter(x => x.personId === update.userId)[0];
                    if (colleague) colleague.status = update.status;
                });
        }

        private isCalendarFacetAvailable(): boolean {
            const isFacetAvailable =
                window.config &&
                window.config.facets &&
                window.config.facets.calendar &&
                window.config.facets.calendar[0] &&
                window.config.facets.calendar[0].available;
            return isFacetAvailable;
        }

        private loadCalendar(colleagueId: System.Guid, day: Date, nrOfDaysToLoad: number) {
            if (!this.isCalendarFacetAvailable() || !day) return;

            this.$scope.appointments = null;
            this.$scope.loading = true;
            this.$scope.selectedDay = day.midnight();

            this.calendarResource
                .getAppointmentsForDay({
                    id: colleagueId,
                    day: this.$scope.selectedDay,
                    days: nrOfDaysToLoad
                })
                .$promise.then(data => {
                    const convertStringsToDates = (a: AppointmentModel) =>
                        <AppointmentModel>{
                            ...a,
                            startTime: new Date(a.startTime),
                            endTime: new Date(a.endTime)
                        };
                    this.$scope.appointments = data.map(convertStringsToDates);
                })
                .finally(() => (this.$scope.loading = false));
        }

        private loadFunctionGroups(colleagueId: System.Guid): void {
            this.colleagueResource.getById({ id: colleagueId }).$promise.then(colleague => {
                this.$scope.functionGroups = colleague.functiongroups;
                this.$scope.functionGroupsDoneLoading = !this.$scope.functionGroupsDoneLoading;
            });
        }

        private loadColleaguesWithExpertise(tagId: System.Guid, unitId?: System.Guid): void {
            if (!tagId) return;

            const promise = unitId
                ? this.colleagueResource.getColleaguesByTagAndUnitId({
                      id: tagId,
                      unitId,
                      pageSize: 20,
                      page: 0
                  }).$promise
                : this.colleagueResource.getColleaguesByTagId({
                      id: tagId,
                      pageSize: 20,
                      page: 0
                  }).$promise;

            promise.then((colleagues: SummarizedColleagueModel[]) => {
                this.$scope.colleaguesWithExpertise = colleagues;
                if (colleagues.length) this.$scope.setColleague(colleagues[0]);

                this.loadPresence(colleagues);
            });
        }

        private loadPresence(colleagues: SummarizedColleagueModel[]): void {
            if (!colleagues || !colleagues.length) return;

            this.presenceResource
                .getStatusByIds({ ids: colleagues.map(x => x.personId) })
                .$promise.then(colleaguePhoneStatuses =>
                    this.mapPhoneStatusesToColleagues(colleaguePhoneStatuses, colleagues)
                );
        }

        private mapPhoneStatusesToColleagues(
            colleaguePhoneStatuses: StatusUpdateModel[],
            colleagues: SummarizedColleagueModelWithStatus[]
        ) {
            colleagues.forEach(colleague => {
                const foundStatus = colleaguePhoneStatuses.filter(x => x.userId === colleague.personId)[0];
                if (foundStatus) colleague.status = foundStatus.status;
            });
        }

        private async loadContractsAndDetermineExpertiseByFirstContract(customerId: System.Guid): Promise<void> {
            const data = await this.contractResource.getAllFilterValuesByRoleId({
                id: customerId
            }).$promise;
            this.$scope.contracts = data;
            this.$scope.selectedContract = data && data[0];

            const tagId = this.$scope.faq && this.$scope.faq.mainTag.id;
            const unitId = this.$scope.selectedContract && this.$scope.selectedContract.unitId;
            this.loadColleaguesWithExpertise(tagId, unitId);
        }
    }
}
