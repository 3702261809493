﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../ComponentRouteUtils.ts" />
/// <reference path="../../../Window.d.ts"/>
/// <reference path="../../customerservice.routeconfig.ts" />

namespace Umbrella.CustomerService.CustomerCard.Bills {
    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('customerservice.customercard.bills', {
                url: '/financieel',
                reloadOnSearch: false,
                data: {
                    tab: 'bills',
                    authorize: ps => ps.customerCard && ps.billRead
                },
                onEnter: [
                    '$stateParams',
                    'CustomerCardBillInfoService',
                    'BillJitService',
                    'CustomerCardStore',
                    (
                        $stateParams: CardStateParams,
                        billInfoService: CustomerCardBillInfoService,
                        billJitService: BillJitService,
                        customerCardStore: CustomerCardStore
                    ) => {
                        billInfoService.ensureLoaded();
                    }
                ],
                views: {
                    'tab@customerservice': {
                        template:
                            '<customer-bills state="state$ | async:this"></customer-bills>',
                        controller: Umbrella.createComponentRouteController<
                            CustomerCardState,
                            CustomerBillsComponentState
                        >('CustomerCardStore', s => ({
                            loading: s.billInfo && s.billInfo.loading &&
                            (s.registrationInfo &&
                                s.registrationInfo.loading),
                            person: s.personal && s.personal.person,
                            customer:
                                s && s.customerInfo && s.customerInfo.customer,
                            billInfo: s.billInfo,
                            registrationInfo:
                                s.registrationInfo &&
                                s.registrationInfo.registrations
                        }))
                    },
                    'left@customerservice': {
                        template:
                            '<customer-bill-filters state="state$ | async:this"></customer-bill-filters>',
                        controller: Umbrella.createComponentRouteController<
                            CustomerCardState,
                            CustomerBillFiltersComponentState
                        >('CustomerCardStore', s => ({
                            person: s.personal.person
                        }))
                    }
                }
            });
        }
    ]);
}
