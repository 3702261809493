namespace Umbrella.KCC.CaseFlowDashboard {
    @Pipe('Dashboard', 'canCancelCase')
    class CanCancelCaseFilter {
        public transform(c: Umbrella.CaseFlow.CaseFlowModel) {
            if (!c) return false;

            return (
                c.status === Umbrella.CaseFlow.CaseFlowStatus.Submitted ||
                <any>c.status === 'Submitted' ||
                c.status === Umbrella.CaseFlow.CaseFlowStatus.PickedUp ||
                <any>c.status === 'PickedUp'
            );
        }
    }
}
