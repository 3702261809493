namespace Umbrella.CustomerService {
    import ActivityModel = Modules.ActivityModel;

    @Pipe('CustomerService', 'activityIcon')
    class ActivityIconFilter {
        public transform(activity: ActivityModel): string {
            return activity.type;
        }
    }
}
