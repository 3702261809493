/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../Scope.d.ts"/>

namespace Umbrella.CustomerService {
    import WorkAreaModel = Umbrella.Modules.SelfService.Models.WorkAreaModel;

    export interface IWorkAreaResource
        extends angular.resource.IResource<WorkAreaModel.ContactPerson> {}

    export interface IWorkAreaResourceClass
        extends angular.resource.IResourceClass<WorkAreaModel.ContactPerson> {
        getAllForResidentialZone(params: any): WorkAreaModel.ContactPerson[];
        getAllForResidentialZones(
            params: any
        ): angular.resource.IResource<{
            [id: string]: WorkAreaModel.ContactPerson[];
        }>;
    }

    const resource = $resource => {
        return $resource(
            '/api/v1/workarea/:action/:id',
            {},
            {
                getAllForResidentialZone: {
                    method: 'GET',
                    isArray: true,
                    params: { action: 'allforresidentialzone', id: '' }
                },
                getAllForResidentialZones: {
                    method: 'GET',
                    isArray: false,
                    params: { action: 'allforresidentialzones', ids: '' }
                }
            }
        );
    };

    angular
        .module('SelfService')
        .service('WorkAreaResource', ['$resource', resource]);
}
