namespace Umbrella.CustomerService {
    import ICustomerResource = Umbrella.Modules.Customers.ICustomerResource;
    import IOnboardingResource = Umbrella.Modules.Onboarding.IOnboardingResource;
    import CreateCustomerModel = Umbrella.Modules.Customers.CreateCustomerModel;
    import RelationModel = Umbrella.Onboarding.relationModel;
    import Gender = Umbrella.Modules.Gender;
    import GetEnumValueAsNumber = Umbrella.Helpers.GetEnumValueAsNumber;

    @Component('CustomerService', {
        selector: 'relation-popup',
        templateUrl: '/Top/RelationPopup.html',
        bindings: {
            model: '<person'
        }
    })
    @Inject('$mdDialog', 'CustomerResource', 'EmptyGuid', 'ToastMessageService', 'OnboardingResource')
    class RelationPopupComponent {
        public model: CreateCustomerModel;
        public relationModel: RelationModel;

        public relationNumber: string;

        constructor(
            private $mdDialog,
            private customerResource: ICustomerResource,
            private emptyGuid: System.Guid,
            private toastMessageService: ToastMessageService,
            private onboardingResource: IOnboardingResource
        ) {}

        public $onInit() {
            if (!this.model) {
                this.model = {
                    customerId: this.emptyGuid,
                    email: '',
                    firstName: '',
                    gender: null,
                    insertion: '',
                    lastName: '',
                    phoneNumber: '',
                    externalId: null,
                    agentInfoId: this.emptyGuid
                };
            }

            this.relationNumber = '';
        }

        public getRelation(): void {
            if (this.relationNumber && this.relationNumber.length > 0) {
                this.onboardingResource
                    .getRelationByExternalId({
                        externalId: this.relationNumber
                    })
                    .$promise.then(data => {
                        this.relationModel = data;
                        this.copyData();
                    })
                    .catch(e => {
                        if (e.status === 409) {
                            this.customerResource
                                .getById({ id: e.data as System.Guid })
                                .$promise.then(customer => {
                                    this.$mdDialog.hide(customer);
                                })
                                .finally(() => {
                                    this.toastMessageService.info('Deze relatie is reeds bekend');
                                });
                        } else if (e.status === 404) {
                            this.toastMessageService.error('Geen gegevens gevonden voor dit relatienummer');
                        } else {
                            this.toastMessageService.error('Fout opgetreden tijdens ophalen gegevens');
                        }
                    });
            }
        }

        public cancel(): void {
            this.$mdDialog.cancel();
        }

        public add(): void {
            this.model.gender = GetEnumValueAsNumber(Gender, this.model.gender || Gender.Unknown);

            this.customerResource
                .add(this.model)
                .$promise.then(customer => {
                    this.$mdDialog.hide(customer);
                })
                .catch(e => {
                    this.toastMessageService.error('Aanmaken nieuwe relatie mislukt');
                });
        }

        private copyData(): void {
            this.model = {
                customerId: this.emptyGuid,
                email: this.relationModel.email,
                firstName: this.relationModel.firstName,
                gender: this.relationModel.gender,
                insertion: this.relationModel.insertion,
                lastName: this.relationModel.lastName,
                phoneNumber: this.relationModel.phoneNumber,
                externalId: this.relationModel.externalId,
                agentInfoId: this.relationModel.agentInfoId
            };
        }
    }
}
