﻿/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../TaskOverviewService.ts"/>

namespace Umbrella.TaskHandling {
    angular.module('TaskHandling').config([
        '$stateProvider',
        $stateProvider => {
            $stateProvider.state(
                'dashboard.tasks.overview.details.linkedtasks',
                {
                    url: '/koppelingen',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Gekoppelde taken',
                        authorize: ps => ps.viewTask || ps.editTask
                    },
                    views: {
                        tabcontent: {
                            template:
                                '<linked-tasks state="state$ | async:this"></linked-tasks>',
                            controller: Umbrella.createComponentRouteController<
                                Overview.State,
                                LinkedTasksComponentState
                            >('TaskOverviewStore', s => ({
                                task: s && s.selectedTask,
                                linkedTasks: s && s.linkedTasks
                            }))
                        }
                    }
                }
            );
        }
    ]);
}
