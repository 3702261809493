/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Decorators.ts" />

namespace Umbrella.Modules.Sandbox {
    @Component('Sandbox', {
        selector: 'sandbox-tabs',
        templateUrl: '/Sandbox/SandboxTabsComponent/SandboxTabs.html'
    })
    class SandboxMenuComponent {
        public tabs: IMenuTab[];

        constructor() {
            this.tabs = [];

            this.tabs.push(
                {
                    title: 'Dashboard',
                    state: 'sandbox.dashboard'
                },
                {
                    title: 'Customer Service',
                    state: 'sandbox.customerservice'
                }
            );
        }
    }
}
