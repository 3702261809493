/// <reference path="Scripts/TypeScript/rxjs/rx.lite.es6.d.ts"/>

namespace Umbrella.ObservableStore {
    export type Reducer<T> = (currentState: T) => T;

    function reduce<T>(currentState: T, reduce: Reducer<T>): T {
        return reduce(currentState);
    }

    export interface Lens<TStore, T> {
        get(state: TStore): T;
        set(state: TStore, prop: T): TStore;
    }

    interface InternalStore<T> {
        readonly state$: Rx.Observable<T>;
        getState(): T;
        readonly reducer$: Rx.Observer<Reducer<T>>;
        map<TOut>(lens: Lens<T, TOut>): InternalStore<TOut>;
    }

    function mapStore<TStore, T>(
        store: InternalStore<TStore>,
        lens: Lens<TStore, T>
    ): InternalStore<T> {
        const subState$ = store.state$
            .map(x => lens.get(x))
            .distinctUntilChanged();

        const subReduce$ = new Rx.Subject<Reducer<T>>();

        function transform(reduceSub) {
            function subReduce(currentState: TStore) {
                const currentSubState = lens.get(currentState);
                const newSubState = reduceSub(currentSubState);
                return lens.set(currentState, newSubState);
            }

            store.reducer$.onNext(subReduce);
        }

        subReduce$.subscribe(transform);

        const mappedStore = {
            state$: subState$,
            reducer$: subReduce$,
            getState: () => lens.get(store.getState()),
            map: lens => mapStore(mappedStore, lens)
        };

        return mappedStore;
    }

    function createStore<T>(initialState: T): InternalStore<T> {
        const state$ = new Rx.BehaviorSubject(initialState);

        const store = {
            state$,
            reducer$: new Rx.Subject<Reducer<T>>(),
            getState: () => state$.getValue(),
            map: lens => mapStore(store, lens)
        };

        store.reducer$.subscribe(reducer => {
            const newState = reduce(store.state$.getValue(), reducer);
            store.state$.onNext(newState);
        });

        return store;
    }

    export type EventHandler<TState, TEvent> = (
        event: TEvent
    ) => Reducer<TState>;

    export interface Store<TState> {
        readonly state$: Rx.Observable<TState>;
        getState(): TState;
    }

    export interface EventStore<TState, TEvent> extends Store<TState> {
        readonly event$: Rx.Subject<TEvent>;
        map<TMapState, TMapEvent>(
            lens: Lens<TState, TMapState>,
            handler: EventHandler<TMapState, TMapEvent>
        ): EventStore<TMapState, TMapEvent>;
    }

    function createEventSubject<TState, TEvent>(
        reducer$: Rx.Observer<Reducer<TState>>,
        handler: EventHandler<TState, TEvent>
    ): Rx.Subject<TEvent> {
        const event$ = new Rx.Subject<TEvent>();

        event$.subscribe(e => {
            const reduce = handler(e);
            reducer$.onNext(reduce);
        });

        return event$;
    }

    function map<TState, TMapState, TMapEvent>(
        store: InternalStore<TState>,
        lens: Lens<TState, TMapState>,
        handler: EventHandler<TMapState, TMapEvent>
    ): EventStore<TMapState, TMapEvent> {
        const mappedStore = store.map(lens);
        const event$ = createEventSubject<TMapState, TMapEvent>(
            mappedStore.reducer$,
            handler
        );

        return {
            state$: mappedStore.state$,
            event$,
            getState: () => mappedStore.getState(),
            map: (lens, handler) => map(mappedStore, lens, handler)
        };
    }

    export function create<TState, TEvent>(
        initialState: TState,
        handler: EventHandler<TState, TEvent>
    ): EventStore<TState, TEvent> {
        const store = createStore<TState>(initialState);
        const event$ = createEventSubject<TState, TEvent>(
            store.reducer$,
            handler
        );

        return {
            state$: store.state$,
            event$,
            getState: () => store.getState(),
            map: (lens, handler) => map(store, lens, handler)
        };
    }
}
