namespace Umbrella.KCC.CaseFlowDashboard {
    @Pipe('Dashboard', 'isCaseActive')
    class IsCaseActiveFilter {
        public transform(
            status: Umbrella.CaseFlow.CaseFlowStatus | string | number
        ) {
            switch (status) {
                case Umbrella.CaseFlow.CaseFlowStatus.PickedUp:
                case 'PickedUp':
                case 0:
                    return true;
                default:
                    return false;
            }
        }
    }
}
