namespace Umbrella.Modules {
    @Component('Umbrella', {
        selector: 'undelivered-message',
        templateUrl: '/Modules/Umbrella/UndeliveredMessage.html',
        bindings: {
            message: '<'
        }
    })
    @Inject('$scope', '$timeout', 'UndeliveredMessageResource')
    class UndeliveredMessageComponent {
        message: Modules.Monitoring.Messaging.UndeliveredMessageModel;
        pollPromise: ng.IPromise<void>;

        constructor(private $scope, private $timeout, private resource) {
            $scope.$on('$destroy', () => {
                if (this.pollPromise) {
                    $timeout.cancel(this.pollPromise);
                }
            });

            this.updateScope(this.message);
        }

        private retry(id) {
            this.$scope.waiting = true;
            this.resource
                .retry({ id })
                .$promise.then(item => {
                    this.updateScope(item);
                    this.pollPromise = this.poll(id);
                })
                .finally(() => (this.$scope.waiting = false));
        }

        private getConsumer(queueName) {
            return queueName.split('_')[3];
        }

        private getMessageType(queueName) {
            if (!queueName) return '';

            let fullName = queueName.split('_')[1].split(':')[0];
            let parts = fullName.split('.');
            return parts[parts.length - 1];
        }

        private agentStatus(agent) {
            if (!agent.alive) {
                return { text: 'Offline', class: 'red' };
            } else if (!agent.healthy) {
                return { text: 'Problematisch', class: 'red' };
            }

            return { text: 'Online', class: 'green' };
        }

        private poll(id) {
            return this.$timeout(() => {
                this.fetch(id);
                if (this.$scope.status === 'Retrying') {
                    this.poll(id);
                }
            }, 1000);
        }

        private updateScope(item) {
            let metaData = item.message.metaData;
            let agent = window.config.agents.find(
                i => i.name === (metaData && metaData.application)
            );

            let statusText = '?';
            let retryButtonTitle = '?';
            let retryable = false;

            switch (item.status) {
                case 'Failed':
                    statusText = 'Foutief';
                    retryButtonTitle = 'Opnieuw';
                    retryable = window.user.permissions.serviceBusErrorRetry;
                    break;

                case 'Retrying':
                    statusText = 'Wordt opnieuw verwerkt...';
                    retryButtonTitle = 'Bezig...';
                    break;

                case 'Resolved':
                    statusText = 'Verwerkt';
                    retryButtonTitle = 'Opgelost';
                    break;
            }

            angular.extend(this.$scope, {
                application: metaData.application,
                agent: agent && {
                    name: agent.name,
                    status: this.agentStatus(agent)
                },
                consumer: this.getConsumer(item.message.queueName),
                messageType: this.getMessageType(item.message.queueName),
                lastAttempt: item.faultTime,
                errorMessage: item.exception,
                retryable,
                resolvedTime: item.resolvedTime,
                retry: () => {
                    this.retry(item.id);
                },
                statusText,
                retryButtonTitle,
                messageBody: item.message.body,
                status: item.status,
                metaData: item.message.metaData
            });
        }

        private fetch(id) {
            this.resource
                .getById({ id })
                .$promise.then(item => {
                    this.updateScope(item);
                })
                .catch(error => {
                    this.$scope.errors = [error];
                });
        }
    }
}
