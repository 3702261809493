﻿namespace Umbrella.Modules.Housing {
    import IResource = ng.resource.IResource;
    import Guid = System.Guid;

    export interface ISearchParams extends PagedRequestParams {
        query?: string;
    }

    export interface IGetByIdsParams {
        ids: Guid[];
    }

    export interface IResidentialZoneResourceClass {
        getByIds(params: IGetByIdsParams): IResource<ResidentialZoneModel[]>;
        search(params: ISearchParams): IResource<ResidentialZoneModel[]>;
    }

    angular.module('Housing').service('ResidentialZoneResource', [
        '$resource',
        ($resource: ng.resource.IResourceService) =>
            $resource(
                '/api/v1/residentialzone/:action/:id',
                {},
                {
                    getByIds: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'set' }
                    },
                    search: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'search', id: '' }
                    }
                }
            )
    ]);
}
