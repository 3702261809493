/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />
/// <reference path="../../../Modules/SelfService/Appointments/Appointment.d.ts"/>

namespace Umbrella.Modules.SelfService.Appointments {
    import Guid = System.Guid;
    import AppointmentManagementModel = Umbrella.Modules.SelfService.Appointment.AppointmentManagementModel;

    export interface IAppointmentRequestParams {
        withSelfServiceReport: boolean;
        fromDate: string;
        toDate: string;
    }
    export interface AppointmentResource
        extends angular.resource.IResource<AppointmentManagementModel> {}
    export interface AppointmentResourceClass
        extends angular.resource.IResourceClass<AppointmentResource> {
        getAppointments(
            params: IAppointmentRequestParams
        ): ng.resource.IResource<PagedItemsModel<AppointmentManagementModel>>;
        getById(params: IdRequestParams): AppointmentResource;
    }
    angular.module('SelfService').service('AppointmentResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                'api/v1/appointment/:action/:id',
                {},
                {
                    getById: {
                        method: 'Get',
                        isArray: false,
                        params: { action: '' }
                    },
                    getAppointments: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'paged' }
                    }
                }
            )
    ]);
}
