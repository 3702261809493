﻿namespace Umbrella.CustomerService.CustomerCard.Activities.Registration {
    import KnowledgebaseItemModel = Modules.Knowledgebase.KnowledgebaseItemModel;
    import IKnowledgebaseItemResource = Modules.Knowledgebase.IKnowledgebaseItemResource;

    interface IBindingChange {
        currentValue: any;
        previousValue: any;
        isFirstChange(): boolean;
    }

    @Component('CustomerService', {
        selector: 'thesaurus-tag-media',
        bindings: {
            tagId: '<'
        },
        templateUrl:
            '/CustomerService/_CustomerCard/_Activities/Registration/ThesaurusTagMediaComponent/ThesaurusTagMedia.html'
    })
    @Inject('KnowledgebaseItemResource')
    class ThesaurusTagMediaComponent {
        public knowledgebaseItem: KnowledgebaseItemModel.WithMedia;
        private tagId: System.Guid;
        public mediaLoading: boolean;

        constructor(
            protected knowledgebaseItemResource: IKnowledgebaseItemResource
        ) {}

        private $onInit() {
            this.loadMediaItems(this.tagId);
        }

        private loadMediaItems = tagId => {
            if (tagId) {
                this.mediaLoading = true;

                this.knowledgebaseItemResource
                    .getByTagId({ id: tagId })
                    .$promise.then(data => {
                        this.knowledgebaseItem = data;
                    })
                    .finally(() => {
                        this.mediaLoading = false;
                    });
            } else {
                this.knowledgebaseItem = null;
            }
        };

        public $onChanges(bindings: { tagId: IBindingChange }) {
            if (
                bindings.tagId &&
                bindings.tagId.currentValue !== bindings.tagId.previousValue
            ) {
                this.loadMediaItems(bindings.tagId.currentValue);
            }
        }
    }
}
