namespace Umbrella.CustomerService.CustomerCard.Impersonation {
    import SelfServiceScenarioResource = Umbrella.CustomerService.SelfServiceScenarioResource;
    import ImpersonationService = CustomerService.CustomerCard.ImpersonationService;
    import ImpersonationResponseModel = Modules.SelfService.ImpersonationResponseModel;

    @Component('CustomerService', {
        selector: 'impersonation-window',
        templateUrl:
            '/CustomerService/_CustomerCard/_Impersonation/ImpersonationWindowComponent/ImpersonationWindow.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('SelfServiceScenarioResource', 'ImpersonationService', '$stateParams')
    export class ImpersonationWindowComponent {
        public state: ImpersonationWindowState;

        constructor(
            private selfServiceScenarioResource: SelfServiceScenarioResource,
            private impersonationService: ImpersonationService,
            private $stateParams: ImpersonationWindowState
        ) {}

        public $onInit() {
            const scenarioId = this.$stateParams.scenarioId || this.state.scenarioId;
            const personId = this.state.personId || this.$stateParams.personId;
            const channelType = this.$stateParams.channel || this.state.channel;
            const caseId = this.$stateParams.caseId || this.state.caseId;

            this.selfServiceScenarioResource
                .impersonate({
                    id: scenarioId,
                    personId,
                    channelType: channelType.toString(),
                    caseId
                })
                .$promise.then(response => this.startImpersonationSession(response));
        }

        private startImpersonationSession(response: ImpersonationResponseModel) {
            const selfServiceForm = <HTMLFormElement>document.getElementById('selfServiceIFrameForm');

            if (selfServiceForm) {
                this.impersonationService.setPortalUrl(new URL(response.url.toString()).origin);
                selfServiceForm.action = response.url.toString();
                selfServiceForm.data.value = response.packet;
                selfServiceForm.submit();
            }
        }
    }
}
