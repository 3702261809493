namespace Umbrella.CustomerService.CustomerCard {
    import AnnouncementModel = Modules.Announcements.AnnouncementModel;
    import PersonAnnouncementResourceClass = Umbrella.Modules.Announcements.PersonAnnouncementResourceClass;
    import AnnouncementResourceClass = Umbrella.Modules.Announcements.AnnouncementResourceClass;

    const PAGE_SIZE: number = 20;

    @Service('CustomerService', 'CustomerCardAnnouncementInfoService')
    @Inject(
        'CustomerCardStore',
        'PersonAnnouncementResource',
        'AnnouncementResource'
    )
    export class CustomerCardAnnouncementInfoService extends BaseStoreService<
        CustomerCardState,
        CustomerCardEvent,
        CustomerCardStore
    > {
        constructor(
            store: CustomerCardStore,
            private personAnnouncementResource: PersonAnnouncementResourceClass,
            private announcementResource: AnnouncementResourceClass
        ) {
            super(store);
        }

        public ensureLoaded(pageSize = PAGE_SIZE): void {
            if (!this.getState().announcementInfo)
                this.loadPage(
                    0,
                    pageSize,
                    null,
                    announcements => announcements
                );
        }

        public reload(): void {
            const state = this.getState();
            const pageSize =
                (state.announcementInfo &&
                    state.announcementInfo.announcements &&
                    state.announcementInfo.announcements.pageSize) ||
                PAGE_SIZE;
            const contractId =
                state.announcementInfo && state.announcementInfo.contractId;

            this.loadPage(
                0,
                pageSize,
                contractId,
                announcements => announcements
            );
        }

        public loadMore(): void {
            const state = this.store.getState();
            const pagedItems =
                state.announcementInfo && state.announcementInfo.announcements;
            const contractId =
                state.announcementInfo && state.announcementInfo.contractId;

            this.loadPage(
                pagedItems.page + 1,
                pagedItems.pageSize,
                contractId,
                announcements => ({
                    items: pagedItems
                        ? pagedItems.items.concat(announcements.items)
                        : announcements.items,
                    total: announcements.total,
                    page: announcements.page,
                    pageSize: announcements.pageSize
                })
            );
        }

        public filterByContractId(contractId: System.Guid): void {
            const state = this.store.getState();
            const pageSize =
                (state.announcementInfo &&
                    state.announcementInfo.announcements &&
                    state.announcementInfo.announcements.pageSize) ||
                PAGE_SIZE;

            this.loadPage(
                0,
                pageSize,
                contractId,
                announcements => announcements
            );
        }

        private loadPage(
            page: number,
            pageSize: number,
            contractId: System.Guid,
            loaded: ((
                a: PagedItemsModel<AnnouncementModel>
            ) => PagedItemsModel<AnnouncementModel>)
        ) {
            const state = this.getState();
            if (state.personal.person) {
                this.emit({
                    type: 'CustomerCardAnnouncementsLoadingEvent',
                    page,
                    pageSize,
                    contractId
                });

                let request;
                if (contractId) {
                    request = this.announcementResource.byContractId({
                        id: contractId,
                        page,
                        pageSize
                    }).$promise;
                } else {
                    request = this.personAnnouncementResource.getPagedByPersonId(
                        { id: state.personal.person.id, page, pageSize }
                    ).$promise;
                }

                request
                    .then(announcements => {
                        const newAnnouncements = loaded(announcements);
                        this.emit({
                            type: 'CustomerCardAnnouncementsLoadedEvent',
                            announcements: newAnnouncements,
                            contractId
                        });
                    })
                    .catch(e =>
                        this.emit({
                            type: 'CustomerCardAnnouncementsLoadErrorEvent',
                            error: e
                        })
                    );
            } else {
                this.store.state$
                    .map(x => x && x.personal)
                    .filter(
                        personal => !personal.loading && !personal.loadError
                    )
                    .take(1)
                    .subscribeOnCompleted(() =>
                        this.loadPage(page, pageSize, contractId, loaded)
                    );
            }
        }
    }
}
