/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.CustomerService.CustomerCard {
    angular.module('Umbrella').directive('stickyElements', [
        '$window',
        $window => {
            return {
                link(scope, element) {
                    const mainEl = <HTMLElement>document.getElementById('main');
                    const heightTopBar = getHeightTopBar();
                    let viewportHeight = getViewportHeight();
                    let itemHeaderHeight: number;

                    angular.element($window).bind('resize', () => {
                        viewportHeight = getViewportHeight();
                    });

                    mainEl.addEventListener('scroll', () => {
                        if (element[0].classList.contains('collapsed')) {
                            const elementPosition = element[0].getBoundingClientRect();
                            const elementContainsStickyFooterClass = element[0].classList.contains('sticky-footer');
                            const elementContainsStickyHeaderClass = element[0].classList.contains('sticky-header');

                            if (
                                !isTopElementInViewport(elementPosition) &&
                                isBodyElementInViewport(elementPosition) &&
                                !elementContainsStickyHeaderClass
                            ) {
                                element[0].classList.add('sticky-header');
                            }

                            if (isTopElementInViewport(elementPosition) && elementContainsStickyHeaderClass) {
                                element[0].classList.remove('sticky-header');
                            }

                            const itemHeaderEl = document.getElementsByClassName('activity-header');
                            if (!itemHeaderEl || itemHeaderEl.length < 1) return;

                            itemHeaderHeight = document.getElementsByClassName('activity-header')[0].clientHeight;

                            if (!isBodyElementInViewport(elementPosition) && elementContainsStickyHeaderClass) {
                                element[0].classList.remove('sticky-header');
                            }

                            if (
                                isBodyElementInViewport(elementPosition) &&
                                !isBottomElementInViewport(elementPosition) &&
                                !elementContainsStickyFooterClass
                            ) {
                                element[0].classList.add('sticky-footer');
                            }

                            if (
                                isBodyElementInViewport(elementPosition) &&
                                isBottomElementInViewport(elementPosition) &&
                                elementContainsStickyFooterClass
                            ) {
                                element[0].classList.remove('sticky-footer');
                            }

                            if (
                                !isBodyElementInViewport(elementPosition) &&
                                !isBottomElementInViewport(elementPosition) &&
                                elementContainsStickyFooterClass
                            ) {
                                element[0].classList.remove('sticky-footer');
                            }
                        }
                    });

                    function getHeightTopBar() {
                        if (window.device.tablet()) {
                            return 0;
                        }

                        if (document.getElementsByClassName('top-bar')[0].clientHeight > 0) {
                            return document.getElementsByClassName('top-bar')[0].clientHeight;
                        } else {
                            return document.getElementById('responsive-navigation').clientHeight;
                        }
                    }

                    function isTopElementInViewport(elementPosition) {
                        return elementPosition.top <= viewportHeight && elementPosition.top > heightTopBar
                            ? true
                            : false;
                    }

                    function isBottomElementInViewport(elementPosition) {
                        return elementPosition.bottom <= viewportHeight && elementPosition.bottom > heightTopBar
                            ? true
                            : false;
                    }

                    function isBodyElementInViewport(elementPosition) {
                        return (elementPosition.top > heightTopBar + itemHeaderHeight ||
                            elementPosition.bottom > heightTopBar + itemHeaderHeight) &&
                            elementPosition.top < viewportHeight - itemHeaderHeight
                            ? true
                            : false;
                    }

                    function getViewportHeight() {
                        return window.innerHeight || document.documentElement.clientHeight;
                    }
                }
            };
        }
    ]);
}
