namespace Umbrella.CustomerService {
    import PhoneService = Modules.Telephony.PhoneService;
    import StatusModel = Umbrella.Modules.Colleagues.StatusModel;
    import PresenceResource = Umbrella.IPresenceResource;

    @Component('CustomerService', {
        selector: 'profile-with-popup',
        templateUrl:
            'CustomerService/ProfileWithPopupComponent/ProfileWithPopup.html',
        bindings: {
            person: '<',
            personId: '<',
            personName: '@',
            position: '@',
            extended: '<',
            description: '<'
        }
    })
    @Inject('PersonResource', 'PhoneService', 'PresenceResource', '$state')
    export class ProfileWithPopupComponent {
        public person: PersonModel;
        public personName: string;
        public position: string;
        public extended: boolean;
        public description: string;
        public phoneNumbers: Modules.Telephony.ContactPhoneNumberModel[];
        public emailAddresses: EmailAddressModel[];
        public availabilityStatus: StatusModel;
        public isOnSelectedPersonCustomerCard: boolean;
        private personId: Guid;
        private loaded: boolean;

        constructor(
            private personResource: PersonResource,
            private phoneService: PhoneService,
            private presenceResource: PresenceResource,
            private $state: ng.ui.IStateService
        ) {}

        public async openMenu($mdMenu) {
            if (!this.loaded) await this.load();
            $mdMenu.open();
        }

        public callNr(nr: string): void {
            this.phoneService.callNr(nr);
        }

        private async load() {
            if (!this.person && this.personId) {
                this.person = await this.personResource.getById({
                    id: this.personId
                }).$promise;
            }

            this.getPhoneNumbers();
            this.getEmailAddresses();
            this.getPosition();
            await this.getAvailabilityStatus(this.personId);
            this.getSpawnPath();

            this.loaded = true;
        }

        private getPhoneNumbers() {
            const contactDetails = this.person && this.person.contactDetails;
            this.phoneNumbers = contactDetails && contactDetails.phoneNumbers;
        }

        private getEmailAddresses() {
            const contactDetails = this.person && this.person.contactDetails;
            this.emailAddresses =
                contactDetails && contactDetails.emailAddresses;
        }

        private getPosition() {
            switch (this.position) {
                case 'left':
                    this.position = 'right'; // switched because of the way mdMenu works
                    break;
                case 'right':
                    this.position = 'left';
                    break;
                case 'top':
                    this.position = 'top';
                    break;
                default:
                    this.position = 'bottom';
                    break;
            }
        }

        private async getAvailabilityStatus(id: System.Guid): Promise<void> {
            const presence = await this.presenceResource.getStatusByIds({
                ids: [id]
            }).$promise;
            if (!presence || !presence.length) return;

            this.availabilityStatus = presence[0].status;
        }

        private getSpawnPath() {
            const customerCardPath = 'customerservice.customercard';
            const isOnCustomerCard = this.$state.current.name.includes(
                customerCardPath
            );
            const isCustomerSelected =
                this.$state.params['personId'] &&
                this.$state.params['personId'] == this.person.id;

            this.isOnSelectedPersonCustomerCard =
                isOnCustomerCard && isCustomerSelected;
        }
    }
}
