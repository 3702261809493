namespace Umbrella.CustomerService.CustomerCard.Activities {
    @Service('CustomerService', 'ActivityJitService')
    @Inject('CustomerCardStore', 'ActivityHub', 'CustomerCardActivityInfoService', 'ToastMessageService')
    export class ActivityJitService {
        private message: any;

        constructor(
            store: CustomerCardStore,
            private activityHub: IActivityHubProxy,
            customerCardActivityInfoService: CustomerCardActivityInfoService,
            private toastMessageService: ToastMessageService
        ) {
            const getPersonId = (state: CustomerCardState) =>
                state && state.personal && state.personal.person && state.personal.person.id;

            activityHubOnUpdated$
                .filter(personId => getPersonId(store.getState()) === personId)
                .debounce(() => Rx.Observable.timer(5000))
                .subscribe(list => {
                    this.clearToastMessage();
                    customerCardActivityInfoService.reload();
                });

            activityHubOnStarted$
                .filter(personId => getPersonId(store.getState()) === personId)
                .subscribe(() => {
                    if (this.message) {
                        return;
                    }

                    this.message = this.toastMessageService.updating('Tijdlijn gegevens', 60000, {
                        onHidden: () => {
                            this.message = null;
                        }
                    });

                    setTimeout(() => {
                        if (this.message) {
                            this.message = null;
                        }
                    }, 60000);
                });

            store.state$
                .map(getPersonId)
                .distinctUntilChanged()
                .flatMapLatest(Modules.waitUntilSignalRConnected)
                .debounce(() => Rx.Observable.timer(1000))
                .subscribe(personId => this.subscribe(personId));

            store.event$
                .where(e => e.type === 'CustomerCardPersonUnloadedEvent')
                .subscribe(_ => this.unsubscribe(getPersonId(store.getState())));
        }

        private subscribe(personId: Guid) {
            if(personId) {
                this.activityHub.server.subscribe(personId);
            }
            this.clearToastMessage();
        }

        private unsubscribe(personId: Guid) {
            this.clearToastMessage();
            this.activityHub.server.unsubscribe(personId);
        }

        private clearToastMessage() {
            if (this.message) {
                this.toastMessageService.clear(this.message);
                this.message = null;
            }
        }
    }
}
