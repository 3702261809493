/// <reference path="../../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseModel = Umbrella.Modules.Customers.v2.CaseModel;

    export interface CaseFlowContactMomentsComponentState {
        caseHistory: CaseModel;
        person: PersonModel;
        }

    @Component('Dashboard', {
        selector: 'caseflow-contact-moments',
        templateUrl:
            '/CaseFlowProcessing/_Overview/_Details/_ContactMoments/CaseFlowContactMomentsComponent/CaseFlowContactMomentsComponent.html',
        bindings: {
            state: '<'
        }
    })
    @Inject()
    class CaseFlowContactMomentsComponent {
        public state: CaseFlowContactMomentsComponentState;

    }
}
