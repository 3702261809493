namespace Umbrella.ChatConversationHandling {
    import customerCardStore = Umbrella.CustomerService.CustomerCard.customerCardStore;
    import Emojifier = Umbrella.ChatConversationHandling.emojifier;

    @Component('Chat', {
        selector: 'chat-conversation-modal',
        templateUrl: '/Modules/Chat/ChatConversationModal/ChatConversationModal.html'
    })
    @Inject('$state', 'ChatService', 'ChatStore')
    export class ChatConversationModalComponent {
        public defaultMessages: Umbrella.Chat.ChatDefaultMessageModel[];
        public newMessage: string;
        public allowedEmoticons;
        public showNotification: boolean;
        public toggleMessages: boolean;
        public notification: string;
        public disableMessageSending: boolean;
        public showSmileys: boolean;
        public conversation: any;
        public operatorAvatarUrl: string;
        public showOperatorAvatar: boolean;
        public person: PersonModel;
        public contact: string;
        public chatType: string;

        private endChatButtonDisabled: boolean;
        private activeConversationObserver: Rx.IDisposable;
        private settingsObserver: Rx.Disposable;
        private personObserver: Rx.IDisposable;
        private selectedAnswerObserver: Rx.IDisposable;

        constructor(
            private $state: ng.ui.IStateService,
            private chatService: ChatService,
            private chatStore: ChatStore
        ) {}

        $onInit() {
            this.allowedEmoticons = allowedEmoticons.filter(e => e !== ':p' && e !== ':d' && e !== ':o');
            this.observeActiveConversation();
            this.observeCurrentlyLoadedCustomerCard();
            this.observeSelectedAnswers();
        }

        $onDestroy() {
            if (this.activeConversationObserver) this.activeConversationObserver.dispose();
            if (this.settingsObserver) this.settingsObserver.dispose();
            if (this.personObserver) this.personObserver.dispose();
            if (this.selectedAnswerObserver) this.selectedAnswerObserver.dispose();
        }

        minimize = (conversation: ChatConversation) => {
            this.chatService.closeConversation(conversation);
        };

        confirmCloseConversation = (id: Guid) => {
            if (this.endChatButtonDisabled) {
                return;
            }
            this.chatService.endAfterCallWork(id);
            this.endChatButtonDisabled = true;
            setTimeout(() => {
                this.endChatButtonDisabled = false;
            }, 1000);
        };

        sendMessage = (conversationId: string) => {
            if (this.newMessage) {
                this.chatService.sendMessage(conversationId, this.newMessage);
                this.newMessage = '';
            }
        };

        startTyping = params => {
            const event = params.event;
            if (event.which === 13) {
                event.preventDefault();
                if (event.shiftKey || event.ctrlKey) {
                    this.newMessage += '\n';
                } else {
                    this.sendMessage(params.conversationId);
                }
            }

            this.chatService.conversationMessagesRead(params.conversationId);
        };

        provideDefaultMessage = (msg: string) => {
            this.newMessage = msg;
            this.toggleMessages = false;
        };

        readMessages = (conversation: ChatConversation) => {
            if (!conversation) return;

            this.chatService.conversationMessagesRead(conversation.id);
        };

        setEmoticonToInput = emoticon => {
            this.showSmileys = false;
            if (!this.disableMessageSending) {
                this.newMessage = `${this.newMessage || ''} ${Emojifier.emojify(emoticon)} `;
                const newMessageElement = document.getElementById('newMessage');
                this.putCursorAtEnd(newMessageElement);
            }
        };

        public getBannerText(): string {
            if (this.showLinkOpenedCard()) {
                return ' Dit gesprek is niet gekoppeld aan de geopende relatiekaart!';
            } else if (this.showOpenLinkedCard()) {
                return ' Gekoppelde relatiekaart wordt niet getoond';
            } else {
                return ' Er is nog geen relatie gekoppeld aan dit gesprek...';
            }
        }

        public showBanner(): boolean {
            if (this.isOnCustomerCard() && (this.person && this.person.id === this.conversation.customer.id)) {
                return false;
            }
            return true;
        }

        public showLinkOpenedCard(): boolean {
            return this.isOnCustomerCard() && (this.person && this.person.id !== this.conversation.customer.id);
        }

        public showOpenLinkedCard(): boolean {
            return (
                (!this.conversation.isAnonymous && !this.isOnCustomerCard()) ||
                (!this.conversation.isAnonymous && this.isOnCustomerCard() && this.person && this.person.id !== this.conversation.customer.id)
            );
        }

        public linkCustomer = (conversation: ChatConversation, person: PersonModel) => {
            if (!conversation || !person || person.id === conversation.customer.id) return;

            this.chatService.link(conversation.id, person);
        };

        public addAnswerToInput(answer: string) {
            this.newMessage += answer + ' ';
        }

        public isOnCustomerCard(): boolean {
            return (
                this.$state &&
                this.$state.current &&
                this.$state.current.name.indexOf('customerservice.customercard') !== -1
            );
        }

        public navigateToCustomerCard(): void {
            if (this.conversation.customer) {
                this.$state.go('customerservice.customercard.timeline', {
                    personId: this.conversation.customer.id
                });
            } else {
                this.$state.go('customerservice.customercard.timeline', {
                    personId: this.person.id
                });
            }
        }

        private observeActiveConversation() {
            this.activeConversationObserver = this.chatStore.state$
                .filter(x => !!x)
                .map(x => x.activeId && x.conversations && x.conversations.find(c => c.id === x.activeId))
                .distinctUntilChanged()
                .subscribe(conversation => {
                    const currentId = this.conversation && this.conversation.id;

                    if (!conversation || conversation.id !== currentId) this.newMessage = '';

                    this.conversation = conversation;
                    this.disableMessageSending = !conversation || isAcwOrEndedConversation(conversation.status);
                });
        }

        private observeSelectedAnswers() {
            this.selectedAnswerObserver = this.chatStore.state$
                .filter(x => !!x)
                .map(x => x.selectedAnswer)
                .distinctUntilChanged()
                .subscribe(answer => {
                    this.addAnswerToInput(answer);
                });
        }

        private observeCurrentlyLoadedCustomerCard(): void {
            this.personObserver = customerCardStore.state$
                .map(s => s && s.personal && s.personal.person && s.personal.person)
                .filter(person => person !== null)
                .distinctUntilChanged()
                .subscribe(person => {
                    this.person = person;
                });
        }

        private putCursorAtEnd(el) {
            const $el = $(el);
            $el.blur();
            setTimeout(() => {
                $el.focus();
                // If this function exists...
                if (el.setSelectionRange) {
                    // ... then use it (Doesn't work in IE)
                    // Double the length because Opera is inconsistent about whether a carriage return is one character or two. Sigh.
                    const len = $el.val().length * 2;
                    el.setSelectionRange(len, len);
                } else {
                    // ... otherwise replace the contents with itself
                    // (Doesn't work in Google Chrome)
                    $el.val($el.val());
                }
                // Scroll to the bottom, in case we're in a tall textarea
                // (Necessary for Firefox and Google Chrome)
                el.scrollTop = 999999;
            }, 200);
        }
    }
}
