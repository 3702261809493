/// <reference path="../../../../Decorators.ts" />

namespace Umbrella.CustomerService.CustomerCard {
    @Component('CustomerService', {
        selector: 'account-info',
        templateUrl:
            '/CustomerService/_CustomerCard/_Personal/AccountInfoComponent/AccountInfo.html',
        bindings: {
            person: '<'
        }
    })
    @Inject('ToastMessageService')
    class AccountInfoComponent {
        public person: PersonModel;

        constructor(private toastMessageService: ToastMessageService) {}

        public accountCreated(): void {
            this.toastMessageService.success('Account succesvol aangemaakt');
        }

        public accountCreationFailed(): void {
            this.toastMessageService.error(
                'Aanmaken account mislukt, mogelijk omdat het e-mail adres niet beschikbaar is.'
            );
        }

        public accountRecovered(): void {
            this.toastMessageService.success(
                'Accountherstel gestart. Verificatie e-mail wordt verstuurd.'
            );
        }

        public accountRecoverFailed(): void {
            this.toastMessageService.success(
                'Accountherstel mislukt, mogelijk omdat het e-mail adres al is gekoppeld aan een ander account.'
            );
        }
    }
}
