/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts" />
/// <reference path="../../../Scope.d.ts" />

namespace Umbrella.Modules.Colleagues {
    export interface ICalendarResource
        extends angular.resource.IResource<Calendar.CalendarModel>,
            Calendar.CalendarModel {}
    export interface ICalendarResourceClass
        extends angular.resource.IResourceClass<ICalendarResource> {
        getAppointmentsForDay(
            params: ICallendarRequestParam
        ): ng.resource.IResource<
            Umbrella.Modules.Colleagues.Calendar.AppointmentModel[]
        >;
    }

    export interface ICallendarRequestParam {
        id: System.Guid;
        day: Date;
        days?: number;
    }

    class CalendarResourceBuilder {
        static $inject = ['$resource'];

        constructor(public $resource: angular.resource.IResourceService) {}

        getCalendarResource(): ICalendarResource {
            return this.$resource<Calendar.CalendarModel, ICalendarResource>(
                '/api/v1/calendar/:action/:id',
                {},
                {
                    getAppointmentsForDay: {
                        method: 'GET',
                        isArray: true,
                        params: {
                            action: 'forday',
                            id: '@id',
                            day: '@day',
                            days: '@days'
                        }
                    }
                }
            );
        }
    }

    angular
        .module('Colleagues')
        .service('CalendarResource', [
            '$resource',
            $resource =>
                new CalendarResourceBuilder($resource).getCalendarResource()
        ]);
}
