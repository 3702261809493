/// <reference path='../../../../Scripts/TypeScript/angularjs/angular.d.ts'/>
/// <reference path='../../../../Scripts/TypeScript/umbrella/umbrella.d.ts'/>

namespace Umbrella.Modules.Chat {
    import debounce = Umbrella.Helpers.debounce;

    interface SelectedItem {
        text: string;
        id: number;
    }

    @Component('Dashboard', {
        selector: 'chat-conversation-filters',
        templateUrl:
            '/ChatConversationHandling/_Overview/_Filters/ChatConversationFiltersComponent/ChatConversationFilters.html'
    })
    class ChatConversationFiltersComponent {
        public searchQuery = '';
        public chatConversationStatus: SelectedItem;

        /*        constructor(
            private chatOverviewService: Umbrella.Modules.Chat.ChatOverviewService
        ) {}

        public filterByQuery = debounce(
            (query: string) => {
                this.chatOverviewService.searchChatConvesations(query);
            },
            500,
            false
        );

        public filterByStatus(status: string) {
            this.chatOverviewService.loadConvesationsByStatus(status);
        }*/
    }
}
