namespace Umbrella.Modules.Knowledgebase {
    export interface ThesaurusResource {
        getById(
            params: IdRequestParams
        ): ng.resource.IResource<ThesaurusTagModel>;
        getRootTags(): ng.resource.IResource<ThesaurusTagModel[]>;
        forParent(
            params: IdRequestParams
        ): ng.resource.IResource<ThesaurusTagModel[]>;
    }

    angular.module('Knowledgebase').service('ThesaurusResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) =>
            $resource(
                '/api/v1/thesaurus/:action/:id',
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'item', id: '' }
                    },
                    getRootTags: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'forParent', id: '' }
                    },
                    forParent: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'forParent', id: '@id' }
                    }
                }
            )
    ]);
}
