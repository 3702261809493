/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella.Sandbox.CustomerService {
    angular.module('Sandbox').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider
                .state('sandbox.customerservice', {
                    url: '/customerservice',
                    reloadOnSearch: false,
                    redirectTo: 'sandbox.customerservice.icons',
                    data: {
                        pageTitle: 'Sandbox - Customer Service',
                        authorize: p => p.shell
                    },
                    views: {
                        'left@sandbox': {
                            templateUrl:
                                '/Sandbox/_CustomerService/Navigation.html'
                        }
                    }
                })
                .state('sandbox.customerservice.attachment', {
                    url: '/attachment',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Customer Service attachments',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl:
                                '/Sandbox/_CustomerService/Attachment.html'
                        }
                    }
                })
                .state('sandbox.customerservice.buttons', {
                    url: '/buttons',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Customer Service buttons',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl:
                                '/Sandbox/_CustomerService/Buttons.html'
                        }
                    }
                })
                .state('sandbox.customerservice.datepicker', {
                    url: '/datepicker',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Customer Service datepicker',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl:
                                '/Sandbox/_CustomerService/DatePicker.html'
                        }
                    }
                })
                .state('sandbox.customerservice.dialog', {
                    url: '/dialog',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Customer Service dialog',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_CustomerService/Dialog.html'
                        }
                    }
                })
                .state('sandbox.customerservice.icons', {
                    url: '/icons',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Customer Service icons',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_CustomerService/Icons.html'
                        }
                    }
                })
                .state('sandbox.customerservice.input', {
                    url: '/input',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Customer Service input',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_CustomerService/Input.html'
                        }
                    }
                })
                .state('sandbox.customerservice.list', {
                    url: '/list',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Customer Service list',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_CustomerService/List.html'
                        }
                    }
                })
                .state('sandbox.customerservice.namecard', {
                    url: '/namecard',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Customer Service namecard',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl:
                                '/Sandbox/_CustomerService/Namecard.html'
                        }
                    }
                })
                .state('sandbox.customerservice.pagination', {
                    url: '/pagination',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Customer Service pagination',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl:
                                '/Sandbox/_CustomerService/Pagination.html'
                        }
                    }
                })
                .state('sandbox.customerservice.radiobuttons', {
                    url: '/select',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Customer Service radio buttons',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl:
                                '/Sandbox/_CustomerService/Radiobuttons.html'
                        }
                    }
                })
                .state('sandbox.customerservice.select', {
                    url: '/select',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Customer Service select',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_CustomerService/Select.html'
                        }
                    }
                })
                .state('sandbox.customerservice.tabs', {
                    url: '/tabs',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Customer Service tabs',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl: '/Sandbox/_CustomerService/Tabs.html'
                        }
                    }
                })
                .state('sandbox.customerservice.tooltip', {
                    url: '/tooltip',
                    reloadOnSearch: false,
                    data: {
                        pageTitle: 'Sandbox - Customer Service tooltip',
                        authorize: p => p.shell
                    },
                    views: {
                        'center@sandbox': {
                            templateUrl:
                                '/Sandbox/_CustomerService/Tooltip.html'
                        }
                    }
                });
        }
    ]);
}
