﻿/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>

namespace Umbrella {
    @Pipe('Umbrella', 'phonenr')
    class PhoneNrFilter {
        public transform(tel: string) {
            if (!tel) return 'Niet bekend';

            const displayInInternationalFormat = false;

            let phoneNr = tel
                .toString()
                .trim()
                .replace(/^\+/, '');
            phoneNr = phoneNr.replace(/\s/g, ''); // remove spaces

            if (phoneNr.match(/[^0-9]/)) return tel;

            let countryCode, netNr, rest: string;
            let hasCountryCode = true;

            switch (phoneNr.length) {
                case 10:
                    hasCountryCode = false;
                    netNr = this.getNetNumber(tel);
                    if (!netNr) return tel;

                    rest = phoneNr.slice(netNr.length);
                    break;

                default:
                    return tel;
            }

            if (!hasCountryCode) countryCode = '+31 ';

            let re = (netNr + '-' + rest).trim();
            if (displayInInternationalFormat || countryCode !== '+31 ') {
                rest = rest.slice(0, 3) + '-' + rest.slice(3);
                re = (
                    countryCode +
                    netNr.replace('0', '(0)') +
                    ' ' +
                    rest
                ).trim();
            }

            return re;
        }

        private getNetNumber(tel: string): string {
            for (const netNr of this.netNumbers) {
                if (tel.indexOf(netNr) === 0) return netNr;
            }

            return null;
        }

        private netNumbers = [
            '0111',
            '0113',
            '0114',
            '0115',
            '0117',
            '0118',
            '0161',
            '0162',
            '0164',
            '0165',
            '0166',
            '0167',
            '0168',
            '0172',
            '0174',
            '0180',
            '0181',
            '0182',
            '0183',
            '0184',
            '0186',
            '0187',
            '0222',
            '0223',
            '0224',
            '0226',
            '0227',
            '0228',
            '0229',
            '0251',
            '0252',
            '0255',
            '0294',
            '0297',
            '0299',
            '0313',
            '0314',
            '0315',
            '0316',
            '0317',
            '0318',
            '0320',
            '0321',
            '0341',
            '0342',
            '0343',
            '0344',
            '0345',
            '0346',
            '0347',
            '0348',
            '0411',
            '0412',
            '0413',
            '0416',
            '0418',
            '0475',
            '0478',
            '0481',
            '0485',
            '0486',
            '0487',
            '0488',
            '0492',
            '0493',
            '0495',
            '0497',
            '0499',
            '0511',
            '0512',
            '0513',
            '0514',
            '0515',
            '0516',
            '0517',
            '0518',
            '0519',
            '0521',
            '0522',
            '0523',
            '0524',
            '0525',
            '0527',
            '0528',
            '0529',
            '0541',
            '0543',
            '0544',
            '0545',
            '0546',
            '0547',
            '0548',
            '0561',
            '0562',
            '0566',
            '0570',
            '0571',
            '0572',
            '0573',
            '0575',
            '0577',
            '0578',
            '0591',
            '0592',
            '0593',
            '0594',
            '0595',
            '0596',
            '0597',
            '0598',
            '0599',
            '0800',
            '0900',
            '0906',
            '0909',
            '0970',
            '010',
            '013',
            '014',
            '014',
            '015',
            '020',
            '023',
            '024',
            '026',
            '030',
            '033',
            '035',
            '036',
            '038',
            '040',
            '043',
            '045',
            '046',
            '050',
            '053',
            '055',
            '058',
            '070',
            '071',
            '072',
            '073',
            '074',
            '075',
            '076',
            '077',
            '078',
            '079',
            '082',
            '084',
            '085',
            '087',
            '088',
            '091',
            '06'
        ];
    }
}
