﻿namespace Umbrella.Modules.Knowledgebase {
    import IResource = ng.resource.IResource;

    export interface FindRequestParams extends PagedRequestParams {
        id: string;
        index?: number;
    }

    export interface IMetaTagResource {
        getById(params: IdRequestParams): IResource<MetaTagModel>;
        getAll(
            params: PagedRequestParams
        ): IResource<PagedItemsModel<MetaTagModel>>;
        findByName(
            params: FindRequestParams
        ): IResource<PagedItemsModel<MetaTagModel>>;

        save(model: MetaTagModel): IResource<MetaTagModel>;
        update(model: MetaTagModel): IResource<MetaTagModel>;
        delete(params: IdRequestParams): IResource<void>;
        deleteWithIdsIn(params: { ids: System.Guid[] }): IResource<void>;
    }

    angular.module('Knowledgebase').factory('MetaTagResource', [
        '$resource',
        ($resource: ng.resource.IResourceService) =>
            $resource(
                '/api/v1/metatag/:action/:id',
                {},
                {
                    getAll: {
                        method: 'GET',
                        isArray: false,
                        params: { page: 0, pageSize: 10 }
                    },
                    getRoot: {
                        method: 'GET',
                        isArray: false,
                        params: { action: '', page: 0, pageSize: 10 }
                    },
                    findByName: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'Find',
                            type: null,
                            id: '',
                            index: 0,
                            page: 0,
                            pageSize: 25
                        }
                    },
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'item', id: '', edit: true }
                    },
                    save: {
                        method: 'POST',
                        isArray: false,
                        params: { action: '' }
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: { action: '' }
                    },
                    deleteWithIdsIn: {
                        method: 'DELETE',
                        isArray: false,
                        params: { action: 'deletewithidsin' }
                    }
                }
            )
    ]);
}
