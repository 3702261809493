/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts" />
/// <reference path="../../Scope.d.ts" />

namespace Umbrella.Modules.Housing {
    export interface SearchRequestParams extends PagedRequestWithIdParams {
        searchQuery?: string;
    }

    export interface IContractResource {
        getById(params: IdRequestParams): ng.resource.IResource<ContractModel>;
        getPagedByRoleId(
            params: PagedRequestWithIdParams
        ): ng.resource.IResource<PagedItemsModel<ContractModel.Detailed>>;
        getFilterValuesByRoleId(
            params: SearchRequestParams
        ): ng.resource.IResource<PagedItemsModel<ContractFilterModel>>;
        getAllFilterValuesByRoleId(params: IdRequestParams): ng.resource.IResource<ContractFilterModel[]>;
        getAllByUnitIdPaged(params: PagedRequestWithIdParams): ng.resource.IResource<PagedItemsModel<ContractModel>>;
        getActiveAssociatedContractors(params: IdRequestParams): ng.resource.IResource<ContractorModel[]>;
        getContacts(params: IdRequestParams): ng.resource.IResource<RoleModel[]>;
        getAssociatedContractors(params: IdRequestParams): ng.resource.IResource<ContractorModel[]>;
        getActiveFor(params: IdRequestParams): ng.resource.IResource<ContractModel[]>;
        getOutstandingBalanceFor(params: IdRequestParams): ng.resource.IResource<OutstandingBalanceModel>;
    }

    export interface IAssociatedRolesRequestParam extends IdRequestParams {
        includingProvidedRole?: boolean;
    }

    interface IContractResourceRequestParams {
        id?: string;
        action?: string;
        subaction?: string;
        roleId?: string;
    }

    function getResource(
        method: string,
        isArray: boolean,
        params?: IContractResourceRequestParams,
        headers?: any
    ): angular.resource.IActionDescriptor {
        return { method, isArray, params, headers };
    }

    angular.module('Housing').service('ContractResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) => {
            return $resource(
                '/api/v1/contract/:action/:id/:subaction',
                {},
                {
                    getById: getResource('GET', false, { id: '@id' }),
                    getPagedByRoleId: getResource('GET', false, {
                        action: 'byRole',
                        id: '@roleId'
                    }),
                    getFilterValuesByRoleId: getResource('GET', false, {
                        action: 'byRole',
                        id: '@roleId',
                        subaction: 'filters'
                    }),
                    getAllFilterValuesByRoleId: getResource('GET', true, {
                        action: 'byRole',
                        id: '@id',
                        subaction: 'allFilters'
                    }),
                    getAllByUnitIdPaged: getResource('GET', false, {
                        action: 'byUnit',
                        id: '@unitId'
                    }),
                    getActiveAssociatedContractors: getResource('GET', true, {
                        action: 'active',
                        id: '@roleId',
                        subaction: 'associatedContractors'
                    }),
                    getAssociatedContractors: getResource('GET', true, {
                        id: '@roleId',
                        subaction: 'associatedContractors'
                    }),
                    getContacts: getResource('GET', true, {
                        id: '@id',
                        subaction: 'contacts'
                    }),
                    getActiveFor: getResource('GET', true, {
                        action: 'active',
                        id: '@roleId'
                    }),
                    getOutstandingBalanceFor: getResource('GET', false, {
                        action: 'outstandingbalance',
                        id: '@id'
                    })
                }
            );
        }
    ]);
}
