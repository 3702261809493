/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.UnitCard.Contracts {
    @Service('CustomerService', 'UnitContractJitService')
    @Inject('UnitCardStore', 'UnitContractHub', 'ToastMessageService', 'UnitCardService')
    export class UnitContractJitService {
        private message: any;
        constructor(
            store: UnitCardStore,
            private unitContractHub: IUnitContractHubProxy,
            private toastMessageService: ToastMessageService,
            unitCardService: UnitCardService
        ) {
            const getUnitId = (state: UnitCardState) => state && state.id;

            const containsContractId = (s: UnitCardState, contractId) => {

                if (s && s.contractInfo && s.contractInfo.contracts && s.contractInfo.contracts.items && s.contractInfo.contracts.items) {
                    return s.contractInfo.contracts.items.some(x => x.id === contractId);
                }

                return false;
            };

            unitContractHubOnUpdated$
                .filter(unitId => getUnitId(store.getState()) === unitId)
                .subscribe(_ => {
                    if (store.getState().contractInfo) unitCardService.loadContracts(undefined, undefined, true);

                    this.clearToastMessage();
                });

            unitContractHubOnContractUpdated$
                .filter(contractId => containsContractId(store.getState(), contractId))
                .subscribe(_ => unitCardService.loadContracts(undefined, undefined, true));

            unitContractHubOnStarted$
                .filter(unitId => getUnitId(store.getState()) === unitId)
                .subscribe(_ => {
                    if (this.message) {
                        return;
                    }

                    this.message = this.toastMessageService.updating('Contractgegevens', 60000, {
                        onHidden: () => {
                            this.message = null;
                        }
                    });

                    setTimeout(() => {
                        if (this.message) {
                            this.message = null;
                        }
                    }, 60000);
                });

            store.event$.where(e => e.type === 'UnitCardUnloadedEvent').subscribe(_ => this.unsubscribe(getUnitId(store.getState())));

            store.state$
                .map(getUnitId)
                .distinctUntilChanged()
                .flatMapLatest(Modules.waitUntilSignalRConnected)
                .debounce(() => Rx.Observable.timer(1000))
                .subscribe(unitId => this.subscribe(unitId));            
        }

        private subscribe(unitId: System.Guid) {
            if (unitId) {
                this.unitContractHub.server.subscribe(unitId);
            }
            this.clearToastMessage();
        }

        private unsubscribe(unitId: System.Guid) {
            this.unitContractHub.server.unsubscribe(unitId);
            this.clearToastMessage();
        }

        private clearToastMessage() {
            if (this.message) {
                this.toastMessageService.clear(this.message);
                this.message = null;
            }
        }
    }
}
