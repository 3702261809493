/// <reference path="Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="Window.d.ts"/>
/// <reference path="Decorators.ts" />
/// <reference path="ComponentRouteUtils.ts" />
/// <reference path="Session.ts" />
/// <reference path="Session.ts" />
/// <reference path="deviceModeMethods.ts" />

namespace Umbrella {
    export const isSearchActive$ = new Rx.Subject();

    import SignalRStateNotifyingService = Umbrella.Modules.SignalRStateNotifyingService;
    import AuthorizationService = Umbrella.Authorization.AuthorizationService;
    import session$ = Umbrella.session$;
    import getStoredDeviceMode = Umbrella.getStoredDeviceMode;
    import applyStylingToDeviceMode = Umbrella.applyStylingToDeviceMode;
    import isDesktopModeActive = Umbrella.isDesktopModeActive;

    @Component('Umbrella', {
        selector: 'app-root',
        templateUrl: '/AppRoot.html',
        bindings: {
            getThemeColor: '&?'
        }
    })
    @Inject('SignalRStateNotifyingService', 'AuthorizationService')
    class AppRootComponent {
        public isSearchActive: boolean;
        private isSearchActiveSubscription: Rx.Disposable;

        constructor(
            signalRStateNotifyingService: SignalRStateNotifyingService,
            authorizationService: AuthorizationService
        ) {
            signalRStateNotifyingService.start();
            authorizationService.start();
        }

        $onInit() {
            this.subscribeEvents();

            if (window.device.surface()) {
                applyStylingToDeviceMode(window.localStorage.getItem(DEVICE_STORAGE_KEY));
                if (window.localStorage.getItem(DEVICE_STORAGE_KEY) === DEVICE_MODE.TABLET) {
                    return;
                }
                if (window.localStorage.getItem(DEVICE_STORAGE_KEY) === DEVICE_MODE.DESKTOP) {
                    this.isSearchActive = true;
                } else {
                    this.setDeviceMode();
                }
            } else {
                this.setDeviceMode();
            }
        }

        $onDestroy() {
            this.unSubscribeEvents();
        }

        private setDeviceMode() {
            window.localStorage.setItem(DEVICE_STORAGE_KEY, window.device.getDeviceMode());
            applyStylingToDeviceMode(window.device.getDeviceMode());
            if (window.device.desktop()) {
                this.isSearchActive = true;
            }
        }

        private subscribeEvents() {
            this.isSearchActiveSubscription = isSearchActive$.subscribe(() => {
                if (getStoredDeviceMode() === DEVICE_MODE.DESKTOP) {
                    this.isSearchActive = true;
                } else {
                    this.isSearchActive = !this.isSearchActive;
                }
            });
        }

        private unSubscribeEvents() {
            if (this.isSearchActiveSubscription) {
                this.isSearchActiveSubscription.dispose();
                this.isSearchActiveSubscription = null;
            }
        }
    }
}
