﻿/// <reference path="ComponentRouteUtils.ts" />
/// <reference path="Decorators.ts" />
/// <reference path="Window.d.ts" />

namespace Umbrella {
    angular
        .module('Umbrella')
        .config([
            '$stateProvider',
            $stateProvider => {
                $stateProvider
                    .state('root', {
                        url: '',
                        abstract: true,
                        views: {
                            'top@': {
                                template: '<top state="state$ | async:this"><top>',
                                controller: createComponentRouteController<TopBarState, TopComponentState>(
                                    'TopBarStore',
                                    s => ({
                                        impersonationInfo: s && s.impersonationInfo
                                    })
                                )
                            },
                            'left@': {
                                templateUrl: '/Left.html'
                            }
                        }
                    })
                    .state('umbrella', {
                        url: '',
                        parent: 'root',
                        abstract: true
                    });
            }
        ])
        .run([
            '$rootScope',
            '$state',
            ($rootScope: IUmbrellaRootScope, $state: ng.ui.IStateService, $mdDialog: any) => {
                const hasError = x => x.error && x.error.name;
                session$
                    .filter(x => hasError(x))
                    .take(1)
                    .subscribe(x => {
                        console.warn('Unauthorized');

                        $state.transitionTo('umbrella.error_401');

                        return;
                    });

                $rootScope.$on(
                    '$stateChangeStart',
                    (
                        event: ng.IAngularEvent,
                        toState: ng.ui.IState,
                        toParams: ng.ui.IState,
                        fromState: ng.ui.IState,
                        fromParams: {}
                    ) => {
                        if (toState.name === 'umbrella.error_401') {
                            console.log('Error 401');
                        } else if (toState.name === 'umbrella.error_general') {
                            console.log('Error general');
                        } else if (!window.user || !window.user.permissions) {
                            console.log('Waiting..');
                            session$
                                .skipWhile(x => !x.user || !x.user.permissions)
                                .take(1)
                                .subscribe(session => $state.transitionTo(toState.name, toParams));

                            event.preventDefault();
                            return false;
                        } else if (
                            toState.data &&
                            toState.data.authorize &&
                            !toState.data.authorize(window.user.permissions)
                        ) {
                            console.warn('Unauthorized');

                            event.preventDefault();
                            $state.transitionTo('umbrella.error_401');

                            return false;
                        }

                        return true;
                    }
                );
            }
        ]);
}
