/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/angularjs/angular-resource.d.ts" />

namespace Umbrella.Modules.Knowledgebase.Faq {
    export interface IFaqResource extends ng.resource.IResource<FaqModel> {}

    export interface IdAndTargetGroupIdRequestParams extends IdRequestParams {
        readonly targetGroups: string;
    }

    export interface IdAndTargetGroupIdPagedRequestParams
        extends IdRequestParams,
            PagedRequestParams {
        readonly targetGroups?: string;
    }

    export interface StringIdsRequestParams {
        ids: String;
    }

    export interface SearchRequestParams extends PagedRequestParams {
        readonly q: string;
        readonly getFaqsWithoutItems?: boolean;
        readonly targetGroups?: string;
    }

    export interface OrderedByViewCountRequestParams {
        count: number;
    }

    export interface FaqResourceClass
        extends ng.resource.IResourceClass<IFaqResource> {
        getAll(params: any): ng.resource.IResource<PagedItemsModel<FaqModel>>;
        getById(
            params: IdRequestParams
        ): ng.resource.IResource<FaqModel.Detailed>;
        getByIds(
            params: IdsRequestParams
        ): ng.resource.IResource<FaqModel[]>;
        getByIdAndTargetGroupId(
            params: IdAndTargetGroupIdRequestParams
        ): ng.resource.IResource<FaqModel.Detailed>;
        getByTagIdPaged(
            params: IdAndTargetGroupIdPagedRequestParams
        ): ng.resource.IResource<PagedItemsModel<FaqModel>>;
        getStatuses(): StatusModel[];
        increaseViewCount(faqId: System.Guid): number;
        getOrderedByViewCount(params: OrderedByViewCountRequestParams): ng.resource.IResource<FaqModel[]>;
        search(params: any): ng.resource.IResource<PagedItemsModel<FaqModel>>;
        searchThesaurusFaqsForSelfserviceScenario(
            params: any
        ): ng.resource.IResource<
            PagedItemsModel<
                Modules.SelfService.SelfServiceScenarioModel.Detailed
            >
        >;
        similar(
            params: FindSimilarFaqsModel
        ): ng.resource.IResource<PagedSearchItemsModel<SearchFaqModel>>;
    }
    angular.module('Knowledgebase').service('FaqResource', [
        '$resource',
        ($resource: ng.resource.IResourceService) => {
            return $resource(
                '/api/v1/faqs/:action/:id/:subaction',
                {},
                {
                    getAll: {
                        method: 'GET',
                        isArray: false,
                        params: { action: '', page: 0, pageSize: 10 }
                    },
                    getByTagIdPaged: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'bytagpaged',
                            id: '',
                            page: 0,
                            pageSize: 10,
                            mediaUrl: Config.makeAbsoluteApiUrl('api/v1/media/view/')
                        }
                    },
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id', targetGroups: '', edit: true, mediaUrl: Config.makeAbsoluteApiUrl('api/v1/media/view/') }
                    },
                    getByIds: {
                        method: 'GET',
                        isArray: true,
                        params: { action:'set', ids: '@ids' }
                    },
                    getByIdAndTargetGroupId: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            id: '@id',
                            targetGroups: '@targetGroups',
                            edit: true
                        }
                    },
                    getStatuses: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'statuses' }
                    },
                    increaseViewCount: {
                        method: 'PUT',
                        isArray: false,
                        params: { id: '@id', subaction: 'increaseviewcount' }
                    },
                    getOrderedByViewCount: {
                        url: '/api/v1/faqs/orderedbyviewcount',
                        method: 'GET',
                        isArray: true,
                        params: { count: '@count', mediaUrl: Config.makeAbsoluteApiUrl('api/v1/media/view/') }
                    },
                    search: {
                        url: '/api/v1/faqs/search/:q',
                        method: 'GET',
                        isArray: false,
                        params: { q: '@q', page: 0, pageSize: 25, mediaUrl: Config.makeAbsoluteApiUrl('api/v1/media/view/') }
                    },
                    searchThesaurusFaqsForSelfserviceScenario: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action:
                                'SearchThesaurusFaqsForSelfserviceScenarios',
                            id: '',
                            page: 0,
                            pageSize: 5
                        }
                    },
                    similar: {
                        method: 'POST',
                        isArray: false,
                        params: { action: 'similar' },
                        transformRequest: (data: FindSimilarFaqsModel) => {
                            return JSON.stringify(data);
                        }
                    }
                }
            );
        }
    ]);
}
