﻿/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../umbrella.routeconfig.ts"/>

namespace Umbrella {
    angular.module('Umbrella').config([
        '$stateProvider',
        ($stateProvider: ng.ui.IStateProvider) => {
            $stateProvider
                .state('umbrella.error_general', {
                    url: '/error',
                    data: { pageTitle: 'Error' },
                    views: {
                        'center@': { templateUrl: '/Errors/Error.html' }
                    }
                })
                .state('umbrella.error_401', {
                    url: '/error/401',
                    data: { pageTitle: '401' },
                    views: {
                        'new@': { templateUrl: '/Errors/Unauthorized.html' }
                    }
                })
                .state('umbrella.error_404', {
                    url: '/error/404',
                    data: { pageTitle: '404' },
                    views: {
                        'center@': { templateUrl: '/Errors/NotFound.html' }
                    }
                })
                .state('umbrella.error_503', {
                    url: '/error/503',
                    data: { pageTitle: '503' },
                    views: {
                        'center@': {
                            templateUrl: '/Errors/ServerNotAvailable.html'
                        }
                    }
                })
                .state('umbrella.otherwise', {
                    data: { pageTitle: '404' },
                    views: {
                        'center@': { templateUrl: '/Errors/NotFound.html' }
                    }
                });
        }
    ]);
}
