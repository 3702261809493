﻿namespace Umbrella.TaskHandling {
    import Guid = System.Guid;

    @Component('Umbrella', {
        selector: 'task-errors',
        templateUrl:
            '/TaskHandling/_Overview/_Details/_Information/TaskErrorsComponent/TaskErrors.html',
        bindings: {
            onlyIconAndFilename: '<',
            hideTitle: '<',
            properties: '<'
        }
    })
    export class TaskErrorsComponent {
        public onlyIconAndFilename: boolean;
        public hideTitle: boolean;
        private properties: any;
        public linkProperties = [];
        private readonly undeliveredMessageIdFieldName = 'undeliveredMessageId';
        private readonly scopeIdFieldName = 'scopeId';
        private readonly selfServiceReportIdFieldName = 'selfServiceReportId';

        public $onInit() {
            if (!this.properties) return;

            if (this.properties[this.undeliveredMessageIdFieldName])
                this.linkProperties.push({
                    title: 'Bekijk melding gegevens',
                    url: Config.makeManagementErrorPath(
                        this.properties[this.undeliveredMessageIdFieldName]
                    )
                });
            if (this.properties[this.scopeIdFieldName])
                this.linkProperties.push({
                    title: 'Bekijk logs',
                    url: Config.makeManagementLogPath(
                        this.properties[this.scopeIdFieldName]
                    )
                });
            if (this.properties[this.selfServiceReportIdFieldName])
                this.linkProperties.push({
                    title: 'Bekijk selfservice aanvraag',
                    url: Config.makeManagementPortalPath(
                        this.properties[this.selfServiceReportIdFieldName]
                    )
                });
        }
    }
}
