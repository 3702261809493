/// <reference path="../../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="Log.ts" />

namespace Umbrella.Modules {
    export interface SignalRInitialState {
        state: 'uninitialized';
    }

    export interface SignalRConnectedState {
        state: 'connected';
        slow: boolean;
    }

    export interface SignalRDisconnectedState {
        state: 'disconnected';
        reconnecting: boolean;
    }

    export type SignalRConnectionState =
        | SignalRConnectedState
        | SignalRDisconnectedState
        | SignalRInitialState;
    export const signalRConnectionState$ = new Rx.BehaviorSubject<
        SignalRConnectionState
    >({ state: 'uninitialized' });

    const delaySecAfterDisconnect = 2;

    const connected = () => {
        signalRConnectionState$.onNext({ state: 'connected', slow: false });
    };

    const connect = (cfg: Config.Config, token: string) => {
        $.connection.hub.qs = 't=' + token;

        $.connection.hub
            .start({ transport: cfg.signalRTransports || 'auto' })
            .progress(() => Log.info('SignalR connection starting...'))
            .done(connected)
            .fail(error => {
                Log.error('SignalR connection failed to start! ');
                Log.error(error);
            });
    };

    signalRConnectionState$
        .map<Log.LogOutput>(x => {
            switch (x.state) {
                case 'connected':
                    return x.slow
                        ? { level: 'warn', msg: 'SignalR slow' }
                        : {
                              level: 'info',
                              msg:
                                  'SignalR connected, transport = ' +
                                  $.connection.hub.transport.name
                          };

                case 'disconnected':
                    return x.reconnecting
                        ? {
                              level: 'warn',
                              msg: 'SignalR disconnected, re-connecting...'
                          }
                        : {
                              level: 'warn',
                              msg: `SignalR disconnected, scheduling connect in ${delaySecAfterDisconnect} sec...`
                          };

                case 'uninitialized':
                    return { level: 'info', msg: 'SignalR initializing' };
            }
        })
        .skip(1)
        .subscribe(x => Log.log$.onNext(x));

    function setup(cfg: Config.Config) {
        if (cfg.signalRTransports === 'disabled') return;

        signalRConnectionState$.onNext({
            state: 'disconnected',
            reconnecting: false
        });

        $.connection.hub.url = Config.makeAbsoluteApiUrl('signalr');
        $.connection.hub.reconnected(connected);

        $.connection.hub.connectionSlow(() =>
            signalRConnectionState$.onNext({ state: 'connected', slow: true })
        );

        $.connection.hub.reconnecting(() =>
            signalRConnectionState$.onNext({
                state: 'disconnected',
                reconnecting: true
            })
        );

        $.connection.hub.disconnected(() => {
            signalRConnectionState$.onNext({
                state: 'disconnected',
                reconnecting: false
            });
            setTimeout(() => connect(cfg, window.session.accessToken), delaySecAfterDisconnect * 1000);
        });

        connect(cfg, window.session.accessToken);
    }

    session$
        .skipWhile(x => !x.config)
        .take(1)
        .subscribe(x => setup(window.clientConfig));

    export const isOnline$ = new Rx.BehaviorSubject<boolean>(true);

    window.addEventListener('offline', () => isOnline$.onNext(false));
    window.addEventListener('online', () => isOnline$.onNext(true));

    export function onSignalRInitialized(callback: () => void): void {
        Modules.signalRConnectionState$
            .skipWhile(x => x.state === 'uninitialized')
            .take(1)
            .subscribe(() => {
                callback();
            });
    }

    export function onSignalRConnected(callback: () => void): void {
        Umbrella.Modules.signalRConnectionState$
            .skipWhile(x => {
                return x.state !== 'connected';
            })
            .take(1)
            .subscribe(() => {
                callback();
            });
    }

    export function waitUntilSignalRConnected<T>(value: T): Rx.Observable<T> {
        return Umbrella.Modules.signalRConnectionState$
            .skipWhile(x => x.state !== 'connected')
            .take(1)
            .map(_ => value);
    }
}
