namespace Umbrella {
    @Component('Umbrella', {
        selector: 'top-search-header',
        templateUrl: '/Top/TopSearchHeaderComponent/TopSearchHeader.html',
        bindings: {
            title: '@',
            cssClass: '@',
            count: '@',
            total: '@',
            onMore: '&'
        }
    })
    class TopSearchHeaderComponent {
        public title: string;
        public cssClass: string;
        public count: string;
        public total: string;

        public onMore: () => void;
    }
}
