namespace Umbrella.CustomerService.UnitCard {
    @Component('CustomerService', {
        selector: 'unitcard-header',
        templateUrl:
            'CustomerService/_UnitCard/UnitCardHeaderComponent/UnitCardHeader.html',
        bindings: {
            unit: '<'
        }
    })
    class UnitCardHeaderComponent {}
}
