﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../ComponentRouteUtils.ts" />
/// <reference path="../../../Window.d.ts"/>
/// <reference path="../../customerservice.routeconfig.ts" />

namespace Umbrella.CustomerService.CustomerCard.Registrations {
    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('customerservice.customercard.registrations', {
                url: '/registraties',
                reloadOnSearch: false,
                data: {
                    tab: 'registraties',
                    authorize: ps => ps.customerCard && ps.registrationRead
                },
                views: {
                    'tab@customerservice': {
                        template:
                            '<prospect-registrations state="state$ | async:this"></prospect-registrations>',
                        controller: Umbrella.createComponentRouteController<
                            CustomerCardState,
                            ProspectRegistrationsComponentState
                        >('CustomerCardStore', s => ({
                            person: s.personal && s.personal.person,
                            loading:
                                (s.personal && s.personal.loading) ||
                                (s.registrationInfo &&
                                    s.registrationInfo.loading),
                            customer:
                                s && s.customerInfo && s.customerInfo.customer,
                            registrations:
                                s.registrationInfo &&
                                s.registrationInfo.registrations
                        }))
                    }
                }
            });
        }
    ]);
}
