/// <reference path="../../../../../Window.d.ts" />

namespace Umbrella.TaskHandling {
    import TaskModel = Modules.Tasks.TaskModel;
    import CustomSelfServiceReportModel = Modules.SelfService.Custom.CustomSelfServiceReportModel;
    import SelfServiceReportResource = Modules.SelfService.SelfServiceReportResource;
    import isDate = Umbrella.Helpers.isDate;


    @Component('TaskHandling', {
        selector: 'self-service-report-information',
        templateUrl:
            '/TaskHandling/_Overview/_Details/_Information/SelfServiceReportInformationComponent/SelfServiceReportInformation.html',
        bindings: {
            selfServiceReport: '<',
        }
    })
    class SelfServiceReportInformationComponent {
        public selfServiceReport: CustomSelfServiceReportModel;

        public getFormValue(value: any): any {
            if (!value) return;

            if (isDate(value.toString())) {
                return new Date(value).toDDMMYYYYString();
            }

            return value;
        }
    }
}
