/// <reference path="../../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>

namespace Umbrella.CustomerService.ComplexCard {
    import ComplexModel = Umbrella.Modules.Housing.ComplexModel;
    import IComplexResource = Umbrella.Modules.Housing.IComplexResource;
    import AnnouncementResourceClass = Umbrella.Modules.Announcements.AnnouncementResourceClass;
    import AnnouncementModel = Umbrella.Modules.Announcements.AnnouncementModel;

    @Component('CustomerService', {
        selector: 'complex-announcements',
        templateUrl:
            'CustomerService/_ComplexCard/_Announcements/ComplexAnnouncementsComponent/ComplexAnnouncements.html',
        bindings: {
            state: '<'
        }
    })
    @Inject('$stateParams', 'ComplexResource', 'AnnouncementResource')
    class ComplexAnnouncementsComponent {
        public complex: ComplexModel;
        public announcements: PagedItemsModel<AnnouncementModel>;
        public loadingAnnouncements: boolean;
        private page = 0;

        constructor(
            private $stateParams: ComplexCardStateParams,
            private complexResource: IComplexResource,
            private announcementResource: AnnouncementResourceClass
        ) {}

        public $onInit() {
            const complexId = this.$stateParams.complexId;

            this.loadComplex(complexId);
            this.loadAnnouncements();
        }

        public loadMore(): void {
            this.loadAnnouncements(this.page + 1);
        }

        private async loadComplex(complexId: System.Guid): Promise<void> {
            if (!complexId) return;

            this.complex = await this.complexResource.getById({ id: complexId })
                .$promise;
        }

        private async loadAnnouncements(
            page = 0,
            pageSize = 10
        ): Promise<void> {
            const complexId = this.$stateParams.complexId;
            if (!complexId || this.loadingAnnouncements) return;

            this.loadingAnnouncements = true;
            this.page = page;
            const announcements = await this.announcementResource.byComplexId({
                id: complexId,
                page,
                pageSize
            }).$promise;
            if (announcements) {
                this.announcements =
                    page === 0
                        ? announcements
                        : {
                              ...this.announcements,
                              page,
                              items: this.announcements.items.concat(
                                  announcements.items
                              )
                          };
            }
            this.loadingAnnouncements = false;
        }
    }
}
