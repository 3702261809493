namespace Umbrella.Modules.Customers {
    export interface IRoleResource
        extends angular.resource.IResource<RoleModel> {
        getById(params: IdRequestParams): ng.resource.IResource<RoleModel>;
        getAllByIds(params: IdsRequestParams): ng.resource.IResource<RoleModel[]>;
    }

    angular.module('Umbrella').service('RoleResource', [
        '$resource',
        ($resource: ng.resource.IResourceService) =>
            $resource(
                '/api/v1/role/:action/:id',
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getAllByIds: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'withIds', id: '@ids' }
                    }
                }
            )]);
}
