﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Window.d.ts"/>

namespace Umbrella.TaskHandling.Overview {
    import CaseFlowModel = Umbrella.CaseFlow.CaseFlowModel;
    import SummarizedColleagueModel = Modules.Colleagues.SummarizedColleagueModel;

    @Pipe('Dashboard', 'canModifyCaseFlow')
    class CanModifyCaseFlowFilter {
        public transform(c: CaseFlowModel, employee: SummarizedColleagueModel) {
            if (!c || !employee) return false;
            if (c.status === Umbrella.CaseFlow.CaseFlowStatus.Finished || c.status.toString() === Umbrella.CaseFlow.CaseFlowStatus.Finished.toString()) return false;
            if (c.status === Umbrella.CaseFlow.CaseFlowStatus.Canceled || c.status.toString() === Umbrella.CaseFlow.CaseFlowStatus.Canceled.toString()) return false;
            if (c.status === Umbrella.CaseFlow.CaseFlowStatus.Submitted || c.status.toString() === Umbrella.CaseFlow.CaseFlowStatus.Submitted.toString()) return false;
            if (window.user.permissions.editCaseFlow) return true;
            if (c.ownerModel && c.ownerModel.id && c.ownerModel.id === employee.id) return true;

            return false;
        }
    }
}
