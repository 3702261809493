namespace Umbrella.Modules.Knowledgebase.Faq {
    export interface IAdminFaqResource
        extends angular.resource.IResource<AdminFaqModel>,
            AdminFaqModel {}
    export interface AdminFaqResourceClass
        extends angular.resource.IResourceClass<IAdminFaqResource> {
        getById(params: any): IAdminFaqResource;
        update(model: AdminFaqModel): IAdminFaqResource;
        findByName(
            {
                id,
                page,
                pageSize,
                status,
                applicationId
            }: {
                id: string;
                page: number;
                pageSize: number;
                status?: PublicationStatus;
                applicationId?: System.Guid;
            },
            onSuccessCallback?: Function,
            onErrorCallback?: Function
        ): angular.resource.IResource<PagedItemsModel<FaqModel>>;
        getAll(
            {
                page,
                pageSize,
                status,
                thesaurus,
                applicationId
            }: {
                page: number;
                pageSize: number;
                status?: PublicationStatus;
                thesaurus?: System.Guid;
                applicationId?: System.Guid;
            },
            onSuccessCallback?: Function,
            onErrorCallback?: Function
        ): angular.resource.IResource<PagedItemsModel<FaqModel>>;
    }
    angular.module('Knowledgebase').service('AdminFaqResource', [
        '$resource',
        ($resource: angular.resource.IResourceService) => {
            return $resource(
                '/api/v1/faqs/admin/:action/:id',
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    save: {
                        method: 'POST',
                        isArray: false,
                        params: {}
                    },
                    update: {
                        method: 'PUT',
                        isArray: false,
                        params: {}
                    },
                    findByName: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'search',
                            id: '',
                            page: 0,
                            pageSize: 25,
                            applicationId: null
                        }
                    },
                    getAll: {
                        method: 'GET',
                        isArray: false,
                        params: {
                            action: 'all',
                            page: 0,
                            pageSize: 25,
                            applicationId: null
                        }
                    },
                }
            );
        }
    ]);
}
