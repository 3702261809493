namespace Umbrella.Helpers {
    export const debounce = (func, wait, immediate) => {
        let timeout;
        return function() {
            const context = this,
                args = arguments;
            const later = () => {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait || 200);
            if (callNow) {
                func.apply(context, args);
            }
        };
    };

    export const throttle = (func, limit) => {
        let inThrottle;
        return function() {
            const args = arguments;
            const context = this;
            if (!inThrottle) {
                func.apply(context, args);
                inThrottle = true;
                setTimeout(() => (inThrottle = false), limit);
            }
        };
    };

    export const copyToClipboard = (text: string, successCallback?: Function, failCallback?: Function) => {
        // tslint:disable-next-line:no-string-literal
        if (window['clipboardData'] && window['clipboardData'].setData) {
            // IE specific code path to prevent textarea being shown while dialog is visible.
            // tslint:disable-next-line:no-string-literal
            return window['clipboardData'].setData('Text', text);
        } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
            const textarea = document.createElement('textarea');
            textarea.textContent = text;
            textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                if (successCallback) successCallback();
                return document.execCommand('copy'); // Security exception may be thrown by some browsers.
            } catch (ex) {
                if (failCallback) failCallback();
                console.warn('Copy to clipboard failed.', ex);
                return false;
            } finally {
                document.body.removeChild(textarea);
            }
        }
    };

    export function GetEnumValueAsNumber<T>(typ: T, val: string | number): number {
        if (typeof val === 'number') return val;
        return typ[val];
    }

    export const generateGuid = (): Guid => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            const r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };

    export function isDate(value: string): boolean {
        return /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}/.test(value);
    }     
}
