/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts" />
/// <reference path="../Scope.d.ts" />
/// <reference path="../Scripts/TypeScript/signalr/signalr.d.ts" />
/// <reference path="../Modules/Umbrella/SignalR.ts" />
interface SignalR {
    presenceHub: Umbrella.IPresenceHubProxy;
}

namespace Umbrella {
    import StatusUpdateModel = Modules.Colleagues.StatusUpdateModel;

    export interface IPresenceHubServerResponse {
        done(calback: () => void): IPresenceHubServerResponse;
        fail(
            callback: (ErrorService: string) => void
        ): IPresenceHubServerResponse;
    }

    export interface IPresenceHubClient {
        update(statusUpdate: StatusUpdateModel);
    }

    export interface IPresenceHubProxy extends HubProxy {
        client: IPresenceHubClient;
    }

    export const colleagueStatusUpdated$ = new Rx.Subject<StatusUpdateModel>();

    angular
        .module('Telephony')
        .factory('ColleagueHub', () => $.connection.presenceHub);

    Modules.onSignalRInitialized(() => {
        $.connection.presenceHub.client.update = statusUpdate => {
            colleagueStatusUpdated$.onNext(statusUpdate);
        };
    });
}
