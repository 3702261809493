﻿@Component('Umbrella', {
    selector: 'role-label',
    template: `
        <span
            class="abbr"
            title="{{ vm.labelTitle }}"
            ng-bind="vm.labelText"
        ></span>
    `,
    bindings: {
        labelText: '@',
        labelTitle: '@'
    }
})
class RoleLabelComponent {
    labelText: string;
}
