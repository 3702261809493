namespace Umbrella {
    export abstract class BaseStoreService<
        TState,
        TEvent,
        TStore extends ObservableStore.EventStore<TState, TEvent>
    > {
        constructor(protected store: TStore) {}

        protected emit<T extends TEvent>(e: T) {
            this.store.event$.onNext(e);
        }

        protected getState = () => this.store.getState();
    }
}
