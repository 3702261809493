﻿/// <reference path="../../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../../ComponentRouteUtils.ts" />
/// <reference path="../../../Window.d.ts"/>
/// <reference path="../../customerservice.routeconfig.ts" />

namespace Umbrella.CustomerService.CustomerCard.Announcements {
    angular.module('CustomerService').config([
        '$stateProvider',
        ($stateProvider: angular.ui.IStateProvider) => {
            $stateProvider.state('customerservice.customercard.announcements', {
                url: '/mededelingen',
                reloadOnSearch: false,
                data: {
                    tab: 'announcements',
                    authorize: ps => ps.customerCard && ps.announcementRead
                },
                onEnter: [
                    'CustomerCardAnnouncementInfoService',
                    (
                        announcementInfoService: CustomerCardAnnouncementInfoService
                    ) => {
                        announcementInfoService.ensureLoaded();
                    }
                ],
                views: {
                    'left@customerservice': {
                        template:
                            '<customer-announcement-filters state="state$ | async:this"></customer-announcement-filters>',
                        controller: Umbrella.createComponentRouteController<
                            CustomerCardState,
                            CustomerAnnouncementFiltersComponentState
                        >('CustomerCardStore', s => ({
                            person: s && s.personal && s.personal.person,
                            selectedContract:
                                s &&
                                s.activityInfo &&
                                s.activityInfo.filters &&
                                s.activityInfo.filters.contractId
                        }))
                    },
                    'tab@customerservice': {
                        template:
                            '<customer-announcements state="state$ | async:this"></customer-announcements>',
                        controller: createComponentRouteController<
                            CustomerCardState,
                            CustomerAnnouncementsComponentState
                        >('CustomerCardStore', s => ({
                            loading:
                                s.announcementInfo &&
                                s.announcementInfo.loading  &&
                                s.registrationInfo &&
                                s.registrationInfo.loading,
                            person: s.personal && s.personal.person,
                            customer:
                                s && s.customerInfo && s.customerInfo.customer,
                            announcementInfo: s.announcementInfo,
                            registrationInfo:
                                s.registrationInfo &&
                                s.registrationInfo.registrations
                        }))
                    }
                }
            });
        }
    ]);
}
