/// <reference path="../../../ChatConversationHandling/ChatStore.ts" />
/// <reference path="../../../ChatConversationHandling/ChatService.ts" />

namespace Umbrella.ChatConversationHandling {
    import Guid = System.Guid;
    import CustomerCardEvent = Umbrella.CustomerService.CustomerCard.CustomerCardEvent;

    @Component('Chat', {
        selector: 'incoming-chat-request',
        templateUrl: '/Modules/Chat/ChatIncomingRequestComponent/ChatIncomingRequest.html'
    })
    @Inject('$state', 'ChatStore', 'ChatService')
    class ChatIncomingRequestComponent {
        public collapsed = false;
        public incomingChatRequest: ChatConversation;
        public pendingConversations: ChatConversation[] = [];
        public waitingTime = 0;

        private cancelInterval;
        private newChatCheckSubscription: Rx.IDisposable;
        private suspendedChatId: Guid;
        private appearedAtDateTime: Date;
        private minTimeBeforeSuspendMs = 2000;

        constructor(
            private $state: ng.ui.IStateService,
            private chatStore: ChatStore,
            private chatService: ChatService
        ) {}
        /*
        private $onInit() {
            const hasOperatorConversations = s => s && s.operatorConversations;
            this.newChatCheckSubscription = chatStore.state$
                .filter(hasOperatorConversations)
                .map(s => s.operatorConversations)
                .distinctUntilChanged()
                .subscribe(s => {
                    this.pendingConversations = s.filter(
                        conversation => isPendingConversation(conversation.status) || conversation.forContinuation
                    );
                    this.incomingChatRequest = this.pendingConversations[0];

                    if (this.incomingChatRequest) {
                        clearInterval(this.cancelInterval);
                        this.CalculateWaitingTime();
                    } else {
                        this.collapsed = false;
                    }
                });
        }

        private $onDestroy() {
            this.newChatCheckSubscription.dispose();
        }

        public CalculateWaitingTime(): void {
            this.cancelInterval = setInterval(() => {
                if (this.incomingChatRequest) {
                    const waitingTimeDifference =
                        new Date().getTime() - new Date(this.incomingChatRequest.createdOn).getTime();

                    this.waitingTime = waitingTimeDifference / 1000.0;
                }
            }, 500);
        }

        public pickup(): void {
            if (this.incomingChatRequest) {
                this.chatService.pickupChat(this.incomingChatRequest);
            } else {
                this.collapsed = false;
            }
        }

        public OpenTimelineAndPickup(): void {
            if (this.incomingChatRequest) {
                this.chatService.pickupChat(this.incomingChatRequest);
                this.$state.go('customerservice.customercard.personal', {
                    personId: this.incomingChatRequest.customer.id
                });
            } else {
                this.collapsed = false;
            }
        }

        public suspend(): void {
            if (this.waitingTime <= this.minTimeBeforeSuspendMs / 1000.0) {
                return;
            }

            if (this.incomingChatRequest && this.incomingChatRequest.id) {
                this.collapsed = true;
                this.suspendedChatId = this.incomingChatRequest.id;
            }
        }*/
    }
}
