﻿namespace Umbrella.Modules.Chat {
    import PhotoUrlService = Modules.Colleagues.PhotoUrlService;
    import Guid = System.Guid;
    import ChatConversation = Umbrella.ChatConversationHandling.ChatConversation;
    import ChatResource = Umbrella.ChatConversationHandling.ChatResource;

    @Component('Chat', {
        selector: 'chat-conversation-history',
        templateUrl: '/Modules/Chat/ChatConversationHistoryComponent/ChatConversationHistory.html',
        bindings: {
            conversationId: '<',
            conversation: '<',
            limitMessagesTo: '<'
        }
    })
    @Inject('ChatResource', 'PhotoUrlService')
    export class ChatConversationHistoryComponent {
        public conversationId: Guid;
        public conversation: ChatConversation;
        public limitMessagesTo: number;
        public initialMessage: any;
        public chatConversation: any;
        public operatorAvatarUrl: string;
        public customerAvatarUrl: string;

        constructor(private chatConversationResource: ChatResource, private photoUrlService: PhotoUrlService) {}

        $onInit() {
            if (this.conversation) {
                this.assignConversation(this.conversation);
            } else {
                this.loadConversation(this.conversationId);
            }
        }

        private loadConversation(id: Guid): void {
            this.chatConversationResource.getById({ id }).$promise.then(conversation => {
                this.assignConversation(conversation);
            });
            
        }

        private assignConversation(conversation: any): void {
            conversation.messages.sort(
                (m1, m2) => new Date(m1.timestamp.toString()).getTime() - new Date(m2.timestamp.toString()).getTime()
            );
            const initialParticipant = conversation.participantInfo.find(x => !x.chatOperator);
            const initialParticipantId = (initialParticipant && initialParticipant.participantId.toString()) || null;

            this.initialMessage = conversation.messages.splice(0, 1)[0];
            this.customerAvatarUrl = this.photoUrlService.photoUrl(conversation.customerId, 100, 100);
            this.operatorAvatarUrl = this.photoUrlService.photoUrl(conversation.customerId, 100, 100);
            this.chatConversation = conversation;
            
            this.chatConversation.messages.forEach(x => {
                x.participantId.toString() == initialParticipantId ? 
                    x.direction = 'ToOperator' : x.direction = 'toCustomer';
            });
        }
    }
}
