﻿/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Scope.d.ts"/>

namespace Umbrella.Modules.Housing.Units {
    export interface IUnitResource {
        getById(params: IdRequestParams): ng.resource.IResource<UnitModel>;
        getAllByComplexId(params: IdRequestParams): ng.resource.IResource<UnitModel.WithCustomerAndContract[]>;
        getByContractId(params: IdRequestParams): ng.resource.IResource<UnitModel.Extended>;
        search(params: PagedRequestWithIdParams): ng.resource.IResource<PagedItemsModel<UnitModel>>;
        getCollectiveObjectsByCode(params: {id: System.Guid, code: string}): ng.resource.IResource<CollectiveObjectModel[]>;
    }

    angular.module('Housing').service('UnitResource', [
        '$resource',
        $resource => {
            return $resource(
                '/api/v1/unit/:action/:subaction/:id',
                {},
                {
                    getById: {
                        method: 'GET',
                        isArray: false,
                        params: { id: '@id' }
                    },
                    getAllByComplexId: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'byComplex', id: '@id' }
                    },
                    getByContractId: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'active', subaction: 'byContract', id: '@id' }
                    },
                    search: {
                        method: 'GET',
                        isArray: false,
                        params: { action: 'search', id: '@query' }
                    },
                    getCollectiveObjectsByCode: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'collectiveobjectsbycode', id: '@id' }
                    }
                }
            );
        }
    ]);
}
